import React from 'react';
import { InputText, InputSelect } from './';
import { FilterVisual, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface VisualFilterProps {
    filter?: FilterVisual,
    prepared: SelectOption[],
    assigned: SelectOption[],
    sections: SelectOption[],
    priority: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterVisual) => void
}

interface State {
    name?: string,
    prepared?: string,
    assigned?: string,
    section?: string,
    priority?: string,
}

type VisualFilterPropsSum = VisualFilterProps & WithTranslation;

class VisualFilterComponent extends React.Component<VisualFilterPropsSum, State> {

    constructor(props: VisualFilterPropsSum) {
        super(props);

        this.state = {
            name: props.filter?.name,
            prepared: props.filter?.prepared,
            assigned: props.filter?.assigned,
            section: props.filter?.section,
            priority: props.filter?.priority,
        };
    }

    render () {
        const { t } = this.props;
        return (
            <div className="filter-content filter-content--show">
                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_visuals') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={() => this.props.onClose()}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="name" label={ t('name') } value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('states') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="prepared" label={ t('prepared') } value={this.state.prepared} emptyValue={true} options={this.props.prepared} onChange={(value) => this.setState({ prepared: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="assigned" label={ t('assigned') } value={this.state.assigned} emptyValue={true} options={this.props.assigned} onChange={(value) => this.setState({ assigned: value || '' })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('types') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="section" label={ t('section') } value={this.state.section} emptyValue={true} options={this.props.sections} onChange={(value) => this.setState({ section: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="priority" label={ t('priority') } value={this.state.priority} emptyValue={true} options={this.props.priority} onChange={(value) => this.setState({ priority: value || '' })} />
                        </div>
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.props.onSubmit(this.state)}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>
            </div>
        );
    };
}

const VisualFilter = withTranslation()(VisualFilterComponent);
export { VisualFilter };
