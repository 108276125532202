import {
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_CAMPAIGNS,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_SUPPORT,
    OrderAssignVisualActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, FilterCampaignAndVisual, Support } from "../../../interfaces/interfaces";

export interface OrderAssignVisualState {
    loading: boolean,
    filter: FilterCampaignAndVisual,
    campaigns: Campaign[],
    assignationType: string,
    support: Support,
}

const INITIAL_STATE: OrderAssignVisualState = {
    loading: false,
    filter: new FilterCampaignAndVisual(),
    campaigns: [],
    assignationType: '',
    support: {},
};

const reducer: Reducer<OrderAssignVisualState, OrderAssignVisualActions> = (state = INITIAL_STATE, action: OrderAssignVisualActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterCampaignAndVisual() };
        case UPDATE_CAMPAIGNS:
            return { ...state, campaigns: action.payload };
        case UPDATE_ASSIGNATION_TYPE:
            return { ...state, assignationType: action.payload };
        case UPDATE_SUPPORT:
            return { ...state, support: action.payload };
        default:
            return state;
    }
}

export default reducer;