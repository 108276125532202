import React from 'react';
import { InputText, InputSelect } from '.';
import { SelectOption, FilterSupport } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SupportFilterListProps {
    filter?: FilterSupport,
    supportTypes: SelectOption[],
    storeTypes: SelectOption[],
    supportSections: SelectOption[],
    supportPriorities: SelectOption[],
    supportProductionLanguages: SelectOption[],
    textilTypes: SelectOption[],
    countries: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterSupport) => void
}

interface State {
    no_soporte_cliente?: string,
    text?: string,
    width?: string,
    height?: string,
    depth?: string,
    focused: boolean|null,
    city?: string,
    country?: string,
    support_type?: string,
    store_type?:string,
    section?:string,
    priority?:string,
    production_language?:string,
    textil_type?: string,
    store_id?: string,
    store_text?: string,
}

type SupportFilterListPropsSum = SupportFilterListProps & WithTranslation;

class SupportFilterListComponent extends React.Component<SupportFilterListPropsSum, State> {

    constructor(props: SupportFilterListPropsSum) {
        super(props);

        this.state = {
            no_soporte_cliente: props.filter?.no_soporte_cliente,
            text: props.filter?.text,
            focused: null,
            city: props.filter?.city,
            country: props.filter?.country,
            width: props.filter?.width,
            height: props.filter?.height,
            depth: props.filter?.depth,
            support_type: props.filter?.support_type,
            store_type: props.filter?.store_type,
            section: props.filter?.section,
            priority: props.filter?.priority,
            production_language: props.filter?.production_language,
            textil_type: props.filter?.textil_type,
            store_id: props.filter?.store_id,
            store_text: props.filter?.store_text,
        };
    }

    render () {
        const { t } = this.props;
        return (
            <div className="filter-content filter-content--show">

                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_supports') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={()=> {this.props.onClose()}}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="id" label={ t('id') } value={this.state.no_soporte_cliente} onChange={(e) => this.setState({ no_soporte_cliente: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="text" label={ t('name') } value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('type') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="support-type" label={ t('support_type') } value={this.state.support_type} emptyValue={true} options={this.props.supportTypes} onChange={(value) => this.setState({ support_type: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputText id="text" label={ t('width') } value={this.state.width} onChange={(e) => this.setState({ width: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="text" label={ t('height') } value={this.state.height} onChange={(e) => this.setState({ height: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="text" label={ t('depth') } value={this.state.depth} onChange={(e) => this.setState({ depth: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="section" label={ t('section') } value={this.state.section} emptyValue={true} options={this.props.supportSections} onChange={(value) => this.setState({ section: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="priority" label={ t('priority') } value={this.state.priority} emptyValue={true} options={this.props.supportPriorities} onChange={(value) => this.setState({ priority: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="production-language" label={ t('production_language') } value={this.state.production_language} emptyValue={true} options={this.props.supportProductionLanguages} onChange={(value) => this.setState({ production_language: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="textil-type" label={ t('textil_type') } value={this.state.textil_type} emptyValue={true} options={this.props.textilTypes} onChange={(value) => this.setState({ textil_type: value || '' })} />
                            {/* <InputText id="textil-type" label={ t('textil_type') } value={this.state.textil_type} onChange={(e) => this.setState({ textil_type: e.target.value })} /> */}
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('store') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="store-id" label={ t('store_id') } value={this.state.store_id} onChange={(e) => this.setState({ store_id: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="store-name" label={ t('store_name') } value={this.state.store_text} onChange={(e) => this.setState({ store_text: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="store-type" label={ t('store_type') } value={this.state.store_type} emptyValue={true} options={this.props.storeTypes} onChange={(value) => this.setState({ store_type: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputText id="city" label={ t('city') } value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="country" label={ t('country') } value={this.state.country} emptyValue={true} options={this.props.countries} onChange={(value) => this.setState({ country: value })} />
                        </div>
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.props.onSubmit(this.state)}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>

            </div>
        );
    };
}

const SupportFilterList = withTranslation()(SupportFilterListComponent);
export { SupportFilterList };
