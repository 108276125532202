import React from 'react';
import { InputText, InputSelect } from './';
import { FilterStore, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface StoreFilterProps {
    filter?: FilterStore,
    storeTypes: SelectOption[],
    supportTypes: SelectOption[],
    statuses: SelectOption[],
    countries: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterStore) => void
}

interface State {
    customer_shop_no?: string,
    name?: string,
    country?: string,
    region?: string,
    city?: string,
    postal_code?: string,
    store_type?: string,
    support_type?: string
    status?: string
}

type StoreFilterPropsSum = StoreFilterProps & WithTranslation;

class StoreFilterComponent extends React.Component<StoreFilterPropsSum, State> {

    constructor(props: StoreFilterPropsSum) {
        super(props);

        this.state = {
            customer_shop_no: props.filter?.customer_shop_no,
            name: props.filter?.name,
            country: props.filter?.country,
            region: props.filter?.region,
            city: props.filter?.city,
            postal_code: props.filter?.postal_code,
            store_type: props.filter?.store_type,
            support_type: props.filter?.support_type,
            status: props.filter?.status
        };
    }


    render () {
        const { t } = this.props;

        return (
            <div className="filter-content filter-content--show">
                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_stores') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={() => this.props.onClose()}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="id" label={ t('id') } value={this.state.customer_shop_no} onChange={(e) => this.setState({ customer_shop_no: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="name" label={ t('name') } value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="store-type" label={ t('store_type') } value={this.state.store_type} emptyValue={true} options={this.props.storeTypes} onChange={(value) => this.setState({ store_type: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="store-status" label={ t('store_status') } value={this.state.status} emptyValue={true} options={this.props.statuses} onChange={(value) => this.setState({ status: value || '' })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('localization') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="city" label={ t('city') } value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="country" label={ t('country') } value={this.state.country} emptyValue={true} options={this.props.countries} onChange={(value) => this.setState({ country: value })} />
                        </div>
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.props.onSubmit(this.state)}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>
            </div>
        );
    };
}

const StoreFilter = withTranslation()(StoreFilterComponent);
export { StoreFilter };
