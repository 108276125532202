import {
    UPDATE_SUPPORTS,
    APPEND_SUPPORTS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    UPDATE_FILTER_SHOW,
    CLEAR_FILTER,
    SupportIndexActions
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Support, FilterSupport } from '../../../interfaces/interfaces';

export interface SupportIndexState {
    loading: boolean,
    filterShow: boolean,
    supports: Support[],
    filter: FilterSupport,
}

const INITIAL_STATE: SupportIndexState = {
    loading: false,
    filterShow: false,
    supports: [],
    filter: new FilterSupport(),
};

const reducer: Reducer<SupportIndexState, SupportIndexActions> = (state = INITIAL_STATE, action: SupportIndexActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER_SHOW:
            return { ...state, filterShow: action.payload };
        case UPDATE_SUPPORTS:
            return { ...state, supports: action.payload };
        case APPEND_SUPPORTS:
            return { ...state, supports: [ ...state.supports, ...action.payload ] };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterSupport() }
        default:
            return state;
    }
}

export default reducer;