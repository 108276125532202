import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
    updateUser,
    updateUserInfoShow,
    updateUserEditModalShow,
} from './UserDetailActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumb, CollapsedArrow, ProfileAddModal, Spinner, BackArrow } from '../../../components';
import { StoreStatus, User } from '../../../interfaces/interfaces';
import { edit_user, upload_profile_image } from '../../../api';
import cookies from '../../../util/cookies';
import { statusPillClass, statusName} from '../../../util/store';



const mapStateToProps = (state: RootState) => {
    return {
        user_detail_state: state.userDetail,
        datatypes: state.datatypes,
    }
}

const connector = connect(mapStateToProps, { 
    updateUser,
    updateUserInfoShow,
    updateUserEditModalShow,
    edit_user,
    upload_profile_image,
});

interface ParamTypes {
	id: string
}
type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Detail extends React.Component<Props> {

    componentDidMount() {
        this.props.updateUser(this.props.match.params.id);
    }

    getCurrentLanguage() {
        return cookies.get('b2b-kendu-language');
    }

    renderUserInformation() {
        const { t } = this.props;
        const { user, userInfoShow } = this.props.user_detail_state;
        if (userInfoShow) {
            return (
                <ul id="info-card">
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('name_and_lastname') }</h3>
                        <p className="info-row-data text-2--regular">{user?.name} {user?.last_name}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('role') }</h3>
                        <p className="info-row-data text-2--regular">{user?.role}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('contact') }</h3>
                        <p className="info-row-data text-2--regular">{user?.email}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('phone') }</h3>
                        <p className="info-row-data text-2--regular">{user?.phone}</p>
                    </li>
                </ul>
            );
        }
    }

    renderEditUserModal() {
        const { userEditModalShow, user } = this.props.user_detail_state;
        if (userEditModalShow) {
            return (
                <ProfileAddModal
                    user={user||undefined}
                    onClose={() => this.props.updateUserEditModalShow(!userEditModalShow)}
                    onSubmit={(user: User) => {
                        edit_user(user).then(() => {
                            this.props.updateUserEditModalShow(!userEditModalShow);
							this.props.updateUser(user.id);
                        }).catch(err => console.error);
                    }}
                />
            );
        }
    }


    render () {
        const { t } = this.props;
        const { loading, userInfoShow, userEditModalShow, user } = this.props.user_detail_state;
        if (loading) {
            return (
                <div className="base-content">
                    <div className="content">
                        <Spinner message={ t('loading_profile') } />
                    </div>
                </div>
            );
        }
        return (
            <div className="base-content">
                {this.renderEditUserModal()}
                <div className="content">
                    <div className="container">

                        <Breadcrumb items={[{ text: t('my_data') }]}/>

                        <div className="section-header">
                            <div className="section-header-left">
                            <BackArrow onClick={() => this.props.history.goBack()} />
                                <h1 className="headline-1--regular">{user?.name} {user?.last_name}</h1>
                            </div>
                            <div className="section-header-right">
                                <button className="btn--secondary btn--l pop-reveal" onClick={() => this.props.updateUserEditModalShow(!userEditModalShow)}><i className="icon icon-16-edit"></i>{ t('edit') }</button>
                            </div>
                        </div>

                        <div className="basic-card basic-card--32 info-card">

                            <div className="card-header">
                                <div className="card-header-left">
                                    <h2 className="headline-3--semibold">{ t('user_information') }</h2>
                                </div>
                                <div className="class-header-right">
                                    <CollapsedArrow collapsed={userInfoShow} clicked={() => this.props.updateUserInfoShow(!userInfoShow)}/>
                                </div>
                            </div>
                            {this.renderUserInformation()}
                        </div>


						<div className="basic-card basic-card--32 info-card">

							<div className="card-header">
								<div className="card-header-left">
									<h2 className="headline-3--semibold">{ t('stores') }</h2>
								</div>
								<div className="class-header-right">
                                    <Link to={`/users/${user?.id}/add_store`} className="btn--primary btn--m" >{ t('add_store') }</Link>  
								</div>
							</div>

							<ul id="shops_list">
                                { (user?.stores||[]).length === 0 &&
                                    <div className="alert alert--info">
                                        <p className="text-2--regular">{ t('no_stores_assigned_2_user') }</p>
                                    </div>
                                }
                                { (user?.stores||[]).map((store) => (
                                    <li key={store.id}>
                                        <section className="shop_detail">
                                            <h4>{store.name}<span> #{store.customer_shop_no}</span></h4>
                                            <p>{store.tipo_tienda}<span> {store.ciudad} ({store.pais})</span></p>
                                        </section>
                                        <section className="shop_state">
                                            <p className={'text-3--semibold status-pill ' + statusPillClass(store?.status as StoreStatus)}>{statusName(store?.status as StoreStatus, t)}</p>
                                        </section>
                                    </li>
                                ))}
							</ul>

						</div>


                    </div>

                </div>

            </div>
        );
    }
}

export default withTranslation()(connector(Detail));

