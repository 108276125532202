import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, SelectOption, FilterStore, Visual, AssignedVisuals } from '../../../interfaces/interfaces';

export const UPDATE_CAMPAIGN = 'update_campaign';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SHOW_FILTER = 'update_show_filter_massive_edition';
export const UPDATE_VISUAL = 'UPDATE_VISUAL';
export const UPDATE_VISUALS_LIST = 'UPDATE_VISUALS_LIST';
export const APPEND_VISUALS_LIST = 'APPEND_VISUALS_LIST';
export const UPDATE_FILTER = 'update_filter_massive_edition';
export const CLEAR_FILTER = 'clear_filter';
export const UPDATE_SAVEING = 'UPDATE_SAVEING';
export const UPDATE_SAVE_OK = 'UPDATE_SAVE_OK';
export const UPDATE_SAVE_ERROR = 'UPDATE_SAVE_ERROR';
export const UPDATE_ASSIGNATION_TYPE = 'UPDATE_ASSIGNATION_TYPE';
export const UPDATE_ASSIGNED_VISUALS = 'UPDATE_ASSIGNED_VISUALS';
export const UPDATE_PREPARED = 'UPDATE_PREPARED';
export const UPDATE_ASSIGNED = 'UPDATE_ASSIGNED';

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateVisualAction {
    type: typeof UPDATE_VISUAL,
    payload: Visual
}

export interface UpdateShowFilterAction {
    type: typeof UPDATE_SHOW_FILTER,
    payload: boolean
}

export interface UpdateVisualsListAction {
    type: typeof UPDATE_VISUALS_LIST,
    payload: { visual: Visual, hasChanges: boolean }[]
}

export interface AppendVisualsListAction {
    type: typeof APPEND_VISUALS_LIST,
    payload: { visual: Visual, hasChanges: boolean }[]
}

export interface UpdateFilter {
    type: typeof UPDATE_FILTER,
    payload: FilterStore
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateSaveingAction {
    type: typeof UPDATE_SAVEING,
    payload: boolean
}

export interface UpdateSaveOkAction {
    type: typeof UPDATE_SAVE_OK,
    payload: boolean
}

export interface UpdateSaveErrorAction {
    type: typeof UPDATE_SAVE_ERROR,
    payload: boolean
}

export interface UpdateAssignationTypeAction {
    type: typeof UPDATE_ASSIGNATION_TYPE,
    payload: string
}

export interface UpdateAssignedVisualsAction {
    type: typeof UPDATE_ASSIGNED_VISUALS,
    payload: AssignedVisuals
}

export interface UpdatePreparedAction {
    type: typeof UPDATE_PREPARED,
    payload: SelectOption[]
}

export interface UpdateAssignedAction {
    type: typeof UPDATE_ASSIGNED,
    payload: SelectOption[]
}

export type CampaignMassiveEditionActions =
UpdateCampaignAction |
UpdateLoadingAction |
UpdateVisualAction |
UpdateShowFilterAction |
UpdateVisualsListAction |
AppendVisualsListAction |
UpdateFilter |
ClearFilterAction |
UpdateSaveingAction |
UpdateSaveOkAction |
UpdateSaveErrorAction |
UpdateAssignationTypeAction |
UpdateAssignedVisualsAction |
UpdatePreparedAction |
UpdateAssignedAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
