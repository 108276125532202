import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputCheckBoxProps {
    id: string,
    checked: boolean,
    checkBoxChange: (value: boolean) => void
}

type InputCheckBoxPropsSum = InputCheckBoxProps & WithTranslation;

class InputCheckBoxComponent extends React.Component<InputCheckBoxPropsSum> {

    render () {
        return (
            <div className="check-row check-row--big">
                <label className="checkbox-element">
                    <input type="checkbox" onChange={(event) => this.props.checkBoxChange(event.target.checked)} checked={this.props.checked}/>
                    <span className="checkmark"></span>
                </label>
            </div>
        );
    };
}

const InputCheckBox = withTranslation()(InputCheckBoxComponent);
export { InputCheckBox };
