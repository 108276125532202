import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputTextAreaProps {
    id: string,
    label: string,
    value?: string,
    disabled?: boolean,
    helpText?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

type InputTextAreaPropsSum = InputTextAreaProps & WithTranslation;

class InputTextAreaComponent extends React.Component<InputTextAreaPropsSum> {

    render () {
        return (
            <TextField
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                error={!!this.props.helpText} helperText={this.props.helpText}
            />
        );
    };
}

const InputTextArea = withTranslation()(InputTextAreaComponent);
export { InputTextArea };
