import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../reducers';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export interface UpdateTokenAction {
    type: typeof UPDATE_TOKEN,
    payload: string
}

export type AuthActions =
UpdateTokenAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
