import React from 'react';
import { Link } from "react-router-dom";
import { SelectOption, Support } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportDetail } from '.';

export interface SupportCardProps {
    support: Support,
    production_languages: SelectOption[],
}

type SupportCardPropsSum = SupportCardProps & WithTranslation;

class SupportCardComponent extends React.Component<SupportCardPropsSum> {

    cardLink(id: string|undefined) {
        return id === undefined ? '#' : `/supports/${id}`;
    }

    render () {
        const { support, production_languages } = this.props;
        const { id, support_type } = this.props.support;
        return (
            <Link to={this.cardLink(id)} key={id} className="basic-card basic-card--16 generic-card">
                <div className="generic-card-left">
                    <div className="card-img card-img--64" style={{ backgroundImage: `url(${support.visual_url ?? '/img/img-hardware.png'})` }}></div>
                    <div className="generic-title-section">
                        <p className="headline-2--regular">{support_type}</p>
                        <p className="text-3--regular generic-subtitle"><SupportDetail support={support} production_languages={production_languages} /></p>
                    </div>
                </div>
            </Link>
        );
    };
}

const SupportCard = withTranslation()(SupportCardComponent);
export { SupportCard };
