import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SHOW_FILTER,
    UPDATE_FILTER,
    UPDATE_SELECTED_SUPPORTS,
    APPEND_SELECTED_SUPPORTS,
    REMOVE_SELECTED_SUPPORTS,
    CLEAR_FILTER,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_SHOW_CONFIRM_MODAL,
    UPDATE_SUPPORT_STATES,
    CampaignSummaryActions,
    AppThunk
} from './ActionTypes';
import {
    one_campaign,
    get_stores_with_supports_campaign,
    get_selected_supports,
    get_assigned_images
} from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { FilterStore } from '../../../interfaces/interfaces';
import { assignedSelect } from '../../../util/visual';

export const updateShowFilter = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_FILTER,
            payload: value
        } as CampaignSummaryActions);
    }
}

export const getStores = (campaignId: string, lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignSummaryActions);

        const stores = await get_stores_with_supports_campaign(campaignId, {
            ...getState().campaignSummary.filter,
            key: lastKey,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as CampaignSummaryActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignSummaryActions);
    }
}

export const oneCampaign = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignSummaryActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignSummaryActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignSummaryActions);
    }
}

export const updateFilter = (value: FilterStore): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as CampaignSummaryActions);
    }
}

export const updateSelectedSupports = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignSummaryActions);

        const supports = await get_selected_supports(id);

        dispatch({
            type: UPDATE_SELECTED_SUPPORTS,
            payload: supports
        } as CampaignSummaryActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignSummaryActions);
    }
}

export const addSelectedSupports = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: APPEND_SELECTED_SUPPORTS,
            payload: ids
        } as CampaignSummaryActions);
    }
}

export const removeSelectedSupports = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: REMOVE_SELECTED_SUPPORTS,
            payload: ids
        } as CampaignSummaryActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as CampaignSummaryActions);
    }
}

export const getAssignedImages = (id: string): AppThunk => {
    return async dispatch => {
        const assignedImages = await get_assigned_images(id);

        dispatch({
            type: UPDATE_ASSIGNED_VISUALS,
            payload: assignedImages
        } as CampaignSummaryActions);
    }
}

export const updateShowConfirmModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_CONFIRM_MODAL,
            payload: value
        } as CampaignSummaryActions);
    }
}

export const updateSupportStates = (t: any): AppThunk => {
    return async dispatch => {
        let assigned = assignedSelect(t);
        dispatch({
            type: UPDATE_SUPPORT_STATES,
            payload: assigned
        } as CampaignSummaryActions);
    }
}