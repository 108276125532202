import axios from 'axios';
import { Campaign, Support, Store, User, Visual, Client } from '../interfaces/interfaces';
import { with_auth, with_auth_check } from './helper';
import { FilterCampaignRequest, FilterStoreRequest, FilterSupportRequest, FilterVisualRequest, FilterCampaignAndVisualRequest, FilterOrderRequest } from './interfaces';

export * from './datatypes';

export const one_client = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/customers/with_stats`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return null;
    }));
}

export const get_campaigns = (params: FilterCampaignRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return [];
    }));
}

export const get_campaigns_count = (params: FilterCampaignRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/count`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const get_campaigns_filtered = (params: FilterCampaignRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/filter`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const one_campaign = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return null;
    }));
}

export const create_campaign = (campaign: Campaign) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/add`, campaign, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return false;
    }));
}

export const edit_campaign = (campaign: Campaign) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/edit`, campaign, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_campaign = (id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/campaigns/${encodeURIComponent(id)}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_support = (id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/hardwares/${encodeURIComponent(id)}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_stores = (params: FilterStoreRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const get_stores_with_supports = (params: FilterStoreRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/with_supports`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const get_stores_with_supports_campaign = (campaign_id: string, params: FilterStoreRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/with_supports_campaign/${campaign_id}`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return [];
    }));
}

export const one_store = (id: string, customer_id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stores/with_supports/${customer_id}/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403) {
            window.location.href = "/stores";
        }
        return null;
    }));
}

export const create_store = (store: Store) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/add`, store, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_store = (store: Store) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/stores/edit`, store, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_supports = (params: FilterSupportRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares`, with_auth({ params: params }))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const one_support = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/hardwares/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/supports";
        }
        return null;
    }));
}

export const create_support = (support: Support) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/add`, support, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_support = (store: Support) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/edit`, store, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const create_section = (sectionName: string, store_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/section/add`, { sectionName: sectionName, store_id: store_id }, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_selected_supports = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/supports/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return [];
    }));
}

export const get_assigned_images = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/assigned_images/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return {};
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return {};
    }));
}

export const get_visuals = (id: string, params: FilterVisualRequest) => {
   return axios.get(`${process.env.REACT_APP_API_URL}/visuals/${id}`, with_auth({params:params}))
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return {};
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return {};
    }));
}

export const select_campaign_supports = (campaign_id: string, support_ids: string[]) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/select_supports/${campaign_id}`, support_ids, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}

export const delete_campaign_support = (campaign_id: string, support_id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/campaigns/support/${campaign_id}/${support_id}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}

export const upload_image = (campaign_id: string, images: File[]) => {
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
        formData.append(`image`, images[i]);
    }

    return axios.post(`${process.env.REACT_APP_API_URL}/campaigns/upload_images/${campaign_id}`, formData, with_auth({ headers: { 'Content-type': 'multipart/form-data' } }))
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}

export const edit_visuals = (visuals: Visual[]) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/visuals/update`, visuals, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const delete_visual = (id: string, campaign_id: string) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/visuals/${encodeURIComponent(id)}/${encodeURIComponent(campaign_id)}`, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}

export const assign_visual_2_support = (campaign_id: string, support_id: string, visual_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/assign/${support_id}`, {campaign_id: campaign_id, visual_id: visual_id}, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}

export const delete_visual_2_support = (campaign_id: string, support_id: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/hardwares/unassign/${support_id}`, {campaign_id: campaign_id}, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return false;
    }));
}



export const get_languages = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/languages`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const edit_language = (language_id: string) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/users/language/${language_id}`, null, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_clients = (): Promise<Client[]> => {
    return axios.get(`${process.env.REACT_APP_API_URL}/customers`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data as Client[];
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const login = (user: string, password: string) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { username: user, password: password })
    .then(response => {
        if (response.status === 201) {
            return response.data.access_token;
        }
        return null;
    })
    .catch(error => {
        console.error(error);
        return null;
    });
}

export const get_profile = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/me`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const create_profile = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/add`, user, with_auth()).then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    }).catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const edit_profile = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/update`, user, with_auth()).then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    }).catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const upload_profile_image = (image: File | null) => {
    const formData = new FormData();
    if (image) {
        formData.append(`image`, image);
    }
    return axios.put(`${process.env.REACT_APP_API_URL}/users/photo`, formData, with_auth({ headers: { 'Content-type': 'multipart/form-data' } }))
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const assign_automatic = (campaignId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/assign_automatic/${campaignId}`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return [];
    }));
}
export const unassign_all = (campaignId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/unassign_all/${campaignId}`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return [];
    }));
}


export const launch_campaign = (campaignId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/launch/${campaignId}`, with_auth()).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/campaigns";
        }
        return [];
    }));
}

export const get_users = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const edit_user = (user: User) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/users/edit`, user, with_auth()).then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    }).catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_user = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const select_user_stores = (user_id: string, stores_ids: string[]) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/users/assign-stores/${user_id}`, stores_ids, with_auth())
    .then(response => {
        if (response.status === 201) {
            return true;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        return false;
    }));
}

export const get_available_visuals = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/orders/campaigns`, with_auth()).then(response => {
        if (response.status === 200) {
            const result = response.data.map(function(element: Campaign) { return element.images }).flat();
            return result
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const get_orders = (params: FilterOrderRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/orders`, with_auth({ params: params })).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const create_order = () => {
    return axios.post(`${process.env.REACT_APP_API_URL}/orders/add`, null, with_auth())
    .then(response => {
        if (response.status === 201) {
            return response.data.No;
        }
        return false;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return false;
    }));
}

export const get_order_campaigns = (params?: FilterCampaignAndVisualRequest) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/orders/campaigns`, with_auth({ params: params })).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}

export const one_order = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/orders/detail/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/orders";
        }
        return null;
    }));
}

export const calculate_prices = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/campaigns/calculate_prices/${id}`, with_auth())
    .then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return null;
    })
    .catch(with_auth_check(error => {
        console.error(error);
        if (error.response?.status === 403 || error.response?.status === 404) {
            window.location.href = "/";
        }
        return null;
    }));
}