import {
    UPDATE_SUPPORT,
    UPDATE_LOADING,
    UPDATE_SUPPORT_INFO_SHOW,
    UPDATE_SUPPORT_ADD_MODAL_SHOW,
    SupportDetailActions,
    AppThunk
} from './ActionTypes';
import { one_support } from '../../../api';

export const updateSupport = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as SupportDetailActions);

        const support = await one_support(id);

        dispatch({
            type: UPDATE_SUPPORT,
            payload: support
        } as SupportDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as SupportDetailActions);
    }
}

export const updateSupportInfoShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SUPPORT_INFO_SHOW,
            payload: value
        } as SupportDetailActions);
    }
}

export const updateSupportAddModalShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SUPPORT_ADD_MODAL_SHOW,
            payload: value
        } as SupportDetailActions);
    }
}