import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
	updateShowFilter,
	loadData,
	updateVisualsList,
	updateVisual,
	saveVisuals,
	updateSaveOk,
	updateSaveError,
	updateFilter,
	clearFilter,
	getVisuals,
	getAssignationType,
	getAssignedImages,
} from './CampaignMassiveEditionActions';
import { Spinner, Breadcrumb, BackArrow, VisualEdit, SaveingModal, VisualFilter } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { Visual } from '../../../interfaces/interfaces';
import Alert from '@material-ui/lab/Alert';
import { is_ready } from '../../../util/visual';
import { is_blocked } from '../../../util/campaign';
import { section_map } from '../../../util/select_maps';
import { getSections, getPriorities, getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { filter_style } from '../../../util/filter';


const mapStateToProps = (state: RootState) => {
	return {
		campaign_massive_edition_state: state.campaignMassiveEdition,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateShowFilter,
	loadData,
	updateVisualsList,
	updateVisual,
	saveVisuals,
	updateSaveOk,
	updateSaveError,
	updateFilter,
	clearFilter,
	getVisuals,
	getAssignationType,
	getAssignedImages,
	getProductionLanguages,
	getSections,
	getPriorities,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class MassiveEdition extends React.Component<Props> {

	componentDidMount() {
		const { t } = this.props;
		this.props.loadData(this.props.match.params.id, t);
		this.props.getVisuals(this.props.match.params.id);
		this.props.updateSaveOk(false);
		this.props.updateSaveError(false);
		this.props.getAssignationType();
		this.props.getAssignedImages(this.props.match.params.id);
		this.props.getProductionLanguages();
		this.props.getSections(); 
		this.props.getPriorities();
	}

	saveChanges() {
		const { visualsList }  = this.props.campaign_massive_edition_state;
		this.props.saveVisuals(visualsList.filter(object => object.hasChanges === true).map(object => object.visual));
	}

	renderVisualCount() {
		const { t } = this.props;
		const { visualsList, assignationType } = this.props.campaign_massive_edition_state;
        const count = visualsList.length||0;
		const readyCount = visualsList.filter(object => is_ready(object.visual, assignationType)).length;
		const notReadyCount = visualsList.filter(object => !is_ready(object.visual, assignationType)).length;

        return (
            <p style={{marginRight: 20, color: count === 0 ? 'rgba(0, 0, 0, 0.2)' : 'inherit'}}>
				{ readyCount } { t('ready') } / { notReadyCount } { t('not_ready') }
			</p>
        );
	}

	renderVisual(visual: Visual) {
		const { assignedImages, assignationType } = this.props.campaign_massive_edition_state;
		const { sections, priorities, production_languages } = this.props.datatypes;
        return (
            <VisualEdit
                visual={visual}
                sections={sections.map(section_map)}
				priorities={priorities}
				production_languages={production_languages}
				assignedImages={assignedImages}
				assignationType={assignationType}
                onChange={(value: Visual) => {
					this.props.updateVisual(value);
                }}
            />
        );
	}

	renderSaveing() {
		const { t } = this.props;
		if (this.props.campaign_massive_edition_state.saveing) {
			return (
				<SaveingModal message={t('saveing_visuals')} />
			);
		}
	}

	renderSaveOk() {
		const { t } = this.props;
		if (this.props.campaign_massive_edition_state.saveOk) {
			return (
				<div className="store-hardware-content">
					<Alert severity="success">{t('visuals_has_been_saved_successfully')}</Alert>
				</div>
			);
		}
	}

	renderSaveError() {
		const { t } = this.props;
		if (this.props.campaign_massive_edition_state.saveError) {
			return (
				<div className="store-hardware-content">
					<Alert severity="error">{t('an_error_occurred_saveing_the_visuals')}</Alert>
				</div>
			);
		}
	}

	renderFilter() {
		const { t } = this.props;
		if (this.props.campaign_massive_edition_state.showFilter) {
			return (
				<VisualFilter
					filter={this.props.campaign_massive_edition_state.filter}
					prepared={this.props.campaign_massive_edition_state.prepared}
					assigned={this.props.campaign_massive_edition_state.assigned}
					sections={this.props.datatypes.sections.map(section_map)}
					priority={this.props.datatypes.priorities}
					onClose={() => this.props.updateShowFilter(!this.props.campaign_massive_edition_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateShowFilter(!this.props.campaign_massive_edition_state.showFilter);
						this.props.getVisuals(this.props.match.params.id);
					}}
					onSubmit={(filter) => {
						let next = true;
						if (!this.props.campaign_massive_edition_state.visualsList.every(object => object.hasChanges === false)) {
							if (!window.confirm(t('not_saved_if_continue_all_changes_are_discard'))) {
								next = false;
							}
						}
						if (next) {
							this.props.updateFilter(filter);
							this.props.updateShowFilter(!this.props.campaign_massive_edition_state.showFilter);
							this.props.getVisuals(this.props.match.params.id);
						}
					}}
				/>
			);
		}
	}

	loadMoreVisuals() {
		this.props.getVisuals(this.props.match.params.id);
	}

	render () {
		const { campaign, loading } = this.props.campaign_massive_edition_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_visuals') } />
					</div>
				</div>
			);
		}
		return (
			<div className="base-content">
				{this.renderSaveing()}
				{this.renderFilter()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Campañas', url: '/campaigns' }, { text: `${campaign?.title}`, url: `/campaigns/${campaign?.id}` }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<BackArrow onClick={() => this.props.history.goBack()} />
								<h1 className="headline-1--regular section-header-title">{ t('massive_edition') }</h1>
							</div>
							<div className="section-header-right">
								<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_massive_edition_state.filter)} onClick={() => this.props.updateShowFilter(!this.props.campaign_massive_edition_state.showFilter)}></button>
								{ is_blocked(campaign) &&
									<button className="btn--primary btn--l pop-reveal btn--disabled">{ t('save') }</button>}
								{ !is_blocked(campaign) &&
									<button className="btn--primary btn--l pop-reveal" onClick={() => this.saveChanges()}>{ t('save') }</button>}

							</div>
						</div>
						<div className="basic-card basic-card--32 store-card">
							<div className="store-card--view">
								<div className="store-header">
									<div className="campaing-card-left">
										<p className="headline-3--regular">{ t('images') }</p>
										<p className="text-3--regular store-id">{this.renderVisualCount()}</p>
									</div>
								</div>
								{this.renderSaveOk()}
								{this.renderSaveError()}
								<div className="store-hardware-content">
									{(this.props.campaign_massive_edition_state.visualsList || []).map((object, index) => (
										<React.Fragment key={index}>
											{this.renderVisual(object.visual)}
										</React.Fragment>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(MassiveEdition));
