import React from 'react';
import { Support, AssignedVisuals } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VisualPreview } from '.';
import { VisualImage } from './VisualImage';

export interface SupportCardSummaryProps {
    support: Support,
    assignedImages?: AssignedVisuals
}

interface SupportCardSummaryState {
    showPreview: boolean,
}

type SupportCardSummaryPropsSum = SupportCardSummaryProps & WithTranslation;

class SupportCardSummaryComponent extends React.Component<SupportCardSummaryPropsSum, SupportCardSummaryState> {

    constructor(props: SupportCardSummaryPropsSum) {
        super(props);

        this.state = {
            showPreview: false,
        };
    }

    renderAssignedImage() {
        const { support, assignedImages } = this.props;

		if (assignedImages && assignedImages[support.id!] && assignedImages[support.id!].image) {
			return (
                <div className="card-img card-img--visual" onClick={(event) => event.preventDefault()}>
                    <VisualImage visual={assignedImages[support.id!]} onClick={() => this.setState({ showPreview: !this.state.showPreview })} />
                </div>
            );
		}else{
            return (<div className="card-img card-img--48" style={{backgroundImage: `url('/img/img-hardware.png')`}}></div>);
        }
    }

    renderVisualPreview () {
        const { support, assignedImages } = this.props;
		if (assignedImages && assignedImages[support.id!] && this.state.showPreview) {
			return(
				<VisualPreview visual={assignedImages[support.id!]} onClose={() => this.setState({ showPreview: !this.state.showPreview })} />
			);
		}
	}

    isAssigned(support_id: string) {
        return this.props.assignedImages && this.props.assignedImages[support_id] && this.props.assignedImages[support_id].image;
    }

    render () {
        const { id, name, width, height, no_soporte_cliente } = this.props.support;
        const { t } = this.props;
        return (
            <>
                {this.renderVisualPreview()}
                <div className="store-row--hardware-view">
                    <div className="hardware-row-left">
                        {this.renderAssignedImage()}
                        <div className="hardware-info">
                            <p className="text-1--regular hardware-title">{name}</p>
                            <p className="text-3--regular hardware-subinfo">ID {no_soporte_cliente} &nbsp; &nbsp; {width}m x {height}m</p>
                        </div>
                    </div>
                    <div className="hardware-row-right">
                        {!this.isAssigned(id||'') ? <p className="text-3--semibold status-pill status-pill--not-assigned">{t('not_assigned')}</p> : ''}
                    </div>
                </div>
            </>
        );
    };
}

const SupportCardSummary = withTranslation()(SupportCardSummaryComponent);
export { SupportCardSummary };
