import {
    UPDATE_LOADING,
    UPDATE_USER,
    UPDATE_USER_INFO_SHOW,
    UPDATE_USER_EDIT_MODAL_SHOW,
    UserDetailActions,
    AppThunk,
} from './ActionTypes';
import { get_user } from '../../../api';

export const updateUser = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as UserDetailActions);

        const user = await get_user(id);

        dispatch({
            type: UPDATE_USER,
            payload: user
        } as UserDetailActions);


        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as UserDetailActions);
    }
}

export const updateUserInfoShow = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_USER_INFO_SHOW,
            payload: value
        } as UserDetailActions);
    }
}

export const updateUserEditModalShow = (value: boolean): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_USER_EDIT_MODAL_SHOW,
            payload: value
        } as UserDetailActions);
    }
}
