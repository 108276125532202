import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Store, AssignedVisuals, FilterVisual, Visual, SelectOption, FilterStoreAndSupport } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_CAMPAIGN = 'update_selected_campaign';
export const UPDATE_SELECTED_SUPPORTS = 'update_selectd_supports';
export const UPDATE_STORES = 'update_stores';
export const UPDATE_SHOW_VISUAL_ADD_MODAL = 'update_show_visual_add_modal';
export const UPDATE_ASSIGNED_VISUALS = 'update_assigned_visuals';
export const UPDATE_VISUAL_FILTER_VISIBILITY = 'update_filter_visibility_visual';
export const CLEAR_VISUAL_FILTER = 'clear_filter_visual';
export const UPDATE_VISUAL_FILTER = 'update_filter_visual';
export const UPDATE_VISUALS = 'update_visual';
export const APPEND_VISUALS = 'append_visuals';
export const UPDATE_LOADING_VISUALS = 'update_loading_visuals';
export const UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY = 'update_filter_visibility_storeAndSupport';
export const CLEAR_STORE_AND_SUPPORT_FILTER = 'clear_filter_storeAndSupport';
export const UPDATE_STORE_AND_SUPPORT_FILTER = 'update_filter_storeAndSupport';
export const UPDATE_ASSIGNATION_TYPE = 'update_assignation_type';
export const UPDATE_ASSIGNING_MODAL = 'UPDATE_ASSIGNING_MODAL';
export const UPDATE_UNASSIGNING_MODAL = 'UPDATE_UNASSIGNING_MODAL';
export const UPDATE_ASSIGNATION_OK = 'UPDATE_ASSIGNATION_OK';
export const UPDATE_ASSIGNATION_ERROR = 'UPDATE_ASSIGNATION_ERROR';
export const UPDATE_PREPARED = 'UPDATE_PREPARED';
export const UPDATE_ASSIGNED = 'UPDATE_ASSIGNED';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateSelectedSupportsAction {
    type: typeof UPDATE_SELECTED_SUPPORTS,
    payload: string[]
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface UpdateShowVsualAddModalAction {
    type: typeof UPDATE_SHOW_VISUAL_ADD_MODAL,
    payload: boolean
}

export interface UpdateAssignedVisualsAction {
    type: typeof UPDATE_ASSIGNED_VISUALS,
    payload: AssignedVisuals
}
export interface UpdateVisualFilterVisibilityAction {
    type: typeof UPDATE_VISUAL_FILTER_VISIBILITY,
    payload: boolean
}

export interface ClearVisualFilterAction {
    type: typeof CLEAR_VISUAL_FILTER,
    payload: null
}

export interface UpdateVisualFilterAction {
    type: typeof UPDATE_VISUAL_FILTER,
    payload: FilterVisual
}

export interface UpdateVisuals {
    type: typeof UPDATE_VISUALS,
    payload: Visual[]
}
export interface AppendVisuals {
    type: typeof APPEND_VISUALS,
    payload: Visual[]
}

export interface UpdateLoadingVisualsAction {
    type: typeof UPDATE_LOADING_VISUALS,
    payload: boolean
}

export interface UpdateStoreAndSupportFilterVisibilityAction {
    type: typeof UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY,
    payload: boolean
}

export interface ClearStoreAndSupportFilterAction {
    type: typeof CLEAR_STORE_AND_SUPPORT_FILTER,
    payload: null
}

export interface UpdateStoreAndSupportFilterAction {
    type: typeof UPDATE_STORE_AND_SUPPORT_FILTER,
    payload: FilterStoreAndSupport
}

export interface UpdateAssignationTypeAction {
    type: typeof UPDATE_ASSIGNATION_TYPE,
    payload: string
}

export interface UpdateAssigningModalAction {
    type: typeof UPDATE_ASSIGNING_MODAL,
    payload: boolean
}

export interface UpdateUnAssigningModalAction {
    type: typeof UPDATE_UNASSIGNING_MODAL,
    payload: boolean
}

export interface UpdateAssignationOkAction {
    type: typeof UPDATE_ASSIGNATION_OK,
    payload: boolean
}

export interface UpdateAssignationErrorAction {
    type: typeof UPDATE_ASSIGNATION_ERROR,
    payload: boolean
}

export interface UpdatePreparedAction {
    type: typeof UPDATE_PREPARED,
    payload: SelectOption[]
}

export interface UpdateAssignedAction {
    type: typeof UPDATE_ASSIGNED,
    payload: SelectOption[]
}

export type CampaignDetailActions =
UpdateLoadingAction |
UpdateCampaignAction |
UpdateSelectedSupportsAction |
UpdateStoresAction |
UpdateShowVsualAddModalAction |
UpdateAssignedVisualsAction |
UpdateVisualFilterVisibilityAction |
ClearVisualFilterAction |
UpdateVisualFilterAction |
UpdateVisuals |
AppendVisuals |
UpdateLoadingVisualsAction |
UpdateStoreAndSupportFilterVisibilityAction |
ClearStoreAndSupportFilterAction |
UpdateStoreAndSupportFilterAction |
UpdateAssignationTypeAction |
UpdateAssigningModalAction |
UpdateUnAssigningModalAction |
UpdateAssignationOkAction |
UpdateAssignationErrorAction |
UpdatePreparedAction |
UpdateAssignedAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
