import { AssignedVisuals, Support, Store } from "../interfaces/interfaces";

export const renderPrice = (price: number, fix?: number): string => {
    return `${price.toFixed(fix || 2)}€`;
}

export const calculateStoreTotal = (supports: Support[], assignedImages: AssignedVisuals): number => {
	return (supports||[]).reduce((sum, support) => 
        sum + Number(assignedImages[support.id||''].image 
                ? Number(assignedImages[support.id||''].unit_price)
                : 0)
        , 0);
}

export const calculateStoreShippingPrice = (store: Store, assignedImages: AssignedVisuals): number => {
        /*Get first shipping_price from store with an assigned image support*/
        const support_with_shipping_price = store.supports?.find((support : Support) => assignedImages && assignedImages[support.id!] && assignedImages[support.id!].image && assignedImages[support.id||''].shipping_price !== 'undefined') || undefined
        if (support_with_shipping_price)
                return Number(assignedImages[support_with_shipping_price.id!].shipping_price)
        else
                return 0
    }