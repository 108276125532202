import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
	getAssignedImages,
	updateShowConfirmModal,
	updateSupportStates,
} from './CampaignSummaryActions';
import { getStoreTypes, getSupportTypes, getCountries } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, BackArrow, SupportListSummary, ConfirmModal, StoreAndSupportFilter, OrderTimeline } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { calculate_prices, launch_campaign } from '../../../api';
import { is_blocked } from '../../../util/campaign';
import { country_map } from '../../../util/select_maps';
import Alert from '@material-ui/lab/Alert';
import { filter_style } from '../../../util/filter';
import { get_role } from '../../../util/jwt';


const mapStateToProps = (state: RootState) => {
	return {
		campaign_summary_state: state.campaignSummary,
		datatypes: state.datatypes,
		auth_state: state.auth
	}
}

const connector = connect(mapStateToProps, {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	getStoreTypes,
	getSupportTypes,
	getCountries,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
	getAssignedImages,
	updateShowConfirmModal,
	launch_campaign,
	updateSupportStates
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Summary extends React.Component<Props> {

	componentDidMount() {
		const { t } = this.props;
		this.props.getStores(this.props.match.params.id);
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getCountries();
		this.props.oneCampaign(this.props.match.params.id);
		this.props.getAssignedImages(this.props.match.params.id);
		this.props.updateSelectedSupports(this.props.match.params.id);
		this.props.updateSupportStates(t);
	}

	renderSupportFilter() {
		if (this.props.campaign_summary_state.showFilter) {
			return (
				<StoreAndSupportFilter
					filter={this.props.campaign_summary_state.filter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					countries={this.props.datatypes.countries.map(country_map)}
					supportStates={this.props.campaign_summary_state.supportStates}
					onClose={() => this.props.updateShowFilter(!this.props.campaign_summary_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateShowFilter(!this.props.campaign_summary_state.showFilter);
						this.props.getStores(this.props.match.params.id);
					}}
					onSubmit={(storeAndSupportfilter) => {
						this.props.updateFilter(storeAndSupportfilter);
						this.props.updateShowFilter(!this.props.campaign_summary_state.showFilter);
						this.props.getStores(this.props.match.params.id);
					}}
				/>
			);
		}
	}

	renderStores() {
		const { stores, loading, selectedSupports } = this.props.campaign_summary_state;
		const { t } = this.props;

		if (loading) {
			return (
				<div className="content">
					<Spinner message={ t('loading_campaign') } />
				</div>
			);
		}
		let storeList = [ ...stores ];
		storeList = storeList.filter(store => {
			return ((store.supports?.length ?? 0) > 0) && (store.supports!.some(support => selectedSupports.includes(support.id || '')))
		});
		return storeList.map((store) => (
			<div key={store.id} className="basic-card basic-card--32 store-card">
				<div className="store-card--view">
					<SupportListSummary
						id={store.id||''}
						name={store.name}
						supports={(store.supports || [])}
						selectedSupports={this.props.campaign_summary_state.selectedSupports}
						assignedImages={this.props.campaign_summary_state.assignedImages}
					/>
				</div>
			</div>
		));
	}

	renderConfirmationModal() {
		const { t } = this.props;
		const { campaign, stores, selectedSupports, assignedImages, showConfirmModal } = this.props.campaign_summary_state;
		const storeList = [ ...stores ].filter(store => {
			return ((store.supports?.length ?? 0) > 0) && (store.supports!.some(support => selectedSupports.includes(support.id || '')))
		});
		const supportsList = storeList.map(store => store.supports).flat().filter(support => { return selectedSupports.includes(support?.id || '') });
		const assignedImagesList = supportsList.filter(support => assignedImages[support?.id || '']?.image);
		if (showConfirmModal) {
			return (
				<ConfirmModal
					title={ t('summary') }
					acceptButtonText={ t('view_budget') }
					cancelButtonText={ t('cancel') }
					onClose={() => this.props.updateShowConfirmModal(!this.props.campaign_summary_state.showConfirmModal)}
					onSubmit={() => {
						calculate_prices(this.props.match.params.id).then(() => {
							window.location.href = `/campaigns/${this.props.match.params.id}/budget`;
						});
					}}
				>
					<div className="form-row-content">
						<p className="">{ t('confirmation_explanation') }</p>
					</div>

					{this.renderPendingSupportsAlert()}

					<div className="table-row-content">
						<div className="table-row">
							<div className="table-row-left">{ t('images') }</div>
							<div className="table-row-right">{ campaign?.images.length||0 }</div>
						</div>
						<div className="table-row">
							<div className="table-row-left">{ t('supports') }</div>
							<div className="table-row-right">{ supportsList.length }</div>
						</div>
						<div className="table-row">
							<div className="table-row-left">{ t('no_image_supports') }</div>
							<div className="table-row-right">{ supportsList.length - assignedImagesList.length }</div>
						</div>
					</div>
				</ConfirmModal>
			);
		}
	}

	renderPendingSupportsAlert() {
        const { t } = this.props;
		const { stores, selectedSupports, assignedImages } = this.props.campaign_summary_state;
		let storeList = [ ...stores ];
		storeList = storeList.filter(store => {
			return ((store.supports?.length ?? 0) > 0) && (store.supports!.some(support => selectedSupports.includes(support.id || '')))
		});
		let supportsList = storeList.map(store => store.supports).flat().filter(support => { return selectedSupports.includes(support?.id || '') });
		let assignedImagesList = supportsList.filter(support => assignedImages[support?.id || '']?.image);
        if (supportsList.length - assignedImagesList.length > 0) {
            return (
                <div className="form-row-content">
                    <Alert severity="warning">{ t('there_are_supports_without_visual') }</Alert>
                </div>
            );
        }
    }

	render () {
		const { campaign, loading } = this.props.campaign_summary_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_supports') } />
					</div>
				</div>
			);
		}
		return (
			<>
				<div className="base-content">
					{this.renderSupportFilter()}
					{this.renderConfirmationModal()}
					<div className="content">
						<div className="container">
							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Campañas', url: '/campaigns' }, { text: `${campaign?.title}`, url: `/campaigns/${campaign?.id}` }]}/>
							<div className="section-header">
								<div className="section-header-left">
									<BackArrow onClick={() => this.props.history.goBack()} />
									<h1 className="headline-1--regular section-header-title">{ t('campaign_summary') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_summary_state.filter)} onClick={() => this.props.updateShowFilter(!this.props.campaign_summary_state.showFilter)}></button>
									{ is_blocked(campaign) &&
										(['backdoor','admin'].includes(get_role(this.props.auth_state.token!)))
											? <button className="btn--primary btn--l pop-reveal " onClick={() => window.location.href = `/campaigns/${this.props.match.params.id}/budget`} >{ t('view_budget') }</button>
											: <button className="btn--primary btn--l pop-reveal btn--disabled">{ t('view_budget') }</button>
									}
									{ !is_blocked(campaign) &&
										<button className="btn--primary btn--l pop-reveal" onClick={() => this.props.updateShowConfirmModal(!this.props.campaign_summary_state.showConfirmModal)}>{ t('view_budget') }</button>}
								</div>
							</div>
							<OrderTimeline step="summary" />
							{this.renderStores()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Summary));
