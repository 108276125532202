import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, FilterCampaign, SelectOption } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_CAMPAIGNS = 'update_selected_campaigns';
export const APPEND_CAMPAIGNS = 'append_selected_campaigns';
export const UPDATE_ADD_MODAL_VISIBILITY = 'update_add_modal_visibility_campaign';
export const UPDATE_FILTER_VISIBILITY = 'update_filter_visibility_campaign';
export const UPDATE_FILTER = 'update_filter_campaign';
export const UPDATE_STATUSES = 'update_statuses';
export const CLEAR_FILTER = 'clear_filter_campaign';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateCampaignsAction {
    type: typeof UPDATE_CAMPAIGNS,
    payload: Campaign[]
}

export interface AppendCampaignsAction {
    type: typeof APPEND_CAMPAIGNS,
    payload: Campaign[]
}

export interface UpdateAddModalVisibilityAction {
    type: typeof UPDATE_ADD_MODAL_VISIBILITY,
    payload: boolean
}
export interface UpdateFilterVisibilityAction {
    type: typeof UPDATE_FILTER_VISIBILITY,
    payload: boolean
}
export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterCampaign
}
export interface UpdateStatusesAction {
    type: typeof UPDATE_STATUSES,
    payload: SelectOption[]
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type CampaignIndexActions =
UpdateLoadingAction |
UpdateCampaignsAction |
AppendCampaignsAction |
UpdateAddModalVisibilityAction |
UpdateFilterVisibilityAction |
UpdateFilterAction |
UpdateStatusesAction |
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
