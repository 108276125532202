import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
} from './CampaignAddSupportActions';

import { getStoreTypes, getSupportTypes, getCountries, getSections, getProductionLanguages, getPriorities } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, BackArrow, SupportFilterAddSupport, SupportList, SaveingModal } from '../../../components';
import { select_campaign_supports } from '../../../api';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { is_blocked } from '../../../util/campaign';
import { country_map, section_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_add_support_state: state.campaignAddSupport,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	getStoreTypes,
	getSupportTypes,
	getCountries,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
	getSections,
	getProductionLanguages,
	getPriorities,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showSaving: boolean,
}

class AddSupport extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showSaving: false,
        };
	}

	componentDidMount() {
		this.props.getStores();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getCountries();
		this.props.getProductionLanguages();
		this.props.oneCampaign(this.props.match.params.id);
		this.props.updateSelectedSupports(this.props.match.params.id);
		this.props.getProductionLanguages();
		this.props.getSections();
		this.props.getPriorities();
	}

	selectAllSupports() {
		const ids = (this.props.campaign_add_support_state.stores||[]).map(function(store) {
			return (store.supports||[]).map(function(support) {
				return support.id||'';
			})
		}).flat();
		this.props.addSelectedSupports(ids);
	}

	saveClicked() {
		this.setState({ showSaving: true }, () =>
			select_campaign_supports(
				this.props.match.params.id,
				this.props.campaign_add_support_state.selectedSupports
			).then(() => {
				this.props.history.goBack();
			}).catch(err => console.error)
		);
	}

	renderSaveingSpinner() {
		const { showSaving } = this.state;
		const { t } = this.props;
		if (showSaving) {
			return (<SaveingModal message={ t('saveing') } />);
		}
	}

	renderSupportFilter() {
		if (this.props.campaign_add_support_state.showFilter) {
			return (
				<SupportFilterAddSupport
					filter={this.props.campaign_add_support_state.filter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					countries={this.props.datatypes.countries.map(country_map)}
					supportProductionLanguages={this.props.datatypes.production_languages}
					supportSections={this.props.datatypes.sections.map(section_map)}
					supportPriorities={this.props.datatypes.priorities}
					onClose={() => this.props.updateShowFilter(!this.props.campaign_add_support_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateShowFilter(!this.props.campaign_add_support_state.showFilter);
						this.props.getStores();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getStores();
						this.props.updateShowFilter(!this.props.campaign_add_support_state.showFilter)
					}}
				/>
			);
		}
	}

	renderStores() {
		const { stores, loading } = this.props.campaign_add_support_state;
		const { t } = this.props;

		if (loading) {
			return (
				<div className="content">
					<Spinner message={ t('loading_campaign') } />
				</div>
			);
		}
		let storeList = [ ...stores ];
		return storeList.map((store) => (
			<div key={store.id} className="basic-card basic-card--32 store-card">
				<div className="store-card--view">
					<SupportList
						id={store.id||''}
						name={store.name}
						collapsed={true}
						supports={(store.supports || [])}
						checkBox={true}
						selectedSupports={this.props.campaign_add_support_state.selectedSupports}
						production_languages={this.props.datatypes.production_languages}
						assignedImages={{}}
						addVisual={() => null}
						deleteSupport={() => null}
						addSupports={(ids: string[]) => {
							this.props.addSelectedSupports(ids);
						}}
						removeSupports={(ids: string[]) => {
							this.props.removeSelectedSupports(ids);
						}}
					/>
				</div>
			</div>
		));
	}

	render () {
		const { campaign, loading } = this.props.campaign_add_support_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_supports') } />
					</div>
				</div>
			);
		}
		return (
			<>
				{this.renderSaveingSpinner()}
				<div className="base-content">
					{this.renderSupportFilter()}
					<div className="content">
						<div className="container">
							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Campañas', url: '/campaigns' }, { text: `${campaign?.title}`, url: `/campaigns/${campaign?.id}` }]}/>
							<div className="section-header">
								<div className="section-header-left">
									<BackArrow onClick={() => this.props.history.goBack()} />
									<h1 className="headline-1--regular section-header-title">{ t('select_stores') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_add_support_state.filter)} onClick={() => this.props.updateShowFilter(!this.props.campaign_add_support_state.showFilter)}></button>
									{ is_blocked(campaign) &&
										<button className="btn--secondary btn--l pop-reveal btn--disabled">{ t('select_all') }</button>}
									{ !is_blocked(campaign) &&
										<button className="btn--secondary btn--l pop-reveal" onClick={() => this.selectAllSupports()}>{ t('select_all') }</button>}
									{ is_blocked(campaign) &&
										<button className="btn--primary btn--l pop-reveal btn--disabled">{ t('save') }</button>}
									{ !is_blocked(campaign) &&
										<button className="btn--primary btn--l pop-reveal" onClick={() => this.saveClicked()}>{ t('save') }</button>}
								</div>
							</div>
							{this.renderStores()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(AddSupport));
