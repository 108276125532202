import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { AssignedVisuals, Store, Visual } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_STORES = 'UPDATE_STORES';
export const UPDATE_AVAILABLE_VISUALS = 'UPDATE_AVAILABLE_VISUALS';
export const UPDATE_ASSIGNED_IMAGES = 'UPDATE_ASSIGNED_IMAGES';
export const UPDATE_VAT = 'UPDATE_VAT';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface UpdateAssignedImagesAction {
    type: typeof UPDATE_ASSIGNED_IMAGES,
    payload: AssignedVisuals
}

export interface UpdateAvailableVisualsAction {
    type: typeof UPDATE_AVAILABLE_VISUALS,
    payload: Visual[]
}

export interface UpdateVatAction {
    type: typeof UPDATE_VAT,
    payload: number
}

export type OrderBasketActions =
UpdateLoadingAction |
UpdateStoresAction |
UpdateAssignedImagesAction |
UpdateAvailableVisualsAction |
UpdateVatAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
