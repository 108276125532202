import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import {
    updateUser,
    updateProfileInfoShow,
    updateProfileEditModalShow,
    updateSelectedLanguage,
} from './ProfileDetailActions';
import { getLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Breadcrumb, CollapsedArrow, ProfileAddModal, Spinner, InputSelect } from '../../../components';
import { User } from '../../../interfaces/interfaces';
import { edit_profile, upload_profile_image, edit_language } from '../../../api';
import cookies from '../../../util/cookies';
import i18n from 'i18next';

const mapStateToProps = (state: RootState) => {
    return {
        profile_detail_state: state.profileDetail,
        datatypes: state.datatypes,
    }
}

const connector = connect(mapStateToProps, {
    getLanguages, 
    updateUser,
    updateSelectedLanguage, 
    updateProfileInfoShow,
    updateProfileEditModalShow,
    edit_profile,
    upload_profile_image,
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

class Detail extends React.Component<Props> {

    componentDidMount() {
        this.props.updateUser();
        this.props.getLanguages();
        this.props.updateSelectedLanguage(this.getCurrentLanguage());
    }

    getCurrentLanguage() {
        return cookies.get('b2b-kendu-language');
    }

    renderProfileInformation() {
        const { t } = this.props;
        const { user, profileInfoShow } = this.props.profile_detail_state;
        if (profileInfoShow) {
            return (
                <ul id="info-card">
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('name_and_lastname') }</h3>
                        <p className="info-row-data text-2--regular">{user?.name} {user?.last_name}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('role') }</h3>
                        <p className="info-row-data text-2--regular">{user?.role}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('contact') }</h3>
                        <p className="info-row-data text-2--regular">{user?.email}</p>
                    </li>
                    <li>
                        <h3 className="info-row-label text-3--regular">{ t('phone') }</h3>
                        <p className="info-row-data text-2--regular">{user?.phone}</p>
                    </li>
                </ul>
            );
        }
    }

    renderEditProfileModal() {
        const { profileEditModalShow, user } = this.props.profile_detail_state;
        if (profileEditModalShow) {
            return (
                <ProfileAddModal
                    user={user||undefined}
                    onClose={() => this.props.updateProfileEditModalShow(!profileEditModalShow)}
                    onSubmit={(user: User) => {
                        edit_profile(user).then(() => {
                            this.props.updateProfileEditModalShow(!profileEditModalShow);
							this.props.updateUser();
                        }).catch(err => console.error);
                    }}
                />
            );
        }
    }

    setLanguage(value: string) {
        cookies.set('b2b-kendu-language', value.toLowerCase(), { path: '/' });
        this.props.updateSelectedLanguage(value.toLowerCase());
        edit_language(value.toUpperCase());
        i18n.changeLanguage(value.toLowerCase(), (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }

    render () {
        const { t } = this.props;
        const { loading, profileInfoShow, profileEditModalShow } = this.props.profile_detail_state;
        if (loading) {
            return (
                <div className="base-content">
                    <div className="content">
                        <Spinner message={ t('loading_profile') } />
                    </div>
                </div>
            );
        }
        return (
            <div className="base-content">
                {this.renderEditProfileModal()}
                <div className="content">
                    <div className="container">

                        <Breadcrumb items={[{ text: t('my_data') }]}/>

                        <div className="section-header">
                            <div className="section-header-left">
                                <h1 className="headline-1--regular">{ t('my_data') }</h1>
                            </div>
                            <div className="section-header-right">
                                <button className="btn--secondary btn--l pop-reveal" onClick={() => this.props.updateProfileEditModalShow(!profileEditModalShow)}><i className="icon icon-16-edit"></i>{ t('edit') }</button>
                            </div>
                        </div>

                        <div className="basic-card basic-card--32 info-card">

                            <div className="card-header">
                                <div className="card-header-left">
                                    <h2 className="headline-3--semibold">{ t('personal_data') }</h2>
                                </div>
                                <div className="class-header-right">
                                    <CollapsedArrow collapsed={profileInfoShow} clicked={() => this.props.updateProfileInfoShow(!profileInfoShow)}/>
                                </div>
                            </div>
                            {this.renderProfileInformation()}
                        </div>

                        <div className="basic-card basic-card--32 info-card">

                            <div className="card-header">
                                <div className="card-header-left">
                                    <h2 className="headline-3--semibold">{ t('languages') }</h2>
                                </div>
                            </div>

                            <ul id="info-card">
                                <li>
                                    <InputSelect
                                        id="languages"
                                        label="Idioma"
                                        options={this.props.datatypes.languages.filter(language => ['esp', 'enu', 'fra'].includes(language.value))}
                                        value={this.props.profile_detail_state.selectedLanguage}
                                        onChange={(value) => this.setLanguage(value || '')}
                                        emptyValue={false}
                                    />
                                </li>
                            </ul>

                            </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default withTranslation()(connector(Detail));

