import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Breadcrumb, OrderFilter, Spinner } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { statusPillClass, statusName } from '../../../util/order';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { getOrders, updateFilter, clearFilter, getStatuses, getUsers } from './OrderIndexActions';
import { Order, OrderStatus } from '../../../interfaces/interfaces';
import { create_order } from '../../../api';
import { filter_style } from '../../../util/filter';
import { get_role } from '../../../util/jwt';

const mapStateToProps = (state: RootState) => {
	return {
		order_index_state: state.orderIndex,
		auth_state: state.auth,
	}
}

const connector = connect(mapStateToProps, {
	getOrders,
	getStatuses,
	getUsers,
	updateFilter,
	clearFilter,
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	show_filter: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			show_filter: false,
        };
	}

	componentDidMount() {
		this.props.getOrders();
		this.props.getStatuses();
		this.props.getUsers();
	}

	orderLinkTo(order: Order) {
		if (order.status === OrderStatus.DRAFT) {
			return `/orders/new_order/${order.id}`;
		}
		return `/orders/detail/${order.id}`;
	}

	goToOrder(order: Order) {
		this.props.history.push(this.orderLinkTo(order));
	}

	renderContent() {
		const { t } = this.props;
		const { orders, loading } = this.props.order_index_state;
		if (loading) {
			return (
				<Spinner message={ t('loading_orders') } />
			);
		}
		return (
			<div className="basic-card basic-card--32 store-card">
				<div className="store-card--view">
					<div style={{display: 'flex', marginBottom: '32px'}}>
						<div className="resume-pill">
							<span className="title">{ t('orders') }</span>
							<span className="count">{ this.props.order_index_state.orders.length }</span>
						</div>
						<div className="resume-pill">
							<span className="title">{ t('stores') }</span>
							<span className="count">{ this.props.order_index_state.orders.reduce((sum, order) => sum + (order.stores || 0), 0) }</span>
						</div>
						<div className="resume-pill">
							<span className="title">{ t('visuals') }</span>
							<span className="count">{ this.props.order_index_state.orders.reduce((sum, order) => sum + (order.visuals || 0), 0) }</span>
						</div>
					</div>
					<table id="user_list">
						<thead>
							<tr>
								<th>{ t('ref_num') }</th>
								<th>{ t('kendu_ref') }</th>
								<th>{ t('stores') }</th>
								<th>{ t('visuals') }</th>
								<th>{ t('date') }</th>
								<th>{ t('user') }</th>
								<th>{ t('status') }</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{orders.map(order =>
								<tr key={order.id}>
									<td onClick={() => this.goToOrder(order)}>{order.reference || '-'}</td>
									<td onClick={() => this.goToOrder(order)}>{order.kendu_reference || '-'}</td>
									<td onClick={() => this.goToOrder(order)}>{order.stores || 0}</td>
									<td onClick={() => this.goToOrder(order)}>{order.visuals || 0}</td>
									<td onClick={() => this.goToOrder(order)}>{moment(order.date).format('ll')}</td>
									<td>
										<div className="worker" style={{display: 'flex'}}>
											{/* <Link to={`/users/detail/1`} className="circle" style={{marginRight: '10px'}}>
												<img src={"https://kendub2b.blob.core.windows.net/b2b/users/28/profile.jpeg?v="+ Date.now()} alt={order.user_name} style={{width: '24px', height: '24px'}} />
											</Link> */}
											<div className="name" style={{display: 'flex', alignItems: 'center'}}>
												<Link to={`/users/detail/1`}>
													{order.user_name}
												</Link>
											</div>
										</div>
									</td>
									<td onClick={() => this.goToOrder(order)}>
										<span className={`status-pill-mini ${statusPillClass(order.status as OrderStatus)}`}>{ statusName(order.status as OrderStatus, t) }</span>
									</td>
									{/* <td style={{textAlign: 'left'}}><div className="icon icon-16-dowload" style={{color: '#AEB2C3'}}></div></td> */}
									<td></td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	renderFilter() {
		if (this.state.show_filter) {
			return (
				<OrderFilter
					filter={this.props.order_index_state.filter}
					users ={this.props.order_index_state.users}
    				statuses={this.props.order_index_state.statuses}
					role={get_role(this.props.auth_state.token!)}
					onClose={() => {
						this.setState({ show_filter: !this.state.show_filter })
					}}
					onClear={() => {
						this.props.clearFilter();
						this.setState({ show_filter: !this.state.show_filter })
						this.props.getOrders();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.setState({ show_filter: !this.state.show_filter })
						this.props.getOrders();
					}}
				/>
			);
		}
	}

	newOrder() {
		create_order().then((campaignId) => {
			this.props.history.push(`/orders/new_order/${campaignId}`);
		}).catch(err => console.error);
	}

	render () {
		const { t } = this.props;
		return (
			<div className="base-content">
				{this.renderFilter()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('orders') }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<h1 className="headline-1--regular section-header-title">{ t('orders') }</h1>
							</div>
							<div className="section-header-right">
								<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.order_index_state.filter)} onClick={() => this.setState({ show_filter: !this.state.show_filter })}></button>
								<button className="btn--primary btn--l" onClick={() => this.newOrder()}><i className="icon icon-16-add"></i>{ t('new_order') }</button>
							</div>
						</div>
						{this.renderContent()}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Index));
