import {
    UPDATE_ORDERS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    UPDATE_STATUSES,
    CLEAR_FILTER,
    OrderIndexActions,
    UPDATE_USERS,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Order, FilterOrder, SelectOption } from "../../../interfaces/interfaces";

export interface OrderIndexState {
    orders: Order[],
    loading: boolean,
    filter: FilterOrder,
    statuses: SelectOption[],
    users: SelectOption[],
}

const INITIAL_STATE: OrderIndexState = {
    orders: [],
    loading: false,
    filter: new FilterOrder(),
    statuses: [],
    users: [],
};

const reducer: Reducer<OrderIndexState, OrderIndexActions> = (state = INITIAL_STATE, action: OrderIndexActions) => {
    switch(action.type) {
        case UPDATE_ORDERS:
            return { ...state, orders: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterOrder() };
        case UPDATE_STATUSES:
            return { ...state, statuses: action.payload };
        case UPDATE_USERS:
            return { ...state, users: action.payload };
        default:
            return state;
    }
}

export default reducer;