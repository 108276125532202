import {
    UPDATE_SUPPORT,
    UPDATE_LOADING,
    UPDATE_SUPPORT_INFO_SHOW,
    UPDATE_SUPPORT_ADD_MODAL_SHOW,
    SupportDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Support } from '../../../interfaces/interfaces';

export interface SupportDetailState {
    loading: boolean,
    support: Support|null,
    supportInfoShow: boolean,
    supportAddModalShow: boolean,
}

const INITIAL_STATE: SupportDetailState = {
    loading: false,
    support: null,
    supportInfoShow: true,
    supportAddModalShow: false,
};

const reducer: Reducer<SupportDetailState, SupportDetailActions> = (state = INITIAL_STATE, action: SupportDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SUPPORT_INFO_SHOW:
            return { ...state, supportInfoShow: action.payload };
        case UPDATE_SUPPORT:
            return { ...state, support: action.payload };
        case UPDATE_SUPPORT_ADD_MODAL_SHOW:
            return { ...state, supportAddModalShow: action.payload };
        default:
            return state;
    }
}

export default reducer;