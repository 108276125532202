import { get_assigned_images, get_available_visuals, get_stores_with_supports, one_client, one_order } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { FilterStoreAndSupport } from '../../../interfaces/interfaces';
import { client_map } from '../../client/client.maps';
import {
    UPDATE_LOADING,
    UPDATE_ORDER,
    UPDATE_VAT,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_AVAILABLE_VISUALS,
    UPDATE_ASSIGNED_IMAGES,
    APPEND_STORES,
    UPDATE_STORES,
    OrderDetailActions,
    AppThunk,
} from './ActionTypes';

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as OrderDetailActions);

        const stores = await get_stores_with_supports({
            ...getState().orderNewOrder.filter,
            key: lastKey,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as OrderDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as OrderDetailActions);
    }
}

export const getOrder = (orderId: string): AppThunk => {
    return async dispatch => {
        const order = await one_order(orderId);

        dispatch({
            type: UPDATE_ORDER,
            payload: order
        } as OrderDetailActions);
    }
}

export const getOneOrder = (orderId: string): AppThunk => {
    return async dispatch => {
        const visuals = await get_available_visuals();

        dispatch({
            type: UPDATE_AVAILABLE_VISUALS,
            payload: visuals
        } as OrderDetailActions);

        const assignedImages = await get_assigned_images(orderId);
        dispatch({
            type: UPDATE_ASSIGNED_IMAGES,
            payload: assignedImages
        } as OrderDetailActions);
    }
}

export const getVat = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        if (client) {
            let mappedClient = client_map(client);
            dispatch({
                type: UPDATE_VAT,
                payload: mappedClient.vat / 100
            } as OrderDetailActions);
        }

    }
}

export const updateFilter = (filter: FilterStoreAndSupport): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: filter
        } as OrderDetailActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as OrderDetailActions);
    }
}