import { Campaign } from '../../interfaces/interfaces';

export const campaign_map = (campaign: any): Campaign => ({
    key: campaign.key,
    id: campaign.id,
    title: campaign.title,
    description: campaign.description,
    start_date: campaign.start_date,
    end_date: campaign.end_date,
    image: campaign.image,
    images: campaign.images,
    status: campaign.status,
});