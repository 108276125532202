import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_PASSWORD_REPEAT = 'UPDATE_PASSWORD_REPEAT';
export const UPDATE_PASSWORD_REPEAT_ERROR = 'UPDATE_PASSWORD_REPEAT_ERROR';

export interface UpdatePasswordAction {
    type: typeof UPDATE_PASSWORD,
    payload: string
}

export interface UpdatePasswordErrorAction {
    type: typeof UPDATE_PASSWORD_ERROR,
    payload: string
}

export interface UpdatePasswordRepeatAction {
    type: typeof UPDATE_PASSWORD_REPEAT,
    payload: string
}

export interface UpdatePasswordRepeatErrorAction {
    type: typeof UPDATE_PASSWORD_REPEAT_ERROR,
    payload: string
}

export type SetingsActions =
UpdatePasswordAction |
UpdatePasswordErrorAction |
UpdatePasswordRepeatAction |
UpdatePasswordRepeatErrorAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
