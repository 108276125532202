import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface OrderTimelineProps {
    step: "summary" | "budget" | "launchment"
}

type OrderTimelinePropsSum = OrderTimelineProps & WithTranslation;

class OrderTimelineComponent extends React.Component<OrderTimelinePropsSum> {

    render () {
        const { t } = this.props;
        return (
            <div className="order-timeline">
                <div className="timeline-row">
                    <div className={`timeline-step-box ${['summary', 'budget', 'launchment'].includes(this.props.step) ? 'timeline-step-box--selected' : ''}`}>
                        <span className="rounded-number">1</span>
                        <i className="icon icon-16-resume"></i>
                        { t('summary') }
                    </div>
                    <div className={`timeline-step-box ${['budget', 'launchment'].includes(this.props.step) ? 'timeline-step-box--selected' : ''}`}>
                        <span className="rounded-number">2</span>
                        <i className="icon icon-16-invoice"></i>
                        { t('budget') }
                    </div>
                    <div className={`timeline-step-box ${['launchment'].includes(this.props.step) ? 'timeline-step-box--selected' : ''}`}>
                        <span className="rounded-number">3</span>
                        <i className="icon icon-16-launch"></i>
                        { t('launchment') }
                    </div>
                </div>
                <div className="timeline-row">
                    <span className={`line ${['budget', 'launchment'].includes(this.props.step) ? 'line--selected' : ''}`}></span>
                    <span className={`line ${['launchment'].includes(this.props.step) ? 'line--selected' : ''}`}></span>
                </div>
            </div>
        );
    };
}

const OrderTimeline = withTranslation()(OrderTimelineComponent);
export { OrderTimeline };
