import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_ERROR,
    SetingsActions
} from "./ActionTypes";
import { Reducer } from 'redux';

export interface ClientDetailState {
    email: string,
    emailError: string,
}

const INITIAL_STATE: ClientDetailState = {
    email: '',
    emailError: '',
};

const reducer: Reducer<ClientDetailState, SetingsActions> = (state = INITIAL_STATE, action: SetingsActions) => {
    switch(action.type) {
        case UPDATE_EMAIL:
            return { ...state, email: action.payload };
        case UPDATE_EMAIL_ERROR:
            return { ...state, emailError: action.payload };
        default:
            return state;
    }
}

export default reducer;