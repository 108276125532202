import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_USER,
    UPDATE_LOADING,
    UPDATE_SELECTED_STORES,
    APPEND_SELECTED_STORES,
    REMOVE_SELECTED_STORES,
    UserAddStoreActions,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_FILTER,
    CLEAR_FILTER,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, User, FilterStore } from "../../../interfaces/interfaces";

export interface UserAddStoreState {
    stores: Store[],
    user: User|null,
    loading: boolean,
    filter: FilterStore,
    selectedStores: string[],
    showFilter: boolean,
}

const INITIAL_STATE: UserAddStoreState = {
    stores: [],
    user: null,
    loading: false,
    filter: new FilterStore(),
    selectedStores: [],
    showFilter: false,
};

const reducer: Reducer<UserAddStoreState, UserAddStoreActions> = (state = INITIAL_STATE, action: UserAddStoreActions) => {
    switch(action.type) {
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case APPEND_STORES:
            return { ...state, stores: [ ...state.stores, ...action.payload ] };
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SELECTED_STORES:
            return { ...state, selectedStores: action.payload };
        case APPEND_SELECTED_STORES:
            return { ...state, selectedStores: [ ...state.selectedStores, ...action.payload ] };
        case REMOVE_SELECTED_STORES:
            return { ...state, selectedStores: state.selectedStores.filter(val => !action.payload.includes(val)) }
        case UPDATE_FILTER_VISIBILITY:
            return { ...state, showFilter: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload }
        case CLEAR_FILTER:
            return { ...state, filter: new FilterStore() }
        default:
            return state;
    }
}

export default reducer;