import React from 'react';
import { Visual, Support } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VisualImage } from './VisualImage';
import { checkSizes } from '../util/support';
import { remove_decimals } from '../util/number';

export interface VisualAddModalProps {
    visuals: Visual[],
    support: Support,
    selectedVisual: Visual,
    onClose: () => void,
    onSubmit: (support_id: string, visual_id: string) => void,
}

interface VisualAddModalState {
    selectedVisual: Visual|null
}

type VisualAddModalPropsSum = VisualAddModalProps & WithTranslation;

class VisualAddModalComponent extends React.Component<VisualAddModalPropsSum, VisualAddModalState> {

    constructor(props: VisualAddModalPropsSum) {
        super(props);

        this.state = {
            selectedVisual: null
        };
    }

    componentDidMount() {
        this.setState({
            selectedVisual: this.props.selectedVisual
        });
    }

    selectedVisualClass(selected: boolean) {
        return selected ? "pop-hardware-element-content pop-hardware-element--active" : "pop-hardware-element-content";
    }

    assignButtonClicked() {
        this.props.onSubmit(this.props.support.id!, this.state.selectedVisual!.id);
    }

    render () {

        const { support, t } = this.props;
        const { selectedVisual } = this.state;

        return (
            <div className="pop-layout">
                <div className="pop-bg pop-bg-big">
                    <div className="pop-box pop-box-add-visuals pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{ t('assign_images') }</p>
                        </div>
                        <div className="pop-box-content">
                            <div className="pop-hardware-info">
                                <div className="hardware-info-img" style={{backgroundImage: `url('/img/img-hardware.png')`}}></div>
                                <div className="hardware-info-info">
                                    <p className="text-1--regular hardware-title">{support?.name}</p>
                                    <p className="text-3--regular hardware-subinfo">ID {support?.id} &nbsp; &nbsp; {support?.height}mm x {support?.width}mm</p>
                                </div>
                            </div>
                            <div className="pop-hardware-content">
                                {(this.props.visuals||[]).filter(visual => checkSizes(visual, support)).map((visual, index) => (
                                    <div className={this.selectedVisualClass(selectedVisual?.id === visual.id)} onClick={() => this.setState({ selectedVisual: visual })}>
                                        <div className="pop-hardware-element">
                                            <div className="card-img card-img--visual-xl">
                                                <VisualImage visual={visual} />
                                            </div>
                                            <p className="hardware-element-caption text-2--regular-multiline">{visual.name}</p>
                                            <p className="text-3--regular hardware-subinfo" style={{ marginTop: '8px' }}>{remove_decimals(Number(visual.original_width))} x {remove_decimals(Number(visual.original_height))}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> {this.assignButtonClicked()}}>{ t('assign_images') }</button>
                                <button className="btn--secondary btn--l close-pop" onClick={() => {this.props.onClose()}}>{ t('cancel') }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const VisualAddModal = withTranslation()(VisualAddModalComponent);
export { VisualAddModal };
