import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
    getStores,
    getUser,
    addSelectedStores,
    removeSelectedStores,
    updateFilterVisibility,
    updateFilter,
	clearFilter,
} from './UserAddStoreActions';

import { Spinner, Breadcrumb, BackArrow, StoreFilter } from '../../../components';
import { select_user_stores } from '../../../api';
import { withTranslation, WithTranslation } from 'react-i18next';
import { StoreCardWithCheckBox } from '../../../components/StoreCardWithCheckBox';
import { Store } from "../../../interfaces/interfaces";
import { filter_style } from '../../../util/filter';
import { getCountries, getStoreTypes } from '../../../reducers/datatypes/DatatypesActions';
import { country_map } from '../../../util/select_maps';
import { StoreStatus } from '../../../interfaces/interfaces';


const mapStateToProps = (state: RootState) => {
    return {
		user_add_store_state: state.userAddStore,
        datatypes: state.datatypes
    }
}

const connector = connect(mapStateToProps, {
    getStores,
    getUser,
    addSelectedStores,
    removeSelectedStores,
    updateFilterVisibility,
    updateFilter,
    clearFilter,
    getCountries,
    getStoreTypes,

});

interface ParamTypes {
    id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class AddStore extends React.Component<Props> {

    componentDidMount() {
        this.props.getStores();
        this.props.getUser(this.props.match.params.id);
        this.props.getStoreTypes();
		this.props.getCountries();
    }

    selectAllStores() {
        const ids = (this.props.user_add_store_state.stores||[]).map(store => store.id || '');
        this.props.addSelectedStores(ids);
    }

    saveClicked() {
        select_user_stores(
            this.props.match.params.id,
            this.props.user_add_store_state.selectedStores
        ).then(() => {
            this.props.history.goBack();
        }).catch(err => console.error);
    }

	isAssigned(store: Store){
		const { selectedStores } = this.props.user_add_store_state;
        return selectedStores.some(selectedStore => selectedStore === store.id) || false;
	}

    renderStores() {

		const { stores, loading } = this.props.user_add_store_state;
		const { t } = this.props;

        if (loading) {
            return (
                <div className="content">
                    <Spinner message={ t('loading_stores') } />
                </div>
			);
        }

        return stores.map((store) => (
			<StoreCardWithCheckBox
				key={ store.id }
				store = { store }
				checked = { this.isAssigned(store) }
				addStores = {(ids: string[]) => this.props.addSelectedStores(ids) }
				removeStores = {(ids: string[]) => this.props.removeSelectedStores(ids) }
            />
        ));
    }
    renderFilter() {
        const { t } = this.props
		if (this.props.user_add_store_state.showFilter) {
			return (
				<StoreFilter
					filter={this.props.user_add_store_state.filter}
					storeTypes={this.props.datatypes.store_types}
					statuses={[ { "name": t("store_status_open"), "value": StoreStatus.OPEN }, {"name": t("store_status_closed"), "value": StoreStatus.CLOSED }, {"name": "NO STATUS", "value": StoreStatus.NO_STATUS } ]}
					supportTypes={this.props.datatypes.support_types}
					countries={this.props.datatypes.countries.map(country_map)}
					onClose={() => this.props.updateFilterVisibility(!this.props.user_add_store_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateFilterVisibility(!this.props.user_add_store_state.showFilter);
						this.props.getStores();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.updateFilterVisibility(!this.props.user_add_store_state.showFilter);
						this.props.getStores();
					}}
				/>
			);
		}
	}

    render () {
        const { user, loading } = this.props.user_add_store_state;
        const { t } = this.props;
        if (loading) {
            return (
                <div className="base-content">
                    <div className="content">
                        <Spinner message={ t('loading_stores') } />
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="base-content">
                {this.renderFilter()}
                    <div className="content">
                        <div className="container">
                            <Breadcrumb items={[{ text: t('users'), url: '/settings' }, { text: `${user?.name}` , url: `/users/detail/${user?.id}` }, { text: t('select_stores'), url: `/users/${user?.id}/add_store` }]}/>
                            <div className="section-header">
                                <div className="section-header-left">
                                    <BackArrow onClick={() => this.props.history.goBack()} />
                                    <h1 className="headline-1--regular section-header-title">{ t('select_stores') }</h1>
                                </div>
                                <div className="section-header-right">
                                    <button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.user_add_store_state.filter)} onClick={() => this.props.updateFilterVisibility(!this.props.user_add_store_state.showFilter)}></button>
                                    <button className="btn--secondary btn--l pop-reveal" onClick={() => this.selectAllStores()}>{ t('select_all') }</button>
                                    <button className="btn--primary btn--l pop-reveal" onClick={() => this.saveClicked()}>{ t('save') }</button>
                                </div>
                            </div>
                            {this.renderStores()}
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default withTranslation()(connector(AddStore));

