import React from 'react';
import { SelectOption, Support } from '../interfaces/interfaces';
import { InputCheckBox, SupportDetail } from './';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SelectableProps {
    id: string,
    checked: boolean,
    support: Support,
    production_languages: SelectOption[],
    addSupport: (ids: string[]) => void,
    removeSupport: (ids: string[]) => void,
}

type SelectablePropsSum = SelectableProps & WithTranslation;

class SupportCardSelectableComponent extends React.Component<SelectablePropsSum> {

    selectCheckBox(value: boolean) {
        if (value) {
            this.props.addSupport([this.props.id]);
        } else {
            this.props.removeSupport([this.props.id]);
        }
    }

    render () {
        const { production_languages, support } = this.props;
        const { support_type } = this.props.support;
        return (
            <div className="store-row--hardware">

                <div className="hardware-element-left">
                    <div className="card-img card-img--48" style={{backgroundImage: `url(${support.visual_url ?? '/img/img-hardware.png'})`}}></div>
                    <div className="hardware-info">
                        <p className="text-1--regular hardware-title">{support_type}</p>
                        <p className="text-3--regular hardware-subinfo"><SupportDetail support={support} production_languages={production_languages} /></p>
                    </div>
                </div>

                <div className="hardware-element-right">
                    <InputCheckBox id="" checked={this.props.checked} checkBoxChange={(value: boolean) => this.selectCheckBox(value)}/>
                </div>

            </div>
        );
    };
}

const SupportCardSelectable = withTranslation()(SupportCardSelectableComponent);
export { SupportCardSelectable };
