import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { oneCampaign } from './CampaignLaunchedActions';
import { Breadcrumb, BackArrow, OrderTimeline } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_launched_state: state.campaignLaunched,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	oneCampaign,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Summary extends React.Component<Props> {

	componentDidMount() {
		this.props.oneCampaign(this.props.match.params.id);
	}

	render () {
		const { campaign } = this.props.campaign_launched_state;
		const { t } = this.props;
		return (
			<>
				<div className="base-content">
					<div className="content">
						<div className="container">
							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Campañas', url: '/campaigns' }, { text: `${campaign?.title}`, url: `/campaigns/${campaign?.id}` }]}/>
							<div className="section-header">
								<div className="section-header-left">
									<BackArrow onClick={() => this.props.history.goBack()} />
									<h1 className="headline-1--regular section-header-title">{ t('order') }</h1>
								</div>
								<div className="section-header-right">
									<Link to="/campaigns">
										<button className="btn--primary btn--l pop-reveal">{ t('back_to_campaigns') }</button>
									</Link>
								</div>
							</div>
							<OrderTimeline step="launchment" />
							<div className="basic-card basic-card--32 store-card">
								<div className="store-card--view">
									<div className="launched">
										{ t('campaign_launched_correctly') }
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Summary));
