import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SELECTED_SUPPORTS,
    UPDATE_STORES,
    UPDATE_SHOW_VISUAL_ADD_MODAL,
    CampaignDetailActions,
    AppThunk,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_VISUAL_FILTER_VISIBILITY,
    CLEAR_VISUAL_FILTER,
    UPDATE_VISUAL_FILTER,
    UPDATE_VISUALS,
    UPDATE_LOADING_VISUALS,
    UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY,
    CLEAR_STORE_AND_SUPPORT_FILTER,
    UPDATE_STORE_AND_SUPPORT_FILTER,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_ASSIGNING_MODAL,
    UPDATE_UNASSIGNING_MODAL,
    UPDATE_ASSIGNATION_OK,
    UPDATE_ASSIGNATION_ERROR,
    UPDATE_PREPARED,
    UPDATE_ASSIGNED,
} from './ActionTypes';
import {
    one_campaign,
    get_selected_supports,
    get_stores_with_supports_campaign,
    get_assigned_images,
    get_visuals,
    one_client,
} from '../../../api';
import { FilterStoreRequest, FilterVisualRequest } from '../../../api/interfaces';
import { FilterStoreAndSupport, FilterVisual } from '../../../interfaces/interfaces';
import { client_map } from '../../client/client.maps';
import { preparedSelect, assignedSelect } from '../../../util/visual';


export const oneCampaign = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignDetailActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignDetailActions);
    }
}

export const getSelectedSupports2 = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignDetailActions);

        const supports = await get_selected_supports(id);

        dispatch({
            type: UPDATE_SELECTED_SUPPORTS,
            payload: supports
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignDetailActions);
    }
}

export const getAssignedImages = (id: string): AppThunk => {
    return async dispatch => {
        const assignedImages = await get_assigned_images(id);

        dispatch({
            type: UPDATE_ASSIGNED_VISUALS,
            payload: assignedImages
        } as CampaignDetailActions);
    }
}

export const getVisuals = (id: string): AppThunk => {
    return async (dispatch, getState) => {

        dispatch({
            type: UPDATE_LOADING_VISUALS,
            payload: true
        } as CampaignDetailActions);


        let visuals = await get_visuals(
            id,{
            ...getState().campaignDetail.visualFilter,
            page_size: 0
        } as FilterVisualRequest);


        dispatch({
            type: UPDATE_VISUALS,
            payload: visuals
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING_VISUALS,
            payload: false
        } as CampaignDetailActions);
    }
}

export const getStores2 = (campaign_id: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignDetailActions);

        let stores = await get_stores_with_supports_campaign(campaign_id, {
            ...getState().campaignDetail.storeAndSupportFilter,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: UPDATE_STORES,
            payload: stores
        } as CampaignDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignDetailActions);
    }
}

export const updateShowVisualAddModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_VISUAL_ADD_MODAL,
            payload: value
        } as CampaignDetailActions);
    }
}

export const updateVisualFilterVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_VISUAL_FILTER_VISIBILITY,
            payload: value
        } as CampaignDetailActions);
    }
}

export const clearVisualFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_VISUAL_FILTER,
            payload: null
        } as CampaignDetailActions);
    }
}

export const updateVisualFilter = (value: FilterVisual): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_VISUAL_FILTER,
            payload: value
        } as CampaignDetailActions);
    }
}

export const updateStoreAndSupportFilterVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY,
            payload: value
        } as CampaignDetailActions);
    }
}

export const clearStoreAndSupportFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_STORE_AND_SUPPORT_FILTER,
            payload: null
        } as CampaignDetailActions);
    }
}

export const updateStoreAndSupportFilter = (value: FilterStoreAndSupport): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_STORE_AND_SUPPORT_FILTER,
            payload: value
        } as CampaignDetailActions);
    }
}

export const getAssignationType = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        if (client) {
            let mappedClient = client_map(client);
            dispatch({
                type: UPDATE_ASSIGNATION_TYPE,
                payload: mappedClient.assignation_type
            } as CampaignDetailActions);
        }

    }
}

export const updateAssigningModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ASSIGNING_MODAL,
            payload: value
        } as CampaignDetailActions);
    }
}
export const updateUnAssigningModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_UNASSIGNING_MODAL,
            payload: value
        } as CampaignDetailActions);
    }
}


export const updateAssignationOk = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ASSIGNATION_OK,
            payload: value
        } as CampaignDetailActions);
    }
}

export const updateAssignationError = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ASSIGNATION_ERROR,
            payload: value
        } as CampaignDetailActions);
    }
}

export const updatePreparedSelect = (t: any): AppThunk => {
    return async dispatch => {
        let prepared = preparedSelect(t);
        dispatch({
            type: UPDATE_PREPARED,
            payload: prepared
        } as CampaignDetailActions);
    }
}

export const updateAssignedSelect = (t: any): AppThunk => {
    return async dispatch => {
        let assigned = assignedSelect(t);
        dispatch({
            type: UPDATE_ASSIGNED,
            payload: assigned
        } as CampaignDetailActions);
    }
}