import React from 'react';
import { InputText, InputSelect } from '.';
import { FilterStore, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SupportFilterAddSupportProps {
    filter: FilterStore,
    storeTypes: SelectOption[],
    supportTypes: SelectOption[],
    supportProductionLanguages: SelectOption[],
    supportPriorities: SelectOption[],
    supportSections: SelectOption[],
    countries: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterStore) => void
}

interface State {
    customer_shop_no?: string,
    name?: string,
    postal_code?: string,
    city?: string,
    region?: string,
    country?: string,
    store_type?: string,
    support_type?: string,
    text?: string,
    section?: string,
    priority?: string,
    production_language?: string
}

type SupportFilterAddSupportPropsSum = SupportFilterAddSupportProps & WithTranslation;

class SupportFilterAddSupportComponent extends React.Component<SupportFilterAddSupportPropsSum, State> {

    constructor(props: SupportFilterAddSupportPropsSum) {
        super(props);

        this.state = {
            customer_shop_no: props.filter?.customer_shop_no,
            name: props.filter?.name,
            postal_code: props.filter.postal_code,
            city: props.filter.city,
            region: props.filter.region,
            country: props.filter.country,
            store_type: props.filter.store_type,
            support_type: props.filter.support_type,
            text: props.filter.text,
            section: props.filter.section,
            priority: props.filter.priority,
            production_language: props.filter.production_language

        };
    }

    filter() {
        this.props.onSubmit(this.state);
    }

    render () {
        const { t } = this.props;
        return (
            <div className="filter-content filter-content--show">

                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_stores') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={()=> {this.props.onClose()}}></i>
                    </div>
                    <p className="text-3--regular form-group-title">{ t('store') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="text" label={ t('name') } value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="city" label={ t('city') } value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="country" label={ t('country') } value={this.state.country} emptyValue={true} options={this.props.countries} onChange={(value) => this.setState({ country: value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="store-type" label={ t('store_type') } value={this.state.store_type} emptyValue={true} options={this.props.storeTypes} onChange={(value) => this.setState({ store_type: value || '' })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('support') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="support_name" label={ t('name') } value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="support-section" label={ t('section') } value={this.state.section} emptyValue={true} options={this.props.supportSections} onChange={(value) => this.setState({ section: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="support-priority" label={ t('priority') } value={this.state.priority} emptyValue={true} options={this.props.supportPriorities} onChange={(value) => this.setState({ priority: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="production-language" label={ t('production_language') } value={this.state.production_language} emptyValue={true} options={this.props.supportProductionLanguages} onChange={(value) => this.setState({ production_language: value || '' })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="support-type" label={ t('support_type') } value={this.state.support_type} emptyValue={true} options={this.props.supportTypes} onChange={(value) => this.setState({ support_type: value || '' })} />
                        </div>
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.filter()}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>

            </div>
        );
    };
}

const SupportFilterAddSupport = withTranslation()(SupportFilterAddSupportComponent);
export { SupportFilterAddSupport };
