import { SelectOption, Visual } from "../interfaces/interfaces";

const FIELDS_MAP = {
    'Seccion': 'section',
    'Prioridad': 'priority',
    'Idioma': 'production_language',
};

export const is_ready = (visual: Visual, assignationType: string) : boolean => {
    const assignationFields = get_assignemt_fields (assignationType)
    return build_is_ready(assignationFields)(visual);
}

export const preparedSelect = (t: any) : SelectOption[] =>{
    return [
        { name: t('prepared'), value: "1" },
        { name: t('not_prepared'), value: "0" }
    ];
}

export const assignedSelect = (t: any) : SelectOption[] =>{
    return [
        { name: t('assigned'), value: "1" },
        { name: t('not_assigned'), value: "0" }
    ];
}

export const get_assignemt_fields = (assignment_type: string) => {
    return assignment_type.split('_').map(field => FIELDS_MAP[field as keyof typeof FIELDS_MAP]).filter(field => field);
}

export const build_is_ready = (fields: string[]) => (visual: Visual) : boolean => {
    return fields.length > 0 && fields.every(field => visual[field as keyof Visual] && visual[field as keyof Visual] !== '');
}

export const is_section_assigned = (assignation : string) : boolean => {
    return !!get_assignemt_fields(assignation).find(x => x === 'section');
}

export const is_priority_assigned = (assignation : string) : boolean => {
    return !!get_assignemt_fields(assignation).find(x => x === 'priority');
}

export const is_production_language_assigned = (assignation : string) : boolean => {
    return !!get_assignemt_fields(assignation).find(x => x === 'production_language');
}

export const checkVisualSizes = (visual1: Visual, visual2: Visual) : boolean => {

    const visual1_height = parseInt(visual1.original_height || '0');
    const visual1_width = parseInt(visual1.original_width || '0');
    const visual2_height = parseInt(visual2.original_height || '0');
    const visual2_width = parseInt(visual2.original_width || '0');

    if (visual1_height === visual2_height && visual1_width === visual2_width) {
        return true;
    }
    return false;
}