import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Store, SelectOption, FilterStore } from '../../../interfaces/interfaces';

export const UPDATE_STORES = 'update_stores';
export const APPEND_STORES = 'append_stores';
export const UPDATE_FILTER_VISIBILITY = 'update_filter_visibility_store';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_ADD_MODAL_VISIBILITY = 'update_add_modal_visibility';
export const UPDATE_FILTER = 'update_filter_store';
export const CLEAR_FILTER = 'clear_filter_store';
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface AppendStoresAction {
    type: typeof APPEND_STORES,
    payload: Store[]
}

export interface UpdateFilterVisibilityAction {
    type: typeof UPDATE_FILTER_VISIBILITY,
    payload: boolean
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateAddModalVisibilityAction {
    type: typeof UPDATE_ADD_MODAL_VISIBILITY,
    payload: boolean
}

export interface UpdateAddModalVisibilityAction {
    type: typeof UPDATE_ADD_MODAL_VISIBILITY,
    payload: boolean
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterStore
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateClientsAction {
    type: typeof UPDATE_CLIENTS,
    payload: SelectOption[]
}

export type StoreIndexActions =
UpdateStoresAction |
AppendStoresAction |
UpdateFilterVisibilityAction |
UpdateLoadingAction |
UpdateAddModalVisibilityAction |
UpdateFilterAction |
ClearFilterAction |
UpdateClientsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
