import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SHOW_FILTER,
    UPDATE_FILTER,
    UPDATE_SELECTED_SUPPORTS,
    APPEND_SELECTED_SUPPORTS,
    REMOVE_SELECTED_SUPPORTS,
    CLEAR_FILTER,
    CampaignAddSupportActions,
    AppThunk
} from './ActionTypes';
import { one_campaign, get_stores_with_supports, get_selected_supports } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { FilterStore } from '../../../interfaces/interfaces';

export const updateShowFilter = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_FILTER,
            payload: value
        } as CampaignAddSupportActions);
    }
}

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignAddSupportActions);

        const stores = await get_stores_with_supports({
            ...getState().campaignAddSupport.filter,
            key: lastKey,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as CampaignAddSupportActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignAddSupportActions);
    }
}

export const oneCampaign = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignAddSupportActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignAddSupportActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignAddSupportActions);
    }
}

export const updateFilter = (value: FilterStore): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as CampaignAddSupportActions);
    }
}

export const updateSelectedSupports = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignAddSupportActions);

        const supports = await get_selected_supports(id);

        dispatch({
            type: UPDATE_SELECTED_SUPPORTS,
            payload: supports
        } as CampaignAddSupportActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignAddSupportActions);
    }
}

export const addSelectedSupports = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: APPEND_SELECTED_SUPPORTS,
            payload: ids
        } as CampaignAddSupportActions);
    }
}

export const removeSelectedSupports = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: REMOVE_SELECTED_SUPPORTS,
            payload: ids
        } as CampaignAddSupportActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as CampaignAddSupportActions);
    }
}