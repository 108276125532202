import {
    UPDATE_STORE,
    UPDATE_LOADING,
    UPDATE_STORE_INFO_SHOW,
    UPDATE_SUPPORTS,
    UPDATE_USERS,
    UPDATE_SECTION_ADD_MODAL_SHOW,
    UPDATE_STORE_EDIT_MODAL_SHOW,
    UPDATE_SUPPORT_ADD_MODAL_SHOW,
    StoreDetailActions,
    UPDATE_CLIENTS,
    AppThunk,
} from './ActionTypes';
import {
    one_store,
    get_clients,
} from '../../../api';
import { Section } from '../../../interfaces/interfaces';
import { UPDATE_SECTIONS } from '../../../reducers/datatypes/DatatypesTypes';

export const updateStore = (id: string, customer_id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreDetailActions);

        let store = await one_store(id, customer_id);

        dispatch({
            type: UPDATE_STORE,
            payload: store
        } as StoreDetailActions);

        if (store) {
            dispatch({
                type: UPDATE_SUPPORTS,
                payload: store.supports
            } as StoreDetailActions);

            dispatch({
                type: UPDATE_USERS,
                payload: store.users
            } as StoreDetailActions);
        }

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as StoreDetailActions);
    }
}

export const updateStoreInfoShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_STORE_INFO_SHOW,
            payload: value
        } as StoreDetailActions);
    }
}

export const updateSectionAddModalShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SECTION_ADD_MODAL_SHOW,
            payload: value
        } as StoreDetailActions);
    }
}

export const updateStoreEditModalShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_STORE_EDIT_MODAL_SHOW,
            payload: value
        } as StoreDetailActions);
    }
}

export const updateSupportAddModalShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SUPPORT_ADD_MODAL_SHOW,
            payload: value
        } as StoreDetailActions);
    }
}

export const swapSectionCollapsed = (section: Section): AppThunk => {
    return async (dispatch, getState) => {
        let sections = getState().datatypes.sections;
        sections.map(function(element, index) {
            if (element.id === section.id) {
                element.collapsed = !element.collapsed;
            }
            return element;
        });
        dispatch({
            type: UPDATE_SECTIONS,
            payload: sections
        });
    }
}

export const getClients = (): AppThunk => {
    return async dispatch => {

        const clients = await get_clients();

        dispatch({
            type: UPDATE_CLIENTS,
            payload: clients.map(client => ({ value: client.id, name: client.name }))
        } as StoreDetailActions);
    }
}