import { get_assigned_images, get_available_visuals, get_stores_with_supports, one_client } from '../../../api';
import { client_map } from '../../client/client.maps';
import {
    UPDATE_LOADING,
    UPDATE_STORES,
    UPDATE_AVAILABLE_VISUALS,
    UPDATE_ASSIGNED_IMAGES,
    UPDATE_VAT,
    OrderBasketActions,
    AppThunk,
} from './ActionTypes';

export const getStores = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as OrderBasketActions);

        const stores = await get_stores_with_supports({});

        dispatch({
            type: UPDATE_STORES,
            payload: stores
        } as OrderBasketActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as OrderBasketActions);
    }
}

export const getOneOrder = (orderId: string): AppThunk => {
    return async dispatch => {
        const visuals = await get_available_visuals();

        dispatch({
            type: UPDATE_AVAILABLE_VISUALS,
            payload: visuals
        } as OrderBasketActions);

        const assignedImages = await get_assigned_images(orderId);
        dispatch({
            type: UPDATE_ASSIGNED_IMAGES,
            payload: assignedImages
        } as OrderBasketActions);
    }
}

export const getVat = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        if (client) {
            let mappedClient = client_map(client);
            dispatch({
                type: UPDATE_VAT,
                payload: mappedClient.vat / 100
            } as OrderBasketActions);
        }

    }
}