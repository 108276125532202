export interface Campaign {
    key?: string;
    id?: string;
    title?: string;
    description?: string;
    start_date: string;
    end_date: string;
    image?: string;
    images: Visual[];
    assigned_visuals?: AssignedVisuals;
    status?: string;
    reference?: string;
    user_id?: string;
    user_name?: string;
}

export interface Store {
    key?: string;
    customer_no?: string;
	id?: string;
    name: string;
    name2: string;
    direccion: string;
    direccion2: string;
    ciudad: string;
    telefono: string;
	pais: string;
    provincia: string;
    codigo_postal: string;
    email: string;
    home_page: string;
    customer_shop_no: string;
    es_centro_distribucion: boolean;
    pisos: number;
    grupo_tienda: string;
    tipo_tienda: string;
    status: string;
    supports?: Support[];
    campaigns?: Campaign[];
    current_campaign?: Campaign;
    users?: Partial<User>[]
}

export interface Support {
    key?: string;
    id?: string;
    name?: string;
    customer_id?: string;
    store_id?: string;
    width?: number;
    height?: number;
    depth?: number;
    comments?: string;
    section?: string;
    section_id?: string;
    support_type?: string;
    production_language?: string;
    color?: string;
    priority?: string;
    textil_type?: string;
    material_id?: string;
    reference?: string;
    campaigns?: Campaign[];
    store?: Store;
    no_soporte_cliente?: string;
    visual_url?: string;
    doble_cara?: string;
}

export interface Visual {
    key: string;
    id: string;
    name: string;
    image: string;
    section: string;
    priority: string;
    original_height?: string;
    original_width?: string;
    campaign_id: string;
    production_language: string;
    has_assignation_error: boolean;
}

export interface Section {
    key: string,
    id: string;
    description: string,
    type: string,
    collapsed: boolean;
    customer_no: string,
}

export interface NavElement {
    id: string;
    label: string;
    active: boolean;
    iconClass: string;
    url: string;
    selectedString: string;
}

export enum CampaignStatus {
    DRAFT = "Draft",
    LAUNCH = "Launch",
    QUOTE = "Quote",
    APPROVED = "Approved",
    LIVE = "Live",
    END = "End",
}

export enum StoreStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
    NO_STATUS = '0',
}


export enum OrderStatus {
    CONFIRMED = 'Confirmed',
    PENDING = 'Pending',
    CANCELLED = 'Cancelled',
    DRAFT = 'Draft',
    LAUNCHED = 'Launch',
}

export interface SelectOption {
    value: string;
    name: string;
}

export interface Client {
    key: string;
    id: string;
    name: string;
    name2: string;
    search_name: string;
    address: string;
    address2: string;
    city: string;
    contact: string;
    phone_no: string;
    country_region_code: string;
    fax_no: string;
    vat_registration_no: string;
    post_code: string;
    county: string;
    email: string;
    home_page: string;
    assignation_type: string;
    vat: number;
    rate: number;
}

export interface AssignedVisuals {
    [index: string]: Visual & { 'amount': string, 'quantity': string, 'unit_price': string, 'shipping_price': string, 'country': string }
}

export class FilterCampaign {
    start_date?: number;
    end_date?: number;
    postal_code?: string;
    city?: string;
    region?: string;
    country?: string;
    status?: string;
    store_type?: string;
    support_type?: string;
}

export class FilterStore {
    customer_shop_no?: string;
    name?: string;
    postal_code?: string;
    city?: string;
    region?: string;
    country?: string;
    store_type?: string;
    support_type?: string;
    text?: string;
    section?: string;
    priority?: string;
    production_language?: string;
    status?: string;
}

export class FilterSupport {
    id?: string;
    text?: string;
    city?: string;
    country?: string;
    support_type?: string;
    store_type?: string;
    section?: string;
    priority?: string;
    production_language?: string;
    textil_type?: string;
    store_id?: string;
    store_text?: string;
    no_soporte_cliente?: string;
    width?: string;
    height?: string;
    depth?: string;
}
export class FilterVisual {
    name?: string;
    prepared?: string;
    assigned?: string;
    section?: string;
    priority?: string;
}

export class FilterStoreAndSupport {
    name?: string;
    direction?: string;
    postal_code?: string;
    city?: string;
    region?: string;
    country?: string;
    area?: string;
    store_type?: string;
    support_type?: string;
    support_state?: string;
}

export class FilterCampaignAndVisual {
    title?: string;
    ids?: string[];
    section?: string;
    priority?: string;
    production_language?: string;
}

export interface User {
    id: string;
    name: string;
    last_name: string;
    email: string;
    role?: string;
    phone: string;
    password: string;
    photo?: string;
    language?: string;
    customer?: string;
    position: string;
    stores: Store[],
}

export interface Country {
    key: string;
    id: string;
    name: string;
}

export interface ProductionLanguage {
    key: string;
    id: string;
    name: string;
}

export class FilterOrder {
    name?: string;
    status?: string;
    start_date?: number;
    end_date?: number;
    user?: string;
}

export interface Order {
    id: string;
    reference: string;
    kendu_reference: string;
    stores: number;
    visuals: number;
    date: string;
    user_id?: string;
    user_name?: string;
    status: string;
    store_list?: Store[];
}