import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Campaign } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { status_pill_class } from '../util/campaign';

export interface CampaignCardProps {
    campaign: Campaign
}

type CampaignCardPropsSum = CampaignCardProps & WithTranslation;

class CampaignCardComponent extends React.Component<CampaignCardPropsSum> {

    cardLink(id: string|undefined) {
        if (id === undefined) {
            return '#';
        }
        return `/campaigns/${id}`;
    }

    render () {
        const { id, image, title, start_date, end_date,status } = this.props.campaign;

        return (
            <Link to={this.cardLink(id)} key={id} className="basic-card basic-card--16 campaing-card">
                <div className="campaing-card-left">
                    <div className="card-img card-img--64" style={{ backgroundImage: `url(${image || '/img/img-placeholder.png'})` }}></div>
                    <div className="campaing-title-section">
                        <p className="headline-2--regular">{title}</p>
                        <p className="text-3--regular campaing-subtitle">{moment(start_date).format('ll')} › {moment(end_date).format('ll')}</p>
                    </div>
                </div>
                <div className="campaing-card-right">
                    <p className={'text-3--semibold status-pill ' + status_pill_class(status || '')}>{status}</p>
                </div>
            </Link>
        );
    };
}

const CampaignCard = withTranslation()(CampaignCardComponent);
export { CampaignCard };
