import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Order, FilterOrder, SelectOption } from '../../../interfaces/interfaces';

export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_STATUSES = 'UPDATE_STATUSES';
export const UPDATE_USERS = 'UPDATE_USERS';

export interface UpdateOrdersAction {
    type: typeof UPDATE_ORDERS,
    payload: Order[]
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterOrder
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}
export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterOrder
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}
export interface UpdateStatusesAction {
    type: typeof UPDATE_STATUSES,
    payload: SelectOption[]
}
export interface UpdateUsersAction {
    type: typeof UPDATE_USERS,
    payload: SelectOption[]
}

export type OrderIndexActions =
UpdateOrdersAction |
UpdateLoadingAction |
UpdateFilterAction |
ClearFilterAction |
UpdateStatusesAction |
UpdateUsersAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
