import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, FilterCampaignAndVisual, Support } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
export const UPDATE_ASSIGNATION_TYPE = 'UPDATE_ASSIGNATION_TYPE';
export const UPDATE_SUPPORT = 'UPDATE_SUPPORT';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateFilter {
    type: typeof UPDATE_FILTER,
    payload: FilterCampaignAndVisual
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateCampaignsAction {
    type: typeof UPDATE_CAMPAIGNS,
    payload: Campaign[]
}

export interface UpdateAssignationTypeAction {
    type: typeof UPDATE_ASSIGNATION_TYPE,
    payload: string
}

export interface UpdateSupportAction {
    type: typeof UPDATE_SUPPORT,
    payload: Support
}

export type OrderAssignVisualActions =
UpdateLoadingAction |
UpdateFilter |
ClearFilterAction |
UpdateCampaignsAction |
UpdateAssignationTypeAction |
UpdateSupportAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>