import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';

export interface CampaignConfirmModalProps {
    visualsCount: number,
    supportsCount: number,
    assignedImagesCount: number,
    onClose: () => void,
    onSubmit: () => void
}

interface State {
}

type CampaignConfirmModalPropsSum = CampaignConfirmModalProps & WithTranslation;

class CampaignConfirmModalComponent extends React.Component<CampaignConfirmModalPropsSum, State> {

    constructor(props: CampaignConfirmModalPropsSum) {
        super(props);
        this.state = {};
    }

    renderPendingSupportsAlert() {
        const { t, supportsCount, assignedImagesCount } = this.props;
        if (supportsCount - assignedImagesCount > 0) {
            return (
                <div className="form-row-content">
                    <Alert severity="warning">{ t('there_are_supports_without_visual') }</Alert>
                </div>
            );
        }
    }

    render () {
        const { t, visualsCount, supportsCount, assignedImagesCount } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{ t('confirmation') }</p>
                        </div>

                        <div className="pop-box-content">

                            <div className="form-row-content">
                                <p className="">{ t('confirmation_explanation') }</p>
                            </div>

                            {this.renderPendingSupportsAlert()}

                            <div className="table-row-content">
                                <div className="table-row">
                                    <div className="table-row-left">{ t('images') }</div>
                                    <div className="table-row-right">{ visualsCount }</div>
                                </div>
                                <div className="table-row">
                                    <div className="table-row-left">{ t('supports') }</div>
                                    <div className="table-row-right">{ supportsCount }</div>
                                </div>
                                <div className="table-row">
                                    <div className="table-row-left">{ t('no_image_supports') }</div>
                                    <div className="table-row-right">{ supportsCount - assignedImagesCount }</div>
                                </div>
                            </div>

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.props.onSubmit()}>{ t('launch_order') }</button>
                                <button className="btn--secondary btn--l close-pop" onClick={()=> {this.props.onClose()}}>{ t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const CampaignConfirmModal = withTranslation()(CampaignConfirmModalComponent);
export { CampaignConfirmModal };
