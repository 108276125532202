import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Support, FilterSupport } from '../../../interfaces/interfaces';

export const UPDATE_SUPPORTS = 'update_supports';
export const APPEND_SUPPORTS = 'append_supports';
export const UPDATE_FILTER_SHOW = 'update_filter_show';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_FILTER = 'update_filter';
export const CLEAR_FILTER = 'clear_filter';

export interface UpdateSupportsAction {
    type: typeof UPDATE_SUPPORTS,
    payload: Support[]
}

export interface AppendSupportsAction {
    type: typeof APPEND_SUPPORTS,
    payload: Support[]
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateFilterShowAction {
    type: typeof UPDATE_FILTER_SHOW,
    payload: boolean
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterSupport
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type SupportIndexActions =
UpdateSupportsAction |
AppendSupportsAction |
UpdateLoadingAction |
UpdateFilterShowAction |
UpdateFilterAction |
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
