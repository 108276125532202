import { Visual, Support, AssignedVisuals } from "../interfaces/interfaces";

export const checkSizes = (visual: Visual, support: Support) : boolean =>{
    const margin = 23;

    const visual_height = parseInt(visual.original_height || '0');
    const visual_width = parseInt(visual.original_width || '0');
    const support_height = parseInt((support?.height || 0).toString());
    const support_width = parseInt((support?.width || 0).toString());

    if (
        support_height <= visual_height && (support_height + margin) >= visual_height &&
        support_width <= visual_width && (support_width + margin) >= visual_width
    ) {
        return true;
    }

    return false;
}

export const hasAssignationError = (support: Support, assignedVisuals: AssignedVisuals) : boolean => {
    return ((!!support && !!support.id && assignedVisuals[support.id] && assignedVisuals[support.id].has_assignation_error) || false);
}