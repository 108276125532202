import React from 'react';
import { InputText, InputNumber, InputSelect } from './';
import { Support, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { is_empty_string } from "../util/form";

export interface SupportAddModalProps {
    support?: Support,
    supportTypes: SelectOption[],
    colors: SelectOption[],
    textilTypes: SelectOption[],
    productionLanguages: SelectOption[],
    sections: SelectOption[],
    priorities: SelectOption[],
    onClose: () => void,
    onSubmit: (support: Support) => void,
}

interface State {
    id: string,
    name: string,
    no_soporte_cliente: string,
    reference: string,
    support_type: string,
    production_language: string,
    color: string,
    textil_type: string,
    material_id: string,
    width: number,
    height: number,
    depth: number,
    priority: string,
    floor: number,
    section: string,
    support_name_error: string,
    support_support_type_error: string,
    support_production_language_error: string,
    support_textil_type_error: string,
    support_section_error: string,
    support_width_error: string,
    support_height_error: string,
    support_depth_error: string,
    support_priority_error: string,
    support_size_error: string,
    support_reference_error: string,
    support_id_support_error: string,
    support_color_error: string,
}

type SupportAddModalPropsSum = SupportAddModalProps & WithTranslation;

class SupportAddModalComponent extends React.Component<SupportAddModalPropsSum, State> {

    constructor(props: SupportAddModalPropsSum) {
        super(props);

        this.state = {
            id: '',
            name: '',
            no_soporte_cliente: '',
            reference: '',
            support_type: '',
            production_language: '',
            color: '',
            textil_type: '',
            material_id: '',
            width: 0,
            height: 0,
            depth: 0,
            priority: '',
            floor: 0,
            section: '',
            support_name_error: '',
            support_support_type_error: '',
            support_textil_type_error: '',
            support_production_language_error: '',
            support_section_error: '',
            support_width_error: '',
            support_height_error: '',
            support_depth_error: '',
            support_priority_error: '',
            support_size_error: '',
            support_reference_error: '',
            support_id_support_error: '',
            support_color_error: '',
        };
    }

    componentDidMount() {
        const { support } = this.props;
        this.setState({ production_language: support?.production_language || '1',});
        if (support !== undefined) {
            this.setState({
                id: support.id || '',
                name: support.name || '',
                no_soporte_cliente: support.no_soporte_cliente || '',
                reference: support.reference || '',
                support_type: support.support_type || '',
                color: support.color || '',
                textil_type: support.textil_type || '',
                material_id: support.material_id || '',
                width: support.width || 0,
                height: support.height || 0,
                depth: support.depth || 0,
                section: support.section || '',
                priority: support.priority || '',
            });
        }
    }

    onSubmit() {
        if (this.isValid()) {
            this.props.onSubmit({
                ...this.props.support,
                ...this.state,
                campaigns: undefined,
                store: undefined
            });
        }
    }

    isValid() {
        const { t } = this.props;
        const { name, support_type, textil_type, material_id, section, height, width, priority } = this.state;
        let isValid = true;

        this.setState({
            support_name_error: '',
            support_support_type_error: '',
            support_textil_type_error: '',
            support_production_language_error: '',
            support_section_error: '',
            support_width_error: '',
            support_height_error: '',
            support_depth_error: '',
            support_priority_error: '',
            support_size_error: '',
            support_reference_error: '',
            support_id_support_error: '',
            support_color_error: '',
        });

        if (is_empty_string(name)) {
            isValid = false;
            this.setState({ support_name_error: t('support_name_error') });
        }
        if (is_empty_string(textil_type) || is_empty_string(material_id) ) {
            isValid = false;
            this.setState({ support_textil_type_error: t('support_textil_type_error') });
        }
        if (is_empty_string(support_type)) {
            isValid = false;
            this.setState({ support_support_type_error: t('support_support_type_error') });
        }
        if (is_empty_string(section)) {
            isValid = false;
            this.setState({ support_section_error: t('support_section_type_error') });
        }
        if (height === 0) {
            isValid = false;
            this.setState({ support_height_error: t('support_height_error') });
        }
        if (width === 0) {
            isValid = false;
            this.setState({ support_width_error: t('support_width_error') });
        }
        if (is_empty_string(priority)) {
            isValid = false;
            this.setState({ support_priority_error: t('support_priority_error') });
        }
       

        return isValid;
    }

    render () {
        const { name, reference, support_type, color, material_id, width, height, depth, section, priority, no_soporte_cliente, production_language } = this.state;
        const { t } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg pop-bg-big">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{this.props.support === undefined ? t('create_support') : t('edit_support')}</p>
                            <i className="icon icon-16-x close-pop" onClick={() => this.props.onClose()}></i>
                        </div>

                        <div className="pop-box-content">

                            <div className="form-row-content">
                                <div className="form-row">
                                    <InputText id="name" label={ t('support_name') } value={name} onChange={(e) => this.setState({ name: e.target.value })} helpText={this.state.support_name_error}/>
                                </div>
                                <div className="form-row">
                                    <InputText id="reference" label={ t('reference') } value={reference} onChange={(e) => this.setState({ reference: e.target.value })} />
                                </div>
                                <div className="form-row">
                                    <InputText id="reference" label={ t('support_id') } value={no_soporte_cliente} onChange={(e) => this.setState({ no_soporte_cliente: e.target.value })} />
                                </div>
                                <div className="form-row-content grid-layout--small">
                                    <div className="form-row grid-half-1">
                                        <InputSelect id="support-type" label={ t('support_type') } value={support_type} emptyValue={true} options={this.props.supportTypes} onChange={(value) => this.setState({ support_type: value || '' })} helpText={this.state.support_support_type_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputSelect id="color" label={ t('color') } value={color} emptyValue={true} options={this.props.colors} onChange={(value) => this.setState({ color: value || '' })} />
                                    </div>
                                    <div className="form-row grid-half-1">
                                        <InputSelect id="textil-type" label={ t('textil_type') } value={material_id} emptyValue={true} options={this.props.textilTypes} onChange={(value, label) =>{ this.setState({  textil_type: label || '', material_id: value || ''})}} helpText={this.state.support_textil_type_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputSelect id="section" label={ t('section') } value={section} emptyValue={true} options={this.props.sections} onChange={(value) => this.setState({ section: value || '' })} helpText={this.state.support_section_error}/>
                                    </div>
                                    <div className="form-row grid-half-1">
                                        <InputSelect id="production-language" label={ t('production_language') } value={production_language} emptyValue={false} options={this.props.productionLanguages} onChange={(value) => this.setState({ production_language: value || '' })} helpText={this.state.support_production_language_error}/>
                                    </div>
                                </div>
                            </div>

                            <p className="text-3--regular form-group-title">{ t('dimensions') }</p>

                            <div className="form-row-content grid-layout--small">
                                <div className="form-row grid-half-1">
                                    <InputNumber id="width" label={ t('width') } value={width} onChange={(e) => this.setState({ width: parseFloat(e.target.value) })} helpText={this.state.support_width_error}/>
                                </div>
                                <div className="form-row grid-half-2">
                                    <InputNumber id="height" label={ t('height') } value={height} onChange={(e) => this.setState({ height: parseFloat(e.target.value) })} helpText={this.state.support_height_error}/>
                                </div>
                                <div className="form-row grid-half-1">
                                    <InputNumber id="depth" label={ t('depth') } value={depth} onChange={(e) => this.setState({ depth: parseFloat(e.target.value) })} helpText={this.state.support_depth_error}/>
                                </div>
                            </div>

                            <div className="form-row-content grid-layout--small">

                                <p className="text-3--regular form-group-title">{ t('priority') }</p>
                                <div className="form-row grid-all">
                                    <InputSelect id="priority" label={ t('priority') } value={priority} emptyValue={true} options={this.props.priorities} onChange={(value) => this.setState({ priority: value || '' })} helpText={this.state.support_priority_error}/>
                                </div>

                            </div>

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.onSubmit()}>{this.props.support === undefined ? t('create') : t('edit')}</button>
                                <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClose()}>{ t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const SupportAddModal = withTranslation()(SupportAddModalComponent);
export { SupportAddModal };
