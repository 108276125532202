import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { AssignedVisuals, FilterStore, Store, Visual } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_STORES = 'update_stores';
export const APPEND_STORES = 'append_stores';
export const UPDATE_FILTER = 'update_filter';
export const CLEAR_FILTER = 'clear_filter';
export const UPDATE_ASSIGNED_IMAGES = 'UPDATE_ASSIGNED_IMAGES';
export const UPDATE_AVAILABLE_VISUALS = 'UPDATE_AVAILABLE_VISUALS';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface AppendStoresAction {
    type: typeof APPEND_STORES,
    payload: Store[]
}

export interface UpdateFilter {
    type: typeof UPDATE_FILTER,
    payload: FilterStore
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateAssignedImagesAction {
    type: typeof UPDATE_ASSIGNED_IMAGES,
    payload: AssignedVisuals
}

export interface UpdateAvailableVisualsAction {
    type: typeof UPDATE_AVAILABLE_VISUALS,
    payload: Visual[]
}

export type OrderNewOrderActions =
UpdateLoadingAction |
UpdateStoresAction |
AppendStoresAction |
UpdateFilter |
ClearFilterAction |
UpdateAssignedImagesAction |
UpdateAvailableVisualsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
