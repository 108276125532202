import {
    UPDATE_LOADING,
    UPDATE_USER,
    UPDATE_USER_INFO_SHOW,
    UPDATE_USER_EDIT_MODAL_SHOW,
    UserDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, User } from '../../../interfaces/interfaces';

export interface CampaignIndexState {
    loading: boolean,
    user: User | null,
    userInfoShow: boolean,
    userEditModalShow: boolean,
    selectedLanguage: string,
    stores: Store[],
}

const INITIAL_STATE: CampaignIndexState = {
    selectedLanguage: '',
    loading: false,
    user: null,
    userInfoShow: true,
    userEditModalShow: false,
    stores: [],
};

const reducer: Reducer<CampaignIndexState, UserDetailActions> = (state = INITIAL_STATE, action: UserDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO_SHOW:
            return { ...state, userInfoShow: action.payload };
        case UPDATE_USER_EDIT_MODAL_SHOW:
            return { ...state, userEditModalShow: action.payload };
        default:
            return state;
    }
}

export default reducer;