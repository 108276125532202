import React from 'react';
import { AssignedVisuals, SelectOption, Visual } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputSelect, VisualImage, VisualPreview } from '.';
import { is_ready, is_section_assigned, is_priority_assigned, is_production_language_assigned } from '../util/visual';
import { remove_decimals } from '../util/number';

export interface VisualEditProps {
    visual: Visual,
    sections: SelectOption[],
    priorities: SelectOption[],
    production_languages: SelectOption[],
    assignedImages: AssignedVisuals,
    assignationType: string,
    onChange: (value: Visual) => void,
}

type VisualEditPropsSum = VisualEditProps & WithTranslation;

interface VisualEditState {
    showPreview: boolean,
}

class VisualEditComponent extends React.Component<VisualEditPropsSum, VisualEditState> {

    constructor(props: VisualEditPropsSum) {
        super(props);

        this.state = {
            showPreview: false,
        };
    }

    renderPreview() {
        const { visual } = this.props;
        if (this.state.showPreview) {
            return (
                <VisualPreview visual={visual} onClose={() => this.setState({ showPreview: !this.state.showPreview })} />
            );
        }
    }

    renderStatus() {

		const { assignedImages, assignationType, visual } = this.props;
		const { t }  = this.props;

		if (Object.values(assignedImages).find(element => element.id === visual.id)) {
            return <p className={'text-3--semibold status-pill status-pill--assigned'}>{ t('assigned') }</p>
		} else if (is_ready(visual, assignationType)) {
            return <p className={'text-3--semibold status-pill status-pill--live'}>{ t('ready') }</p>
		} else {
            return <p className={'text-3--semibold status-pill status-pill--not-assigned'}>{ t('not_ready') }</p>
		}
    }

    renderDetails() {
        const { assignationType } = this.props;
        let details =  [];

        let selectPosition = 1;
        let className="grid-third-" + selectPosition;
        if (is_section_assigned(assignationType)) {
            details.push(<div className={className}>{this.renderSection()}</div>);
            selectPosition++;
        }
        className = "grid-third-" + selectPosition;
        if (is_priority_assigned(assignationType)) {
            details.push(<div className={className}>{this.renderPriority()}</div>);
        }
        className = "grid-third-" + selectPosition;
        if (is_production_language_assigned(assignationType)) {
            details.push(<div className={className}>{this.renderProductionLanguage()}</div>)
        }
        return(details);
    }

    renderSection() {
        const { visual, t } = this.props;
        return (
            <InputSelect id={`section-${visual.key}`} label={t('section')} options={this.props.sections} value={visual.section||''} emptyValue={true} onChange={(value?: string) => {
                this.props.onChange({ ...visual, section: value||'' });
            }} />
        );
    }
    renderPriority() {
        const { visual, t } = this.props;
        return (
            <InputSelect id={`priority-${visual.key}`} label={t('priority')} options={this.props.priorities} value={visual.priority||''} emptyValue={true} onChange={(value?: string) => {
                this.props.onChange({ ...visual, priority: value||'' });
            }} />
        );
    }
    renderProductionLanguage() {
        const { visual, t } = this.props;
        return (
            <InputSelect id={`production-language-${visual.key}`} label={t('production_language')} options={this.props.production_languages} value={visual.production_language||'1'} emptyValue={false} onChange={(value?: string) => {
                this.props.onChange({ ...visual, production_language: value ||'' });
            }} />
        );
    }

    render () {
        const { visual} = this.props;

        return (
            <>
                {this.renderPreview()}
                <div className="store-row--hardware visual-edit-card">

                    <div className="hardware-element-left visual-edit-card-left">
                        <VisualImage visual={visual} className="card-img card-img--48" onClick={() => this.setState({ showPreview: !this.state.showPreview })} />
                    </div>

                    <div className="hardware-element-right visual-edit-card-rigth">
                        <div className="visual-edit-card-top">
                            <div className="hardware-info">
                                <p className="text-1--regular hardware-title">{visual.name} <span className="text-3--regular" style={{marginLeft: '8px'}}>{remove_decimals(Number(visual.original_width))} x {remove_decimals(Number(visual.original_height))}</span></p>
                            </div>
                            {this.renderStatus()}
                        </div>
                        <div className="visual-edit-card-bottom grid-layout--small">
                            {this.renderDetails()}
                        </div>
                    </div>

                </div>
            </>
        );
    };
}

const VisualEdit = withTranslation()(VisualEditComponent);
export { VisualEdit };
