import axios, { AxiosError, AxiosRequestConfig } from "axios";
import cookies from '../util/cookies';

export const with_auth_check = (callback: (value: AxiosError) => any) => {
    return (error: AxiosError) => {
        if (error.response?.status === 401) {
            cookies.remove('b2b-kendu-token');
        }
        return callback(error);
    }
}

export const with_auth = (config: AxiosRequestConfig = {}) => {
    return { ...config, headers: { ...config.headers, Authorization: `Bearer ${cookies.get('b2b-kendu-token') || ''}` }};
}

export const get_with_auth = (url: string, params = {}) => {
    return () => axios.get(url, with_auth(params)).then(response => {
        if (response.status === 200) {
            return response.data;
        }
        return [];
    }).catch(with_auth_check(error => {
        console.error(error);
        return [];
    }));
}