import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputText } from '.';
import Alert from '@material-ui/lab/Alert';
import { is_empty_string } from '../util/form';

export interface ContinueCancelModalProps {
    title: string,
    message: string,
    continueButtonText?: string,
    cancelButtonText?: string,
    onContinue: (reference: string) => void,
    onCancel: () => void
}

interface State {
    reference: string,
    reference_error: string,
}

type ContinueCancelModalPropsSum = ContinueCancelModalProps & WithTranslation;

class ContinueCancelModalComponent extends React.Component<ContinueCancelModalPropsSum, State> {

    constructor(props: ContinueCancelModalPropsSum) {
        super(props);

        this.state = {
            reference: '',
            reference_error: '',
        };
    }

    componentDidMount() {
    }

    isValid() {
        const { t } = this.props;
        const { reference } = this.state;

        this.setState({
            reference_error: ''
        });

        let isValid = true;
        if (is_empty_string(reference)) {
            isValid = false;
            this.setState({ reference_error: t('support_reference_error') });
        }
        return isValid;
    }

    continueClicked() {
        const { reference } = this.state;
        if (this.isValid()) {
            this.props.onContinue(reference);
        }
    }

    render () {
        const { t, title, message } = this.props;
        const { reference, reference_error } = this.state;
        return (
            <div className="pop-layout">
                <div className="pop-bg">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{title}</p>
                        </div>

                        <div className="pop-box-content">

                            <p className="text-3--regular form-group-title">{message}</p>
                            <div className="form-row-content">
                                <Alert severity="warning">{ t('reference_is_required_to_finish_the_order') }</Alert>
                            </div>
                            <div className="form-row-content">
                                <div className="form-row">
                                    <InputText id="campaing-name" label={ t('reference') } value={reference} onChange={(e) => this.setState({ reference: e.target.value })} helpText={reference_error}/>
                                </div>
                            </div>

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.continueClicked()}>{ this.props.continueButtonText ? this.props.continueButtonText : t('continue') }</button>
                                <button className="btn--primary btn--destruction btn--l close-pop" onClick={() => this.props.onCancel()}>{ this.props.cancelButtonText ? this.props.cancelButtonText : t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const ContinueCancelModal = withTranslation()(ContinueCancelModalComponent);
export { ContinueCancelModal };
