import React from 'react';
import { Support, AssignedVisuals, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportDetail, VisualPreview } from '.';
import { VisualImage } from './VisualImage';
import { hasAssignationError } from '../util/support';

export interface SupportCardActionProps {
    support: Support,
    assignedImages?: AssignedVisuals,
    isCampaignBlocked?: boolean,
    production_languages?: SelectOption[],
    onModalClicked?: (supportId: string) => void,
    onDeleteClicked?: (supportId: string) => void,
    onDeleteAssignation?: (supportId: string) => void
}

interface SupportCardActionState {
    showPreview: boolean,
}

type SupportCardActionPropsSum = SupportCardActionProps & WithTranslation;

class SupportCardActionsComponent extends React.Component<SupportCardActionPropsSum, SupportCardActionState> {

    constructor(props: SupportCardActionPropsSum) {
        super(props);

        this.state = {
            showPreview: false,
        };
    }

    renderAssignedImage() {
        const { support, assignedImages } = this.props;

		if (assignedImages && this.isAssigned()) {
			return (
                <div className="card-img card-img--visual" onClick={ (event) => event.preventDefault() }>
                    <VisualImage visual={assignedImages[support.id!]} onClick={() => this.setState({ showPreview: !this.state.showPreview })} />
                </div>
            );
		}
    }

    renderVisualPreview () {
        const { support, assignedImages } = this.props;
		if (assignedImages && this.isAssigned() && this.state.showPreview) {
			return(<VisualPreview visual={assignedImages[support.id!]} onClose={() => this.setState({ showPreview: !this.state.showPreview })} />);
		}
	}

    isAssigned() {
        const { support, assignedImages } = this.props;
        return assignedImages && !!assignedImages[support.id!].image;
    }

    renderAssignButton() {
        const { support, onModalClicked, onDeleteAssignation, t } = this.props;

        if (onModalClicked) {
            return (
                <>
                    {this.props.isCampaignBlocked &&
                        <button className={ this.isAssigned() ? `btn--secondary btn--s pop-reveal btn--disabled` : `btn--primary btn--s pop-reveal btn--disabled`}>{ this.isAssigned() ? t('reassign') : t('add_visual') }</button>}
                    {!this.props.isCampaignBlocked &&
                        <button className={ this.isAssigned() ? `btn--secondary btn--s pop-reveal` : `btn--primary btn--s pop-reveal`} onClick={() => onModalClicked(support.id!)}>{ this.isAssigned() ? t('reassign') : t('add_visual') }</button>}
                    {this.isAssigned() && onDeleteAssignation && !this.props.isCampaignBlocked ?
                        <button className="icon icon-16-unassign section-header-icon" onClick={() => onDeleteAssignation(support.id!)}></button> :
                        <button className="icon icon-16-unassign section-header-icon icon--disable"></button>}
                </>
            );
        }
    }

    renderDeleteAction() {
        const { support, onDeleteClicked } = this.props;

        if (onDeleteClicked) {
            return this.props.isCampaignBlocked ? <button className="icon icon-16-delete section-header-icon icon--disable"></button> : <button className="icon icon-16-delete section-header-icon" onClick={() => onDeleteClicked(support.id!)}></button>
        }
    }

    getErrorStyle(support: Support, assignedImages: AssignedVisuals) {
        if (hasAssignationError(support, assignedImages)) {
            return {
                border: "1px solid #EB4559"
            };
        }
        return {};
    }

    render () {
        const { support, production_languages, assignedImages } = this.props;
        const { support_type } = this.props.support;
        return (
            <>
                {this.renderVisualPreview()}
                <div className="store-row--hardware-view" style={this.getErrorStyle(support, assignedImages || {})}>
                    <div className="hardware-row-left">
                        <div className="card-img card-img--48" style={{backgroundImage: `url(${support.visual_url ?? '/img/img-hardware.png'})`}}></div>
                        {this.renderAssignedImage()}
                        <div className="hardware-info">
                            <p className="text-1--regular hardware-title">{support_type}</p>
                            <p className="text-3--regular hardware-subinfo"><SupportDetail support={support} production_languages={production_languages || []} /></p>
                        </div>
                    </div>
                    <div className="hardware-row-right">
                        {this.renderAssignButton()}
                        <div className="card-options">
                            {this.renderDeleteAction()}
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const SupportCardActions = withTranslation()(SupportCardActionsComponent);
export { SupportCardActions };
