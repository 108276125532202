import { Support } from '../../interfaces/interfaces';

export const support_map = (support: any): Support => ({
    key: support.key,
    id: support.id,
    name: support.name,
    height: support.height,
    width: support.width,
    depth: support.depth,
    reference: support.reference,
    support_type: support.support_type,
    color: support.color,
    textil_type: support.textil_type,
    material_id: support.material_id,
    store: support.store,
    store_id: support.store_id,
    section: support.section,
    section_id: support.section_id,
    no_soporte_cliente: support.no_soporte_cliente,
    production_language: support.production_language,
    doble_cara: support.doble_cara,
    visual_url: support.visual_url,
    priority: support.priority
});