import {
    UPDATE_SUPPORTS,
    APPEND_SUPPORTS,
    UPDATE_LOADING,
    UPDATE_FILTER_SHOW,
    UPDATE_FILTER,
    CLEAR_FILTER,
    SupportIndexActions,
    AppThunk
} from './ActionTypes';
import { get_supports } from '../../../api';
import { FilterSupportRequest } from '../../../api/interfaces';
import { support_map } from '../support.maps';
import { FilterSupport } from '../../../interfaces/interfaces';

export const updateSupports = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as SupportIndexActions);

        const filter = getState().supportIndex.filter;

        let supports = await get_supports({
            ...filter,
            store_ids: filter.store_id ? [filter.store_id] : [],
            key: lastKey
        } as FilterSupportRequest);

        supports = supports.map(support_map);

        dispatch({
            type: lastKey ? APPEND_SUPPORTS : UPDATE_SUPPORTS,
            payload: supports
        } as SupportIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as SupportIndexActions);
    }
}

export const updateFilterShow = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER_SHOW,
            payload: value
        } as SupportIndexActions);
    }
}

export const updateFilter = (value: FilterSupport): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as SupportIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as SupportIndexActions);
    }
}