import React from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { InputText, InputTextArea } from './';
import { Campaign } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { is_empty_string } from "../util/form";
import { is_blocked } from '../util/campaign';

export interface CampaignAddModalProps {
    campaign?: Campaign,
    onClose: () => void,
    onSubmit: (campaign: Campaign) => void,
    onDelete?: (id: string) => void
}

interface State {
    name: string | undefined,
    description: string | undefined,
    start_date: moment.Moment | null,
    end_date: moment.Moment | null,
    focused_input: 'startDate' | 'endDate' | null,
    campaign_name_error: string,
    reference: string,
}

type CampaignAddModalPropsSum = CampaignAddModalProps & WithTranslation;

class CampaignAddModalComponent extends React.Component<CampaignAddModalPropsSum, State> {

    constructor(props: CampaignAddModalPropsSum) {
        super(props);

        this.state = {
            name: '',
            description: '',
            start_date: null,
            end_date: null,
            focused_input: null,
            campaign_name_error: '',
            reference: '',
        };
    }

    componentDidMount() {
        const { campaign } = this.props;
        if (campaign !== undefined) {
            this.setState({
                name: campaign.title,
                description: campaign.description,
                start_date: moment(campaign.start_date),
                end_date: moment(campaign.end_date),
                reference: campaign.reference || '',
            });
        }
    }

    createClicked() {
        const { name, description, start_date, end_date, reference } = this.state;
        if (this.validate()) {
            this.props.onSubmit({
                id: this.props.campaign?.id,
                key: this.props.campaign?.key,
                title: name,
                description: description,
                start_date: start_date!.format(),
                end_date: end_date!.format(),
                images: this.props.campaign !== undefined ? this.props.campaign.images : [],
                reference: reference,
            });
        }

    }
    deleteCampaign(id: string) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    validate() {
		const { t } = this.props;
		const { name } = this.state;
        let isValid = true;

        this.setState({
            campaign_name_error: '',
        });

		if (is_empty_string(name)) {
            isValid = false;
            this.setState({ campaign_name_error:t('campaign_name_required')});
		}

		return isValid;
    }

    renderDeleteCampaignButton() {
        const { t } = this.props;
        if (this.props.campaign !== undefined) {
            return(
                <>
                    <p className="text-3--regular form-group-title">{ t('delete_campaign') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <p className="form-row text-2--regular">{ t('delete_notification') }</p>
                            <button className="btn--primary btn--destruction btn--s " onClick={() => { if (window.confirm(t('do_you_want_to_delete_this_campaign'))) this.deleteCampaign(this.props.campaign!!.id || '')}}>{ t('delete') }</button> 
                        </div>
                    </div>
                </>
            );
        }
    }

    field_is_blocked() {
        const { campaign } = this.props;
        return campaign !== undefined && is_blocked(campaign!);
    }

    render () {
        const { name, description, start_date, end_date, reference } = this.state;
        const { t, campaign } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{campaign === undefined ? t('create_campaign') : t('edit_campaign')}</p>
                        </div>

                        <div className="pop-box-content">

                            <div className="form-row-content">
                                <div className="form-row">
                                    {this.field_is_blocked() &&
                                        <InputText id="campaing-name" label={ t('campaign_name') } value={name} disabled={true} />}
                                    {!this.field_is_blocked() &&
                                        <InputText id="campaing-name" label={ t('campaign_name') } value={name} onChange={(e) => this.setState({ name: e.target.value })} helpText={this.state.campaign_name_error}/>}
                                </div>
                                <div className="form-row">
                                    <InputText id="reference" label={ t('reference') } value={reference} onChange={(e) => this.setState({ reference: e.target.value })}/>
                                </div>
                                <div className="form-row">
                                    {this.field_is_blocked() &&
                                        <InputTextArea id="description" label={ t('description') } value={description} disabled={true} />}
                                    {!this.field_is_blocked() &&
                                        <InputTextArea id="description" label={ t('description') } value={description} onChange={(e) => this.setState({ description: e.target.value })} />}
                                </div>
                            </div>

                            <p className="text-3--regular form-group-title">{ t('campaign_dates') }</p>

                            <div className="form-row-content">
                                <div className="form-row">
                                    <DateRangePicker
                                        block
                                        startDate={start_date}
                                        startDateId="start_date"
                                        endDate={end_date}
                                        endDateId="end_date"
                                        disabled={this.field_is_blocked()}
                                        onDatesChange={({ startDate, endDate }) => this.setState({ start_date: startDate, end_date: endDate })}
                                        focusedInput={this.state.focused_input}
                                        onFocusChange={focusedInput => this.setState({ focused_input: focusedInput })}
                                        openDirection="up"
                                    />
                                </div>
                            </div>
                            {!this.field_is_blocked() && this.renderDeleteCampaignButton()}
                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.createClicked()}>{this.props.campaign === undefined ? t('create') : t('edit')}</button>
                                <button className="btn--secondary btn--l close-pop" onClick={()=> {this.props.onClose()}}>{ t('cancel') }</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const CampaignAddModal = withTranslation()(CampaignAddModalComponent);
export { CampaignAddModal };
