import React from 'react';
import { MenuItem, InputLabel, FormControl, Select, FormHelperText } from '@material-ui/core';
import { SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputSelectProps {
    id: string;
    label: string;
    disabled?: boolean;
    options?: SelectOption[];
    value?: string;
    emptyValue?: boolean,
    helpText?: string,
    description?: string,
    onChange?: (value?: string, label?: string) => void
}

type InputSelectPropsSum = InputSelectProps & WithTranslation;

class InputSelectComponent extends React.Component<InputSelectPropsSum> {

    render () {
        let optionsList = this.props.options;
        if (this.props.emptyValue) {
            optionsList = [{ value: '', name: 'Vacío' }, ...this.props.options||[]];
        }
        return (
            <FormControl variant="outlined" style={{ margin: 1, minWidth: 120 }} fullWidth error={!!this.props.helpText}>
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Select
                    labelId={`${this.props.id}-label`}
                    disabled={this.props.disabled}
                    id={`${this.props.id}-select`}
                    value={this.props.value}
                    onChange={(e) => {this.props.onChange?.(e.target.value as string, optionsList?.find(option => option.value === e.target.value)?.name);} }
                    label={this.props.label}
                    variant="outlined"
                    inputProps={{
                        name: this.props.label,
                        id: this.props.id,
                    }}
                >
                    {(optionsList||[]).map(
                        option =>
                            <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{this.props.helpText}</FormHelperText>
            </FormControl>
        );
    };
}

const InputSelect = withTranslation()(InputSelectComponent);
export { InputSelect };
