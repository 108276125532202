import { AssignedVisuals, Visual } from "../interfaces/interfaces";

const orderPrefix = 'b2b-kendu-order';

export const readFromLocalStorage = (localOrderId: string, availableVisuals: Visual[]): AssignedVisuals => {
    // localStorage key syntax: "b2b-kendu-order-{orderId}-support-{supportId}"
    let elements = getOrderItems(localOrderId);

    let assignedImages = {};
    elements.map(function(element) {
        const supportId = element[0].substring(orderPrefix.length + 1 + localOrderId.length + 9, element[0].length);
        const visualId = element[1];
        const visual = availableVisuals.filter(visual => visual.id === visualId)[0];
        if (visual) {
           assignedImages = {
                ...assignedImages,
                [supportId]: visual
            };
        }
        return null;
    });
    return assignedImages;
}

export const write_order_assignation = (localOrderId: string, supportId: string, visualId: string): void => {
    localStorage.setItem(orderPrefix + '-' + localOrderId + '-support-' + supportId, visualId);
}

export const delete_order_assignation = (localOrderId: string, supportId: string): void => {
    localStorage.removeItem(orderPrefix + '-' + localOrderId + '-support-' + supportId);
}

export const delete_order = (localOrderId: string): void => {
    let elements = getOrderItems(localOrderId);
    elements.map(function(element) { localStorage.removeItem(element[0]); return null; });
}

export const get_local_order_to_process = (localOrderId: string): { supportId: string, visualId: string }[] => {
    let elements = getOrderItems(localOrderId);

    let result : { supportId: string, visualId: string }[] = [];
    elements.map(function(element) {
        const supportId = element[0].substring(orderPrefix.length + 1 + localOrderId.length + 9, element[0].length);;
        result.push({ supportId: supportId, visualId: element[1] });
        return null;
    });
    return result;
}

const getOrderItems = (localOrderId: string): [string, any][] => {
    const items = { ...localStorage };
    let elements = Object.entries(items);
    elements = elements.filter(element => element[0].startsWith(orderPrefix + '-' + localOrderId + '-'));
    return elements;
};