import { get_orders, get_users } from '../../../api';
import { FilterOrderRequest } from '../../../api/interfaces';
import { CampaignStatus, FilterOrder, User } from '../../../interfaces/interfaces';
import { user_map } from '../../settings/user.maps';
import { order_map } from '../order.maps';
import {
    UPDATE_ORDERS,
    UPDATE_LOADING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_STATUSES,
    UPDATE_USERS,
    OrderIndexActions,
    AppThunk,
} from './ActionTypes';

export const getOrders = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {

        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as OrderIndexActions);

        let orders = await get_orders({
            ...getState().orderIndex.filter,
            key: lastKey
        } as FilterOrderRequest);
        orders = orders.map(order_map);

        dispatch({
            type: UPDATE_ORDERS,
            payload: orders
        } as OrderIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as OrderIndexActions);

    }
}

export const updateFilter = (value: FilterOrder): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as OrderIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as OrderIndexActions);
    }
}

export const getStatuses = (): AppThunk => {
    return async dispatch => {
        const statuses = [
            { value: CampaignStatus.LIVE, name: 'Live' },
            { value: CampaignStatus.DRAFT, name: 'Draft' },
            { value: CampaignStatus.LAUNCH, name: 'Launch' },
            { value: CampaignStatus.QUOTE, name: 'Quote' },
            { value: CampaignStatus.END, name: 'End' },
            { value: CampaignStatus.APPROVED, name: 'Approved' },
        ];
        dispatch({
            type: UPDATE_STATUSES,
            payload: statuses
        } as OrderIndexActions);
    }
}

export const getUsers = (): AppThunk => {
    return async dispatch => {

        const users = await get_users();
        
        dispatch({
            type: UPDATE_USERS,
            payload: users.map(user_map).map((user: User) => ({ value: user.id, name: `${user.name} ${user.last_name}`}))
        } as OrderIndexActions);
    }
}