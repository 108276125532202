import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Client, SelectOption, Store, Support } from '../../../interfaces/interfaces';

export const UPDATE_CLIENT = 'update_client';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SHOW_DETAIL = 'update_show_detail';
export const UPDATE_STORES = 'UPDATE_STORES';
export const UPDATE_SUPPORTS = 'UPDATE_SUPPORTS';
export const UPDATE_SHOW_ADD_STORE_MODAL = 'UPDATE_SHOW_ADD_STORE_MODAL';
export const UPDATE_CAMPAIGNS_COUNT = 'UPDATE_CAMPAIGNS_COUNT';
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';

export interface UpdateClientAction {
    type: typeof UPDATE_CLIENT,
    payload: Client
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateShowDetailAction {
    type: typeof UPDATE_SHOW_DETAIL,
    payload: boolean
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface UpdateSupportsAction {
    type: typeof UPDATE_SUPPORTS,
    payload: Support[]
}

export interface UpdateShowAddStoreModalAction {
    type: typeof UPDATE_SHOW_ADD_STORE_MODAL,
    payload: boolean
}

export interface UpdateCampaignsCountAction {
    type: typeof UPDATE_CAMPAIGNS_COUNT,
    payload: number
}

export interface UpdateClientsAction {
    type: typeof UPDATE_CLIENTS,
    payload: SelectOption[]
}

export type ClientDetailActions =
UpdateClientAction |
UpdateLoadingAction |
UpdateShowDetailAction |
UpdateStoresAction |
UpdateSupportsAction |
UpdateShowAddStoreModalAction |
UpdateCampaignsCountAction |
UpdateClientsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
