import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SpinnerProps {
    message: string
}

type SpinnerPropsSum = SpinnerProps & WithTranslation;

class SpinnerComponent extends React.Component<SpinnerPropsSum> {

    render () {
        return (
            <div className="loader-content">
                <div className="sk-fading-circle">
                    <div className="sk-circle1 sk-circle"></div>
                    <div className="sk-circle2 sk-circle"></div>
                    <div className="sk-circle3 sk-circle"></div>
                    <div className="sk-circle4 sk-circle"></div>
                    <div className="sk-circle5 sk-circle"></div>
                    <div className="sk-circle6 sk-circle"></div>
                    <div className="sk-circle7 sk-circle"></div>
                    <div className="sk-circle8 sk-circle"></div>
                    <div className="sk-circle9 sk-circle"></div>
                    <div className="sk-circle10 sk-circle"></div>
                    <div className="sk-circle11 sk-circle"></div>
                    <div className="sk-circle12 sk-circle"></div>
                </div>
                <p className="text-2--semibold">{this.props.message}</p>
            </div>
        );
    };
}

const Spinner = withTranslation()(SpinnerComponent);
export { Spinner };
