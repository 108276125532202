import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Store, Support, SelectOption } from '../../../interfaces/interfaces';

export const UPDATE_STORE = 'update_stores';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_STORE_INFO_SHOW = 'update_store_info_show';
export const UPDATE_SUPPORTS = 'UPDATE_SUPPORTS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_SECTION_ADD_MODAL_SHOW = 'update_sectioin_add_modal_show';
export const UPDATE_STORE_EDIT_MODAL_SHOW = 'update_store_edit_modal_show';
export const UPDATE_SUPPORT_ADD_MODAL_SHOW = 'update_support_add_modal_show';
export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';

export interface UpdateStoreAction {
    type: typeof UPDATE_STORE,
    payload: Store
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateStoreInfoShowAction {
    type: typeof UPDATE_STORE_INFO_SHOW,
    payload: boolean
}

export interface UpdateSupportsAction {
    type: typeof UPDATE_SUPPORTS,
    payload: Support[]
}

export interface UpdateUsersAction {
    type: typeof UPDATE_USERS,
    payload: Support[]
}

export interface UpdateSectionAddModalShowAction {
    type: typeof UPDATE_SECTION_ADD_MODAL_SHOW,
    payload: boolean
}

export interface UpdateStoreEditModalShowAction {
    type: typeof UPDATE_STORE_EDIT_MODAL_SHOW,
    payload: boolean
}

export interface UpdateSupportAddModalShowAction {
    type: typeof UPDATE_SUPPORT_ADD_MODAL_SHOW,
    payload: boolean
}

export interface UpdateClientsAction {
    type: typeof UPDATE_CLIENTS,
    payload: SelectOption[]
}

export type StoreDetailActions =
UpdateStoreAction |
UpdateLoadingAction |
UpdateStoreInfoShowAction |
UpdateSupportsAction |
UpdateUsersAction |
UpdateSectionAddModalShowAction |
UpdateStoreEditModalShowAction |
UpdateSupportAddModalShowAction |
UpdateClientsAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
