import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SaveingModalProps {
    message: string
}

type SaveingModalPropsSum = SaveingModalProps & WithTranslation;

class SaveingModalComponent extends React.Component<SaveingModalPropsSum> {
    render () {
        return (
            <div className="pop-new-image" onClick={ (event) => event.preventDefault()}>
                <div className="pop-bg">
                    <div className="pop-box--place">
                        {/* <p>{this.props.message}</p> */}
                        {/* <img src={this.props.image} className="pop-image" alt="" /> */}
                        <div className="loader-content">
                            <div className="sk-fading-circle">
                                <div className="sk-circle1 sk-circle"></div>
                                <div className="sk-circle2 sk-circle"></div>
                                <div className="sk-circle3 sk-circle"></div>
                                <div className="sk-circle4 sk-circle"></div>
                                <div className="sk-circle5 sk-circle"></div>
                                <div className="sk-circle6 sk-circle"></div>
                                <div className="sk-circle7 sk-circle"></div>
                                <div className="sk-circle8 sk-circle"></div>
                                <div className="sk-circle9 sk-circle"></div>
                                <div className="sk-circle10 sk-circle"></div>
                                <div className="sk-circle11 sk-circle"></div>
                                <div className="sk-circle12 sk-circle"></div>
                            </div>
                            <p className="text-2--semibold">{this.props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const SaveingModal = withTranslation()(SaveingModalComponent);
export { SaveingModal };
