import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import moment from 'moment';
import {
	updateStore,
	updateStoreInfoShow,
	swapSectionCollapsed,
	updateSectionAddModalShow,
	updateStoreEditModalShow,
	updateSupportAddModalShow,
	getClients,
} from './StoreDetailActions';
import { getCountries, getStoreTypes, getProductionLanguages, getSections, getSupportTypes, getTextileTypes, getColors, getPriorities } from '../../../reducers/datatypes/DatatypesActions';
import { StoreStatus, Section, Support, SelectOption } from '../../../interfaces/interfaces';
import {
	Spinner,
	Breadcrumb,
	Timeline,
	CollapsedArrow,
	BackArrow,
	StoreAddModal,
	SupportAddModal,
	SupportCardActions,
} from '../../../components';
import { create_support, edit_store } from '../../../api';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { is_central } from '../../../util/jwt';
import { section_map } from '../../../util/select_maps';
import { statusPillClass, statusName} from '../../../util/store';

const mapStateToProps = (state: RootState) => {
	return {
		store_detail_state: state.storeDetail,
		auth_state: state.auth,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateStore,
	updateStoreInfoShow,
	swapSectionCollapsed,
	updateSectionAddModalShow,
	updateStoreEditModalShow,
	updateSupportAddModalShow,
	getStoreTypes,
	getProductionLanguages,
	getSupportTypes,
	getColors,
	getTextileTypes,
	getSections,
	getPriorities,
	getClients,
	getCountries
});

enum tabOptions { bySection, byPriority };

interface ParamTypes {
	id: string,
	customer_id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	currentTab: tabOptions,
	prioritiesCollapse: { name: string, collapse: boolean }[],
}

class Detail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			currentTab: tabOptions.bySection,
			prioritiesCollapse: [],
		};
	}

	componentDidMount() {
		this.props.updateStore(this.props.match.params.id, this.props.match.params.customer_id);
		this.props.getStoreTypes();
		this.props.getProductionLanguages();
		this.props.getSupportTypes();
		this.props.getColors();
		this.props.getTextileTypes();
		this.props.getSections();
		this.props.getPriorities();
		this.props.getClients();
		this.props.getCountries();
	}

	showTab(option: tabOptions) {
		this.setState({ currentTab: option });
	}

	selectedTabClass(option: tabOptions) {
        return this.state.currentTab === option ? 'headline-4--semibold tab-bar-element tab-bar-element--active' : 'headline-4--semibold tab-bar-element';
	}

	swapSectionCollapsed(section: Section) {
		this.props.swapSectionCollapsed(section);
	}

	renderStoreEditModal() {
		const { storeEditModalShow, store, clients } = this.props.store_detail_state;
		const { countries, store_types } = this.props.datatypes;

		if (storeEditModalShow) {
			return (
				<StoreAddModal
					store={store!}
					storeTypes={store_types}
					is_central={is_central(this.props.auth_state.token!)}
					clients={clients}
					countries={countries}
					onClose={() => this.props.updateStoreEditModalShow(!this.props.store_detail_state.storeEditModalShow)}
					onSubmit={(store) => {
						edit_store(store).then(() => {
							this.props.updateStoreEditModalShow(!this.props.store_detail_state.storeEditModalShow)
							this.props.updateStore(this.props.match.params.id, this.props.match.params.customer_id);
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderSupportAddModal() {
		if (this.props.store_detail_state.supportAddModalShow) {
			return (
				<SupportAddModal
					supportTypes={this.props.datatypes.support_types}
					colors={this.props.datatypes.colors}
					textilTypes={this.props.datatypes.textile_types}
					productionLanguages={this.props.datatypes.production_languages}
					sections={this.props.datatypes.sections.map(section_map)}
					priorities={this.props.datatypes.priorities}
					onClose={() => this.props.updateSupportAddModalShow(!this.props.store_detail_state.supportAddModalShow)}
					onSubmit={(support: Support) => {
						create_support({ ...support, store_id: this.props.store_detail_state.store?.id, customer_id: this.props.store_detail_state.store?.customer_no }).then(() => {
							this.props.updateSupportAddModalShow(!this.props.store_detail_state.supportAddModalShow)
							this.props.updateStore(this.props.match.params.id, this.props.match.params.customer_id);
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderStoreInfo() {
		const { t } = this.props;
		const { store } = this.props.store_detail_state;
		const { countries, store_types } = this.props.datatypes;

		if (this.props.store_detail_state.storeInfoShow) {
			return (
				<div className="info-card-content grid-layout">
					<div className="info-row grid-half-1">
						<p className="info-row-label text-3--regular">{ t('store_concept') }</p>
						<p className="info-row-data text-2--regular">{store?.name2}</p>
					</div>
					<div className="info-row grid-half-2">
						<p className="info-row-label text-3--regular">{ t('localization') }</p>
						<p className="info-row-data text-2--regular">{store?.direccion}</p>
						<p className="info-row-data text-2--regular">{store?.ciudad} - {store?.codigo_postal}</p>
						<p className="info-row-data text-2--regular">{countries.find(country => country.id === (store?.pais || ''))?.name || ''}</p>
					</div>
					<div className="info-row grid-sixth-1">
						<p className="info-row-label text-3--regular">{ t('store_id') }</p>
						<p className="info-row-data text-2--regular">#{store?.customer_shop_no}</p>
					</div>
					<div className="info-row grid-sixth-2">
						<p className="info-row-label text-3--regular">{ t('store_type') }</p>
						<p className="info-row-data text-2--regular">{store_types && store?.tipo_tienda && store_types.filter(type => type.value === store?.tipo_tienda).length > 0 ? store_types.filter(type => type.value === store?.tipo_tienda)[0].name : ''}</p>
					</div>
					<div className="info-row grid-sixth-3">
						<p className="info-row-label text-3--regular">{ t('floors') }</p>
						<p className="info-row-data text-2--regular">{store?.pisos}</p>
					</div>
					{/* <div className="info-row grid-half-2">
						<p className="info-row-label text-3--regular">Centro de distribución</p>
						<p className="info-row-data text-2--regular">—</p>
					</div> */}
					{/* <div className="info-row grid-half-1">
						<p className="info-row-label text-3--regular">Idioma</p>
						<p className="info-row-data text-2--regular">Frances, Alemán, Ingles</p>
					</div> */}
					{/* <div className="info-row grid-half-2">
						<p className="info-row-label text-3--regular">Dirección de envio</p>
						<p className="info-row-data text-2--regular">Naunynstraße 60, 10997 Berlin, Germany</p>
					</div> */}
					{this.renderContactList()}
				</div>
			);
		}
	}

	renderSupportList(supports: Support[], show: boolean) {
		if (show) {
			return (
				<div className="store-hardware-content">
					{supports?.map(support => (
						<Link key={support.id} to={`/supports/${support.id}`}>
							<SupportCardActions
								support={support}
							/>
						</Link>
					))}
				</div>
			);
		}
	}

	renderContactList(){
		const { t } = this.props;
		const { users } = this.props.store_detail_state.store ?? {};
		if (users?.length === 0) return
		return (
			<div className="info-row info-row--contact grid-all">
				<p className="info-row-label text-3--regular">{ t('contacts') }</p>
				<div className="info-row-contact-content grid-layout">
					{users?.map( (user, index) =>(
						<div className={`contact-card contact-card--${user.position} grid-third-${((index%3)+1)}`}>
							<div className="contact-card-header">
								<p className="text-2--regular">{user.name}</p>
								<p className="text-4--semibold contact-position">{user.position}</p>
							</div>
							<p className="text-2--regular contact-info">{user.email}</p>
							<p className="text-2--regular contact-info">{user.phone}</p>
						</div>
						)
					)}
				</div>
			</div>
		);
	}

	renderSupportsSection() {
		const { supportAddModalShow, supports } = this.props.store_detail_state;
		const { t } = this.props;

		return (
			<div className="basic-card basic-card--32">
				<div className="tab-bar">
					<button id="tab-imagenes" className={this.selectedTabClass(tabOptions.bySection)} onClick={() => this.showTab(tabOptions.bySection)}>{ t('sections') }</button>
					<button id="tab-tiendas" className={this.selectedTabClass(tabOptions.byPriority)} onClick={() => this.showTab(tabOptions.byPriority)}>{ t('priorities') }</button>
					<p style={{ color: '#AEB2C3', marginLeft: '16px', marginTop: '18px' }} className="text-3--regular store-id">({supports.length} { t('supports') })</p>
					<button className="btn--primary btn--m" onClick={() => this.props.updateSupportAddModalShow(!supportAddModalShow)}>{ t('add_support') }</button>
				</div>
				{this.renderTab()}
			</div>
		)
	}

	renderTab() {
		const { sections, priorities } = this.props.datatypes;
		if (this.state.currentTab === tabOptions.bySection) {
			return (
				<>
					{sections.map((section: Section) => (
						this.renderSection(section)
					))}
					{this.renderOrphanSectionSupports()}
				</>
			);
		} else if (this.state.currentTab === tabOptions.byPriority) {
			return (
				<>
					{priorities.map((priority: SelectOption) => (
						this.renderPriority(priority)
					))}
					{this.renderOrphanPrioritySupports()}
				</>
			);
		}
	}

	renderSection(section: Section) {
		const { t } = this.props;
		const { supports } = this.props.store_detail_state.store ?? {};
		const filteredSupports = (supports||[]).filter(support => support.section?.toLowerCase() === section.id?.toLowerCase());
		if (filteredSupports.length > 0) {
			return (
				<div key={section.id} className="store-card--view">
					<div className="store-header">
						<div className="campaing-card-left">
							<CollapsedArrow collapsed={section.collapsed} clicked={() => this.swapSectionCollapsed(section)}/>
							<p className="headline-3--regular">{section.description}</p>
							<p className="text-3--regular store-id">#{section.id} ({filteredSupports.length} { t('supports').toLowerCase() })</p>
						</div>
					</div>
					{this.renderSupportList(filteredSupports, !section.collapsed)}
				</div>
			);
		}
	}

	renderPriority(priority: SelectOption) {
		const { t } = this.props;
		const { supports } = this.props.store_detail_state.store ?? {};
		const filteredSupports = (supports||[]).filter(support => support.priority?.toLowerCase() === priority.name?.toLowerCase());
		if (filteredSupports.length > 0) {
			return (
				<div key={priority.name} className="store-card--view">
					<div className="store-header">
						<div className="campaing-card-left">
							<CollapsedArrow collapsed={this.isPriorityCollapsed(priority.name)} clicked={() => this.swapPriorityCollapsed(priority.name)}/>
							<p className="headline-3--regular">{priority.name}</p>
							<p className="text-3--regular store-id">#{priority.name} ({filteredSupports.length} { t('supports').toLowerCase() })</p>
						</div>
					</div>
					{this.renderSupportList(filteredSupports, !this.isPriorityCollapsed(priority.name))}
				</div>
			);
		}
	}

	getPriorityStatus(priorityName: string) {
		return this.state.prioritiesCollapse.find(priority => priority.name === priorityName);
	}

	getPriorityStatusIndex(priorityName: string) {
		return this.state.prioritiesCollapse.findIndex(priority => priority.name === priorityName);
	}

	isPriorityCollapsed(priorityName: string) {
		const find = this.getPriorityStatus(priorityName);
		if (find) {
			return find.collapse;
		}
		return false;
	}

	swapPriorityCollapsed(priorityName: string) {
		const { prioritiesCollapse } = this.state;
		const find = this.getPriorityStatus(priorityName);
		if (find) {
			prioritiesCollapse[this.getPriorityStatusIndex(priorityName)].collapse = !prioritiesCollapse[this.getPriorityStatusIndex(priorityName)].collapse;
			this.setState({
				prioritiesCollapse
			});
		} else {
			prioritiesCollapse.push({ name: priorityName, collapse: true });
			this.setState({
				prioritiesCollapse
			});
		}
	}

	renderOrphanSectionSupports() {
		const { supports } = this.props.store_detail_state.store ?? {};
		const { t } = this.props;
		const noSectionSupports = (supports||[]).filter(support => !support.section);
		if (noSectionSupports.length > 0) {
			return (
				<div className="store-card--view">
					<div className="store-header">
						<div className="campaing-card-left">
							<p className="headline-3--regular"><i>{ t('no_section') }</i></p>
							<p className="text-3--regular store-id">({noSectionSupports.length} { t('supports').toLowerCase() })</p>
						</div>
					</div>
					{this.renderSupportList(noSectionSupports, true)}
				</div>
			);
		}
	}

	renderOrphanPrioritySupports() {
		const { supports } = this.props.store_detail_state.store ?? {};
		const { t } = this.props;
		const noSectionSupports = (supports||[]).filter(support => !support.priority);
		if (noSectionSupports.length > 0) {
			return (
				<div className="store-card--view">
					<div className="store-header">
						<div className="campaing-card-left">
							<p className="headline-3--regular"><i>{ t('no_priority') }</i></p>
							<p className="text-3--regular store-id">({noSectionSupports.length} { t('supports').toLowerCase() })</p>
						</div>
					</div>
					{this.renderSupportList(noSectionSupports, true)}
				</div>
			);
		}
	}

	renderCampaignsSection() {
		const { campaigns, current_campaign, supports } = this.props.store_detail_state.store ?? {};
		const { t } = this.props;

		if (campaigns||[].length > 0) {
			return (
				<div className="basic-card basic-card--32 campaing-scope">
					<div className="card-header">
						<div className="card-header-left">
							<p className="headline-4--semibold">{ t('campaigns') }</p>
						</div>
						<div className="class-header-right"></div>
					</div>

					<Timeline campaigns={campaigns || []}/>

					<div className="card-header">
						<div className="card-header-left">
							<p className="headline-3--regular">{current_campaign?.title}</p>
							<p className="text-2--regular">{moment(current_campaign?.start_date).format('ll')} › {moment(current_campaign?.end_date).format('ll')}</p>
						</div>
						<div className="class-header-right">
							<p className="text-2--regular">{moment(current_campaign?.end_date).diff(moment(current_campaign?.start_date), 'days')} { t('days') }</p>
						</div>
					</div>
					<div className="store-card--view">
						<div className="store-hardware-content">
							{(supports||[]).filter(support => (current_campaign?.assigned_visuals || {})[support.id||'']).map(support => (
								<Link key={support.id} to={`/supports/${support.id}`}>
									<SupportCardActions
										support={support}
										assignedImages={current_campaign?.assigned_visuals || {}}
									/>
								</Link>
							))}
						</div>
					</div>
				</div>
			);
		}
	}

	render () {
		const { loading, store, storeEditModalShow, storeInfoShow } = this.props.store_detail_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_store') } />
					</div>
				</div>
			);
		}

		return (
			<div className="base-content">
				{this.renderStoreEditModal()}
				{this.renderSupportAddModal()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('stores'), url: '/stores' }, { text: store?.name || '' }]}/>

						<div className="section-header">
							<div className="section-header-left">
								<BackArrow onClick={() => this.props.history.goBack() }/>
								<h1 className="headline-1--regular">{store?.name}</h1>
								<p className={'text-3--semibold status-pill ' + statusPillClass(store?.status as StoreStatus)}>{ statusName(store?.status as StoreStatus, t)}</p>
							</div>
							<div className="section-header-right">
								<button className="btn--secondary btn--l pop-reveal" onClick={() => this.props.updateStoreEditModalShow(!storeEditModalShow)}><i className="icon icon-16-edit"></i>{ t('edit') }</button>
							</div>
						</div>

						<div className="basic-card basic-card--32 info-card">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-3--semibold">{ t('store_information') }</p>
									<p className="text-3--regular store-id">#{store?.customer_shop_no}</p>
								</div>
								<div className="class-header-right">
									<CollapsedArrow collapsed={storeInfoShow} clicked={() => this.props.updateStoreInfoShow(!storeInfoShow)}/>
								</div>
							</div>
							{this.renderStoreInfo()}
						</div>
						{ this.renderCampaignsSection() }
						{ this.renderSupportsSection() }
						
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Detail));
