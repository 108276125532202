import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportDetail, VisualImage } from '.';
import { AssignedVisuals, SelectOption, Store } from '../interfaces/interfaces';
import { calculateStoreTotal, renderPrice, calculateStoreShippingPrice } from '../util/price';

export interface BudgetTableProps {
    store: Store,
    assignedImages: AssignedVisuals,
    productionLanguages: SelectOption[],
    vat: number,
}

interface State {
}

type BudgetTablePropsSum = BudgetTableProps & WithTranslation;

class BudgetTableComponent extends React.Component<BudgetTablePropsSum, State> {

    constructor(props: BudgetTablePropsSum) {
        super(props);
        this.state = {};
    }

    getFilteredSupports(store: Store) {
		const { assignedImages } = this.props;
		return (store.supports || []).filter(support => assignedImages && assignedImages[support.id!] && assignedImages[support.id!].image);
	}

    render () {
        const { t, store, assignedImages, productionLanguages, vat } = this.props;
        return (
            <table id="user_list" style={{ cursor: 'default' }}>
                <thead>
                    <tr>
                        <th style={{textAlign: 'left'}}>{ t('image') }</th>
                        <th style={{textAlign: 'left'}}>{ t('name') }</th>
                        <th style={{textAlign: 'center'}}>{ t('units') }</th>
                        <th style={{textAlign: 'center'}}>{ t('price') }</th>
                        <th style={{textAlign: 'center'}}>{ t('discount') }</th>
                        <th style={{textIndent: '0', textAlign: 'center'}}>{ t('subtotal') }</th>
                    </tr>
                </thead>
                <tbody>
                    {this.getFilteredSupports(store).map(support =>
                        <tr key={support.id}>
                            <td style={{textAlign: 'left'}}>
                                <div className="card-img card-img--visual" onClick={ (event) => event.preventDefault() }>
                                    <VisualImage visual={assignedImages[support.id!]} onClick={() => this.setState({ previewVisual: assignedImages[support.id!] })} />
                                </div>
                            </td>
                            <td>
                                <span>{support.support_type}</span>
                                <br />
                                <span style={{color: '#AEB2C3'}}><SupportDetail support={support} production_languages={productionLanguages} /></span>
                            </td>
                            <td style={{textAlign: 'center'}}>{Number(assignedImages[support.id!].quantity)}</td>
                            <td style={{textAlign: 'center'}}>{renderPrice(Number(assignedImages[support.id!].unit_price))}</td>
                            <td style={{textAlign: 'center'}}>{renderPrice(0)}</td>
                            <td style={{textAlign: 'center'}}>{renderPrice(Number(assignedImages[support.id!].unit_price))}</td>
                        </tr>
                    )}
                    <tr>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{textAlign: 'center'}}>{ t('net') }</td>
                        <td style={{textAlign: 'center'}}>{ renderPrice(calculateStoreTotal(this.getFilteredSupports(store), assignedImages)) }</td>
                    </tr>
                    {store.pais === "ES" && 
                        <tr>
                            <td style={{borderBottom: '0px'}}></td>
                            <td style={{borderBottom: '0px'}}></td>
                            <td style={{borderBottom: '0px'}}></td>
                            <td style={{borderBottom: '0px'}}></td>
                            <td style={{textAlign: 'center'}}>{ t('vat') } { (vat * 100).toFixed(0) }%</td>
                            <td style={{textAlign: 'center'}}>{ renderPrice(calculateStoreTotal(this.getFilteredSupports(store), assignedImages) * vat) }</td>
                        </tr>
                    }
                    <tr>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{textAlign: 'center'}}>{ t('shipping_price') }</td>
                        <td style={{textAlign: 'center'}}>{ renderPrice(calculateStoreShippingPrice(store, assignedImages))}</td>
                    </tr>
                    <tr>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{borderBottom: '0px'}}></td>
                        <td style={{textAlign: 'center'}}><b>{ t('total') }</b></td>
                        <td style={{textAlign: 'center'}}>{ renderPrice(calculateStoreTotal(this.getFilteredSupports(store), assignedImages) * (1 + vat) + calculateStoreShippingPrice(store, assignedImages)) }</td>
                    </tr>
                </tbody>
            </table>
        );
    };
}

const BudgetTable = withTranslation()(BudgetTableComponent);
export { BudgetTable };
