import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ConfirmModalProps {
    title: string,
    acceptButtonText: string,
    cancelButtonText: string,
    onClose: () => void,
    onSubmit: () => void
}

interface State {
}

type ConfirmModalPropsSum = ConfirmModalProps & WithTranslation;

class ConfirmModalComponent extends React.Component<ConfirmModalPropsSum, State> {

    constructor(props: ConfirmModalPropsSum) {
        super(props);
        this.state = {};
    }

    render () {
        return (
            <div className="pop-layout">
                <div className="pop-bg">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{ this.props.title }</p>
                        </div>

                        <div className="pop-box-content">

                            {this.props.children}

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.props.onSubmit()}>{ this.props.acceptButtonText }</button>
                                <button className="btn--secondary btn--l close-pop" onClick={()=> {this.props.onClose()}}>{ this.props.cancelButtonText }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const ConfirmModal = withTranslation()(ConfirmModalComponent);
export { ConfirmModal };
