import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputNumberProps {
    id: string,
    label: string,
    value?: number,
    helpText?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

type InputNumberPropsSum = InputNumberProps & WithTranslation;

class InputNumberComponent extends React.Component<InputNumberPropsSum> {

    render () {
        return (
            <TextField
                type="number"
                id={this.props.id}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                variant="outlined"
                fullWidth
                error={!!this.props.helpText}
                helperText={this.props.helpText}
            />
        );
    };
}

const InputNumber = withTranslation()(InputNumberComponent);
export { InputNumber };
