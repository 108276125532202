import { Campaign } from "../interfaces/interfaces"
import moment, { DurationInputArg2 } from 'moment';

export const first_date = (campaigns: Campaign[], offset: number = 0, unit: DurationInputArg2 = 'day'): moment.Moment => {
    const firstDate = campaigns.reduce((prev, campaign) => moment.min(prev, moment(campaign.start_date)), moment());
    return firstDate.clone().subtract(offset, unit); 
}

export const last_date = (campaigns: Campaign[], offset: number = 0, unit: DurationInputArg2 = 'days'): moment.Moment => {
    const lastDate = campaigns.reduce((prev, campaign) => moment.max(prev, moment(campaign.end_date)), moment());
    return lastDate.clone().add(offset, unit); 
}