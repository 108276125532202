import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SelectOption, Support } from '../interfaces/interfaces';

export interface SupportDetailProps {
    support: Support,
    production_languages: SelectOption[]
}

type SupportDetailPropsSum = SupportDetailProps & WithTranslation;

class SupportDetailComponent extends React.Component<SupportDetailPropsSum> {

    render () {
        const { t, production_languages } = this.props;
        const { textil_type, no_soporte_cliente, reference, section, width, height, depth, production_language, doble_cara, priority } = this.props.support;
        const language = production_languages && production_language && production_languages.filter(lang => lang.value === production_language).length > 0 ? production_languages.filter(lang => lang.value === production_language)[0].name : '';
        return (
            <>
                {textil_type}
                {no_soporte_cliente && ` - ID${no_soporte_cliente}`}
                {reference && ` - ${reference}`}
                {section && ` - ${section}`}
                {priority && ` - ${priority}`}
                {language &&  ` - ${language}`}
                {` - ${width}w x ${height}h x ${depth}d`}
                {doble_cara ? ` - ${t('double_face')} ` : ``}
            </>
        );
    };
}

const SupportDetail = withTranslation()(SupportDetailComponent);
export { SupportDetail };
