import React from 'react';
import { Support, AssignedVisuals, SelectOption } from '../interfaces/interfaces';
import { InputCheckBox, CollapsedArrow, SupportCardActions, SupportCardSelectable } from './';
import { store_checked } from '../util/store';
import { pluralize } from '../util/text';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SupportListProps {
    id: string,
    name: string,
    collapsed: boolean,
    checkBox: boolean,
    supports: Support[],
    selectedSupports: string[],
    assignedImages: AssignedVisuals,
    isCampaignBlocked?: boolean,
    production_languages?: SelectOption[],
    addVisual: (support: Support) => void,
    addSupports: (ids: string[]) => void,
    removeSupports: (ids: string[]) => void,
    deleteSupport: (id: string) => void,
    onDeleteAssignation?: (id: string) => void
}

interface State {
    collapsed: boolean,
    supports: Support[],
}

type SupportListPropsSum = SupportListProps & WithTranslation;

class SupportListComponent extends React.Component<SupportListPropsSum, State> {

    constructor(props: SupportListPropsSum) {
        super(props);
        this.state = {
            collapsed: this.props.collapsed,
            supports: this.props.supports
        }
    }

    swapStoreCollapse() {
		this.setState({
			collapsed: !this.state.collapsed
		});
    }

    selectShopSupport(value: boolean) {
        for (var i = 0; i < (this.props.supports || []).length; i++) {
            if (value) {
                this.props.addSupports([this.props.supports[i].id||'']);
            } else {
                this.props.removeSupports([this.props.supports[i].id||'']);
            }
		}
    }

    renderMainActions() {
        if (this.props.checkBox) {
            return (
                <InputCheckBox
                    id=""
                    checked={store_checked(this.props.supports, this.props.selectedSupports)}
                    checkBoxChange={(value: boolean) => this.selectShopSupport(value)}
                />
            )
        }
    }

    renderSupports() {
        if (!this.state.collapsed) {
            return (
                <div className="store-hardware-content">
                    {(this.props.supports || []).map((support, index) => (
                        <React.Fragment key={index}>
                            {this.renderSupport(support)}
                        </React.Fragment>
                    ))}
                </div>
            );
        }
    }

    renderSupport(support: Support) {
        if (this.props.checkBox) {
            return (
                <SupportCardSelectable
                    id={support.id||''}
                    checked={this.props.selectedSupports.includes(support.id||'')}
                    support={support}
                    production_languages={this.props.production_languages || []}
                    addSupport={(ids: string[]) => this.props.addSupports(ids)}
                    removeSupport={(ids: string[]) => this.props.removeSupports(ids)}
                />
            );
        } else {
            if (this.props.selectedSupports.includes(support.id||'')) {
                return (
                    <SupportCardActions
                        key={support.id}
                        support={support}
                        assignedImages={this.props.assignedImages}
                        isCampaignBlocked={this.props.isCampaignBlocked}
                        production_languages={this.props.production_languages}
                        onModalClicked={(supportId: string) => this.props.addVisual(support)}
                        onDeleteClicked={(supportId: string) => this.props.deleteSupport(supportId)}
                        onDeleteAssignation={(supportId: string) => this.props.onDeleteAssignation ? this.props.onDeleteAssignation(supportId) : null}
                    />
                );
            }
        }
    }

    renderSupportCount() {
        const { t } = this.props;
        if (this.props.checkBox) {
            const count = this.props.supports.length;

            return (
                <p style={{marginRight: 20, color: count === 0 ? 'rgba(0, 0, 0, 0.2)' : 'inherit'}}>{ pluralize(count, [t('support'), t('supports')]) }</p>
            )
        }
    }

    render () {
        return (
            <>
                <div className="store-header">
                    <div className="campaing-card-left">
                        <CollapsedArrow collapsed={this.state.collapsed} clicked={() => this.swapStoreCollapse()} />
                        <p className="headline-3--regular">{this.props.name}</p>
                    </div>
                    <div className="campaing-card-right">
                        {this.renderSupportCount()}
                        {this.renderMainActions()}
                    </div>
                </div>
                {this.renderSupports()}
            </>
        );
    };
}

const SupportList = withTranslation()(SupportListComponent);
export { SupportList };
