import React from 'react';
import { Store, StoreStatus, SelectOption, Country } from '../interfaces/interfaces';
import { InputText, InputNumber, InputSelect } from './';
import { withTranslation, WithTranslation } from 'react-i18next';
import { is_empty_string } from "../util/form";
import { country_map } from '../util/select_maps';

export interface StoreAddModalProps {
    store?: Store,
    storeTypes: SelectOption[],
    countries: Country[],
    is_central: boolean,
    clients: SelectOption[],
    onClose: () => void,
    onSubmit: (store: Store) => void
}

interface State {
    name: string | undefined,
    concept: string | undefined,
    store_type: string | undefined,
    store_status: string | undefined,
    store_id: string | undefined,
    floors: number | undefined,
    address: string | undefined,
    city: string | undefined,
    postal_code: string | undefined,
    region: string | undefined,
    country: string | undefined,
    client_id: string | undefined,
    distribution_center: string | undefined,
    store_name_error: string,
    store_type_error: string,
    store_status_error: string,
    store_id_error: string,
    store_floors_error: string,
    store_address_error: string,
    store_city_error: string,
    store_postal_code_error: string,
    store_region_error: string,
    store_country_error: string,
    client_id_error: string,
}

type StoreAddModalPropsSum = StoreAddModalProps & WithTranslation;

class StoreAddModalComponent extends React.Component<StoreAddModalPropsSum, State> {

    constructor(props: StoreAddModalPropsSum) {
        super(props);

        this.state = {
            name: '',
            concept: '',
            store_type: '',
            store_status: StoreStatus.OPEN,
            store_id: '',
            floors: 0,
            address: '',
            city: '',
            postal_code: '',
            region: '',
            country: '',
            client_id: '',
            distribution_center: '',
            store_name_error: '',
            store_type_error: '',
            store_status_error: '',
            store_id_error: '',
            store_floors_error: '',
            store_address_error: '',
            store_city_error: '',
            store_postal_code_error: '',
            store_region_error: '',
            store_country_error: '',
            client_id_error: '',
        };
    }

    componentDidMount() {
        const { store } = this.props;
        if (store !== undefined) {
            this.setState({
                name: store.name,
                concept: store.name2,
                store_type: store.tipo_tienda,
                store_status: store.status,
                store_id: store.customer_shop_no,
                floors: store.pisos,
                address: store.direccion,
                city: store.ciudad,
                postal_code: store.codigo_postal,
                region: store.provincia,
                country: store.pais,
                distribution_center: '',
                client_id: store.customer_no
            });
        }
    }

    createClicked() {
        const { name, concept, store_type, store_status, store_id, floors, address, city, postal_code, region, country, client_id } = this.state;

        if (this.isValid()) {
            this.props.onSubmit({
                id: this.props.store?.id,
                key: this.props.store?.key,
                name: name || '',
                name2: concept || '',
                direccion: address || '',
                direccion2: '',
                ciudad: city || '',
                telefono: '',
                pais: country || '',
                provincia: region || '',
                codigo_postal: postal_code || '',
                email: '',
                home_page: '',
                customer_shop_no: store_id || '',
                es_centro_distribucion: false,
                pisos: floors || 0,
                grupo_tienda: '',
                tipo_tienda: store_type || '',
                status: store_status || StoreStatus.OPEN,
                customer_no: client_id
            });
        }
    }

    isValid() {
        const { t } = this.props;
        const { name, store_type, store_status, store_id, address, city, postal_code, region, country, client_id } = this.state;
        let isValid = true;

        this.setState({
            store_name_error: '',
            store_type_error: '',
            store_status_error: '',
            store_address_error: '',
            store_postal_code_error: '',
            store_city_error: '',
            store_region_error: '',
            store_country_error: '',
            store_id_error: '',
        });

        if (is_empty_string(name)) {
            isValid = false;
            this.setState({ store_name_error: t('store_name_required') });
        }
        if (is_empty_string(store_type)) {
            isValid = false;
            this.setState({ store_type_error: t('store_type_required') });
        }
        if (is_empty_string(store_status)) {
            isValid = false;
            this.setState({ store_status_error: t('store_status_required') });
        }
        if (is_empty_string(store_id)) {
            isValid = false;
            this.setState({ store_id_error: t('store_id_required') });
        }
        if (is_empty_string(address)) {
            isValid = false;
            this.setState({ store_address_error: t('store_address_required') });
        }
        if (is_empty_string(city)) {
            isValid = false;
            this.setState({ store_city_error: t('store_city_required') });
        }
        if (is_empty_string(postal_code)) {
            isValid = false;
            this.setState({ store_postal_code_error: t('store_postal_code_required') });
        }
        if (is_empty_string(region)) {
            isValid = false;
            this.setState({ store_region_error: t('store_region_required') });
        }
        if (is_empty_string(country)) {
            isValid = false;
            this.setState({ store_country_error: t('store_country_required') });
        }
        if (this.props.is_central && is_empty_string(client_id)) {
            isValid = false;
            this.setState({ client_id_error: t('client_id_required') });
        }

        return isValid;
    }

    render () {
        const { name, concept, store_type, store_status, store_id, floors, address, city, postal_code, region, country, client_id } = this.state;
        const { t } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg pop-bg-big">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{this.props.store === undefined ? t('create_store') : t('edit_store')}</p>
                            <i className="icon icon-16-x close-pop" onClick={() => this.props.onClose()}></i>
                        </div>

                        <div className="pop-box-content">

                            <div className="form-row-content">

                                <div className="form-row">
                                    <InputText id="name" label={ t('store_name') } value={name} onChange={(e) => this.setState({ name: e.target.value })} helpText={this.state.store_name_error}/>
                                </div>
                                <div className="form-row">
                                    <InputText id="concept" label={ t('store_concept') } value={concept} onChange={(e) => this.setState({ concept: e.target.value })} />
                                </div>
                                <div className="form-row-content grid-layout--small">
                                    <div className="form-row grid-half-1">
                                        <InputSelect id="store-type" label={ t('store_type') } value={store_type} emptyValue={true} options={this.props.storeTypes} onChange={(value) => this.setState({ store_type: value || '' })} helpText={this.state.store_type_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputSelect id="store-status" label={ t('store_status') } value={store_status} emptyValue={true} options={[{value: StoreStatus.OPEN, name: 'Open'}, {value: StoreStatus.CLOSED, name: 'Closed'}]} onChange={(value) => this.setState({ store_status: value as StoreStatus || ''})} helpText={this.state.store_status_error}/>
                                    </div>
                                    <div className="form-row grid-half-1">
                                        <InputText id="store-id" label={ t('store_id') } value={store_id} onChange={(e) => this.setState({ store_id: e.target.value })} helpText={this.state.store_id_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputNumber id="floors" label={ t('floors') } value={floors} onChange={(e) => this.setState({ floors: parseInt(e.target.value) })} helpText={this.state.store_floors_error}/>
                                    </div>
                                </div>

                            </div>

                            <p className="text-3--regular form-group-title">{ t('store_address') }</p>

                            <div className="form-row-content grid-layout--small">

                                <div className="form-row grid-all">
                                    <InputText id="address" label={ t('address') } value={address} onChange={(e) => this.setState({ address: e.target.value })} helpText={this.state.store_address_error}/>
                                </div>

                                <div className="form-row grid-half-1">
                                    <InputText id="city" label={ t('city') } value={city} onChange={(e) => this.setState({ city: e.target.value })} helpText={this.state.store_city_error}/>
                                </div>

                                <div className="form-row grid-half-2">
                                    <InputText id="postal-code" label={ t('postal_code') } value={postal_code} onChange={(e) => this.setState({ postal_code: e.target.value })} helpText={this.state.store_postal_code_error}/>
                                </div>

                                <div className="form-row grid-half-1">
                                    <InputText id="region" label={ t('region') } value={region} onChange={(e) => this.setState({ region: e.target.value })} helpText={this.state.store_region_error}/>
                                </div>

                                <div className="form-row grid-half-2">
                                    <InputSelect id="country" label={ t('country') } value={country} options={this.props.countries.map(country_map)} onChange={(value) => this.setState({ country: value || '' })} helpText={this.state.store_country_error}/>
                                </div>

                            </div>

                            { this.props.is_central &&
                                <>
                                    <p className="text-3--regular form-group-title">{ t('client') }</p>
                                    <div className="form-row-content grid-layout--small">
                                        <div className="form-row grid-half-1">
                                            <InputSelect id="client-id" label={ t('client') } value={client_id} emptyValue={false} options={this.props.clients} onChange={(value) => this.setState({ client_id: value || '' })} helpText={this.state.client_id_error} />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.createClicked()}>{this.props.store === undefined ? t('create') : t('save')}</button>
                                <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClose()}>{ t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const StoreAddModal = withTranslation()(StoreAddModalComponent);
export { StoreAddModal };
