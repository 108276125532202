import {
    UPDATE_LOADING,
    APPEND_STORES,
    UPDATE_STORES,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_ASSIGNED_IMAGES,
    UPDATE_AVAILABLE_VISUALS,
    OrderNewOrderActions,
    AppThunk,
} from './ActionTypes';
import { get_stores_with_supports, get_available_visuals, get_assigned_images } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { AssignedVisuals, FilterStore } from '../../../interfaces/interfaces';

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as OrderNewOrderActions);

        const stores = await get_stores_with_supports({
            ...getState().orderNewOrder.filter,
            key: lastKey,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as OrderNewOrderActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as OrderNewOrderActions);
    }
}

export const updateFilter = (value: FilterStore): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as OrderNewOrderActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as OrderNewOrderActions);
    }
}

export const updateAssignedImages = (value: AssignedVisuals): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ASSIGNED_IMAGES,
            payload: value
        } as OrderNewOrderActions);
    }
}

export const getOneOrder = (orderId: string): AppThunk => {
    return async dispatch => {
        const visuals = await get_available_visuals();

        dispatch({
            type: UPDATE_AVAILABLE_VISUALS,
            payload: visuals
        } as OrderNewOrderActions);

        const assignedImages = await get_assigned_images(orderId);
        dispatch({
            type: UPDATE_ASSIGNED_IMAGES,
            payload: assignedImages
        } as OrderNewOrderActions);
    }
}

