import esp from './esp.json';
import enu from './enu.json';
import fra from './fra.json';

export default {
    esp: {
        translation: esp
    },
    enu: {
        translation: enu
    },
    fra: {
        translation: fra
    }
};