import { Client } from '../../interfaces/interfaces';

export const client_map = (client: any): Client => ({
    key: client.key,
    id: client.id,
    name: client.name,
    name2: client.name2,
    search_name: client.search_name,
    address: client.address,
    address2: client.address2,
    city: client.city,
    contact: client.contact,
    phone_no: client.phone_no,
    country_region_code: client.country_region_code,
    fax_no: client.fax_no,
    vat_registration_no: client.vat_registration_no,
    post_code: client.post_code,
    county: client.county,
    email: client.email,
    home_page: client.home_page,
    assignation_type: client.assignment_type || '',
    vat: client.vat,
    rate: client.rate
});