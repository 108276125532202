import {
    UPDATE_CAMPAIGNS,
    APPEND_CAMPAIGNS,
    UPDATE_LOADING,
    UPDATE_ADD_MODAL_VISIBILITY,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_FILTER,
    UPDATE_STATUSES,
    CLEAR_FILTER,
    CampaignIndexActions,
    AppThunk,
} from './ActionTypes';
import { get_campaigns } from '../../../api';
import { FilterCampaignRequest } from '../../../api/interfaces';
import { CampaignStatus, FilterCampaign } from '../../../interfaces/interfaces';
import { campaign_map } from '../campaign.maps';

export const getCampaigns = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignIndexActions);

        let campaigns = await get_campaigns({
            ...getState().campaignIndex.filter,
            key: lastKey,
        } as FilterCampaignRequest);

        campaigns = campaigns.map(campaign_map);

        dispatch({
            type: lastKey ? APPEND_CAMPAIGNS : UPDATE_CAMPAIGNS,
            payload: campaigns
        } as CampaignIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignIndexActions);
    }
}

export const updateAddModalVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ADD_MODAL_VISIBILITY,
            payload: value
        } as CampaignIndexActions);
    }
}

export const updateFilterVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER_VISIBILITY,
            payload: value
        } as CampaignIndexActions);
    }
}

export const updateFilter = (value: FilterCampaign): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as CampaignIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as CampaignIndexActions);
    }
}

export const getStatuses = (): AppThunk => {
    return async dispatch => {
        const statuses = [
            { value: CampaignStatus.LIVE, name: 'Live' },
            { value: CampaignStatus.DRAFT, name: 'Draft' },
            { value: CampaignStatus.LAUNCH, name: 'Launch' },
            { value: CampaignStatus.QUOTE, name: 'Quote' },
            { value: CampaignStatus.END, name: 'End' },
            { value: CampaignStatus.APPROVED, name: 'Approved' },
        ];
        dispatch({
            type: UPDATE_STATUSES,
            payload: statuses
        } as CampaignIndexActions);
    }
}