import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
	getAssignedImages,
	updateShowConfirmModal,
	updateSupportStates,
	getVat,
} from './CampaignBudgetActions';
import { getStoreTypes, getSupportTypes, getCountries } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, BackArrow, ConfirmModal, StoreAndSupportFilter, OrderTimeline, ListHeader, BudgetTable, BudgetSummary, BudgetSummaryTotal } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { launch_campaign } from '../../../api';
import { is_blocked } from '../../../util/campaign';
import { country_map } from '../../../util/select_maps';
import { getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { filter_style } from '../../../util/filter';
import { Store } from '../../../interfaces/interfaces';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_budget_state: state.campaignBudget,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateShowFilter,
	getStores,
	oneCampaign,
	updateFilter,
	getStoreTypes,
	getSupportTypes,
	getCountries,
	updateSelectedSupports,
	addSelectedSupports,
	removeSelectedSupports,
	clearFilter,
	getAssignedImages,
	updateShowConfirmModal,
	launch_campaign,
	updateSupportStates,
	getVat,
	getProductionLanguages,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Summary extends React.Component<Props> {

	componentDidMount() {
		const { t } = this.props;
		this.props.getStores(this.props.match.params.id);
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getCountries();
		this.props.oneCampaign(this.props.match.params.id);
		this.props.getAssignedImages(this.props.match.params.id);
		this.props.updateSelectedSupports(this.props.match.params.id);
		this.props.updateSupportStates(t);
		this.props.getVat();
		this.props.getProductionLanguages();
	}

	renderSupportFilter() {
		if (this.props.campaign_budget_state.showFilter) {
			return (
				<StoreAndSupportFilter
					filter={this.props.campaign_budget_state.filter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					countries={this.props.datatypes.countries.map(country_map)}
					supportStates={this.props.campaign_budget_state.supportStates}
					onClose={() => this.props.updateShowFilter(!this.props.campaign_budget_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateShowFilter(!this.props.campaign_budget_state.showFilter);
						this.props.getStores(this.props.match.params.id);
					}}
					onSubmit={(storeAndSupportfilter) => {
						this.props.updateFilter(storeAndSupportfilter);
						this.props.updateShowFilter(!this.props.campaign_budget_state.showFilter);
						this.props.getStores(this.props.match.params.id);
					}}
				/>
			);
		}
	}

	groupStoresByCountry(){
		const { stores, assignedImages } = this.props.campaign_budget_state;

		return stores.reduce((storage: any, store: Store) => {
			if((store.supports || []).some(support => assignedImages && assignedImages[support.id!]?.image)){
				storage[store.pais] = storage[store.pais] || [];
				storage[store.pais].push(store)
			}
			return storage;
		  },{});
	}

	renderStores() {
		const { t } = this.props;
		const { stores, assignedImages, loading } = this.props.campaign_budget_state;
		const { countries } = this.props.datatypes;
		if (loading) {
			return (
				<div className="content">
					<Spinner message={ t('loading_campaign') } />
				</div>
			);
		}
		return ( 
			<> 
			{  
				Object.entries(this.groupStoresByCountry()).map( ( [storeCountry, stores] ) => 
					<>  
						<div className="basic-card basic-card--32 store-card" key={storeCountry} style={{ cursor: 'default' , background:'#DBDBDB' }}>
							<div className="store-card--view"> 
							 <p className="headline-2--regular">{countries.find(country => country.id === (storeCountry || ''))?.name || ''} </p>
							</div>
						</div>
						{(stores as Store[]).filter((store : Store) => (store.supports || []).some(support => assignedImages && assignedImages[support.id!]?.image)).map((store : Store) =>
                            <div className="basic-card basic-card--32 store-card" key={store.id} style={{ cursor: 'default' }}>
                                <div className="store-card--view">
                                    <ListHeader name={store.name} id={store.id} subtitle={`${store.direccion}, ${store.codigo_postal}, ${store.ciudad}, ${store.pais}`} subtitleBold={store.grupo_tienda}>
                                        <BudgetTable
                                            store={store}
                                            assignedImages={assignedImages}
                                            productionLanguages={this.props.datatypes.production_languages}
                                            vat={this.props.campaign_budget_state.vat}
                                        />
                                    </ListHeader>
                                </div>
                            </div>
                        )}
						<BudgetSummary
							stores={(stores as Store[])}
							assignedImages={assignedImages}
							vat={this.props.campaign_budget_state.vat}
						/>
					</>
					
				)			
			}
				<div className="basic-card basic-card--32 store-card" key={'total'} style={{ cursor: 'default' , background:'#DBDBDB' }}>
					<div className="store-card--view"> 
						<p className="headline-2--regular">{t('total')}</p>
					</div>
				</div>
				<BudgetSummaryTotal
					stores={stores}
					assignedImages={assignedImages}
					vat={this.props.campaign_budget_state.vat}
				/>
			</>
		);
	}

	renderConfirmationModal() {
		const { t } = this.props;
		const { showConfirmModal } = this.props.campaign_budget_state;
		if (showConfirmModal) {
			return (
				<ConfirmModal
					title={ t('confirmation') }
					acceptButtonText={ t('start_order') }
					cancelButtonText={ t('cancel') }
					onClose={() => this.props.updateShowConfirmModal(!this.props.campaign_budget_state.showConfirmModal)}
					onSubmit={() => {
						launch_campaign(this.props.match.params.id).then(() => {
							this.props.updateShowConfirmModal(!this.props.campaign_budget_state.showConfirmModal)
							window.location.href = `/campaigns/${this.props.match.params.id}/launched`;
						}).catch(err => console.error);
					}}
				>
					<div className="form-row-content">
						<p className="">{ t('confirmation_explanation') }</p>
					</div>
				</ConfirmModal>
			);
		}
	}

	render () {
		const { campaign, loading } = this.props.campaign_budget_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_supports') } />
					</div>
				</div>
			);
		}
		return (
			<>
				<div className="base-content">
					{this.renderSupportFilter()}
					{this.renderConfirmationModal()}
					<div className="content">
						<div className="container">
							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Campañas', url: '/campaigns' }, { text: `${campaign?.title}`, url: `/campaigns/${campaign?.id}` }]}/>
							<div className="section-header">
								<div className="section-header-left">
									<BackArrow onClick={() => this.props.history.goBack()} />
									<h1 className="headline-1--regular section-header-title">{ t('budget') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_budget_state.filter)} onClick={() => this.props.updateShowFilter(!this.props.campaign_budget_state.showFilter)}></button>
									{ is_blocked(campaign) &&
										<button className="btn--primary btn--l pop-reveal btn--disabled">{ t('start_order') }</button>}
									{ !is_blocked(campaign) &&
										<button className="btn--primary btn--l pop-reveal" onClick={() => this.props.updateShowConfirmModal(!this.props.campaign_budget_state.showConfirmModal)}>{ t('start_order') }</button>}
								</div>
							</div>
							<OrderTimeline step="budget" />
							{this.renderStores()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Summary));
