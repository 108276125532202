import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { RootState } from '../../../reducers';
import { Breadcrumb, CollapsedArrow, Spinner, StoreAddModal } from '../../../components';
import {
	updateClient,
	updateShowDetail,
	updateShowAddStoreModal,
	getClients,
} from './ClientDetailActions';
import { getCountries, getStoreTypes } from '../../../reducers/datatypes/DatatypesActions';
import { StoreStatus, Store } from '../../../interfaces/interfaces';
import { create_store } from '../../../api';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_role, is_central } from '../../../util/jwt';
import { statusPillClass, statusName} from '../../../util/store';

const mapStateToProps = (state: RootState) => {
	return {
		client_detail_state: state.clientDetail,
		auth_state: state.auth,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateClient,
	updateShowDetail,
	updateShowAddStoreModal,
	getStoreTypes,
	create_store,
	getClients,
	getCountries
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Detail extends React.Component<Props> {

	componentDidMount() {
		this.props.updateClient();
		this.props.getStoreTypes();
		this.props.getClients();
		this.props.getCountries();
	}

	renderClientDetail() {
		const { client, showDetail } = this.props.client_detail_state;
		const { t } = this.props;
		if (showDetail) {
			return (
				<>
					<div className="info-card-content">
						<section className="grid-row grid_3-1-2">
							<article>
								<h4>{ t('comertial_group') }</h4>
								<p className="info-row-data text-2--regular">{client?.search_name}</p>
							</article>
							<article>
								<h4>{ t('city') }</h4>
								<p className="info-row-data text-2--regular">{client?.city}</p>
							</article>
							<article>
								<h4>{ t('pc') }</h4>
								<p className="info-row-data text-2--regular">{client?.post_code}</p>
							</article>
						</section>
						<section className="grid-row grid_3-1-2">
							<article>
								<h4>{ t('address') }</h4>
								<p className="info-row-data text-2--regular">{client?.address}</p>
							</article>
							<article>
								<h4>{ t('province') }</h4>
								<p className="info-row-data text-2--regular">--</p>
							</article>
							<article>
								<h4>{ t('country_code') }</h4>
								<p className="info-row-data text-2--regular">{client?.country_region_code} - {client?.city}</p>
							</article>
						</section>
					</div>
					{/* <br /> */}
					{/* <div className="card-header">
						<div className="card-header-left">
							<p className="headline-3--semibold">Administrador de cliente</p>
						</div>
					</div>
					<div className="info-card-content">
						<section className="grid-row grid_1-1-1">
							<article>
								<h4>Nombre y apellidos</h4>
								<p className="info-row-data text-2--regular">Floyd Miles</p>
							</article>
							<article>
								<h4>Email</h4>
								<p className="info-row-data text-2--regular">floyd.miles@timberland.eu</p>
							</article>
							<article>
								<h4>Teléfono</h4>
								<p className="info-row-data text-2--regular">+28 678 167 189</p>
							</article>
						</section>
					</div> */}
				</>
			);
		}
	}

	renderStoreList() {
		const { stores, supports } = this.props.client_detail_state;
		const { t } = this.props;
		return (
			<ul id="shops_list">
				{stores.slice(0, 3).map((store, index) =>
					<li key={index}>
						<section className="shop_detail">
							<h4>{store.name} <span>#{store.id}</span></h4>
							<p>{store.grupo_tienda} <span>{supports.filter((support) => support.store_id === store.id).length} { t('supports') }</span></p>
						</section>
						<section className="shop_state">
							<p className={'text-3--semibold status-pill ' + statusPillClass(store?.status as StoreStatus)}>{ statusName(store?.status as StoreStatus, t)}</p>
						</section>
					</li>
				)}
			</ul>
		);
	}

	createStore(store: Store) {
		create_store(store).then(() => {
			this.props.updateShowAddStoreModal(!this.props.client_detail_state.showAddStoreModal)
			this.props.updateClient();
        }).catch(err => console.error);
	}

	renderStoreAddModal() {
		const { showAddStoreModal, clients } = this.props.client_detail_state;
		const { store_types } = this.props.datatypes;

		if (showAddStoreModal) {
			return (
				<StoreAddModal
					storeTypes={store_types}
					is_central={is_central(this.props.auth_state.token!)}
					clients={clients}
					countries={this.props.datatypes.countries}
					onClose={() => this.props.updateShowAddStoreModal(!showAddStoreModal)}
					onSubmit={(store) => this.createStore(store)}
				/>
			);
		}
	}

	render () {
		const { client, loading, showDetail, campaignsCount, stores, supports, showAddStoreModal } = this.props.client_detail_state;
		const { t } = this.props;
		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_client') } />
					</div>
				</div>
			);
		}
		return (
			<>
				<div className="base-content">
					{this.renderStoreAddModal()}
					<div className="content">

						<div className="container">

							<Breadcrumb items={[{ text: client?.name||'' }]}/>

							<div className="section-header">
								<div className="section-header-left">
									<h1 className="headline-1--regular">{client?.name}</h1>
									<p className="text-3--semibold status-pill status-pill--live">Active</p>
								</div>
								{/* <div className="section-header-right">
									<button className="btn--secondary btn--l pop-reveal"><i className="icon icon-16-edit"></i>Editar</button>
								</div> */}
							</div>

							<div id="recap">
								<Link to="/campaigns">
									<h3 className="campaign_summary">{campaignsCount} <span>{ t('campaigns') }</span></h3>
								</Link>
								<Link to="/stores">
									<h3 className="shops_summary">{stores.length} <span>{ t('stores') }</span></h3>
								</Link>
								<Link to="/supports">
									<h3 className="support_summary">{supports.length} <span>{ t('supports') }</span></h3>
								</Link>
								{/* <Link to=""> */}
									{/* <h3 className="incident_summary">xx <span>{ t('incidences') }</span></h3> */}
								{/* </Link> */}
							</div>

							<div className="basic-card basic-card--32 info-card">
								<div className="card-header">
									<div className="card-header-left">
										<p className="headline-3--semibold">{ t('client_information') }</p>
										<p className="text-3--regular store-id">#{client?.id}</p>
									</div>
									<div className="class-header-right">
										<CollapsedArrow collapsed={!showDetail} clicked={() => this.props.updateShowDetail(!showDetail)}/>
									</div>
								</div>
								{this.renderClientDetail()}
							</div>

							<div className="basic-card basic-card--32">
								<div className="card-header">
									<div className="card-header-left">
										<p className="headline-3--semibold">{ t('stores') }</p>
									</div>
									<div className="class-header-right">
										{!['user'].includes(get_role(this.props.auth_state.token || 'user')) &&
											<button className="btn--primary btn--m pop-reveal" onClick={() => this.props.updateShowAddStoreModal(!showAddStoreModal)}>{ t('add_store') }</button>
										}
									</div>
								</div>
								{this.renderStoreList()}
							</div>

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
