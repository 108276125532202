import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { BackArrow, Breadcrumb, ListHeader, OrderCampaignFilter, Spinner, VisualImage, VisualPreview } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { Campaign, SelectOption, Support, Visual } from '../../../interfaces/interfaces';
import { getSections, getPriorities, getProductionLanguages, getLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { pluralize } from '../../../util/text';
import { is_section_assigned, is_priority_assigned, is_production_language_assigned } from '../../../util/visual';
import { RouteComponentProps } from 'react-router';
import { section_map } from '../../../util/select_maps';
import {
	updateFilter,
	clearFilter,
	getCampaigns,
	writeOrderAssignation,
	getAssignationType,
	getSupport,
} from './OrderAssignVisualActions';
import { checkSizes } from '../../../util/support';
import { remove_decimals } from '../../../util/number';
import { filter_style } from '../../../util/filter';
import { assign_visual_2_support } from '../../../api';
import Alert from '@material-ui/lab/Alert';

const mapStateToProps = (state: RootState) => {
	return {
		order_assign_visual_state: state.orderAssignVisual,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getSections,
	getProductionLanguages,
	getPriorities,
	getLanguages,
	updateFilter,
	clearFilter,
	getCampaigns,
	writeOrderAssignation,
	getAssignationType,
	getSupport,
});

interface ParamTypes {
	orderId: string,
	supportId: string,
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	show_filter: boolean,
	previewVisual: Visual | null,
}

class AssignVisual extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			show_filter: false,
			previewVisual: null,
        };
	}

	componentDidMount() {
		this.props.getSections();
		this.props.getProductionLanguages();
		this.props.getPriorities();
		this.props.getLanguages();
		this.props.getCampaigns();
		this.props.getAssignationType();
		this.props.getSupport(this.props.match.params.supportId);
	}

	renderCampaigns() {
		const { t } = this.props;
		const { loading, campaigns } = this.props.order_assign_visual_state;
		if (loading) {
			return (<Spinner message={ t('loading_campaigns') } />);
		}
		return (
			<>
				{(campaigns || []).filter(campaign => campaign.images.length > 0 && campaign.images.some(visual => checkSizes(visual, this.props.order_assign_visual_state.support))).map((campaign, index) => (
					<div key={index} className="basic-card basic-card--32 store-card">
						<div className="store-card--view">
							<React.Fragment key={index}>
								{ this.renderCampaign(campaign) }
							</React.Fragment>
						</div>
					</div>
				))}
			</>
		);
	}

	renderCampaign(campaign: Campaign) {
		const { t } = this.props;
		const imagesCount = campaign.images.filter(visual => checkSizes(visual, this.props.order_assign_visual_state.support)).length;
		return (
			<ListHeader name={campaign.title || ''} collapsed={false} count={imagesCount} countText={pluralize(imagesCount, [t('visual'), t('visuals')])}>
				{(campaign.images || []).filter(visual => checkSizes(visual, this.props.order_assign_visual_state.support)).map((visual, index) => (
					<React.Fragment key={index}>
						{this.renderVisual(visual)}
					</React.Fragment>
				))}
			</ListHeader>
		);
	}

	renderVisualDetails(visual: Visual) {
		const { assignationType } = this.props.order_assign_visual_state;

		return (
			<p className="visualDetail text-3--regular">
				{is_section_assigned(assignationType) ? this.renderSection(visual) : ''}
				{is_priority_assigned(assignationType) ? this.renderPriority(visual) : ''}
				{is_production_language_assigned(assignationType)? this.renderProductionLanguage(visual) : ''}
			</p>
		);
	}

	renderSection(support: Support) {
		const { sections } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('section') } <span className="detail">{sections && support.section && sections.filter(sec => sec.id === support.section).length > 0 ? sections.filter(sec => sec.id === support.section)[0].description : ''}</span></>) ;
	}

	renderPriority(support: Support) {
		const { priorities: prorities } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('priority') } <span className="detail">{prorities && support.priority && prorities.filter(sec => sec.value === support.priority).length > 0 ? prorities.filter(sec => sec.value === support.priority)[0].name : ''}</span></>);
	}

	renderProductionLanguage(support: Support) {
		const { t }  = this.props;
		const { production_languages } = this.props.datatypes;
		const production_language = production_languages.find(production_language => production_language.value === support.production_language);

		return (<>{ t('production_language') } <span className="detail">{ production_language?.name || '' }</span></>) ;
	}

	renderVisualPreview () {
		const { previewVisual } = this.state;
		if (previewVisual) {
			return <VisualPreview visual={previewVisual} onClose={() => this.setState({ previewVisual: null })} />
		}
	}

	renderVisual(visual: Visual) {
		const { t }  = this.props;
        return (
			<>
				{this.renderVisualPreview()}
				<div className="card-row card-row--visual">
					<div className="card-row-left">
						<div className="card-img card-img--visual">
							<VisualImage visual={visual} onClick={() => this.setState({ previewVisual: visual })} />
						</div>
						<div className="visual-info">
							<p className="text-1--regular subcard-title">{visual.name} <span className="text-3--regular" style={{marginLeft: '8px'}}>{remove_decimals(Number(visual.original_width))} x {remove_decimals(Number(visual.original_height))}</span></p>
							{this.renderVisualDetails(visual)}
						</div>
					</div>
					<div className="card-row-right">
						<button className="btn--primary btn--s" onClick={() => this.assignVisual(visual)}>{ t('assign') }</button>
					</div>
				</div>
			</>
        );
    }

	assignVisual(visual: Visual) {
		assign_visual_2_support(this.props.match.params.orderId, this.props.match.params.supportId, visual.id).then(() => {
			this.props.history.push(`/orders/new_order/${this.props.match.params.orderId}`);
		}).catch(console.error);
	}

	renderFilter() {
		if (this.state.show_filter) {
			const campaigns = this.props.order_assign_visual_state.campaigns.map(function(campaign) { return { name: campaign.title, value: campaign.id } as SelectOption});
			return (
				<OrderCampaignFilter
					filter={this.props.order_assign_visual_state.filter}
					assignationType={this.props.order_assign_visual_state.assignationType}
					campaigns={campaigns}
    				sections={this.props.datatypes.sections.map(section_map)}
    				priorities={this.props.datatypes.priorities}
    				languages={this.props.datatypes.production_languages}
					onClose={() => {
						this.setState({ show_filter: !this.state.show_filter })
					}}
					onClear={() => {
						this.props.clearFilter();
						this.setState({ show_filter: !this.state.show_filter })
						this.props.getCampaigns();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.setState({ show_filter: !this.state.show_filter })
						this.props.getCampaigns();
					}}
				/>
			);
		}
	}

	render () {
		const { t } = this.props;
		return (
			<div className="base-content">
				{this.renderFilter()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('orders'), url: '/orders' }, { text: t('assign_visual') }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<BackArrow onClick={() => this.props.history.goBack()} />
								<h1 className="headline-1--regular section-header-title">{ t('assign_visual') }</h1>
							</div>
							<div className="section-header-right">
								<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.order_assign_visual_state.filter)} onClick={() => this.setState({ show_filter: !this.state.show_filter })}></button>
							</div>
						</div>
						<div className="form-row-content">
							<Alert severity="warning">{ t('only_images_with_sizes_that_match_the_support_are_shown') }</Alert>
						</div>
						{this.renderCampaigns()}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(AssignVisual));
