import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import moment from 'moment';
import { RootState } from '../../../reducers';
import {
	getStores2,
	getSelectedSupports2,
	oneCampaign,
	updateShowVisualAddModal,
	getAssignedImages,
	clearVisualFilter,
	updateVisualFilter,
	updateVisualFilterVisibility,
	getVisuals,
	updateStoreAndSupportFilterVisibility,
	clearStoreAndSupportFilter,
	updateStoreAndSupportFilter,
	getAssignationType,
	updateAssigningModal,
	updateUnAssigningModal,
	updateAssignationOk,
	updateAssignationError,
	updatePreparedSelect,
	updateAssignedSelect
} from './CampaignDetailActions';
import { getStoreTypes, getSections, getSupportTypes, getPriorities, getCountries, getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import {
	edit_campaign,
	delete_visual,
	assign_visual_2_support,
	delete_campaign_support,
	delete_visual_2_support,
	edit_visuals,
	assign_automatic,
	unassign_all,
	delete_campaign
} from '../../../api';
import { Campaign, Support, Visual } from '../../../interfaces/interfaces';
import {
	Spinner,
	Breadcrumb,
	Timeline,
	CampaignAddModal,
	BackArrow,
	SupportList,
	VisualAddModal,
	VisualPreview,
	DropzoneWrapper,
	VisualFilter,
	VisualEditModal,
	StoreAndSupportFilter,
	VisualImage,
	SaveingModal
} from '../../../components';
import { pluralize } from '../../../util/text';
import { withTranslation, WithTranslation } from 'react-i18next';
import cookies,{ get_client_name } from '../../../util/cookies';
import 'react-dropzone-uploader/dist/styles.css'
import { is_ready, is_section_assigned, is_priority_assigned, is_production_language_assigned } from '../../../util/visual';
import Alert from '@material-ui/lab/Alert';
import { is_blocked, status_pill_class } from '../../../util/campaign';
import { country_map, section_map } from '../../../util/select_maps';
import { remove_decimals } from '../../../util/number';
import { checkVisualSizes } from '../../../util/visual';
import { hasAssignationError } from '../../../util/support';
import { filter_style } from '../../../util/filter';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_detail_state: state.campaignDetail,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	oneCampaign,
	getStores2,
	getSelectedSupports2,
	updateShowVisualAddModal,
	getAssignedImages,
	updateVisualFilterVisibility,
	updateStoreAndSupportFilterVisibility,
	clearVisualFilter,
	clearStoreAndSupportFilter,
	updateVisualFilter,
	updateStoreAndSupportFilter,
	getVisuals,
	getSections,
	getStoreTypes,
	getSupportTypes,
	getCountries,
	getAssignationType,
	getProductionLanguages,
	getPriorities,
	assign_automatic,
	unassign_all,
	updateAssigningModal,
	updateUnAssigningModal,
	updateAssignationOk,
	updateAssignationError,
	updatePreparedSelect,
	updateAssignedSelect,
	delete_campaign,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

enum tabOptions { visuals, supports };

interface State {
	currentTab: tabOptions,
	currentSupport: Support,
	showAddModal: boolean,
	previewVisual: Visual|null,
	showEditModal: boolean,
	editVisual: Visual|null,
	uploading: boolean
}

class Detail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			currentTab: tabOptions.visuals,
			currentSupport: {} as Support,
			showAddModal: false,
			previewVisual: null,
			showEditModal: false,
			editVisual: null,
			uploading: false
		};
	}

	componentDidMount() {
		this.updateData();
		this.props.updateAssignationOk(false);
	}

	updateData() {
		const { t } = this.props;
		this.props.oneCampaign(this.props.match.params.id);
		this.props.getSelectedSupports2(this.props.match.params.id);
		this.props.getAssignedImages(this.props.match.params.id);
		this.props.getStores2(this.props.match.params.id);
		this.props.getVisuals(this.props.match.params.id);
		this.props.getSections();
		this.props.getPriorities();
		this.props.getProductionLanguages();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getCountries();
		this.props.getAssignationType();
		this.props.updatePreparedSelect(t);
		this.props.updateAssignedSelect(t);
		this.props.updateStoreAndSupportFilterVisibility(false);
		this.props.updateVisualFilterVisibility(false)
	}

	showTab(option: tabOptions) {
		this.setState({ currentTab: option });
	}

	selectedTabClass(option: tabOptions) {
        return this.state.currentTab === option ? 'headline-4--semibold tab-bar-element tab-bar-element--active' : 'headline-4--semibold tab-bar-element';
	}

	deleteVisual(id: string, campaign_id: string) {
		delete_visual(id, campaign_id).then(() => this.updateData()).catch(console.error);
	}

	showAddModalSwap() {
		this.setState({ showAddModal: !this.state.showAddModal });
	}

	deleteSupport(support_id: string) {
		delete_campaign_support(this.props.match.params.id, support_id).then(() => {
			this.updateData();
		})
	}

	deleteSupportVisualAssignation(support_id: string) {
		const { campaign } = this.props.campaign_detail_state;

		delete_visual_2_support(campaign?.id||'', support_id).then(() => {
			this.updateData();
		}).catch(console.error);
	}

	getIndex(value: any, arr: any, prop: string) {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][prop] === value) {
				return i;
			}
		}
		return -1;
	}

	renderCampaignAddModal(campaign: Campaign) {
		if (this.state.showAddModal) {
			return (
				<CampaignAddModal
					campaign={campaign}
					onClose={() => this.showAddModalSwap()}
					onSubmit={(campaign: Campaign) => {
						edit_campaign(campaign).then(() => {
							this.showAddModalSwap();
							this.props.oneCampaign(this.props.match.params.id);
						}).catch(err => console.error);
					}}
					onDelete={(id: string) => {
						delete_campaign(id).then(() => {
							this.showAddModalSwap();
							this.props.history.goBack();
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderVisualAddModal(campaign: Campaign) {
		if (this.props.campaign_detail_state.showVisualAddModal) {
			const filteredImages = this.props.campaign_detail_state?.campaign?.images.filter(image => (image.production_language === this.state.currentSupport.production_language || image.production_language === "1") && (image.section === this.state.currentSupport.section))
			return (
				<VisualAddModal
					visuals={filteredImages||[]}
					support={this.state.currentSupport}
					selectedVisual={{} as Visual}
					onClose={() => this.props.updateShowVisualAddModal(!this.props.campaign_detail_state.showVisualAddModal)}
					onSubmit={(support_id: string, visual_id: string) => {
						assign_visual_2_support(campaign.id!, support_id, visual_id).then(() => {
							this.updateData();
							this.props.updateShowVisualAddModal(!this.props.campaign_detail_state.showVisualAddModal)
						}).catch(console.error);
					}}
				/>
			);
		}
	}

	onImagesUploaded() {
		setTimeout(() => {
			this.updateData()
		}, 1000);
	}

	renderDropzone() {
		const { campaign } = this.props.campaign_detail_state;

		return (
			<DropzoneWrapper
				url={`${process.env.REACT_APP_API_URL}/campaigns/upload_images/${campaign?.id}`}
				token={cookies.get('b2b-kendu-token')}
				onUploadStarted={() => this.setState({ uploading: true })}
				onUploadCompleted={() => {
					this.onImagesUploaded()
					this.setState({ uploading: false })
				}}
			/>
		);
	}

	renderVisualFilter() {

		if (this.props.campaign_detail_state.showVisualFilter) {
			return (
				<VisualFilter
					filter={this.props.campaign_detail_state.visualFilter}
					prepared={this.props.campaign_detail_state.prepared}
					assigned={this.props.campaign_detail_state.assigned}
					sections={this.props.datatypes.sections.map(section_map)}
					priority={this.props.datatypes.priorities}
					onClose={() => this.props.updateVisualFilterVisibility(!this.props.campaign_detail_state.showVisualFilter)}
					onClear={() => {
						this.props.clearVisualFilter();
						this.props.updateVisualFilterVisibility(!this.props.campaign_detail_state.showVisualFilter);
						this.props.getVisuals(this.props.match.params.id);
					}}
					onSubmit={(filter) => {
						this.props.updateVisualFilter(filter);
						this.props.updateVisualFilterVisibility(!this.props.campaign_detail_state.showVisualFilter);
						this.props.getVisuals(this.props.match.params.id);
					}}
				/>
			);
		}
	}

	loadMoreVisuals() {
		this.props.getVisuals(this.props.match.params.id);
	}

	renderStoreAndSupportFilter() {

		if (this.props.campaign_detail_state.showStoreAndSupportFilter) {
			return (
				<StoreAndSupportFilter
					filter={this.props.campaign_detail_state.storeAndSupportFilter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					supportStates={this.props.campaign_detail_state.assigned}
					countries={this.props.datatypes.countries.map(country_map)}
					onClose={() => this.props.updateStoreAndSupportFilterVisibility(!this.props.campaign_detail_state.showStoreAndSupportFilter)}
					onClear={() => {
						this.props.clearStoreAndSupportFilter();
						this.props.updateStoreAndSupportFilterVisibility(!this.props.campaign_detail_state.showStoreAndSupportFilter);
						this.props.getStores2(this.props.campaign_detail_state.campaign?.id!!);
					}}
					onSubmit={(storeAndSupportfilter) => {
						this.props.updateStoreAndSupportFilter(storeAndSupportfilter);
						this.props.updateStoreAndSupportFilterVisibility(!this.props.campaign_detail_state.showStoreAndSupportFilter);
						this.props.getStores2(this.props.campaign_detail_state.campaign?.id!!);
					}}
				/>
			);
		}
	}

	renderStatus(visual: Visual) {
		const { assignedImages, assignationType } = this.props.campaign_detail_state;
		const { t }  = this.props;

		if (Object.values(assignedImages).find(element => element.id === visual.id)) {
            return <p className={'text-3--semibold status-pill status-pill--assigned'}>{ t('assigned') }</p>
		} else if (is_ready(visual, assignationType)) {
            return <p className={'text-3--semibold status-pill status-pill--live'}>{ t('ready') }</p>
		} else {
            return <p className={'text-3--semibold status-pill status-pill--not-assigned'}>{ t('not_ready') }</p>
		}
	}

	renderVisualDetails(visual: Visual) {
		const { assignationType } = this.props.campaign_detail_state;

		return (
			<p className="visualDetail text-3--regular">
				{is_section_assigned(assignationType) ? this.renderSection(visual) : ''}
				{is_priority_assigned(assignationType) ? this.renderPriority(visual) : ''}
				{is_production_language_assigned(assignationType)? this.renderProductionLanguage(visual) : ''}
			</p>
		);

	}

	duplicateVisualProperties(visual: Visual) {
		const { assignationType, visuals } = this.props.campaign_detail_state;

		if (is_ready(visual,assignationType)) {
			let evaluationCondition : any;
			switch(assignationType) {
				default: evaluationCondition = (element:Visual) => element.id !== visual.id && element.priority === visual.priority && element.section === visual.section && element.production_language === visual.production_language && checkVisualSizes(visual, element);
					break;
			}
			const foundDuplicateProperties = visuals.find(evaluationCondition)
			return foundDuplicateProperties;
		}

	}

	renderDuplicatePropertiesError() {
		const { visuals } = this.props.campaign_detail_state;
		const { t }  = this.props;

		const foundDuplicated = visuals.find(visual => this.duplicateVisualProperties(visual));
		const foundError = visuals.some(visual => visual.has_assignation_error);
		if (foundDuplicated || foundError) {
			return (
				<>
					{foundDuplicated &&
						<div className="multipleVisualAlert">
							<Alert severity="warning">
								{ t('MultipleVisuals1Support') }
							</Alert>
						</div>}
					{foundError &&
						<div className="multipleVisualAlert">
							<Alert severity="warning">
								{ t('visuals_assignation_error') }
							</Alert>
						</div>}
				</>
			);
		}

	}

	renderSection(visual: Visual) {
		const { sections } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('section') } <span className="detail">{sections && visual.section && sections.filter(sec => sec.id === visual.section).length > 0 ? sections.filter(sec => sec.id === visual.section)[0].description : ''}</span></>) ;
	}

	renderPriority(visual: Visual) {
		const { priorities: prorities } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('priority') } <span className="detail">{prorities && visual.priority && prorities.filter(sec => sec.value === visual.priority).length > 0 ? prorities.filter(sec => sec.value === visual.priority)[0].name : ''}</span></>);
	}

	renderProductionLanguage(visual: Visual) {
		const { t }  = this.props;
		const { production_languages } = this.props.datatypes;
		const production_language = production_languages.find(production_language => production_language.value.toString() === visual.production_language);

		return (<>{ t('production_language') } <span className="detail">{ production_language?.name || '' }</span></>) ;
	}

	getSelectedStores() {
		const { stores, selectedSupports } = this.props.campaign_detail_state;
		return stores.filter(store => {
			return ((store.supports?.length ?? 0) > 0) && (store.supports!.some(support => selectedSupports.includes(support.id || '')))
		});
	}

	renderSupportsError() {
		const { t }  = this.props;
		const { assignedImages } = this.props.campaign_detail_state;
		if (this.getSelectedStores().some(store => (store.supports||[]).some(support => hasAssignationError(support, assignedImages)))) {
			return (
				<div className="supportsErrorAlert">
					<Alert severity="warning">
						{ t('support_assignation_error') }
					</Alert>
				</div>
			);
		}
	}

	renderTab(campaign: Campaign) {
		const { selectedSupports, visuals } = this.props.campaign_detail_state;
		const { t }  = this.props;

		if (this.state.currentTab === tabOptions.visuals) {
			if (!campaign.images || campaign.images.length === 0) {
				return (this.renderDropzone());
			} else {
				return (
					<div id="campaing-tab-imagenes" className="campaing-tab-content">

						<div className="card-header">
							<div className="card-header-left">
								<p className="text-2--regular" style={{marginRight: 10}}>{ pluralize(visuals.length, [t('visual'), t('visual')]) }</p>
							</div>
							<div className="card-header-right">
							<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_detail_state.visualFilter)} onClick={() => this.props.updateVisualFilterVisibility(!this.props.campaign_detail_state.showVisualFilter)}></button>
								<div className="input-content">
									<input type="file" name="file" id="file" className="inputfile" />
									{ is_blocked(campaign) &&
										<label className="btn--secondary btn--m btn--disabled">{ t('massive_edition') }</label>}
									{ !is_blocked(campaign) &&
										<Link to={`/campaigns/${campaign.id}/massive_edition`}><label className="btn--secondary btn--m">{ t('massive_edition') }</label></Link>}

								</div>
							</div>
						</div>

						{ !is_blocked(campaign) && this.renderDropzone() }

						{this.renderDuplicatePropertiesError()}
						<div className="card-content">
							{visuals.map((visual, index) => (

								<div key={index} className={"card-row card-row--visual" + (this.duplicateVisualProperties(visual) || visual.has_assignation_error ? '--error' : '')}>
									<div className="card-row-left">
										<div className="card-img card-img--visual">
											<VisualImage visual={visual} onClick={() => this.setState({ previewVisual: visual })} />
										</div>
										<div className="visual-info">
											<p className="text-1--regular subcard-title">{visual.name} <span className="text-3--regular" style={{marginLeft: '8px'}}>{remove_decimals(Number(visual.original_width))} x {remove_decimals(Number(visual.original_height))}</span></p>
											{this.renderVisualDetails(visual)}
										</div>
									</div>
									<div className="card-row-right">
										{this.renderStatus(visual)}
										<div className="card-options">
											{ is_blocked(campaign) &&
												<button className="icon icon-16-edit section-header-icon icon--disable"></button>}
											{ !is_blocked(campaign) &&
												<button className="icon icon-16-edit section-header-icon" onClick={() => {
													this.setState({
														editVisual: visual,
														showEditModal: !this.state.showEditModal
													});
												}}></button>}
											{!Object.values(this.props.campaign_detail_state.assignedImages).map(visual => visual.id).includes(visual.id) && !is_blocked(campaign) ?
												<button className="icon icon-16-delete section-header-icon" onClick={() => { if (window.confirm(t('do_you_want_to_delete_this_visual'))) this.deleteVisual(visual.id, campaign.id!)}}></button> :
												<button className="icon icon-16-delete section-header-icon icon--disable"></button>}
										</div>
									</div>
								</div>
							))}
						</div>

					</div>
				);
			}
		} else if (this.state.currentTab === tabOptions.supports) {
			if (selectedSupports.length === 0) {
				return (
					<div id="campaing-tab-tiendas" className="campaing-tab-content">
						<div className="drop-area-content">
							<div className="drop-area">
								<img src="img/icon-48-attach-store.svg" alt=""/>
								<p className="text-2--semibold drop-area-title">{ t('not_assigned_supports') }</p>
								<p className="text-2--regular drop-area-subtitle">{ t('not_assigned_supports_yet') }</p>
								<div className="input-content">
									{ is_blocked(campaign) &&
										<label className="btn--primary btn--m btn--disabled">{ t('manage_stores') }</label>}
									{ !is_blocked(campaign) &&
										<Link to={`/campaigns/${campaign.id}/add_support`}><label className="btn--primary btn--m">{ t('manage_stores') }</label></Link>}
								</div>
							</div>
						</div>
					</div>
				);
			} else {
				const selected_stores = this.getSelectedStores();
				return (
					<div id="campaing-tab-tiendas" className="campaing-tab-content">
						<div className="store-content">
							<div className="card-header">
								<div className="card-header-left">
									<p className="text-2--regular" style={{marginRight: 10}}>{ pluralize(selected_stores.length, [t('store'), t('stores')]) }</p>
									<p className="text-2--regular">{ pluralize(selectedSupports.length, [t('support'), t('supports')]) }</p>
								</div>
								<div className="card-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_detail_state.storeAndSupportFilter)} onClick={() => this.props.updateStoreAndSupportFilterVisibility(!this.props.campaign_detail_state.showStoreAndSupportFilter)}></button>
									<div className="input-content">
										<input type="file" name="file" id="file" className="inputfile" />
										{ is_blocked(campaign) &&
											<label className="btn--secondary btn--m btn--disabled">{ t('manage_stores') }</label>}
										{ !is_blocked(campaign) &&
											<Link to={`/campaigns/${campaign.id}/add_support`}><label className="btn--secondary btn--m">{ t('manage_stores') }</label></Link>}
									</div>
								</div>
							</div>
							{this.renderSupportsError()}
							{selected_stores.map((store) => (
								<div key={store.id} className="store-card--view">
									<SupportList
										key={store.id||''}
										id={store.id||''}
										name={store.name}
										collapsed={true}
										supports={(store.supports||[])}
										checkBox={false}
										isCampaignBlocked={is_blocked(campaign)}
										production_languages={this.props.datatypes.production_languages}
										assignedImages={this.props.campaign_detail_state.assignedImages}
										selectedSupports={this.props.campaign_detail_state.selectedSupports}
										addVisual={(support: Support) => {
											this.setState({ currentSupport: support });
											this.props.updateShowVisualAddModal(!this.props.campaign_detail_state.showVisualAddModal)
										}}
										addSupports={() => null}
										removeSupports={() => null}
										deleteSupport={(support_id) => { if (window.confirm(t('do_you_want_to_delete_this_support'))) this.deleteSupport(support_id) }}
										onDeleteAssignation={(support_id) => { if (window.confirm(t('do_you_want_to_delete_the_visual'))) this.deleteSupportVisualAssignation(support_id) }}
									/>
								</div>
							))}
						</div>
					</div>
				);
			}
		}

	}

	renderVisualPreview () {
		const { previewVisual } = this.state;

		if (previewVisual) {
			return <VisualPreview visual={previewVisual} onClose={() => this.setState({ previewVisual: null })} />
		}
	}

	renderVisualEditModal () {
		if (this.state.showEditModal) {
			return(
				<VisualEditModal
                    visual={this.state.editVisual||{} as Visual}
                    sections={this.props.datatypes.sections.map(section_map)}
                    priorities={this.props.datatypes.priorities}
					production_languages={this.props.datatypes.production_languages}
					assignationType={this.props.campaign_detail_state.assignationType}
                    onClose={() => this.setState({ showEditModal: !this.state.showEditModal })}
                    onSubmit={(visual) => {
						edit_visuals([visual]).then(() => {
							this.setState({ showEditModal: !this.state.showEditModal });
							this.props.getVisuals(this.props.match.params.id);
						}).catch(err => console.error);
					}}
                />
			);
		}
	}

	assignAutomatic() {
		this.props.updateAssigningModal(true);
		this.props.updateAssignationOk(false);
		this.props.updateAssignationError(false);
		assign_automatic(this.props.match.params.id).then(() => {
			this.props.updateAssigningModal(false);
			this.props.updateAssignationOk(true);
			this.updateData();
		}).catch(err => console.error);
	}

	unAssign() {
		this.props.updateUnAssigningModal(true);
		//this.props.updateAssignationOk(false);
		//this.props.updateAssignationError(false);
		unassign_all(this.props.match.params.id).then(() => {
			this.props.updateUnAssigningModal(false);
			//this.props.updateAssignationOk(true);
			this.updateData();
		}).catch(err => console.error);
	}

	renderAssigningModal() {
		const { showAssigningModal } = this.props.campaign_detail_state;
		const { t } = this.props;
		if (showAssigningModal) {
			return (<SaveingModal message={ t('assigning_visuals') } />);
		}
	}

	renderUnAssigningModal() {
		const { showUnAssigningModal } = this.props.campaign_detail_state;
		const { t } = this.props;
		if (showUnAssigningModal) {
			return (<SaveingModal message={ t('unassigning_visuals') } />);
		}
	}

	renderAssignationMessages() {
		const { assignationOk, assignationError } = this.props.campaign_detail_state;
		const { t } = this.props;
		if (assignationOk) {
			return (
				<div className="basic-card basic-card--32">
					<Alert severity="success">{t('assignation_automatic_has_been_finished')}</Alert>
				</div>
			);
		}
		if (assignationError) {
			return (
				<div className="basic-card basic-card--32">
					<Alert severity="error">{t('an_error_occurred_in_the_assignation_automatic')}</Alert>
				</div>
			);
		}
	}

	renderSummaryButton() {
		const { campaign, assignedImages } = this.props.campaign_detail_state;
		const { t } = this.props;
		if (Object.values(assignedImages).length === 0) {
			return (<button className="btn--primary btn--disabled btn--l " disabled={true} ><i className="icon icon-16-resume"></i>{ t('summary') }</button>);
		} else {
			return (<Link to={`/campaigns/${campaign!!.id}/summary`}><button className="btn--primary btn--l " disabled={false} ><i className="icon icon-16-resume"></i>{ t('summary') }</button></Link>);
		}
	}

	renderAssignAutomaticButton() {

		const { campaign, assignationType, visuals } = this.props.campaign_detail_state;
		const { t } = this.props;

		const is_any_ready: boolean = !!visuals.find(element => is_ready(element, assignationType) === true)
		if (is_any_ready && !is_blocked(campaign)) {
            return (<button className="btn--primary btn--m" onClick={() => this.assignAutomatic()} disabled={false}>{ t('assign_automatic') }</button>);
		} else {
            return (<button className="btn--primary btn--disabled btn--m" disabled={true}>{ t('assign_automatic') }</button>);
		}

	}

	renderUnAssignAutomaticButton() {

		const { campaign, assignedImages } = this.props.campaign_detail_state;
		const { t } = this.props;

		const is_any_assigned: boolean = Object.values(assignedImages).some( element => element.id !== undefined)
		if (is_any_assigned && !is_blocked(campaign)) {
            return (<button className="btn--secondary btn--m" onClick={() => this.unAssign()} disabled={false}>{ t('unassign_all') }</button>);
		} else {
            return (<button className="btn--secondary btn--disabled btn--m" disabled={true}>{ t('unassign_all') }</button>);
		}

	}

	visualsTabClicked() {
		if (!this.state.uploading) {
			this.showTab(tabOptions.visuals);
			this.props.updateStoreAndSupportFilterVisibility(false);
		}
	}

	supportsTabClicked() {
		if (!this.state.uploading) {
			this.showTab(tabOptions.supports);
			this.props.updateVisualFilterVisibility(false);
		}
	}

	render () {
		const { campaign, loading, selectedSupports } = this.props.campaign_detail_state;
		const { t } = this.props;

		if (loading || !campaign) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_campaign') } />
					</div>
				</div>
			);
		}
		return (
			<div className="base-content">
				{this.renderVisualFilter()}
				{this.renderStoreAndSupportFilter()}
				{this.renderCampaignAddModal(campaign)}
				{this.renderVisualAddModal(campaign)}
				{this.renderVisualPreview()}
				{this.renderVisualEditModal()}
				{this.renderAssigningModal()}
				{this.renderUnAssigningModal()}
				<div className="content">

					<div className="container">

						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('campaigns'), url: '/campaigns' }, { text: `${campaign.title}` }]}/>

						<div className="section-header">
							<div className="section-header-left">
								<BackArrow onClick={() => this.props.history.goBack()} />
								<div className="card-img card-img--64" style={{backgroundImage: `url('/img/img-placeholder.png')`}}></div>
								<div>
									<h1 className="headline-1--regular">{campaign.title}</h1>
									<h2 className="text-3--regular section-header-subtitle">{moment(campaign.start_date).format('ll')} › {moment(campaign.end_date).format('ll')}</h2>
								</div>
							</div>
							<div className="section-header-right">
								<button className="btn--secondary btn--l" onClick={() => this.showAddModalSwap()}><i className="icon icon-16-edit"></i>{ t('edit_campaign') }</button>

								{this.renderSummaryButton()}
							</div>
						</div>

						<div className="basic-card basic-card--32 campaing-scope">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-4--semibold">{ t('campaign_information') }</p>
									<p className={'text-2--regular days-counter text-3--semibold status-pill ' + status_pill_class(campaign.status || '')}>{campaign.status}</p>
								</div>
								<div className="class-header-right"></div>
							</div>
							{!campaign.reference &&
								<div className="warning-box">
									<div className="warning-box-left">
										{t('campaign_without_reference_fill_to_proceed')}
									</div>
									<div className="warning-box-right">
										<button className="orange-button" onClick={() => this.showAddModalSwap()}>{t('add_reference')}</button>
									</div>
								</div>
							}
							<div className="grid-layout">
								<div className="grid-third-1">
									<p className="item-label text-3--regular">{t('creator')}</p>
									<p className="text-2--regular">{campaign.user_name || '-'}</p>
								</div>
								<div className="grid-third-2">
									<p className="item-label text-3--regular">{t('campaign_duration')}</p>
									<p className="text-2--regular">{moment(campaign.start_date).format('ll')} › {moment(campaign.end_date).format('ll')}</p>
								</div>
								<div className="grid-third-3">
									<p className="item-label text-3--regular">{t('reference')}</p>
									<p className="text-2--regular">{campaign.reference || '-'}</p>
								</div>
							</div>
						</div>

						<div className="basic-card basic-card--32 campaing-scope">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-4--semibold">{ t('campaign_duration') }</p>
									<p className="text-2--regular days-counter">{moment(campaign.end_date).diff(moment(campaign.start_date), 'days')} { t('days') }</p>
								</div>
								<div className="class-header-right"></div>
							</div>
							<Timeline campaigns={[campaign]}/>
						</div>

						{this.renderAssignationMessages()}

						<div className="basic-card basic-card--32">
							<div className="tab-bar">
								<div>
									<button id="tab-imagenes" className={this.selectedTabClass(tabOptions.visuals)} onClick={() => this.visualsTabClicked()}>{ t('images') } 
										<p className="tab-bar-bullet">{campaign.images.length}</p>
									</button>
									<button id="tab-tiendas" className={this.selectedTabClass(tabOptions.supports)} onClick={() => this.supportsTabClicked()}>{ t('stores_and_supports') }
										<p className="tab-bar-bullet">{selectedSupports.length}</p>
									</button>
								</div>
								<div>
									{this.renderUnAssignAutomaticButton()}
									{this.renderAssignAutomaticButton()}
								</div>
							</div>
							{this.renderTab(campaign)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Detail));
