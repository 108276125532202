import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Visual } from '../interfaces/interfaces';
import { VisualImage } from './VisualImage';

export interface VisualPreviewProps {
    visual: Visual,
    onClose: () => void,
}

type VisualPreviewPropsSum = VisualPreviewProps & WithTranslation;

class VisualPreviewComponent extends React.Component<VisualPreviewPropsSum> {
    render () {
        return (
            <div className="pop-new-image" onClick={ (event) => event.preventDefault()}>
                <div className="pop-bg">
                    <div className="pop-img-container pop-box--place">
                        <img src="/img/x.svg" className="pop-image-close"  onClick={() => this.props.onClose()} alt="" />
                        <VisualImage visual={this.props.visual} className="pop-image" />
                    </div>
                </div>
            </div>
        );
    };
}

const VisualPreview = withTranslation()(VisualPreviewComponent);
export { VisualPreview };
