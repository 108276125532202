import {
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_REPEAT,
    UPDATE_PASSWORD_REPEAT_ERROR,
    SetingsActions
} from "./ActionTypes";
import { Reducer } from 'redux';

export interface ClientDetailState {
    password: string,
    passwordError: string,
    passwordRepeat: string,
    passwordRepeatError: string,
}

const INITIAL_STATE: ClientDetailState = {
    password: '',
    passwordError: '',
    passwordRepeat: '',
    passwordRepeatError: '',
};

const reducer: Reducer<ClientDetailState, SetingsActions> = (state = INITIAL_STATE, action: SetingsActions) => {
    switch(action.type) {
        case UPDATE_PASSWORD:
            return { ...state, password: action.payload };
        case UPDATE_PASSWORD_ERROR:
            return { ...state, passwordError: action.payload };
        case UPDATE_PASSWORD_REPEAT:
            return { ...state, passwordRepeat: action.payload };
        case UPDATE_PASSWORD_REPEAT_ERROR:
            return { ...state, passwordRepeatError: action.payload };
        default:
            return state;
    }
}

export default reducer;