import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import {
	getCampaigns,
	updateFilterVisibility,
	updateFilter,
	getStatuses,
	clearFilter
} from './CampaignIndexActions';
import { getStoreTypes, getSupportTypes, getCountries } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, CampaignCard, CampaignAddModal, CampaignFilter } from '../../../components';
import { create_campaign } from '../../../api';
import { Campaign } from '../../../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { country_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';

const mapStateToProps = (state: RootState) => {
	return {
		campaign_index_state: state.campaignIndex,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getCampaigns,
	updateFilterVisibility,
	updateFilter,
	getStatuses,
	getStoreTypes,
	getSupportTypes,
	getCountries,
	clearFilter
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

interface State {
	showAddModal: boolean,
}

class Index extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			showAddModal: false,
        };
	}

	componentDidMount() {
		this.props.getCampaigns();
		this.props.getStatuses();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getCountries();
	}

	loadMoreCampaigns() {
		this.props.getCampaigns(this.props.campaign_index_state.campaigns.slice(-1).pop()?.key)
	}

	renderCampaignAddModal() {
		if (this.state.showAddModal) {
			return (
				<CampaignAddModal
					onClose={() => this.setState({ showAddModal: !this.state.showAddModal })}
					onSubmit={(campaign: Campaign) => {
						create_campaign(campaign).then(() => {
							this.setState({ showAddModal: !this.state.showAddModal });
							this.props.getCampaigns();
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderCampaignFilter() {
		if (this.props.campaign_index_state.showFilter) {
			return (
				<CampaignFilter
					filter={this.props.campaign_index_state.filter}
					storeTypes={this.props.datatypes.store_types}
    				supportTypes={this.props.datatypes.support_types}
    				countries={this.props.datatypes.countries.map(country_map)}
    				statuses={this.props.campaign_index_state.statuses}
					onClose={() => this.props.updateFilterVisibility(!this.props.campaign_index_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateFilterVisibility(!this.props.campaign_index_state.showFilter);
						this.props.getCampaigns();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.updateFilterVisibility(!this.props.campaign_index_state.showFilter);
						this.props.getCampaigns();
					}}
				/>
			);
		}
	}

	renderCampaigns() {
		const { campaigns } = this.props.campaign_index_state;

		return campaigns.map((campaign) => (
			<CampaignCard key={campaign.id} campaign={campaign}/>
		))
	}

	renderLoadMore() {
		const { loading } = this.props.campaign_index_state;
		const { t } = this.props;

		if (loading) {
			return (
				<Spinner message={ t('loading_campaigns') } />
			);
		}
		return (
			<button className="btn--secondary btn--l btn--load-more" onClick={() => this.loadMoreCampaigns()}>{ t('load_more_campaigns') }</button>
		)
	}

	render () {
		const { t } = this.props;
		return (
			<>
				<div className="base-content">
					{this.renderCampaignAddModal()}
					{this.renderCampaignFilter()}
					<div className="content">
						<div className="container">

							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('campaigns') }]}/>

							<div className="section-header">
								<div className="section-header-left">
									<h1 className="headline-1--regular section-header-title">{ t('campaigns') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.campaign_index_state.filter)} onClick={() => this.props.updateFilterVisibility(!this.props.campaign_index_state.showFilter)}></button>
									<button className="btn--primary btn--l pop-reveal" onClick={() => this.setState({ showAddModal: !this.state.showAddModal })}><i className="icon icon-16-add"></i>{ t('new_campaign') }</button>
								</div>
							</div>

							{this.renderCampaigns()}
							{this.renderLoadMore()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
