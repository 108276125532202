import {
    UPDATE_LOADING,
    UPDATE_CAMPAIGN,
    CampaignBudgetActions,
    AppThunk
} from './ActionTypes';
import { one_campaign } from '../../../api';

export const oneCampaign = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignBudgetActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignBudgetActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignBudgetActions);
    }
}