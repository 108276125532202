import { User } from '../../interfaces/interfaces';

export const user_map = (user: any): User => ({
    id: user.id,
    name: user.name,
    last_name: user.last_name,
    email: user.email,
    role: user.role,
    phone: user.phone,
    password: user.password,
    photo: user.photo,
    language: user.language,
    customer: user.customer,
    position: user.position,
    stores: user.stores,

});
