import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface InputTextProps {
    id: string,
    type?: string,
    label: string,
    value?: string,
    disabled?: boolean,
    hasError?: boolean,
    helpText?: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

type InputTextPropsSum = InputTextProps & WithTranslation;

class InputTextComponent extends React.Component<InputTextPropsSum> {

    render () {
        return (
            <TextField
                id={this.props.id}
                type={this.props.type||'text'}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                variant="outlined"
                fullWidth
                disabled={this.props.disabled}
                error={!!this.props.helpText}
                helperText={this.props.helpText}
            />
        );
    };
}

const InputText = withTranslation()(InputTextComponent);
export { InputText };
