import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { InputText } from '../../../components';
import { updateEmail, updateEmailError } from './PasswordRequestActions';
import { withTranslation, WithTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => {
	return {
		security_password_request_state: state.securityPasswordRequest
	}
}

const connector = connect(mapStateToProps, { updateEmail, updateEmailError });

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	send() {
		const { t } = this.props;
		const { email } = this.props.security_password_request_state;
		let hasError = false;
		if (!email) {
			hasError = true;
			this.props.updateEmailError(t('email_is_required'));
		} else {
			this.props.updateEmailError('');
		}
		if (!hasError) {
			// TODO: send request
		}
	}

	render () {
		const { t } = this.props;
		return (
			<section id="login-box">
				<h1><a href="login.php"><img src="img/main-brand.svg" alt="" /></a></h1>

				<h2>{ t('request_password') }</h2>

				<article className="form-row--large">
					<InputText
						id="email"
						label={ t('email') }
						value={this.props.security_password_request_state.email}
						onChange={() => null}
						helpText={this.props.security_password_request_state.emailError}
					/>
				</article>

				<article className="form-row">
					<button className="btn--disabled" onClick={() => this.send()}>{ t('send_recuperation_email') }</button>
				</article>

				<article className="reset_password">
					<p><Link to="/login">{ t('login') }</Link></p>
				</article>

			</section>
		);
	}
}

export default withTranslation()(connector(Settings));
