import {
    UPDATE_LOADING,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_SHOW_ADD_USER_MODAL,
    UPDATE_USERS,
    SetingsActions
} from "./ActionTypes";
import { Reducer } from 'redux';
import { User } from '../../../interfaces/interfaces';

export interface ClientDetailState {
    loading: boolean,
    assignationTypes: string[],
    showAddUserModal: boolean,
    users: User[]
}

const INITIAL_STATE: ClientDetailState = {
    loading: false,
    assignationTypes: [],
    showAddUserModal: false,
    users: [],
};

const reducer: Reducer<ClientDetailState, SetingsActions> = (state = INITIAL_STATE, action: SetingsActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_ASSIGNATION_TYPE:
            return { ...state, assignationTypes: action.payload }; 
        case UPDATE_SHOW_ADD_USER_MODAL:
            return { ...state, showAddUserModal: action.payload };  
        case UPDATE_USERS:
            return { ...state, users: action.payload };
        default:
            return state;
    }
}

export default reducer;