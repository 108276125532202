import {
    UPDATE_LOADING,
    UPDATE_USER,
    UPDATE_PROFILE_INFO_SHOW,
    UPDATE_PROFILE_EDIT_MODAL_SHOW,
    UPDATE_SELECTED_LANGUAGE,
    ProfileDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { User } from '../../../interfaces/interfaces';

export interface CampaignIndexState {
    loading: boolean,
    user: User | null,
    profileInfoShow: boolean,
    profileEditModalShow: boolean,
    selectedLanguage: string,
}

const INITIAL_STATE: CampaignIndexState = {
    selectedLanguage: '',
    loading: false,
    user: null,
    profileInfoShow: true,
    profileEditModalShow: false,
};

const reducer: Reducer<CampaignIndexState, ProfileDetailActions> = (state = INITIAL_STATE, action: ProfileDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SELECTED_LANGUAGE:
            return { ...state, selectedLanguage: action.payload };
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_PROFILE_INFO_SHOW:
            return { ...state, profileInfoShow: action.payload };
        case UPDATE_PROFILE_EDIT_MODAL_SHOW:
            return { ...state, profileEditModalShow: action.payload };
        default:
            return state;
    }
}

export default reducer;