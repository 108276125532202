import { get_order_campaigns, one_client, one_support } from '../../../api';
import { FilterCampaignAndVisualRequest } from '../../../api/interfaces';
import { FilterCampaignAndVisual } from '../../../interfaces/interfaces';
import { write_order_assignation } from '../../../util/local_storage';
import { client_map } from '../../client/client.maps';
import {
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_LOADING,
    UPDATE_CAMPAIGNS,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_SUPPORT,
    OrderAssignVisualActions,
    AppThunk,
} from './ActionTypes';

export const updateFilter = (value: FilterCampaignAndVisual): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as OrderAssignVisualActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as OrderAssignVisualActions);
    }
}

export const getCampaigns = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as OrderAssignVisualActions);

        const campaigns = await get_order_campaigns({
            ...getState().orderAssignVisual.filter,
            key: lastKey,
            page_size: 0
        } as FilterCampaignAndVisualRequest);

        dispatch({
            type: UPDATE_CAMPAIGNS,
            payload: campaigns
        } as OrderAssignVisualActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as OrderAssignVisualActions);
    }
}

export const getSupport = (id: string): AppThunk => {
    return async dispatch => {

        const support = await one_support(id);

        dispatch({
            type: UPDATE_SUPPORT,
            payload: support
        } as OrderAssignVisualActions);
    }
}

export const writeOrderAssignation = (localOrderId: string, supportId: string, visualId: string): AppThunk => {
    return async dispatch => {
        write_order_assignation(localOrderId, supportId, visualId);
    }
}

export const getAssignationType = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        if (client) {
            let mappedClient = client_map(client);
            dispatch({
                type: UPDATE_ASSIGNATION_TYPE,
                payload: mappedClient.assignation_type
            } as OrderAssignVisualActions);
        }

    }
}