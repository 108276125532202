import React from 'react';
import { Visual } from '../interfaces/interfaces';

interface Props {
    visual: Visual,
    className?: string,
    onClick?: () => void,
}

class VisualImage extends React.Component<Props> {
    private img: React.RefObject<HTMLImageElement> = React.createRef();

    image() {
        const { image } = this.props.visual;

        if ((image || '').endsWith('.pdf')) {
            return image.replace('.pdf', '.jpg');
        }
        return image;
    }

    retryImage() {
        setTimeout(() => {
            const img = this.img.current;
            if (img !== null) {
                img.src = this.image();
            }
        }, 5000);
    }

    render () {
        const { visual } = this.props;

        return (
            <img src={this.image()} ref={this.img} onError={(e) => {
                const img = this.img.current;
                if (img !== null) {
                    img.src = '/img/loader.gif';
                }
                this.retryImage();
            }} onClick={this.props.onClick} className={this.props.className} alt={visual.name}/>
        );
    };
}

export { VisualImage };