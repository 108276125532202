import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface CollapseArrowProps {
    collapsed: boolean,
    clicked: () => void
}

type CollapseArrowPropsSum = CollapseArrowProps & WithTranslation;

class CollapsedArrowComponent extends React.Component<CollapseArrowPropsSum> {

    collapsedRotation() {
		if (this.props.collapsed) {
			return {
				transform: 'rotate(-180deg)'
			}
		}
		return {
			transform: 'rotate(0deg)'
		}
    }

    render () {
        return (
            <button className="icon icon-16-drop-down section-header-icon" onClick={() => this.props.clicked()} style={this.collapsedRotation()}></button>
        );
    };
}

const CollapsedArrow = withTranslation()(CollapsedArrowComponent);
export { CollapsedArrow };
