import React from 'react';
import { InputText, InputSelect } from '.';
import {
    FilterCampaignAndVisual,
    SelectOption
} from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { is_priority_assigned, is_production_language_assigned, is_section_assigned } from '../util/visual';

export interface OrderCampaignFilterProps {
    filter?: FilterCampaignAndVisual,
    assignationType: string,
    campaigns: SelectOption[],
    sections: SelectOption[],
    priorities: SelectOption[],
    languages: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterCampaignAndVisual) => void
}

interface State {
    search?: string,
    campaign?: string,
    section?: string,
    priority?: string,
    language?: string,
}

type OrderCampaignFilterPropsSum = OrderCampaignFilterProps & WithTranslation;

class OrderCampaignFilterComponent extends React.Component<OrderCampaignFilterPropsSum, State> {

    constructor(props: OrderCampaignFilterPropsSum) {
        super(props);

        this.state = {
            search: this.props.filter?.title,
            campaign: this.props.filter?.ids && this.props.filter?.ids.length > 0 ? this.props.filter?.ids[0] : '',
            section: this.props.filter?.section,
            priority: this.props.filter?.priority,
            language: this.props.filter?.production_language,
        };
    }

    onSubmit() {
        const { search, campaign, section, priority, language } = this.state;
        this.props.onSubmit({
            title: search,
            ids: campaign ? [campaign] : undefined,
            section,
            priority,
            production_language: language,
        });
    }

    render () {
        const { t, campaigns, sections, priorities, languages } = this.props;
        return (
            <div className="filter-content filter-content--show">

                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_campaigns') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={()=> {this.props.onClose()}}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="search" label={ t('search') } value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                        </div>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="campaign" label={ t('campaign') } options={campaigns} value={this.state.campaign} emptyValue={true} onChange={(value) => this.setState({ campaign: value || '' })} />
                        </div>
                        {is_section_assigned(this.props.assignationType) &&
                            <div className="form-row">
                                <InputSelect id="section" label={ t('section') } options={sections} value={this.state.section} emptyValue={true} onChange={(value) => this.setState({ section: value || '' })} />
                            </div>}
                        {is_priority_assigned(this.props.assignationType) &&
                            <div className="form-row">
                                <InputSelect id="priority" label={ t('priority') } options={priorities} value={this.state.priority} emptyValue={true} onChange={(value) => this.setState({ priority: value || '' })} />
                            </div>}
                        {is_production_language_assigned(this.props.assignationType) &&
                            <div className="form-row"> 
                                <InputSelect id="language" label={ t('language') } options={languages} value={this.state.language} emptyValue={true} onChange={(value) => this.setState({ language: value || '' })} />
                            </div>}
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.onSubmit()}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>

            </div>
        );
    };
}

const OrderCampaignFilter = withTranslation()(OrderCampaignFilterComponent);
export { OrderCampaignFilter };
