import {
    UPDATE_LOADING,
    UPDATE_USER,
    UPDATE_PROFILE_INFO_SHOW,
    UPDATE_PROFILE_EDIT_MODAL_SHOW,
    UPDATE_SELECTED_LANGUAGE,
    ProfileDetailActions,
    AppThunk,
} from './ActionTypes';
import { get_profile } from '../../../api';

export const updateUser = (): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ProfileDetailActions);

        let user = await get_profile();
        dispatch({
            type: UPDATE_USER,
            payload: user
        } as ProfileDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ProfileDetailActions);
    }
}

export const updateProfileInfoShow = (value: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_INFO_SHOW,
            payload: value
        } as ProfileDetailActions);
    }
}

export const updateProfileEditModalShow = (value: boolean): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_PROFILE_EDIT_MODAL_SHOW,
            payload: value
        } as ProfileDetailActions);
    }
}

export const updateSelectedLanguage = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SELECTED_LANGUAGE,
            payload: value
        } as ProfileDetailActions);
    }
}