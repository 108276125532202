import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import {
	updateSupports,
	updateFilterShow,
	updateFilter,
	clearFilter,
} from './SupportIndexActions';
import { getSupportTypes, getTextileTypes, getCountries, getProductionLanguages, getStoreTypes, getPriorities, getSections } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, SupportCard, SupportFilterList } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { country_map, section_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';
import { SelectOption } from '../../../interfaces/interfaces';

const mapStateToProps = (state: RootState) => {
	return {
		support_index_state: state.supportIndex,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateSupports,
	updateFilterShow,
	updateFilter,
	getSupportTypes,
	getStoreTypes,
	getTextileTypes,
	getCountries,
	clearFilter,
	getProductionLanguages,
	getSections,
	getPriorities,
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

class Index extends React.Component<Props> {

	componentDidMount() {
		this.props.updateSupports();
		this.props.getSupportTypes();
		this.props.getStoreTypes();
		this.props.getTextileTypes();
		this.props.getCountries();
		this.props.getProductionLanguages();
		this.props.getSections();
		this.props.getPriorities();
	}

	loadMoreSupports() {
		this.props.updateSupports(this.props.support_index_state.supports.slice(-1).pop()?.key);
	}

	renderLoadMore() {
		const { t } = this.props;
		if (this.props.support_index_state.loading) {
			return (
				<Spinner message={ t('loading_supports') } />
			);
		}
		return (
			<button className="btn--secondary btn--l btn--load-more" onClick={() => this.loadMoreSupports()}>{ t('load_more_supports') }</button>
		)
	}

	renderFilter() {
		if (this.props.support_index_state.filterShow) {
			return (
				<SupportFilterList
					filter={this.props.support_index_state.filter}
					supportTypes={this.props.datatypes.support_types}
					storeTypes={this.props.datatypes.store_types}
					textilTypes={this.props.datatypes.textile_types.map(it => ({ value: it.name, name: it.name}) as SelectOption)}
					supportProductionLanguages={this.props.datatypes.production_languages}
					supportSections={this.props.datatypes.sections.map(section_map)}
					supportPriorities={this.props.datatypes.priorities}
					countries={this.props.datatypes.countries.map(country_map)}
					onClose={() => this.props.updateFilterShow(!this.props.support_index_state.filterShow)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateFilterShow(!this.props.support_index_state.filterShow);
						this.props.updateSupports();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.updateFilterShow(!this.props.support_index_state.filterShow)
						this.props.updateSupports();
					}}
				/>
			);
		}
	}

	render () {

		const { t } = this.props;

		return (
			<>
				<div className="base-content">
					{this.renderFilter()}
					<div className="content">

						<div className="container">

							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: 'Soportes' }]}/>

							<section className="section-header">
								<div className="section-header-left">
									<h1 className="headline-1--regular section-header-title">{ t('supports') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.support_index_state.filter)} onClick={() => this.props.updateFilterShow(!this.props.support_index_state.filterShow)}></button>
								</div>
							</section>
							{this.props.support_index_state.supports.map((support, index) => (
								<SupportCard key={index} support={support} production_languages={this.props.datatypes.production_languages} />
							))}
							{this.renderLoadMore()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
