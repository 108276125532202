import { AppThunk } from '..';
import { get_colors, get_countries, get_languages, get_priorities, get_sections, get_store_types, get_support_types, get_textil_types, get_production_languages_trek } from '../../api';
import {
    DatatypesActions,
    UPDATE_COUNTRIES,
    UPDATE_LANGUAGES,
    UPDATE_SECTIONS,
    UPDATE_SUPPORT_TYPES,
    UPDATE_TEXTIL_TYPES,
    UPDATE_COLORS,
    UPDATE_PRIORITIES,
    UPDATE_STORE_TYPES,
    UPDATE_PRODUCTION_LANGUAGES
} from './DatatypesTypes';

export const getLanguages = (): AppThunk => {
    return async dispatch => {

        const languages = await get_languages();

        dispatch({
            type: UPDATE_LANGUAGES,
            payload: languages.map((language: any) => ({ value: language.id.toLowerCase(), name: language.name }))
        } as DatatypesActions);
    }
}

export const getCountries = (): AppThunk => {
    return async dispatch => {

        const countries = await get_countries();

        dispatch({
            type: UPDATE_COUNTRIES,
            payload: countries
        } as DatatypesActions);
    }
}

export const getStoreTypes = (): AppThunk => {
    return async dispatch => {

        const store_types = await get_store_types();

        dispatch({
            type: UPDATE_STORE_TYPES,
            payload: store_types.map((store_type: any) => ({ value: store_type.id, name: store_type.description }))
        } as DatatypesActions);
    }
}

export const getProductionLanguages = (): AppThunk => {
    return async dispatch => {

        const production_languages = await get_production_languages_trek();

        dispatch({
            type: UPDATE_PRODUCTION_LANGUAGES,
            payload: production_languages.map((production_language: any) => ({ value: production_language.id , name: production_language.description }))
        } as DatatypesActions);
    }
}

export const getSections = (): AppThunk => {
    return async dispatch => {

        const sections = await get_sections();

        dispatch({
            type: UPDATE_SECTIONS,
            payload: sections
        } as DatatypesActions);
    }
}

export const getSupportTypes = (): AppThunk => {
    return async dispatch => {

        let support_types = await get_support_types();

        dispatch({
            type: UPDATE_SUPPORT_TYPES,
            payload: support_types.map((support_type: any) => ({ value: support_type.id, name: support_type.description }))
        } as DatatypesActions);
    }
}

export const getTextileTypes = (): AppThunk => {
    return async dispatch => {

        const textile_types = await get_textil_types();

        dispatch({
            type: UPDATE_TEXTIL_TYPES,
            payload: textile_types.map((textile_types: any) => ({ value: textile_types.id, name: textile_types.description }))
        } as DatatypesActions);
    }
}

export const getColors = (): AppThunk => {
    return async dispatch => {

        const colors = await get_colors();

        dispatch({
            type: UPDATE_COLORS,
            payload: colors.map((color: any) => ({ value: color.id, name: color.description }))
        } as DatatypesActions);
    }
}

export const getPriorities = (): AppThunk => {
    return async dispatch => {
        let priorities = await get_priorities();

        dispatch({
            type: UPDATE_PRIORITIES,
            payload: priorities.map((priority: any) => ({ value: priority.id, name: priority.description }))
        } as DatatypesActions);
    }
}