import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Breadcrumb, BudgetSummary, BudgetTable, ListHeader, OrderStoreFilter, VisualPreview } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { OrderStatus, Visual } from '../../../interfaces/interfaces';
import {
	getStores,
	getOrder,
	getOneOrder,
	getVat,
	updateFilter,
	clearFilter,
} from './OrderDetailActions';
import { RouteComponentProps } from 'react-router-dom';
import { statusName, statusPillClass } from '../../../util/order';
import { getCountries, getStoreTypes, getSupportTypes } from '../../../reducers/datatypes/DatatypesActions';
import { country_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';

const mapStateToProps = (state: RootState) => {
	return {
		order_detail_state: state.orderDetail,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getStores,
	getOrder,
	getOneOrder,
	getVat,
	clearFilter,
	updateFilter,
	getCountries,
	getStoreTypes,
	getSupportTypes,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	show_filter: boolean,
	previewVisual: Visual | null,
}

class Detail extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			show_filter: false,
			previewVisual: null,
        };
	}

	componentDidMount() {
		this.props.getStores();
		this.props.getOrder(this.props.match.params.id);
		this.props.getOneOrder(this.props.match.params.id);
		this.props.getVat();
		this.props.getCountries();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
	}

	renderStores() {
		const { stores, assignedImages, vat } = this.props.order_detail_state;
		return (
			<>
				{stores.filter(store => (store.supports || []).some(support => assignedImages && assignedImages[support.id!])).map(store =>
					<div key={store.id} className="basic-card basic-card--32 store-card" style={{ cursor: 'default' }}>
						<div className="store-card--view">
							<ListHeader name={store.name} id={store.id} subtitle={`${store.direccion}, ${store.codigo_postal}, ${store.ciudad}, ${store.pais}`} subtitleBold={store.grupo_tienda}>
								<BudgetTable
									store={store}
									assignedImages={assignedImages}
									productionLanguages={this.props.datatypes.production_languages}
									vat={vat}
								/>
							</ListHeader>
						</div>
					</div>
				)}
				<BudgetSummary
					stores={stores}
					assignedImages={assignedImages}
					vat={vat}
				/>
			</>
		);
	}

	renderVisualPreview() {
		const { previewVisual } = this.state;
		if (previewVisual) {
			return(
				<VisualPreview visual={previewVisual} onClose={() => this.setState({ previewVisual: null })} />
			);
		}
	}

	renderFilter() {
		if (this.state.show_filter) {
			return (
				<OrderStoreFilter
					filter={this.props.order_detail_state.filter}
					storeTypes={this.props.datatypes.store_types}
    				supportTypes={this.props.datatypes.support_types}
    				countries={this.props.datatypes.countries.map(country_map)}
    				// statuses={this.props.campaign_index_state.statuses}
					onClose={() => {
						this.setState({ show_filter: !this.state.show_filter })
					}}
					onClear={() => {
						this.props.clearFilter();
						this.setState({ show_filter: !this.state.show_filter })
						// this.props.getOneOrder(this.props.match.params.id);
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.setState({ show_filter: !this.state.show_filter })
						// this.props.getOneOrder(this.props.match.params.id);
					}}
				/>
			);
		}
	}

	render () {
		const { t } = this.props;
		const { order } = this.props.order_detail_state;
		return (
			<div className="base-content">
				{this.renderVisualPreview()}
				{this.renderFilter()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('orders'), url: '/orders' }, { text: t('order_detail') }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<h1 className="headline-1--regular section-header-title" style={{marginRight: '32px'}}>{ t('order') } #{order?.reference}</h1><span className={`status-pill-mini ${ statusPillClass(order?.status as OrderStatus) }`}>{ statusName(order?.status as OrderStatus, t) }</span>
							</div>
							<div className="section-header-right">
								<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.order_detail_state.filter)} onClick={() => this.setState({ show_filter: !this.state.show_filter })}></button>
								{/* <button className="btn--primary btn--l"><i className="icon icon-16-dowload"></i>{ t('download') }</button> */}
							</div>
						</div>
						{this.renderStores()}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Detail));
