import { Order } from '../../interfaces/interfaces';

export const order_map = (order: any): Order => ({
    id: order.id,
    reference: order.reference,
    kendu_reference: order.kendu_reference,
    stores: order.stores,
    visuals: order.visuals,
    date: order.start_date,
    user_name: order.user_name,
    status: order.status,
    store_list: order.store_list,
});