import React from 'react';
import { InputText, InputSelect } from '.';
import { FilterStoreAndSupport, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface OrderStoreFilterProps {
    filter?: FilterStoreAndSupport,
    storeTypes: SelectOption[],
    supportTypes: SelectOption[],
    countries: SelectOption[],
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterStoreAndSupport) => void
}

interface State {
    search?: string,
    postal_code?: string,
    city?: string,
    region?: string,
    country?: string,
    store_type?: string,
    support_type?: string,
}

type OrderStoreFilterPropsSum = OrderStoreFilterProps & WithTranslation;

class OrderStoreFilterComponent extends React.Component<OrderStoreFilterPropsSum, State> {

    constructor(props: OrderStoreFilterPropsSum) {
        super(props);

        this.state = {
            search: this.props.filter?.name,
            postal_code: this.props.filter?.postal_code,
            city: this.props.filter?.city,
            region: this.props.filter?.region,
            country: this.props.filter?.country,
            store_type: this.props.filter?.store_type,
            support_type: this.props.filter?.support_type,
        };
    }

    onSubmit() {
        const { search, postal_code, city, region, country, store_type, support_type } = this.state;
        this.props.onSubmit({
            name: search,
            postal_code,
            city,
            region,
            country,
            store_type,
            support_type,
        });
    }

    render () {
        const { t } = this.props;
        return (
            <div className="filter-content filter-content--show">

                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_stores') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={()=> {this.props.onClose()}}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="search" label={ t('search') } value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('localization') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputText id="postal-code" label={ t('postal_code') } value={this.state.postal_code} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="city" label={ t('city') } value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputText id="region" label={ t('region') } value={this.state.region} onChange={(e) => this.setState({ region: e.target.value })} />
                        </div>
                        <div className="form-row">
                            <InputSelect id="country" label={ t('country') } options={this.props.countries} value={this.state.country} emptyValue={true} onChange={(value) => this.setState({ country: value || '' })} />
                        </div>
                    </div>

                    <p className="text-3--regular form-group-title">{ t('types') }</p>
                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="store-type" label={ t('store_type') } value={this.state.store_type} emptyValue={true} options={this.props.storeTypes} onChange={(value) => this.setState({ store_type: value || '' })}/>
                        </div>
                        <div className="form-row">
                            <InputSelect id="support-type" label={ t('support_type') } value={this.state.support_type} emptyValue={true} options={this.props.supportTypes} onChange={(value) => this.setState({support_type: value || ''})} />
                        </div>
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.onSubmit()}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>

            </div>
        );
    };
}

const OrderStoreFilter = withTranslation()(OrderStoreFilterComponent);
export { OrderStoreFilter };
