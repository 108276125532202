import React from 'react';
import { Support, AssignedVisuals } from '../interfaces/interfaces';
import { CollapsedArrow, SupportCardAssignVisual } from '.';
import { pluralize } from '../util/text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DatatypesState } from '../reducers/datatypes/DatatypesReducer';

export interface SupportListOrderProps {
    id: string,
    name: string,
    collapsed: boolean,
    supports: Support[],
    assignedImages: AssignedVisuals,
    datatypes: DatatypesState,
    isCampaignBlocked?: boolean,
    localOrderId?: string,
    assignVisual: (support: Support) => void,
    deleteVisual: (id: string) => void,
}

interface State {
    collapsed: boolean,
    supports: Support[],
}

type SupportListOrderPropsSum = SupportListOrderProps & WithTranslation;

class SupportListOrderComponent extends React.Component<SupportListOrderPropsSum, State> {

    constructor(props: SupportListOrderPropsSum) {
        super(props);
        this.state = {
            collapsed: this.props.collapsed,
            supports: this.props.supports
        }
    }

    swapStoreCollapse() {
		this.setState({
			collapsed: !this.state.collapsed
		});
    }

    renderSupports() {
        if (!this.state.collapsed) {
            return (
                <div className="store-hardware-content">
                    {(this.props.supports || []).map((support, index) => (
                        <React.Fragment key={index}>
                            {this.renderSupport(support)}
                        </React.Fragment>
                    ))}
                </div>
            );
        }
    }

    renderSupport(support: Support) {
        return (
            <SupportCardAssignVisual
                key={support.id}
                support={support}
                datatypes={this.props.datatypes}
                assignedImages={this.props.assignedImages}
                localOrderId={this.props.localOrderId}
                onModalClicked={(supportId: string) => this.props.assignVisual(support)}
                onDeleteAssignation={(supportId: string) => this.props.deleteVisual(supportId)}
            />
        );
    }

    renderSupportCount() {
        const { t } = this.props;
        const count = this.props.supports.length;
        return (
            <p style={{marginRight: 20, color: count === 0 ? 'rgba(0, 0, 0, 0.2)' : 'inherit'}}>{ pluralize(count, [t('support'), t('supports')]) }</p>
        );
    }

    render () {
        return (
            <>
                <div className="store-header">
                    <div className="campaing-card-left">
                        <CollapsedArrow collapsed={this.state.collapsed} clicked={() => this.swapStoreCollapse()} />
                        <p className="headline-3--regular">{this.props.name}</p>
                        <p className="text-3--regular store-id">#{this.props.id}</p>
                    </div>
                    <div className="campaing-card-right">
                        {this.renderSupportCount()}
                    </div>
                </div>
                {this.renderSupports()}
            </>
        );
    };
}

const SupportListOrder = withTranslation()(SupportListOrderComponent);
export { SupportListOrder };
