import {
    UPDATE_TOKEN,
    AuthActions,
    AppThunk
} from './AuthTypes';

export const updateToken = (value: string): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_TOKEN,
            payload: value
        } as AuthActions);
    }
}