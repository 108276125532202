import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { User } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_ASSIGNATION_TYPE = 'UPDATE_ASSIGNATION_TYPE';
export const UPDATE_SHOW_ADD_USER_MODAL = 'UPDATE_SHOW_ADD_USER_MODAL';
export const UPDATE_USERS = 'UPDATE_USERS';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateAssignationTypeAction {
    type: typeof UPDATE_ASSIGNATION_TYPE,
    payload: string[]
}
export interface UpdateShowAddUserModalAction {
    type: typeof UPDATE_SHOW_ADD_USER_MODAL,
    payload: boolean
}
export interface UpdateUsers {
    type: typeof UPDATE_USERS,
    payload: User[]
}

export type SetingsActions =
UpdateLoadingAction |
UpdateAssignationTypeAction |
UpdateShowAddUserModalAction |
UpdateUsers ;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
