import React from 'react';
import './App.css';
import './css/main.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";
import { Nav } from './components';
import ClientDetail from './screens/client/detail/Detail';
import CampaignIndex from './screens/campaign/index/Index';
import CampaignDetail from './screens/campaign/detail/Detail';
import CampaignAddSupport from './screens/campaign/addSupport/AddSupport';
import CampaignMassiveEdition from './screens/campaign/massiveEdition/MassiveEdition';
import CampaignSummary from './screens/campaign/summary/Summary';
import CampaignBudget from './screens/campaign/budget/Budget';
import CampaignLaunched from './screens/campaign/launched/Launched';
import OrderIndex from './screens/order/index/Index';
import OrderNewOrder from './screens/order/newOrder/NewOrder';
import OrderAssignVisual from './screens/order/assignVisual/AssignVisual';
import OrderBasket from './screens/order/basket/Basket';
import OrderDetail from './screens/order/detail/Detail';
import StoreIndex from './screens/store/index/Index';
import StoreDetail from './screens/store/detail/Detail';
import SupportIndex from './screens/support/index/Index';
import SupportDetail from './screens/support/detail/Detail';
import UserDetail from './screens/user/detail/Detail';
import UserAddStore from './screens/user/addStore/AddStore';
import Settings from './screens/settings/settings/Settings';
import Login from './screens/security/login/Login';
import PasswordRequest from './screens/security/password-request/PasswordRequest';
import PasswordReset from './screens/security/password-reset/PasswordReset';
import ProfileDetail from './screens/profile/detail/Detail';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { RootState } from './reducers';
import { connect, ConnectedProps } from 'react-redux';
import { updateToken } from './reducers/AuthActions';
import cookies from './util/cookies';

const mapStateToProps = (state: RootState) => {
    return {
        auth_state: state.auth
    }
}

const connector = connect(mapStateToProps, { updateToken })

class App extends React.Component<ConnectedProps<typeof connector>> {

    script: HTMLScriptElement;

    constructor(props: ConnectedProps<typeof connector>) {
        super(props);

        this.script = document.createElement("script");
        this.script.setAttribute('data-jsd-embedded', '');
        this.script.setAttribute('data-key', '1f46c05e-8f68-413a-82e5-e63d167b2b6c');
        this.script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
        this.script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    }

    componentDidMount() {
        const token = cookies.get('b2b-kendu-token');
        this.props.updateToken(token);
        cookies.addChangeListener((options) => {
            if (options.name === 'b2b-kendu-token') {
                this.props.updateToken(options.value as string);
            }
        });
    }

    clearScript() {
        if (document.body.contains(this.script)) {
            document.body.removeChild(this.script);

            let iframe = document.getElementById('jsd-widget');

            while (iframe !== null) {
                document.body.removeChild(iframe);
                iframe = document.getElementById('jsd-widget');
            }
        }
    }

    appendScript() {
        document.body.appendChild(this.script);

        setTimeout(() => {
            var DOMContentLoaded_event = document.createEvent("Event")
            DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true)
            window.document.dispatchEvent(DOMContentLoaded_event)
        }, 500);
    }

    render() {
        const { token } = this.props.auth_state;

        this.clearScript();

        return (
            <Router>
                {token !== null && !token &&
                    <Switch>
                        <Route path="/login" component={Login}/>
                        <Route path="/password-request" component={PasswordRequest}/>
                        <Route path="/password-reset" component={PasswordReset}/>
                        <Route path="/" component={Login}/>
                    </Switch>
                }
                {token &&
                    <>
                        <Nav/>
                        <Switch>
                            <Route path="/campaigns" exact component={CampaignIndex}/>
                            <Route path="/campaigns/:id" exact component={CampaignDetail}/>
                            <Route path="/campaigns/:id/add_support" component={CampaignAddSupport}/>
                            <Route path="/campaigns/:id/massive_edition" component={CampaignMassiveEdition}/>
                            <Route path="/campaigns/:id/summary" component={ CampaignSummary }/>
                            <Route path="/campaigns/:id/budget" component={CampaignBudget}/>
                            <Route path="/campaigns/:id/launched" component={CampaignLaunched}/>
                            <Route path="/orders" exact component={OrderIndex}/>
                            <Route path="/orders/new_order/:id" exact component={OrderNewOrder}/>
                            <Route path="/orders/assign_visual/:orderId/:supportId" exact component={OrderAssignVisual}/>
                            <Route path="/orders/basket/:orderId" exact component={OrderBasket}/>
                            <Route path="/orders/detail/:id" exact component={OrderDetail}/>
                            <Route path="/stores" exact component={StoreIndex}/>
                            <Route path="/stores/:customer_id/:id" component={StoreDetail}/>
                            <Route path="/supports" exact component={SupportIndex}/>
                            <Route path="/supports/:id" component={SupportDetail}/>
                            <Route path="/settings" component={Settings}/>
                            <Route path="/users/detail/:id" component={UserDetail}/>
                            <Route path="/users/:id/add_store" component={UserAddStore}/>
                            <Route path="/profile" component={ProfileDetail}/>
                            <Route path="/" component={ClientDetail}/>
                        </Switch>
                        { this.appendScript() }
                    </>
                }
            </Router>
        );
    }
}

export default connector(App);
