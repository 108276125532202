import { Action, combineReducers } from 'redux';
import ClientDetailReducer from '../screens/client/detail/ClientDetailReducer';
import CampaignIndexReducer from '../screens/campaign/index/CampaignIndexReducer';
import CampaignDetailReducer from '../screens/campaign/detail/CampaignDetailReducer';
import CampaignAddSupportReducer from '../screens/campaign/addSupport/CampaignAddSupportReducer';
import CampaignMassiveEditionReducer from '../screens/campaign/massiveEdition/CampaignMassiveEditionReducer';
import CampaignSummaryReducer from '../screens/campaign/summary/CampaignSummaryReducer';
import CampaignBudgetReducer from '../screens/campaign/budget/CampaignBudgetReducer';
import CampaignLaunchedReducer from '../screens/campaign/launched/CampaignLaunchedReducer';
import OrderIndexReducer from '../screens/order/index/OrderIndexReducer';
import OrderNewOrderReducer from '../screens/order/newOrder/OrderNewOrderReducer';
import OrderAssignVisualReducer from '../screens/order/assignVisual/OrderAssignVisualReducer';
import OrderBasketReducer from '../screens/order/basket/OrderBasketReducer';
import OrderDetailReducer from '../screens/order/detail/OrderDetailReducer';
import StoreIndexReducer from '../screens/store/index/StoreIndexReducer';
import StoreDetailReducer from '../screens/store/detail/StoreDetailReducer';
import SupportDetailReducer from '../screens/support/detail/SupportDetailReducer';
import SupportIndexReducer from '../screens/support/index/SupportIndexReducer';
import Settings from '../screens/settings/settings/SettingsReducer';
import SecurityLogin from '../screens/security/login/LoginReducer';
import SecurityPasswordRequest from '../screens/security/password-request/PasswordRequestReducer';
import SecurityPasswordReset from '../screens/security/password-reset/PasswordResetReducer';
import AuthReducer from '../reducers/AuthReducer';
import DatatypesReducer from '../reducers/datatypes/DatatypesReducer';
import ProfileDetail from '../screens/profile/detail/ProfileDetailReducer';
import UserDetail from '../screens/user/detail/UserDetailReducer';
import UserAddStoreReducer from '../screens/user/addStore/UserAddStoreReducer';
import { ThunkAction } from 'redux-thunk';

const rootReducer = combineReducers({
    clientDetail: ClientDetailReducer,
    campaignIndex: CampaignIndexReducer,
    campaignDetail: CampaignDetailReducer,
    campaignAddSupport: CampaignAddSupportReducer,
    campaignMassiveEdition: CampaignMassiveEditionReducer,
    campaignSummary: CampaignSummaryReducer,
    campaignBudget: CampaignBudgetReducer,
    campaignLaunched: CampaignLaunchedReducer,
    orderIndex: OrderIndexReducer,
    orderNewOrder: OrderNewOrderReducer,
    orderAssignVisual: OrderAssignVisualReducer,
    orderBasket: OrderBasketReducer,
    orderDetail: OrderDetailReducer,
    storeIndex: StoreIndexReducer,
    storeDetail: StoreDetailReducer,
    supportIndex: SupportIndexReducer,
    supportDetail: SupportDetailReducer,
    settings: Settings,
    securityLogin: SecurityLogin,
    securityPasswordRequest: SecurityPasswordRequest,
    securityPasswordReset: SecurityPasswordReset,
    auth: AuthReducer,
    profileDetail: ProfileDetail,
    datatypes: DatatypesReducer,
    userDetail: UserDetail,
    userAddStore: UserAddStoreReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export default rootReducer;