import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Store } from '../../../interfaces/interfaces';
import {
	updateFilterVisibility,
	updateAddModalVisibility,
	getStores,
	updateFilter,
	clearFilter,
	getClients,
} from './StoreIndexActions';
import { getCountries, getStoreTypes, getSupportTypes } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, StoreCard, StoreFilter, StoreAddModal } from '../../../components';
import { create_store } from '../../../api';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { get_role, is_central } from '../../../util/jwt';
import { country_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';
import { StoreStatus } from '../../../interfaces/interfaces';

const mapStateToProps = (state: RootState) => {
	return {
		store_index_state: state.storeIndex,
		auth_state: state.auth,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateFilterVisibility,
	updateAddModalVisibility,
	getStores,
	create_store,
	updateFilter,
	getStoreTypes,
	getSupportTypes,
	clearFilter,
	getClients,
	getCountries
});

type Props = ConnectedProps<typeof connector> & WithTranslation;

class Index extends React.Component<Props> {

	componentDidMount() {
		this.props.getStores();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getClients();
		this.props.getCountries();
	}

	renderFilter() {
		const { t } = this.props
		if (this.props.store_index_state.showFilter) {
			return (
				<StoreFilter
					filter={this.props.store_index_state.filter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					statuses={[ { "name": t("store_status_open"), "value": StoreStatus.OPEN }, {"name": t("store_status_closed"), "value": StoreStatus.CLOSED }, {"name": "NO STATUS", "value": StoreStatus.NO_STATUS } ]}
					countries={this.props.datatypes.countries.map(country_map)}
					onClose={() => this.props.updateFilterVisibility(!this.props.store_index_state.showFilter)}
					onClear={() => {
						this.props.clearFilter();
						this.props.updateFilterVisibility(!this.props.store_index_state.showFilter);
						this.props.getStores();
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.updateFilterVisibility(!this.props.store_index_state.showFilter);
						this.props.getStores();
					}}
				/>
			);
		}
	}

	createStore(store: Store) {
		create_store(store).then(() => {
			this.props.updateAddModalVisibility(!this.props.store_index_state.addModalVisibility);
			this.props.getStores();
        }).catch(err => console.error);
	}

	renderAddModal() {
		const { addModalVisibility, clients } = this.props.store_index_state;
		const { countries, store_types } = this.props.datatypes;

		if (addModalVisibility) {
			return (
				<StoreAddModal
					storeTypes={store_types}
					is_central={is_central(this.props.auth_state.token!)}
					clients={clients}
					countries={countries}
					onClose={() => this.props.updateAddModalVisibility(!addModalVisibility)}
					onSubmit={(store) => this.createStore(store)}
				/>
			);
		}
	}

	loadMoreStores() {
		this.props.getStores(this.props.store_index_state.stores.slice(-1).pop()?.key);
	}

	renderLoadMore() {
		const { loading } = this.props.store_index_state;
		const { t } = this.props;

		if (loading) {
			return (
				<Spinner message={ t('loading_stores') } />
			);
		}

		return <button className="btn--secondary btn--l btn--load-more" onClick={() => this.loadMoreStores()}>{ t('load_more_stores') }</button>
	}

	renderStoreCards() {
		const { stores } = this.props.store_index_state;

		if (stores && stores.length > 0) {
			return (
				stores.map((store) => (
					<StoreCard key={store.id} store={store}/>
				))
			);
		}
	}

	render () {
		const { t } = this.props;
		return (
			<>
				<div className="base-content">
					{this.renderFilter()}
					{this.renderAddModal()}
					<div className="content">
						<div className="container">

							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('stores') }]}/>

							<div className="section-header">
								<div className="section-header-left">
									<h1 className="headline-1--regular section-header-title">{ t('stores') }</h1>
								</div>
								<div className="section-header-right">
									<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.store_index_state.filter)} onClick={() => this.props.updateFilterVisibility(!this.props.store_index_state.showFilter)}></button>
									{!['user'].includes(get_role(this.props.auth_state.token || 'user')) &&
										<button className="btn--primary btn--l pop-reveal" onClick={() => this.props.updateAddModalVisibility(!this.props.store_index_state.addModalVisibility)}><i className="icon icon-16-add"></i>{ t('new_store') }</button>
									}
								</div>
							</div>
							{this.renderStoreCards()}
							{this.renderLoadMore()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Index));
