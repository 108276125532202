import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';

export interface BreadcrumbProps {
    items: {
        text: string,
        url?: string,
    }[],
}

type BreadcrumbPropsSum = BreadcrumbProps & WithTranslation;

class BreadcrumbComponent extends React.Component<BreadcrumbPropsSum> {

    arrow(index: number) {
        if (this.props.items.length - 1 !== index) {
            return <li className="breadcrumbs-arrow"><i className="icon icon-16-drop-right"></i></li>;
        }
        return '';
    }

    breadUrl(url?: string) {
        if (url !== undefined && url !== '') {
            return url;
        }
        return '#';
    }

    render () {
        return (
            <ul className="breadcrumbs">
                {this.props.items.map((bread, index) => (
                    <React.Fragment key={index}>
                        <li className={'text-3--regular breadcrumbs-link ' + (this.props.items.length - 1 === index ? 'breadcrumbs-link-active' : '')}>
                            <Link to={this.breadUrl(bread.url)}>{bread.text}</Link>
                        </li>
                        {this.arrow(index)}
                    </React.Fragment>
                ))}
            </ul>
        );
    };
}

const Breadcrumb = withTranslation()(BreadcrumbComponent);
export { Breadcrumb };
