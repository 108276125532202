import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { User, Store, FilterStore } from '../../../interfaces/interfaces';

export const UPDATE_STORES = 'update_stores';
export const APPEND_STORES = 'append_stores';
export const UPDATE_USER = 'update_user';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SELECTED_STORES = 'update_selected_stores';
export const APPEND_SELECTED_STORES = 'append_selected_stores';
export const REMOVE_SELECTED_STORES = 'remove_selected_stores';
export const UPDATE_FILTER_VISIBILITY = 'update_filter_visibility_store';
export const UPDATE_FILTER = 'update_filter_store';
export const CLEAR_FILTER = 'clear_filter_store';


export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface AppendStoresAction {
    type: typeof APPEND_STORES,
    payload: Store[]
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: User
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateSelectedStoreAction {
    type: typeof UPDATE_SELECTED_STORES,
    payload: string[]
}

export interface AppendSelectedStoreAction {
    type: typeof APPEND_SELECTED_STORES,
    payload: string[]
}

export interface RemoveSelectedStoreAction {
    type: typeof REMOVE_SELECTED_STORES,
    payload: string[]
}

export interface UpdateFilterVisibilityAction {
    type: typeof UPDATE_FILTER_VISIBILITY,
    payload: boolean
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterStore
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type UserAddStoreActions =
UpdateStoresAction |
AppendStoresAction |
UpdateUserAction |
UpdateLoadingAction |
UpdateSelectedStoreAction |
AppendSelectedStoreAction |
RemoveSelectedStoreAction |
UpdateFilterVisibilityAction |
UpdateFilterAction | 
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
