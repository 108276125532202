import React from 'react';
import { Link, NavLink } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';
import { RootState } from '../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { get_role } from '../util/jwt';
import cookies from '../util/cookies';

const mapStateToProps = (state: RootState) => {
	return {
		auth_state: state.auth,
	}
}

const connector = connect(mapStateToProps, {
});

export interface NavProps {
}

interface NavState {
    open: boolean
}

type NavPropsSum = NavProps & WithTranslation & ConnectedProps<typeof connector>;

class NavComponent extends React.Component<NavPropsSum, NavState> {

    constructor(props: NavPropsSum) {
        super(props);

        this.state = {
            open: false
        };
    }

    buttonMenuCollapse() {
        this.setState({
            open: !this.state.open
        });
    }

    navSliderClass() {
        return this.state.open ? 'nav-slider nav-slider--big' : 'nav-slider';
    }

    showLabelClass() {
        return this.state.open ? 'button-label show-label' : 'button-label';
    }

    render () {
        const { t } = this.props;

        let profileUrl = '/profile';
        if (['backdoor'].includes(get_role(this.props.auth_state.token!))) {
            profileUrl = '#';
        }
        return (
            <div className={this.navSliderClass()}>

                <div className="nav-slider-top">
                    <div className="nav-slider-header">
                        <Link to="/"><div className="main-brand"></div></Link>
                        <div className="open-slider-button" onClick={() => this.buttonMenuCollapse()}>
                            <div className="icon icon-16-menu"></div>
                        </div>
                    </div>
                    <div className="button-group">
                        <NavLink to="/" exact activeClassName="nav-button-element--active" className="nav-button-element">
                            <div className="icon icon-24-users"></div>
                            <p className={this.showLabelClass()}>{t('dashboard')}</p>
                        </NavLink>
                        {['admin', 'backdoor'].includes(get_role(this.props.auth_state.token!)) &&
                            <NavLink to="/campaigns" activeClassName="nav-button-element--active" className="nav-button-element">
                                <div className="icon icon-24-season"></div>
                                <p className={this.showLabelClass()}>{t('campaigns')}</p>
                            </NavLink>
                        }
                        <NavLink to="/orders" activeClassName="nav-button-element--active" className="nav-button-element">
                            <div className="icon icon-24-orders"></div>
                            <p className={this.showLabelClass()}>{t('orders')}</p>
                        </NavLink>
                        <NavLink to="/stores" activeClassName="nav-button-element--active" className="nav-button-element">
                            <div className="icon icon-24-shop"></div>
                            <p className={this.showLabelClass()}>{t('store')}</p>
                        </NavLink>
                        <NavLink to="/supports" activeClassName="nav-button-element--active" className="nav-button-element">
                            <div className="icon icon-24-hardware"></div>
                            <p className={this.showLabelClass()}>{t('support')}</p>
                        </NavLink>
                    </div>
                </div>
                <div className="nav-slider-bottom">
                    <NavLink to="/settings" activeClassName="nav-button-element--active" className="nav-button-element">
                        <div className="icon icon-24-settings"></div>
                        <p className={this.showLabelClass()}>{t('settings')}</p>
                    </NavLink>
                    <Link to={profileUrl}>
                        <div className="profile-element-content">
                            <div className="profile-element">
                                <p className="profile-element-letter">{(cookies.get('b2b-kendu-user-name')||'').charAt(0).toUpperCase()||'B'}</p>
                            </div>
                            <p className={'profile-element-label ' + this.showLabelClass()}>{cookies.get('b2b-kendu-user-name')}</p>
                        </div>
                    </Link>
                </div>

            </div>
        );
    };
}

const Nav = withTranslation()(connector(NavComponent));
export { Nav };
