import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const UPDATE_LOGIN_ERROR = 'UPDATE_LOGIN_ERROR';

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: string
}

export interface UpdateUserErrorAction {
    type: typeof UPDATE_USER_ERROR,
    payload: string
}

export interface UpdatePasswordAction {
    type: typeof UPDATE_PASSWORD,
    payload: string
}

export interface UpdatePasswordErrorAction {
    type: typeof UPDATE_PASSWORD_ERROR,
    payload: string
}

export interface UpdateLoginErrorAction {
    type: typeof UPDATE_LOGIN_ERROR,
    payload: string
}

export type SetingsActions =
UpdateUserAction |
UpdateUserErrorAction |
UpdatePasswordAction |
UpdatePasswordErrorAction |
UpdateLoginErrorAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
