import React from 'react';
import { InputText } from './';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface AddModalProps {
    onClose: () => void,
    onSubmit: (name: string) => void
}

interface AddModalState {
    name: string,
}

type AddModalPropsSum = AddModalProps & WithTranslation;

class SectionAddModalComponent extends React.Component<AddModalPropsSum, AddModalState> {

    constructor(props: AddModalPropsSum) {
        super(props);
        this.state = {
            name: ''
        };
    }

    render () {
        const { t } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{ t('create_section') }</p>
                        </div>
                        <div className="pop-box-content">
                            <div className="form-row-content">
                                <div className="form-row">
                                    <InputText id="section-name" label={ t('section_name') } value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                </div>
                            </div>
                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.props.onSubmit(this.state.name)}>{ t('create') }</button>
                                <button className="btn--secondary btn--l close-pop" onClick={()=> this.props.onClose()}>{ t('cancel') }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const SectionAddModal = withTranslation()(SectionAddModalComponent);
export { SectionAddModal };