import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_USER,
    UPDATE_LOADING,
    APPEND_SELECTED_STORES,
    REMOVE_SELECTED_STORES,
    UserAddStoreActions,
    AppThunk,
    UPDATE_SELECTED_STORES,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_FILTER,
    CLEAR_FILTER,
} from './ActionTypes';
import { get_stores_with_supports, get_user } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { FilterStore } from '../../../interfaces/interfaces';

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as UserAddStoreActions);

        const stores = await get_stores_with_supports({
            ...getState().userAddStore.filter,
            key: lastKey,
            page_size: 0
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as UserAddStoreActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as UserAddStoreActions);
    }
}

export const getUser = (id: string): AppThunk => {
    return async dispatch => {

        const user = await get_user(id);

        dispatch({
            type: UPDATE_USER,
            payload: user
        } as UserAddStoreActions);

        dispatch({
            type: UPDATE_SELECTED_STORES,
            payload: user.stores.map((selectedStore: { id: any; }) => selectedStore.id)
        } as UserAddStoreActions);
    }
}

export const addSelectedStores = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: APPEND_SELECTED_STORES,
            payload: ids
        } as UserAddStoreActions);
    }
}

export const removeSelectedStores = (ids: string[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: REMOVE_SELECTED_STORES,
            payload: ids
        } as UserAddStoreActions);
    }
}

export const updateFilterVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER_VISIBILITY,
            payload: value
        } as UserAddStoreActions);
    }
}

export const updateFilter = (value: FilterStore): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as UserAddStoreActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as UserAddStoreActions);
    }
}