import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SHOW_FILTER,
    UPDATE_VISUAL,
    UPDATE_VISUALS_LIST,
    UPDATE_SAVEING,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_SAVE_OK,
    UPDATE_SAVE_ERROR,
    UPDATE_ASSIGNATION_TYPE,
    CampaignMassiveEditionActions,
    AppThunk,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_PREPARED,
    UPDATE_ASSIGNED
} from './ActionTypes';
import {
    one_campaign,
    edit_visuals,
    get_visuals,
    one_client,
    get_assigned_images
} from '../../../api';
import { FilterVisual, Visual } from '../../../interfaces/interfaces';
import { FilterVisualRequest } from '../../../api/interfaces';
import { client_map } from '../../client/client.maps';
import { preparedSelect, assignedSelect } from '../../../util/visual';

export const updateShowFilter = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_FILTER,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const updateSaveOk = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SAVE_OK,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const updateSaveError = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SAVE_ERROR,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const updateVisualsList = (value: { visual: Visual, hasChanges: boolean }[]): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_VISUALS_LIST,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const updateVisual = (value: Visual): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_VISUAL,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const loadData = (id: string, t: any): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignMassiveEditionActions);

        const campaign = await one_campaign(id);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: campaign
        } as CampaignMassiveEditionActions);

        let prepared = preparedSelect(t);
        dispatch({
            type: UPDATE_PREPARED,
            payload: prepared
        } as CampaignMassiveEditionActions);

        let assigned = assignedSelect(t);
        dispatch({
            type: UPDATE_ASSIGNED,
            payload: assigned
        } as CampaignMassiveEditionActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignMassiveEditionActions);
    }
}

export const getVisuals = (id: string): AppThunk => {
    return async (dispatch, getState) => {

        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as CampaignMassiveEditionActions);


        let visuals = await get_visuals(
            id,{
            ...getState().campaignDetail.visualFilter,
            page_size: 0
        } as FilterVisualRequest);

        let visualsList:{ visual: Visual, hasChanges: boolean }[] = [];
        visualsList = (visuals||[]).map(function(visual: Visual) {
            return { visual: visual, hasChanges: false };
		});

        dispatch({
            type: UPDATE_VISUALS_LIST,
            payload: visualsList
        } as CampaignMassiveEditionActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as CampaignMassiveEditionActions);
    }
}

export const saveVisuals = (visuals: Visual[]): AppThunk => {
    return async dispatch => {

        try {
            dispatch({
                type: UPDATE_SAVEING,
                payload: true
            } as CampaignMassiveEditionActions);

            await edit_visuals(visuals);

        } catch (e) {
            dispatch({
                type: UPDATE_SAVE_ERROR,
                payload: true
            } as CampaignMassiveEditionActions);
        } finally {
            dispatch({
                type: UPDATE_SAVE_OK,
                payload: true
            } as CampaignMassiveEditionActions);

            dispatch({
                type: UPDATE_SAVEING,
                payload: false
            } as CampaignMassiveEditionActions);
        }

    }
}

export const updateFilter = (value: FilterVisual): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as CampaignMassiveEditionActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as CampaignMassiveEditionActions);
    }
}

export const getAssignationType = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        let mappedClient = client_map(client);
        dispatch({
            type: UPDATE_ASSIGNATION_TYPE,
            payload: mappedClient.assignation_type
        } as CampaignMassiveEditionActions);

    }
}

export const getAssignedImages = (id: string): AppThunk => {
    return async dispatch => {
        const assignedImages = await get_assigned_images(id);

        dispatch({
            type: UPDATE_ASSIGNED_VISUALS,
            payload: assignedImages
        } as CampaignMassiveEditionActions);
    }
}