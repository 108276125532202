import {
    UPDATE_LOADING,
    UPDATE_ORDER,
    UPDATE_VAT,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_ASSIGNED_IMAGES,
    UPDATE_AVAILABLE_VISUALS,
    UPDATE_STORES,
    APPEND_STORES,
    OrderDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { AssignedVisuals, FilterStoreAndSupport, Order, Store, Visual } from "../../../interfaces/interfaces";

export interface OrderDetailState {
    loading: boolean,
    order: Order|null,
    vat: number,
    filter: FilterStoreAndSupport,
    assignedImages: AssignedVisuals,
    availableVisuals: Visual[],
    stores: Store[],
}

const INITIAL_STATE: OrderDetailState = {
    loading: false,
    order: null,
    vat: 0,
    filter: new FilterStoreAndSupport(),
    assignedImages: {},
    availableVisuals: [],
    stores: []
};

const reducer: Reducer<OrderDetailState, OrderDetailActions> = (state = INITIAL_STATE, action: OrderDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_ORDER:
            return { ...state, order: action.payload };
        case UPDATE_VAT:
            return { ...state, vat: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterStoreAndSupport() };
        case UPDATE_ASSIGNED_IMAGES:
            return { ...state, assignedImages: action.payload };
        case UPDATE_AVAILABLE_VISUALS:
            return { ...state, availableVisuals: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case APPEND_STORES:
            return { ...state, stores: [ ...state.stores, ...action.payload ] };
        default:
            return state;
    }
}

export default reducer;