import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AssignedVisuals, Store } from '../interfaces/interfaces';
import { calculateStoreTotal, renderPrice, calculateStoreShippingPrice } from '../util/price';

export interface BudgetSummaryProps {
    stores: Store[],
    assignedImages: AssignedVisuals,
    vat: number,
}

interface State {
}

type BudgetSummaryPropsSum = BudgetSummaryProps & WithTranslation;

class BudgetSummaryComponent extends React.Component<BudgetSummaryPropsSum, State> {

    constructor(props: BudgetSummaryPropsSum) {
        super(props);
        this.state = {};
    }

    getFilteredSupports(store: Store) {
		const { assignedImages } = this.props;
		return (store.supports || []).filter(support => assignedImages && assignedImages[support.id!]);
	}

    calculateOrderTotal(stores: Store[]): number {
		const { assignedImages } = this.props;
		return (stores||[]).reduce((sum, store) => sum + calculateStoreTotal(this.getFilteredSupports(store), assignedImages), 0);
	}

    calculateStoresShippingPrice(stores: Store[]): number{
        const { assignedImages } = this.props;
		return (stores||[]).reduce((sum, store) => sum + calculateStoreShippingPrice(store,assignedImages), 0);
    }

    calculateOrderTotalVAT(stores: Store[]): number {
        const { assignedImages } = this.props;
		return (stores||[]).reduce((sum, store) => sum + (calculateStoreTotal(this.getFilteredSupports(store), assignedImages) * (this.getVAT(store))), 0);
    }

    getVAT(store: Store){
        const { vat } = this.props;
        return (store.pais === "ES") ? vat : 0
    }

    render () {
        const { t, stores, vat } = this.props;
        return (
            <div className="basic-card basic-card--32 store-card" style={{ width: '25%', float: 'right', cursor: 'default' }}>
                <div className="store-card--view">
                    <table id="user_list">
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'left'}}>{ t('net') }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores)) }</td>
                            </tr>
                            {stores.find( store => store.pais === "ES" ) && 
                                <tr>
                                    <td style={{textAlign: 'left'}}>{ t('vat') } { (vat * 100).toFixed(0) }%</td>
                                    <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotalVAT(stores)) }</td>
                                </tr>
                            }
                            <tr>
                                <td style={{textAlign: 'left'}}>{ t('shipping_price') }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateStoresShippingPrice(stores)) }</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'left'}}><b>{ t('total') }</b></td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores) + this.calculateOrderTotalVAT(stores) + this.calculateStoresShippingPrice(stores)) }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
}

const BudgetSummary = withTranslation()(BudgetSummaryComponent);
export { BudgetSummary };
