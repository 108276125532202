import {
    UPDATE_LOADING,
    UPDATE_STORES,
    UPDATE_ASSIGNED_IMAGES,
    UPDATE_AVAILABLE_VISUALS,
    UPDATE_VAT,
    OrderBasketActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { AssignedVisuals, Store, Visual } from "../../../interfaces/interfaces";

export interface OrderBasketState {
    loading: boolean,
    stores: Store[],
    assignedImages: AssignedVisuals,
    availableVisuals: Visual[],
    vat: number,
}

const INITIAL_STATE: OrderBasketState = {
    loading: false,
    stores: [],
    assignedImages: {},
    availableVisuals: [],
    vat: 0,
};

const reducer: Reducer<OrderBasketState, OrderBasketActions> = (state = INITIAL_STATE, action: OrderBasketActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_ASSIGNED_IMAGES:
            return { ...state, assignedImages: action.payload };
        case UPDATE_AVAILABLE_VISUALS:
            return { ...state, availableVisuals: action.payload };
        case UPDATE_VAT:
            return { ...state, vat: action.payload };
        default:
            return state;
    }
}

export default reducer;