import {
    UPDATE_LOADING,
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_ASSIGNED_IMAGES,
    UPDATE_AVAILABLE_VISUALS,
    OrderNewOrderActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { AssignedVisuals, FilterStore, Store, Visual } from "../../../interfaces/interfaces";

export interface OrderNewOrderState {
    loading: boolean,
    stores: Store[],
    filter: FilterStore,
    assignedImages: AssignedVisuals,
    availableVisuals: Visual[],
}

const INITIAL_STATE: OrderNewOrderState = {
    stores: [],
    loading: false,
    filter: new FilterStore(),
    assignedImages: {},
    availableVisuals: []
};

const reducer: Reducer<OrderNewOrderState, OrderNewOrderActions> = (state = INITIAL_STATE, action: OrderNewOrderActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case APPEND_STORES:
            return { ...state, stores: [ ...state.stores, ...action.payload ] };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterStore() }
        case UPDATE_ASSIGNED_IMAGES:
            return { ...state, assignedImages: action.payload };
        case UPDATE_AVAILABLE_VISUALS:
            return { ...state, availableVisuals: action.payload };
        default:
            return state;
    }
}

export default reducer;