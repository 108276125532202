import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { InputText } from '../../../components';
import { updateUser, updateUserError, updatePassword, updatePasswordError, updateLoginError } from './LoginActions';
import { updateToken } from '../../../reducers/AuthActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { login, get_profile } from '../../../api';
import { Helmet } from 'react-helmet';
import cookies from '../../../util/cookies';
import i18n from 'i18next';
import Alert from '@material-ui/lab/Alert';
import { is_empty_string, is_email } from "../../../util/form";

const mapStateToProps = (state: RootState) => {
	return {
		security_login_state: state.securityLogin
	}
}

const connector = connect(mapStateToProps, {
	updateUser,
	updateUserError,
	updatePassword,
	updatePasswordError,
	login,
	updateToken,
	get_profile,
	updateLoginError
});

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	login() {
		const { user, password } = this.props.security_login_state;
		const { t } = this.props;
		if (this.validate()) {
			login(user, password).then((token) => {
				this.props.updateLoginError('');
				if (token !== null) {
					this.props.updateLoginError('');
					cookies.set('b2b-kendu-token', token, { path: '/' });
					this.props.updateToken(token);
					get_profile().then((user) => {
						if (user && user.language) {
							cookies.set('b2b-kendu-user-name', user.name + ' ' + user.last_name, { path: '/' });
							const language = user.language.toLowerCase();
							cookies.set('b2b-kendu-language', language, { path: '/' });
							i18n.changeLanguage(language.toLowerCase(), (err, t) => {
								if (err) return console.log('something went wrong loading', err);
							});
						}
					});
				} else {
					this.props.updateLoginError(t('username_or_password_incorrect'));
				}
			});
		}
	}

	validate() {
		const { t } = this.props;
		const { user, password } = this.props.security_login_state;
		let isValid = true;

		this.props.updateUserError('');
		this.props.updatePasswordError('');

		if (!is_email(user)) {
			isValid = false;
			this.props.updateUserError(t('user_should_have_email_format'));
		}
		if (is_empty_string(user)) {
			isValid = false;
			this.props.updateUserError(t('user_is_required'));
		}
		if (is_empty_string(password)) {
			isValid = false;
			this.props.updatePasswordError(t('password_is_required'));
		}

		return isValid;
	}

	renderLoginError() {
		if (this.props.security_login_state.loginError) {
			return (
				<article className="form-row">
					<Alert severity="error">{this.props.security_login_state.loginError}</Alert>
				</article>
			);
		}
	}

	render () {
		const { t } = this.props;
		return (
			<>
				<Helmet>
					<body id="login" />
				</Helmet>
				<section id="login-box">

					<h1><img src="img/main-brand.svg" alt="" /></h1>

					<h2>{ t('login') }</h2>

					{this.renderLoginError()}

					<article className="form-row">
						<InputText
							id="user"
							label={ t('user') }
							value={this.props.security_login_state.user}
							onChange={(event) => this.props.updateUser(event.target.value)}
							helpText={this.props.security_login_state.userError}
						/>
					</article>

					<article className="form-row--large">
						<InputText
							id="password"
							type="password"
							label={ t('password') }
							value={this.props.security_login_state.password}
							onChange={(event) => this.props.updatePassword(event.target.value)}
							helpText={this.props.security_login_state.passwordError}
						/>
					</article>

					<article className="form-row">
						<button className="btn--primary" onClick={() => this.login()}>{ t('login') }</button>
					</article>

					{/* <article className="reset_password">
						<p><Link to="/password-request">{ t('did_you_forget_password') }</Link></p>
					</article> */}

				</section>
			</>
		);
	}
}

export default withTranslation()(connector(Settings));
