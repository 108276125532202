import React from 'react';
import { Support, AssignedVisuals, Visual } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SupportDetail, VisualPreview } from '.';
import { VisualImage } from './VisualImage';
import { is_section_assigned, is_priority_assigned, is_production_language_assigned } from '../util/visual';
import { DatatypesState } from '../reducers/datatypes/DatatypesReducer';
import { Link } from 'react-router-dom';

export interface SupportCardAssignVisualProps {
    support: Support,
    assignedImages?: AssignedVisuals,
    datatypes: DatatypesState,
    localOrderId?: string,
    onModalClicked?: (supportId: string) => void,
    onDeleteAssignation?: (supportId: string) => void
}

interface SupportCardAssignVisualtate {
    showPreview: Visual | null,
}

type SupportCardAssignVisualPropsSum = SupportCardAssignVisualProps & WithTranslation;

class SupportCardAssignVisualComponent extends React.Component<SupportCardAssignVisualPropsSum, SupportCardAssignVisualtate> {

    constructor(props: SupportCardAssignVisualPropsSum) {
        super(props);

        this.state = {
            showPreview: null,
        };
    }

    renderAssignedImage() {
        const { support, assignedImages } = this.props;

        if(assignedImages && assignedImages[support.id!]){
            return (
                <>
                    <div className="card-img card-img--visual" onClick={ (event) => event.preventDefault() }>
                        <img src={support.visual_url || '/img/img-hardware.png'} alt="Placeholder" onClick={() => support.visual_url && this.setState({ showPreview: { image: support.visual_url } as Visual })}/>
                    </div>
                    <button className="icon icon-16-drop-down section-header-icon" style={{transform: 'rotate(-90deg)', backgroundColor: '#00A3DA', borderRadius: '100%', margin: '4px', color: 'white'}}></button>
                    <div className="card-img card-img--visual" onClick={ (event) => event.preventDefault() }>
                        <VisualImage visual={assignedImages[support.id!]} onClick={() => this.setState({ showPreview: assignedImages[support.id!] })} />
                    </div>
                </>
            );

        }else{
            return (
                <>
                    <div className="card-img card-img--48" style={{backgroundImage: `url('/img/img-hardware.png')`, 'marginRight': '16px'}}></div>
                    <div className="card-img card-img--visual" onClick={ (event) => event.preventDefault() }>
                        <img src={support.visual_url || '/img/img-hardware.png'} alt="Placeholder" onClick={() => support.visual_url && this.setState({ showPreview: { image: support.visual_url } as Visual })}/>
                    </div>
                </>
            );
        }

    }

    renderVisualPreview () {
        const { showPreview } = this.state;
		if (showPreview) {
			return(
                <VisualPreview visual={showPreview} onClose={() => this.setState({ showPreview: null })} />
            );
		}
	}

    isAssigned(support_id: string) {
        return this.props.assignedImages && this.props.assignedImages[support_id];
    }

    renderAssignButton() {
        const { support, onModalClicked, onDeleteAssignation, t } = this.props;

        if (onModalClicked) {
            return (
                <>
                    {this.isAssigned(support.id!) && onDeleteAssignation ?
                        <div className="card-options"><button className="icon icon-16-unassign section-header-icon" onClick={() => onDeleteAssignation(support.id!)}></button></div> : ''}
                    <Link to={`/orders/assign_visual/${this.props.localOrderId}/${support.id}`}>
                        <button className={ this.isAssigned(support.id!) ? `btn--secondary btn--s pop-reveal` : `btn--primary btn--s pop-reveal`}>{ this.isAssigned(support.id!) ? t('reassign') : t('add_visual') }</button>
                    </Link>
                </>
            );
        }
    }

    renderSection(support: Support) {
		const { sections } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('section') } <span className="detail">{sections && support.section && sections.filter(sec => sec.id === support.section).length > 0 ? sections.filter(sec => sec.id === support.section)[0].description : ''}</span></>) ;
	}

	renderPriority(support: Support) {
		const { priorities: prorities } = this.props.datatypes;
		const { t }  = this.props;
		return (<>{ t('priority') } <span className="detail">{prorities && support.priority && prorities.filter(sec => sec.value === support.priority).length > 0 ? prorities.filter(sec => sec.value === support.priority)[0].name : ''}</span></>);
	}

	renderProductionLanguage(support: Support) {
		const { t }  = this.props;
		const { production_languages } = this.props.datatypes;
		const production_language = production_languages.find(production_language => production_language.value === support.production_language);

		return (<>{ t('production_language') } <span className="detail">{ production_language?.name || '' }</span></>) ;
	}

    getSelectedStyle() {
        if (this.isAssigned(this.props.support.id!)) {
            return { border: '1px solid #00A3DA', background: '#E5F6FB' };
        }
        return {};
    }
    render () {
        const { support_type, width, height } = this.props.support;
        return (
            <>
                {this.renderVisualPreview()}
                <div className="card-row card-row--visual" style={this.getSelectedStyle()}>
                    <div className="card-row-left">
                        {this.renderAssignedImage()}
                        <div className="visual-info">
                            <p className="text-1--regular subcard-title">{support_type} <span className="text-3--regular" style={{marginLeft: '8px'}}>{width} x {height}</span></p>
                            <p className="visualDetail text-3--regular">
                                {is_section_assigned('Talla_Seccion_Idioma') ? this.renderSection(this.props.support) : ''}
                                {is_priority_assigned('Talla_Seccion_Idioma') ? this.renderPriority(this.props.support) : ''}
                                {is_production_language_assigned('Talla_Seccion_Idioma')? this.renderProductionLanguage(this.props.support) : ''}
                            </p>
                            <p className="visualDetail text-3--regular"><SupportDetail support={this.props.support} production_languages={this.props.datatypes.production_languages} /></p>
                        </div>
                    </div>
                    <div className="card-row-right">
                        {this.renderAssignButton()}
                    </div>
                </div>
            </>
        );
    };
}

const SupportCardAssignVisual = withTranslation()(SupportCardAssignVisualComponent);
export { SupportCardAssignVisual };
