import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AssignedVisuals, Store } from '../interfaces/interfaces';
import { calculateStoreTotal, renderPrice, calculateStoreShippingPrice } from '../util/price';

export interface BudgetSummaryTotalProps {
    stores: Store[],
    assignedImages: AssignedVisuals,
    vat: number,
}

interface State {
}

type BudgetSummaryTotalPropsSum = BudgetSummaryTotalProps & WithTranslation;

class BudgetSummaryTotalComponent extends React.Component<BudgetSummaryTotalPropsSum, State> {

    constructor(props: BudgetSummaryTotalPropsSum) {
        super(props);
        this.state = {};
    }

    getFilteredSupports(store: Store) {
		const { assignedImages } = this.props;
		return (store.supports || []).filter(support => assignedImages && assignedImages[support.id!]);
	}

    calculateOrderTotal(stores: Store[], vat: Boolean): number {
		const { assignedImages } = this.props;
        if (vat){
            return (stores||[]).reduce((sum, store) => sum + ((store.pais === "ES") ? calculateStoreTotal(this.getFilteredSupports(store), assignedImages) : 0), 0);
        }
        return (stores||[]).reduce((sum, store) => sum + ((store.pais !== "ES") ? calculateStoreTotal(this.getFilteredSupports(store), assignedImages) : 0), 0);

	}

    calculateStoresShippingPrice(stores: Store[], vat: Boolean): number{
        const { assignedImages } = this.props;
        if(vat){
            return (stores||[]).reduce((sum, store) => sum + ((store.pais === "ES") ? calculateStoreShippingPrice(store,assignedImages): 0), 0);
        }
		return (stores||[]).reduce((sum, store) => sum + ((store.pais !== "ES") ? calculateStoreShippingPrice(store,assignedImages): 0), 0);
    }

    calculateOrderTotalVAT(stores: Store[]): number {
        const { assignedImages } = this.props;
		return (stores||[]).reduce((sum, store) => sum + (calculateStoreTotal(this.getFilteredSupports(store), assignedImages) * (this.getVAT(store))), 0);
    }

    getVAT(store: Store){
        const { vat } = this.props;
        return (store.pais === "ES") ? vat : 0
    }

    render () {
        const { t, stores, vat } = this.props;
        return (
            <div className="basic-card basic-card--32 store-card" style={{ width: '50%', float: 'right', cursor: 'default' }}>
                <div className="store-hardware-content">
                    <table id="user_list">
                        <thead>
                            <tr>
                                <th></th>
                                <td style={{textAlign: 'right'}}>{ t('no_vat') }</td>
                                <td style={{textAlign: 'right'}}>{ t('vat') } { (vat * 100).toFixed(0) }%</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'left'}}>{ t('net') }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores, false)) }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores, true)) }</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'left'}}>{ t('vat') }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(0) }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotalVAT(stores)) }</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'left'}}>{ t('shipping_price') }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateStoresShippingPrice(stores, false)) }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateStoresShippingPrice(stores, true)) }</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'left'}}><b>{ t('total') }</b></td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores,false) + this.calculateStoresShippingPrice(stores, false)) }</td>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>{ renderPrice(this.calculateOrderTotal(stores, true) + this.calculateOrderTotalVAT(stores) + this.calculateStoresShippingPrice(stores, true)) }</td>
                            </tr>
                            <tr/>
                            <tr>
                                <td style={{textAlign: 'left'}}><b>{ t('total_global') }</b></td>
                                <td/>
                                <td style={{textAlign: 'right', fontSize: '18px'}}>
                                    { renderPrice(
                                        this.calculateOrderTotal(stores,false) + this.calculateStoresShippingPrice(stores, false) +
                                        this.calculateOrderTotal(stores, true) + this.calculateOrderTotalVAT(stores) + this.calculateStoresShippingPrice(stores, true)
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
}

const BudgetSummaryTotal = withTranslation()(BudgetSummaryTotalComponent);
export { BudgetSummaryTotal };
