import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { BackArrow, Breadcrumb, BudgetSummary, BudgetTable, ContinueCancelModal, ListHeader, VisualPreview } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { Campaign, Store, Visual } from '../../../interfaces/interfaces';
import { RouteComponentProps } from 'react-router';
import {
	getOneOrder,
	getStores,
	getVat,
} from './OrderBasketActions';
import { delete_order, get_local_order_to_process } from '../../../util/local_storage';
import { edit_campaign, launch_campaign } from '../../../api';
import { getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { calculateStoreTotal, renderPrice } from '../../../util/price';

const mapStateToProps = (state: RootState) => {
	return {
		order_basket_state: state.orderBasket,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getStores,
	getOneOrder,
	getVat,
	get_local_order_to_process,
	delete_order,
	getProductionLanguages,
});

interface ParamTypes {
	orderId: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	previewVisual: Visual | null,
	showProcessModal: boolean,
}

class Basket extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			previewVisual: null,
			showProcessModal: false,
        };
	}

	componentDidMount() {
		this.props.getStores();
		this.props.getVat();
		this.props.getProductionLanguages();
		this.props.getOneOrder(this.props.match.params.orderId);
	}

	getFilteredSupports(store: Store) {
		const { assignedImages } = this.props.order_basket_state;
		return (store.supports || []).filter(support => assignedImages && assignedImages[support.id!]);
	}

	calculateOrderTotal(stores: Store[]): number {
		const { assignedImages } = this.props.order_basket_state;
		return (stores||[]).reduce((sum, store) => sum + calculateStoreTotal(this.getFilteredSupports(store), assignedImages), 0);
	}

	renderStores() {
		const { stores, assignedImages } = this.props.order_basket_state;
		return (
			<>
				{stores.filter(store => (store.supports || []).some(support => assignedImages && assignedImages[support.id!])).map(store =>
					<div className="basic-card basic-card--32 store-card" key={store.id} style={{ cursor: 'default' }}>
						<div className="store-card--view">
							<ListHeader name={store.name} id={store.id} subtitle={`${store.direccion}, ${store.codigo_postal}, ${store.ciudad}, ${store.pais}`} subtitleBold={store.grupo_tienda}>
								<BudgetTable
									store={store}
									assignedImages={assignedImages}
									productionLanguages={this.props.datatypes.production_languages}
									vat={this.props.order_basket_state.vat}
								/>
							</ListHeader>
						</div>
					</div>
				)}
				<BudgetSummary
					stores={stores}
					assignedImages={assignedImages}
					vat={this.props.order_basket_state.vat}
				/>
			</>
		);
	}

	renderVisualPreview () {
		const { previewVisual } = this.state;
		if (previewVisual) {
			return(
				<VisualPreview visual={previewVisual} onClose={() => this.setState({ previewVisual: null })} />
			);
		}
	}

	renderProcessModal() {
		const { t } = this.props;
		if (this.state.showProcessModal) {
			return (
				<ContinueCancelModal
					title={t('process_order')}
					message={t('process_order_sentence')}
					onContinue={(reference: string) => {
						edit_campaign({'id': this.props.match.params.orderId, 'reference': reference} as Campaign).then(() => {
							launch_campaign(this.props.match.params.orderId).then(() => {
								this.setState({ showProcessModal: !this.state.showProcessModal });
								this.props.history.push('/orders');
							}).catch(err => console.error);
						}).catch(err => console.error);
					}}
					onCancel={() =>  this.setState({ showProcessModal: !this.state.showProcessModal })}
				/>
			);
		}
	}

	render () {
		const { t } = this.props;
		const { stores, vat } = this.props.order_basket_state;
		return (
			<div className="base-content">
				{this.renderVisualPreview()}
				{this.renderProcessModal()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('orders'), url: '/orders' }, { text: t('basket') }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<BackArrow onClick={() => this.props.history.goBack()} />
								<h1 className="headline-1--regular section-header-title">{ t('basket') }</h1>
							</div>
							<div className="section-header-right">
								<button className="btn--primary btn--l" style={{background: '#343E4E', border: '0px'}}>{ renderPrice(this.calculateOrderTotal(stores) * (1 + vat)) }</button>
								<button className="btn--primary btn--l" onClick={() => this.setState({showProcessModal: !this.state.showProcessModal})}>{ t('process_order') }</button>
							</div>
						</div>
						{this.renderStores()}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Basket));
