import React from 'react';
import moment from 'moment';
import { Campaign, Visual } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VisualPreview } from './';
import { VisualImage } from './VisualImage';

export interface VisualCardProps {
    visual: Visual,
    campaign: Campaign
}

type VisualCardPropsSum = VisualCardProps & WithTranslation;

interface VisualCardState {
    showPreview: boolean,
}

class VisualCardComponent extends React.Component<VisualCardPropsSum, VisualCardState> {

    constructor(props: VisualCardPropsSum) {
        super(props);

        this.state = {
            showPreview: false,
        };
    }

    momentDate(date: string) {
        if (date) {
            return moment(date).format('D, MMMM');
        }
    }

    renderPreview() {
        const { visual } = this.props;
        if (this.state.showPreview) {
            return (
                <VisualPreview visual={visual} onClose={() => this.setState({ showPreview: !this.state.showPreview })} />
            );
        }
    }

    render () {
        const { visual, campaign } = this.props;

        return (
            <>
                {this.renderPreview()}
                <div className="generic-row">
                    <span className="event-in-time"></span>
                    <div className="generic-row-left">
                        <div className="card-img card-img--visual">
                            <VisualImage visual={visual} onClick={() => this.setState({ showPreview: !this.state.showPreview })} />
                        </div>
                        <div className="generic-info">
                            <p className="text-1--regular generic-title">{visual.name}</p>
                            <p className="text-3--regular generic-subinfo"><span className="visual_season">{campaign.title}</span> {this.momentDate(campaign.start_date)} &#62; {this.momentDate(campaign.end_date)}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    };
}

const VisualCard = withTranslation()(VisualCardComponent);
export { VisualCard };
