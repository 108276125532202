import React from 'react';
import { Link } from "react-router-dom";
import { Store, StoreStatus } from '../interfaces/interfaces';
import { pluralize } from '../util/text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { statusPillClass, statusName} from '../util/store';

export interface StoreCardProps {
    store: Store
}

type StoreCardPropsSum = StoreCardProps & WithTranslation;

class StoreCardComponent extends React.Component<StoreCardPropsSum> {

    cardLink(store: Store) {
        if (store.id === undefined || store.customer_no === undefined) {
            return '#';
        }
        return `/stores/${store.customer_no}/${store.id}`;
    }

    render () {
        const { t } = this.props;
        const { customer_shop_no, id, name, status, tipo_tienda, supports } = this.props.store;
        return (
            <Link to={this.cardLink(this.props.store)} key={id} className="basic-card basic-card--16 store-card">
                <div className="store-card-left">
                    <div className="store-title-section">
                        <div className="store-title-section--row">
                            <p className="headline-3--regular">{name}</p>
                            <p className="text-3--regular store-id">#{customer_shop_no}</p>
                        </div>
                        <div className="store-subtitle-section--row">
                            <p className="text-3--regular store-subtitle">{tipo_tienda}</p>
                            <p className="text-3--regular store-hardwares">{pluralize(supports?.length || 0, ['Soporte', 'Soportes'])}</p>
                        </div>
                    </div>
                </div>
                <div className="store-card-right">
                    <p className={'text-3--semibold status-pill ' + statusPillClass(status as StoreStatus)}>{ statusName(status as StoreStatus, t)}</p>
                </div>
            </Link>
        );
    };
}

const StoreCard = withTranslation()(StoreCardComponent);
export { StoreCard };
