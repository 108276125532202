import React from 'react';
import moment from 'moment';
import { Campaign } from '../interfaces/interfaces';
import { first_date, last_date } from '../util/date';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface TimelineProps {
    campaigns: Campaign[]
}

interface TimelineState {
    start_date: moment.Moment;
    end_date: moment.Moment;
    scrollPosition: number;
}

const DAY_WIDTH = 10;
const MARGIN_DAYS = 60;

type TimelinePropsSum = TimelineProps & WithTranslation;

class TimelineComponent extends React.Component<TimelinePropsSum, TimelineState> {
    container: React.RefObject<HTMLDivElement> = React.createRef();
    today: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: TimelinePropsSum) {
        super(props);

        this.state = {
            start_date: first_date(props.campaigns, MARGIN_DAYS),
            end_date: last_date(props.campaigns, MARGIN_DAYS),
            scrollPosition: 0
        };
    }

    componentDidMount() {
        this.container.current!.scrollLeft = moment().diff(this.state.start_date, 'days') * DAY_WIDTH - this.today.current!.offsetLeft; 
    }

    activeCampaignClass(index: number) {
        return index === 0 ? 'campaing-scope campaing-scope--active' : 'campaing-scope';
    }

    rowsClass(count: number) {
        switch (count) {
            case 1:
                return 'one';
            case 2:
                return 'two';
            case 3:
                return 'three';
            case 4:
                return 'four';
            default:
                return '';
        }
    }

    renderMonths() {
        const { start_date, end_date } = this.state;
        const diff = end_date.diff(start_date, 'months');

        const month_days = (month: moment.Moment) => {
            if (month.month() === start_date.month()) {
                return month.daysInMonth() - start_date.date();
            }
            if (month.month() === end_date.month()) {
                return end_date.date();
            }
            return month.daysInMonth();
        }

        return Array.from(Array(diff + 1).keys()).map((_, index) => start_date.clone().add(index, 'months')).map((month, index) => {
            return (
                <div key={index} className="month" style={{width: `${month_days(month) * DAY_WIDTH}px`}}><p className="text-4--regular month-name">{month.format('MMM')}</p></div> 
            )
        });
    }

    onScroll() {
        this.setState({
            scrollPosition: this.container.current!.scrollLeft
        });
    }

    renderDate() {
        const { start_date, scrollPosition } = this.state;
        const scrollOffset = this.today.current?.offsetLeft || 0;

        const currentDate = start_date.clone().add((scrollPosition + scrollOffset) / DAY_WIDTH, 'days');

        return (
            <p className="today-date text-3--semibold">
                {this.renderTodayText(currentDate)}
                {currentDate.format('ll')}
            </p>
        );
    }

    renderTodayText(markedDay: moment.Moment|undefined) {
        const { t } = this.props;
        return (markedDay||moment()).isSame(new Date(), 'day') ? t('today') : '';
    }

    render () {
        const { start_date } = this.state;

        const campaign_days = (campaign: Campaign) => {
            return moment(campaign.end_date).diff(moment(campaign.start_date), 'days') + 1;
        }

        const campaign_offset_days = (campaign: Campaign) => {
            return moment(campaign.start_date).diff(start_date, 'days') - 1;
        }

        return (
            <div className={`timeline-container timeline-container--${this.rowsClass(this.props.campaigns.length)}`}>
                <div className="timeline-today">
                    {this.renderDate()}
                    <div ref={this.today} className="today-bar"></div>
                </div>
                <div ref={this.container} className="timeline-content" onScroll={(event) => this.onScroll()}>
                    <div className="timeline-months">
                        {this.renderMonths()}
                    </div>
                    {this.props.campaigns.map((campaign, index) => (
                        <div key={campaign.id} className={this.activeCampaignClass(index)} style={{width: `${campaign_days(campaign) * DAY_WIDTH}px`, top: 4 + index * 28, left: `${campaign_offset_days(campaign) * DAY_WIDTH}px`}}>
                            <div className="campaing-scope-img" style={{backgroundImage: `url('${campaign.image}')`}}></div>
                            <p className="text-3--semibold campaing-scope-name">{campaign.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
}

const Timeline = withTranslation()(TimelineComponent);
export { Timeline };
