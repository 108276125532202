import {
    UPDATE_STORE,
    UPDATE_LOADING,
    UPDATE_STORE_INFO_SHOW,
    UPDATE_SUPPORTS,
    UPDATE_USERS,
    UPDATE_SECTION_ADD_MODAL_SHOW,
    UPDATE_STORE_EDIT_MODAL_SHOW,
    UPDATE_SUPPORT_ADD_MODAL_SHOW,
    UPDATE_CLIENTS,
    StoreDetailActions
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, Support, SelectOption } from '../../../interfaces/interfaces';

export interface StoreIndexState {
    loading: boolean,
    store: Store|null,
    storeInfoShow: boolean,
    sectionAddModalShow: boolean,
    storeEditModalShow: boolean,
    supportAddModalShow: boolean,
    supports: Support[],
    clients: SelectOption[],
}

const INITIAL_STATE: StoreIndexState = {
    loading: false,
    store: null,
    storeInfoShow: true,
    sectionAddModalShow: false,
    storeEditModalShow: false,
    supportAddModalShow: false,
    supports: [],
    clients: [],
};

const reducer: Reducer<StoreIndexState, StoreDetailActions> = (state = INITIAL_STATE, action: StoreDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_STORE:
            return { ...state, store: action.payload };
        case UPDATE_STORE_INFO_SHOW:
            return { ...state, storeInfoShow: action.payload };
        case UPDATE_SECTION_ADD_MODAL_SHOW:
            return { ...state, sectionAddModalShow: action.payload };
        case UPDATE_STORE_EDIT_MODAL_SHOW:
            return { ...state, storeEditModalShow: action.payload };
        case UPDATE_SUPPORT_ADD_MODAL_SHOW:
            return { ...state, supportAddModalShow: action.payload };
        case UPDATE_SUPPORTS:
            return { ...state, supports: action.payload };
        case UPDATE_USERS:
            return { ...state, users: action.payload };
        case UPDATE_CLIENTS:
            return { ...state, clients: action.payload };
        default:
            return state;
    }
}

export default reducer;