import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import {
	updateSupport,
	updateSupportInfoShow,
	updateSupportAddModalShow,
} from './SupportDetailActions';
import { getSections, getSupportTypes, getTextileTypes, getColors, getPriorities, getProductionLanguages } from '../../../reducers/datatypes/DatatypesActions';
import { Spinner, Breadcrumb, CollapsedArrow, BackArrow, SupportAddModal, VisualCard } from '../../../components';
import { edit_support, delete_support } from '../../../api';
import { Campaign, Support } from '../../../interfaces/interfaces';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { section_map } from '../../../util/select_maps';

const mapStateToProps = (state: RootState) => {
	return {
		support_detail_state: state.supportDetail,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	updateSupport,
	updateSupportInfoShow,
	updateSupportAddModalShow,
	getSupportTypes,
	getColors,
	getTextileTypes,
	getProductionLanguages,
	getSections,
	getPriorities,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

const past_filter = (campaign: Campaign) => moment(campaign.end_date).isBefore(moment());
const present_filter = (campaign: Campaign) => moment(campaign.start_date).isBefore(moment()) && moment(campaign.end_date).isAfter(moment());
const future_filter = (campaign: Campaign) => moment(campaign.start_date).isAfter(moment());

class Detail extends React.Component<Props> {

	componentDidMount() {
		this.props.updateSupport(this.props.match.params.id);
		this.props.getSupportTypes();
		this.props.getColors();
		this.props.getTextileTypes();
		this.props.getProductionLanguages();
		this.props.getSections();
		this.props.getPriorities();
	}

	renderSupportAddModal() {
		if (this.props.support_detail_state.supportAddModalShow) {
			const actualSupport = this.props.support_detail_state.support as Support;
			return (
				<SupportAddModal
					support={actualSupport}
					supportTypes={this.props.datatypes.support_types}
					colors={this.props.datatypes.colors}
					textilTypes={this.props.datatypes.textile_types}
					sections={this.props.datatypes.sections.map(section_map)}
					productionLanguages={this.props.datatypes.production_languages}
					priorities={this.props.datatypes.priorities}
					onClose={() => this.props.updateSupportAddModalShow(!this.props.support_detail_state.supportAddModalShow)}
					onSubmit={(support: Support) => {
						edit_support(support).then(() => {
							this.props.updateSupportAddModalShow(!this.props.support_detail_state.supportAddModalShow)
							this.props.updateSupport(this.props.match.params.id);
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}

	renderInfoCard() {
		const { support, supportInfoShow } = this.props.support_detail_state;
		const { production_languages } = this.props.datatypes;
		const { t } = this.props;

		const production_language = production_languages.find(production_language => production_language.value === support?.production_language);

		if (supportInfoShow) {
			return (
				<ul id="info-card">
					<li>
						<h3 className="info-row-label text-3--regular">{ t('references') }</h3>
						<p className="info-row-data text-2--regular">{support?.reference}</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('dimensions') }</h3>
						<p className="info-row-data text-2--regular">{support?.width}w x {support?.height}h x {support?.depth}d</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('model_and_color') }</h3>
						<p className="info-row-data text-2--regular">{support?.support_type} / {/* <span className="color-pick" style={{background: `#B1E9CE`}}></span> */} { support?.color }</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('textil_type') }</h3>
						<p className="info-row-data text-2--regular">{ support?.textil_type }</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('store') }</h3>
						<Link to={`/stores/${support?.store?.customer_no}/${support?.store?.id}`}><p className="info-row-data text-2--regular">{support?.store?.name}</p></Link>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('section') }</h3>
						<p className="info-row-data text-2--regular">{ support?.section }</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('priority') }</h3>
						<p className="info-row-data text-2--regular">{ support?.priority }</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('production_language') }</h3>
						<p className="info-row-data text-2--regular">{production_languages ? production_language?.name : 'I'}</p>
					</li>
					<li>
						<h3 className="info-row-label text-3--regular">{ t('double_face') }</h3>
						<p className="info-row-data text-2--regular">{support?.doble_cara ? t('yes') : t('no')}</p>
					</li>
				</ul>
			);
		}
	}

	renderVisualsBlock() {
		const { support } = this.props.support_detail_state;
		const { t } = this.props;
		if (support && support.campaigns && support.campaigns.length > 0) {
			return (
				<div className="basic-card basic-card--32 campaing-scope">
					<div className="card-header">
						<div className="card-header-left">
							<h2 className="headline-4--semibold">{ t('assigned_visuals') }</h2>
						</div>
						<div className="class-header-right">
						</div>
					</div>
					<div className="store-card--view">
						<div className="generic-content">
							<section id="vertical-timeline">
								<article id="line_timeline"></article>
								<article id="time_items">
									{ this.renderVisuals('past', 'Pasados', past_filter) }
									{ this.renderVisuals('present', 'Presente', present_filter) }
									{ this.renderVisuals('future', 'Futuros', future_filter) }
								</article>
							</section>
						</div>
					</div>
				</div>
			);
		}
	}

	renderVisuals(section: string, title: string, filter: ((campaign: Campaign) => boolean)) {
		const { support } = this.props.support_detail_state;

		if (support && support.campaigns) {
			const campaigns = support.campaigns.filter(filter);

			if (campaigns.length > 0) {
				return (
					<section id={section}>
						<h3>{title}</h3>
						{campaigns.map(campaign =>
							<VisualCard key={campaign.id} visual={campaign.images[0]} campaign={campaign}/>
						)}
					</section>
				)
			}
		}
	}

	render () {
		const { t } = this.props;
		if (this.props.support_detail_state.loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_support') } />
					</div>
				</div>
			);
		}

		return (
			<>
				<div className="base-content">
					{this.renderSupportAddModal()}
					<div className="content">

						<div className="container">

							<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('supports'), url: '/supports' }, { text: this.props.support_detail_state?.support?.name || ''}]}/>

							<div className="section-header">
								<div className="section-header-left">
									<BackArrow onClick={() => this.props.history.goBack() }/>
									<h1 className="headline-1--regular">{this?.props?.support_detail_state?.support?.name}</h1>
								</div>
								<div className="section-header-right">
									<button className="btn--primary btn--destruction btn--l pop-reveal" onClick={() => {
										if (window.confirm(t('do_you_want_to_delete_the_support'))) {
											delete_support(this.props.support_detail_state.support!!.id || '').then(() => {
												this.props.history.goBack();
											}).catch(err => console.error(err));
										}
									}}><i className="icon icon-16-delete"></i>{ t('delete') }</button>
									<button className="btn--secondary btn--l pop-reveal" onClick={() => this.props.updateSupportAddModalShow(!this.props.support_detail_state.supportAddModalShow)}><i className="icon icon-16-edit"></i>{ t('edit') }</button>
								</div>
							</div>
							<div className="basic-card basic-card--32 info-card">
								<div className="card-header">
									<div className="card-header-left">
										<h2 className="headline-3--semibold">{ t('support_information') }</h2>
										<p className="text-3--regular store-id">#{this?.props?.support_detail_state?.support?.no_soporte_cliente}</p>
									</div>
									<div className="class-header-right">
										<CollapsedArrow collapsed={!this.props.support_detail_state.supportInfoShow} clicked={() => this.props.updateSupportInfoShow(!this.props.support_detail_state.supportInfoShow)}/>
									</div>
								</div>
								{this.renderInfoCard()}
							</div>
							{this.renderVisualsBlock()}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(connector(Detail));
