import {
    UPDATE_CLIENT,
    UPDATE_LOADING,
    UPDATE_SHOW_DETAIL,
    UPDATE_STORES,
    UPDATE_SUPPORTS,
    UPDATE_SHOW_ADD_STORE_MODAL,
    UPDATE_CAMPAIGNS_COUNT,
    ClientDetailActions,
    UPDATE_CLIENTS,
    AppThunk,
} from './ActionTypes';
import { one_client, get_clients, get_campaigns_count } from '../../../api';
import { client_map } from '../client.maps';
import { support_map } from '../../support/support.maps';
import cookies from '../../../util/cookies';

export const updateClient = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as ClientDetailActions);

        let client = await one_client();
        let mappedClient = client_map(client);
        dispatch({
            type: UPDATE_CLIENT,
            payload: mappedClient
        } as ClientDetailActions);

        cookies.set('b2b-kendu-client-name', mappedClient.name, { path: '/' });

        dispatch({
            type: UPDATE_STORES,
            payload: client.stores
        } as ClientDetailActions);

        dispatch({
            type: UPDATE_SUPPORTS,
            payload: client.hardwares.map(support_map)
        } as ClientDetailActions);

        let campaigns_count = await get_campaigns_count({});
        dispatch({
            type: UPDATE_CAMPAIGNS_COUNT,
            payload: campaigns_count
        } as ClientDetailActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as ClientDetailActions);
    }
}

export const updateShowDetail = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_DETAIL,
            payload: value
        } as ClientDetailActions);
    }
}

export const updateShowAddStoreModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_ADD_STORE_MODAL,
            payload: value
        } as ClientDetailActions);
    }
}

export const getClients = (): AppThunk => {
    return async dispatch => {

        const clients = await get_clients();

        dispatch({
            type: UPDATE_CLIENTS,
            payload: clients.map(client => ({ value: client.id, name: client.name }))
        } as ClientDetailActions);
    }
}