import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { AssignedVisuals, FilterStoreAndSupport, Order, Store, Visual } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_VAT = 'UPDATE_VAT';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_AVAILABLE_VISUALS = 'UPDATE_AVAILABLE_VISUALS';
export const UPDATE_ASSIGNED_IMAGES = 'UPDATE_ASSIGNED_IMAGES';
export const UPDATE_STORES = 'update_stores';
export const APPEND_STORES = 'append_stores';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateOrderAction {
    type: typeof UPDATE_ORDER,
    payload: Order
}

export interface UpdateVatAction {
    type: typeof UPDATE_VAT,
    payload: number
}

export interface UpdateFilterAction {
    type: typeof UPDATE_FILTER,
    payload: FilterStoreAndSupport
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export interface UpdateAssignedImagesAction {
    type: typeof UPDATE_ASSIGNED_IMAGES,
    payload: AssignedVisuals
}

export interface UpdateAvailableVisualsAction {
    type: typeof UPDATE_AVAILABLE_VISUALS,
    payload: Visual[]
}

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface AppendStoresAction {
    type: typeof APPEND_STORES,
    payload: Store[]
}

export type OrderDetailActions =
UpdateLoadingAction |
UpdateOrderAction |
UpdateVatAction |
UpdateFilterAction |
ClearFilterAction |
UpdateAssignedImagesAction |
UpdateAvailableVisualsAction |
UpdateStoresAction |
AppendStoresAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
