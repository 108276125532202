import React from 'react';
import { CollapsedArrow } from '.';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ListHeaderProps {
    name: string,
    id?: string,
    subtitle?: string,
    subtitleBold?: string,
    collapsed?: boolean,
    count?: number,
    countText?: string,
}

interface State {
    collapsed: boolean,
}

type ListHeaderPropsSum = ListHeaderProps & WithTranslation;

class ListHeaderComponent extends React.Component<ListHeaderPropsSum, State> {

    constructor(props: ListHeaderPropsSum) {
        super(props);
        this.state = {
            collapsed: this.props.collapsed || false,
        }
    }

    swapStoreCollapse() {
		this.setState({
			collapsed: !this.state.collapsed
		});
    }

    renderCardRight() {
        const count = this.props.count || 0;
        if (this.props.count) {
            return (
                <p style={{marginRight: 20, color: count === 0 ? 'rgba(0, 0, 0, 0.2)' : 'inherit'}}>{ this.props.countText || '' }</p>
            );
        }
    }

    renderContent() {
        if (!this.state.collapsed) {
            return (
                <div className="store-hardware-content">
                    {this.props.children}
                </div>
            );
        }
    }

    renderCollapsedArrow() {
        if (this.props.collapsed) {
            return (
                <CollapsedArrow collapsed={this.state.collapsed} clicked={() => this.swapStoreCollapse()} />
            );
        }
    }

    render () {
        return (
            <>
                <div className="store-header">
                    <div className="campaing-card-left">
                        <div className="campaing-title-section">
                            <p className="headline-2--regular" style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>{this.props.name}{this.props.id && <span className="text-3--regular store-id">#{this.props.id}</span>}</p>
                            {(this.props.subtitle || this.props.subtitleBold) && <p className="text-3--regular campaing-subtitle" style={{marginTop: '4px', color: 'black'}}><span style={{marginRight: '16px'}}>{this.props.subtitle}</span><b>{this.props.subtitleBold}</b></p>}
                        </div>
                    </div>
                    <div className="campaing-card-right">
                        {this.renderCardRight()}
                    </div>
                </div>
                {this.renderContent()}
            </>
        );
    };
}

const ListHeader = withTranslation()(ListHeaderComponent);
export { ListHeader };
