import React from 'react';
import { InputSelect } from './';
import { SelectOption, Visual } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { VisualImage } from './VisualImage';
import { is_priority_assigned, is_section_assigned, is_production_language_assigned } from '../util/visual';
import { remove_decimals } from '../util/number';

export interface VisualEditModalProps {
    visual: Visual,
    sections: SelectOption[],
    priorities: SelectOption[],
    production_languages: SelectOption[],
    assignationType: string,
    onClose: () => void,
    onSubmit: (visual: Visual) => void
}

interface State {
    section: string,
    priority: string,
    section_error: string,
    priority_error: string,
    size_error: string,
    production_language: string,
}

type VisualEditModalPropsSum = VisualEditModalProps & WithTranslation;

class VisualEditModalComponent extends React.Component<VisualEditModalPropsSum, State> {

    constructor(props: VisualEditModalPropsSum) {
        super(props);

        this.state = {
            section: '',
            priority: '',
            section_error: '',
            priority_error: '',
            size_error: '',
            production_language: '',
        };
    }

    componentDidMount() {
        const { visual } = this.props;
        this.setState({ production_language: visual?.production_language || '1' });
        if (visual !== undefined) {
            this.setState({
                section: visual.section||'',
                priority: visual.priority||'',
            });
        }
    }

    onSubmit() {
        if (this.isValid()) {
            this.props.onSubmit({
                ...this.props.visual,
                section: this.state.section,
                priority: this.state.priority,
                production_language: this.state.production_language,
            });
        }
    }

    isValid() {
        let isValid = true;

        this.setState({
            section_error: '',
            priority_error: '',
            size_error: '',
        });

        return isValid;
    }

    renderImage() {
        const { visual } = this.props;

        if (visual) {
            return (
                <div className="pop-hardware-content-full-width">
                    <div className="pop-hardware-element-content">
                        <div className="pop-hardware-element">
                            <div className="card-img card-img--visual-xl">
                                <VisualImage visual={visual} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render () {
        const { section, priority, production_language } = this.state;
        const { t } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg pop-bg-big">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{this.props.visual?.name}</p>
                            <i className="icon icon-16-x close-pop" onClick={() => this.props.onClose()}></i>
                        </div>

                        <div className="pop-box-content">

                            { this.renderImage() }

                            <div className="form-row-content">
                                <div className="form-row">
                                    {remove_decimals(Number(this.props.visual?.original_width))} x {remove_decimals(Number(this.props.visual?.original_height))}
                                </div>
                            </div>

                            <div className="form-row-content">
                                { is_section_assigned(this.props.assignationType) &&
                                    <div className="form-row">
                                        <InputSelect id="section" label={ t('section') } value={section} emptyValue={true} options={this.props.sections} onChange={(value) => this.setState({ section: value || '' })} helpText={this.state.section_error}/>
                                    </div>}
                                { is_priority_assigned(this.props.assignationType) &&
                                    <div className="form-row">
                                        <InputSelect id="priority" label={ t('priority') } value={priority} emptyValue={true} options={this.props.priorities} onChange={(value) => this.setState({ priority: value || '' })} helpText={this.state.priority_error}/>
                                    </div>}
                                { is_production_language_assigned(this.props.assignationType) &&
                                    <div className="form-row">
                                        <InputSelect id="production_language" label={ t('production_language') } value={production_language} emptyValue={false} options={this.props.production_languages} onChange={(value) => this.setState({ production_language: value || '' })} helpText={this.state.size_error}/>
                                    </div>
                                }
                            </div>

                            <div className="action-row">
                                <button className="btn--primary btn--l" onClick={()=> this.onSubmit()}>{this.props.visual === undefined ? t('create') : t('edit')}</button>
                                <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClose()}>{ t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const VisualEditModal = withTranslation()(VisualEditModalComponent);
export { VisualEditModal };
