import {
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_SHOW_ADD_USER_MODAL,
    UPDATE_USERS,
    SetingsActions,
    AppThunk
} from './ActionTypes';
import { one_client, get_users } from '../../../api';
import { client_map } from '../../client/client.maps';
import { user_map } from '../user.maps';
import { get_assignemt_fields } from '../../../util/visual';


export const getAssignationType = (): AppThunk => {
    return async dispatch => {

        let client = await one_client();
        if (client) {
            let mappedClient = client_map(client);
            let assignationType = get_assignemt_fields(mappedClient.assignation_type)
            dispatch({
                type: UPDATE_ASSIGNATION_TYPE,
                payload: assignationType
            } as SetingsActions);
        }

    }
}

export const updateShowAddUserModal = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_SHOW_ADD_USER_MODAL,
            payload: value
        } as SetingsActions);
    }
}

export const getUsers = (): AppThunk => {
    return async dispatch => {

        const users = await get_users();

        dispatch({
            type: UPDATE_USERS,
            payload: users.map(user_map)
        } as SetingsActions);
    }
}