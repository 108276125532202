import { StoreStatus, Support } from "../interfaces/interfaces";
import { TFunction } from "i18next";

export const store_checked = (supports: Support[], selected_supports: string[]) => {
    if (supports.length === 0) {
        return false;
    }

    return supports.every(support => selected_supports.includes(support.id!.toString()));
}

export const statusPillClass = (status: StoreStatus|undefined) => {
    switch (status) {
        case StoreStatus.OPEN:
            return 'status-pill--live';
        case StoreStatus.CLOSED:
            return 'status-pill--over';
        default:
            return 'status-pill--coming';
    }
}

export const statusName = (status: StoreStatus|undefined, t: TFunction) => {
    switch (status) {
        case StoreStatus.OPEN:
            return t('store_status_open');
        case StoreStatus.CLOSED:
            return t('store_status_closed');
        default:
            return 'NO STATUS';
    }
}