import React from 'react';
import { InputSelect, InputText } from './';
import { User } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { is_empty_string, is_email, are_equal_strings } from "../util/form";

export interface ProfileAddModalProps {
    user?: User,
    onClose: () => void,
    onSubmit: (user: User) => void
}

interface State {
    id: string,
    name: string,
    last_name: string,
    email: string,
    phone: string,
    password: string,
    passwordRepeat: string,
    photo: string,
    profile_name_error: string,
    profile_last_name_error: string,
    profile_role_error: string,
    profile_position_error: string,
    profile_email_error: string,
    profile_password_error: string,
    profile_password_repeat_error: string,
    role: string,
    file: File | null,
    position: string;
}

type ProfileAddModalPropsSum = ProfileAddModalProps & WithTranslation;

class ProfileAddModalComponent extends React.Component<ProfileAddModalPropsSum, State> {

    constructor(props: ProfileAddModalPropsSum) {
        super(props);

        this.state = {
            id: '',
            name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            passwordRepeat: '',
            role: '',
            position: '',
            photo: '',
            file: null,
            profile_name_error: '',
            profile_last_name_error: '',
            profile_role_error: '',
            profile_position_error: '',
            profile_email_error: '',
            profile_password_error: '',
            profile_password_repeat_error: '',
        };
    }

    componentDidMount() {
        const { user } = this.props;
        if (user !== undefined) {
            this.setState({
                id: user.id || '',
                name: user.name || '',
                last_name: user.last_name || '',
                email: user.email || '',
                phone: user.phone || '',
                role: user.role || '',
                position: user.position || '',
            });
        }
    }

    getBase64 = (file: File) => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                resolve(reader.result);
            };
        });
    }

    submit() {
        const { id, name, last_name, email, phone, position, password, photo, role } = this.state;

        if (this.isValid()) {
                this.props.onSubmit({
                    id: id,
                    name: name,
                    last_name: last_name,
                    email: email,
                    phone: phone,
                    password: password,
                    stores: [],
                    position: position,
                    photo: photo,
                    role: role
                });
        }
    }

    isValid() {
        const { t } = this.props;
        const { name,last_name, email, password, passwordRepeat, position, role } = this.state;
        let isValid = true;

        this.setState({
            profile_password_error: '',
            profile_password_repeat_error: '',
            profile_name_error: '',
            profile_last_name_error: '',
            profile_role_error: '',
            profile_position_error: '',
            profile_email_error: '',
        });


        if (!is_empty_string(password) && !is_empty_string(passwordRepeat) && !are_equal_strings(password, passwordRepeat)) {
            isValid = false;
            this.setState({ profile_password_error: t('passwords_must_be_same')});
        }
        if (is_empty_string(name)) {
            isValid = false;
            this.setState({ profile_name_error: t('profile_name_required')});
        }
        if (is_empty_string(last_name)) {
            isValid = false;
            this.setState({ profile_last_name_error: t('profile_last_name_required')});
        }
        if (!is_email(email)) {
            isValid = false;
            this.setState({ profile_email_error: t('user_should_have_email_format')});
        }
        if (is_empty_string(email)) {
            isValid = false;
            this.setState({ profile_email_error: t('profile_email_required')});
        }
        if (is_empty_string(position)) {
            isValid = false;
            this.setState({ profile_position_error: t('profile_position_required')});
        }
        if (is_empty_string(role)) {
            isValid = false;
            this.setState({ profile_role_error: t('profile_role_required')});
        }
        if (is_empty_string(password)) {
            isValid = false;
            this.setState({ profile_password_error: t('password_required')});
        }
        if (is_empty_string(passwordRepeat)) {
            isValid = false;
            this.setState({ profile_password_repeat_error: t('password_required')});
        }

        return isValid;
    }

    imageSelected(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            this.setState({ file: event.target.files.item(0) });
            this.getBase64(event.target.files.item(0)!).then(result =>{
                this.setState({ photo: result as string });

            })
		}
    }

    uploadedFileUrl() {
        if (this.state.file) {
            return URL.createObjectURL(this.state.file);
        }
    }

    render () {
        const { name, last_name, email, phone, password, passwordRepeat, role, position } = this.state;
        const { user } = this.props;
        const { t } = this.props;
        return (
            <div className="pop-layout">
                <div className="pop-bg pop-bg-big">
                    <div className="pop-box pop-box--place">
                        <div className="pop-box-header">
                            <p className="pop-box-title">{user === undefined ? t('create_user') : t('edit_user')}</p>
                            <i className="icon icon-16-x close-pop" onClick={() => this.props.onClose()}></i>
                        </div>

                        <div className="pop-box-content">

                            <section id="user_avatar">
                                <article className="circle">
                                    <img src={this.uploadedFileUrl() || user?.photo + "?v="+ Date.now() || 'img/img-sample-visual-1.jpeg'} alt="" />
                                </article>
                                <article id="upload_avatar">
                                    <div>
                                        <input type="file" id="file" onChange={(event) => this.imageSelected(event)} style={{display: 'none'}} accept=".png,.jpg,.jpeg" />
                                        <label className="btn--secondary btn--l close-pop" htmlFor="file">{ t('select_one_image') }  </label>
                                        <p className="upload_description">{ t('images_format') } .png, .jpeg, .jpg</p>
                                        <p>{ t('max_size') } 2MB</p>
                                    </div>
                                </article>
                            </section>
                            

                            <p className="text-3--regular form-group-title">{ t('contact_information') }</p>
                            <div className="form-row-content">
                                <div className="form-row-content grid-layout--small">
                                    <div className="form-row grid-half-1">
                                        <InputText id="name" label={ t('name') } value={name} onChange={(e) => this.setState({ name: e.target.value })} helpText={this.state.profile_name_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputText id="last_name" label={ t('last_name') } value={last_name} onChange={(e) => this.setState({ last_name: e.target.value })} helpText={this.state.profile_last_name_error}/>
                                    </div>
                                    <div className="form-row grid-half-1">
                                        <InputSelect id="role" label={ t('role') } value={role} emptyValue={false} options={[{name: 'admin', value: 'admin'},{name: 'user', value: 'user'}]} onChange={(value) => this.setState({ role: value as string })} helpText={this.state.profile_role_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputText id="position" label={ t('position') } value={position} onChange={(e) => this.setState({ position: e.target.value })} helpText={this.state.profile_position_error}/>
                                    </div>
                                </div>
                            </div>

                            <p className="text-3--regular form-group-title">{ t('contact') }</p>
                            <div className="form-row-content">
                                <div className="form-row-content grid-layout--small">
                                    <div className="form-row grid-half-1">
                                        <InputText id="email" label={ t('email') } value={email} onChange={(e) => this.setState({ email: e.target.value })} helpText={this.state.profile_email_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputText id="phone" label={ t('phone') } value={phone} onChange={(e) => this.setState({ phone: e.target.value })}/>
                                    </div>
                                </div>
                            </div>

                            <p className="text-3--regular form-group-title">{ t('change_password') }</p>
                            <div className="form-row-content">
                                <div className="form-row-content grid-layout--small">
                                    <div className="form-row grid-half-1">
                                        <InputText id="password" type="password" label={ t('password') } value={password} onChange={(e) => this.setState({ password: e.target.value })} helpText={this.state.profile_password_error}/>
                                    </div>
                                    <div className="form-row grid-half-2">
                                        <InputText id="passwordRepeat" type="password" label={ t('repeat_password') } value={passwordRepeat} onChange={(e) => this.setState({ passwordRepeat: e.target.value })} helpText={this.state.profile_password_repeat_error}/>
                                    </div>
                                </div>
                            </div>

                            <div className="action-row">
                                <button id="submit-button" className="btn--primary btn--l" onClick={() => this.submit()}>{user === undefined ? t('create') : t('edit')}</button>
                                <button id="cancel-button" className="btn--secondary btn--l close-pop" onClick={() => this.props.onClose()}>{ t('cancel') }</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const ProfileAddModal = withTranslation()(ProfileAddModalComponent);
export { ProfileAddModal };
