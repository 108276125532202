import {
    UPDATE_CAMPAIGNS,
    APPEND_CAMPAIGNS,
    UPDATE_LOADING,
    UPDATE_ADD_MODAL_VISIBILITY,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_FILTER,
    UPDATE_STATUSES,
    CLEAR_FILTER,
    CampaignIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Campaign, FilterCampaign, SelectOption } from "../../../interfaces/interfaces";

export interface CampaignIndexState {
    campaigns: Campaign[],
    loading: boolean,
    addModalVisibility: boolean,
    showFilter: boolean,
    filter: FilterCampaign,
    statuses: SelectOption[],
}

const INITIAL_STATE: CampaignIndexState = {
    campaigns: [],
    loading: false,
    addModalVisibility: false,
    showFilter: false,
    filter: new FilterCampaign(),
    statuses: [],
};

const reducer: Reducer<CampaignIndexState, CampaignIndexActions> = (state = INITIAL_STATE, action: CampaignIndexActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGNS:
            return { ...state, campaigns: action.payload };
        case APPEND_CAMPAIGNS:
            return { ...state, campaigns: [ ...state.campaigns, ...action.payload ] };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_ADD_MODAL_VISIBILITY:
            return { ...state, addModalVisibility: action.payload };
        case UPDATE_FILTER_VISIBILITY:
            return { ...state, showFilter: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case UPDATE_STATUSES:
            return { ...state, statuses: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterCampaign() }
        default:
            return state;
    }
}

export default reducer;