import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_LOADING,
    UPDATE_ADD_MODAL_VISIBILITY,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_CLIENTS,
    StoreIndexActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, SelectOption, FilterStore } from '../../../interfaces/interfaces';

export interface StoreIndexState {
    showFilter: boolean,
    loading: boolean,
    addModalVisibility: boolean,
    stores: Store[],
    filter: FilterStore,
    clients: SelectOption[],
}

const INITIAL_STATE: StoreIndexState = {
    showFilter: false,
    loading: false,
    addModalVisibility: false,
    stores: [],
    filter: new FilterStore(),
    clients: [],
};

const reducer: Reducer<StoreIndexState, StoreIndexActions> = (state = INITIAL_STATE, action: StoreIndexActions) => {
    switch(action.type) {
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case APPEND_STORES:
            return { ...state, stores: [ ...state.stores, ...action.payload ] };
        case UPDATE_FILTER_VISIBILITY:
            return { ...state, showFilter: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_ADD_MODAL_VISIBILITY:
            return { ...state, addModalVisibility: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload }
        case CLEAR_FILTER:
            return { ...state, filter: new FilterStore() }
        case UPDATE_CLIENTS:
            return { ...state, clients: action.payload };
        default:
            return state;
    }
}

export default reducer;