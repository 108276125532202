import React from 'react';
import { Support, AssignedVisuals } from '../interfaces/interfaces';
import { CollapsedArrow, SupportCardSummary } from './';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SupportListSummaryProps {
    id: string,
    name: string,
    supports: Support[],
    selectedSupports: string[],
    assignedImages: AssignedVisuals,
}

interface State {
    collapsed: boolean,
    supports: Support[],
}

type SupportListSummaryPropsSum = SupportListSummaryProps & WithTranslation;

class SupportListSummaryComponent extends React.Component<SupportListSummaryPropsSum, State> {

    constructor(props: SupportListSummaryPropsSum) {
        super(props);
        this.state = {
            collapsed: false,
            supports: this.props.supports
        }
    }

    swapStoreCollapse() {
		this.setState({
			collapsed: !this.state.collapsed
		});
    }

    renderSupports() {
        if (!this.state.collapsed) {
            return (
                <div className="store-hardware-content">
                    {(this.props.supports || []).map((support, index) => (
                        <React.Fragment key={index}>
                            {this.renderSupport(support)}
                        </React.Fragment>
                    ))}
                </div>
            );
        }
    }

    renderSupport(support: Support) {
        if (this.props.selectedSupports.includes(support.id||'')) {
            return (
                <SupportCardSummary
                    key={support.id}
                    support={support}
                    assignedImages={this.props.assignedImages}
                />
            );
        }
    }

    render () {
        return (
            <>
                <div className="store-header">
                    <div className="campaing-card-left">
                        <CollapsedArrow collapsed={this.state.collapsed} clicked={() => this.swapStoreCollapse()} />
                        <p className="headline-3--regular">{this.props.name}</p>
                    </div>
                </div>
                {this.renderSupports()}
            </>
        );
    };
}

const SupportListSummary = withTranslation()(SupportListSummaryComponent);
export { SupportListSummary };
