import jwt_decode from 'jwt-decode';

interface MyToken {
    customer: string
    exp: number
    iat: number
    role: string
    sub: number
    username: string
    is_central: boolean
}

export const get_role = (token: string): string => {
    return jwt_decode<MyToken>(token).role;
}

export const is_central = (token: string): boolean => {
    return jwt_decode<MyToken>(token).is_central || false;
}