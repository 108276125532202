import { Reducer } from 'redux';
import { Country, Section, SelectOption } from '../../interfaces/interfaces';
import { DatatypesActions, 
    UPDATE_LANGUAGES, 
    UPDATE_COUNTRIES, 
    UPDATE_STORE_TYPES, 
    UPDATE_PRODUCTION_LANGUAGES, 
    UPDATE_SECTIONS, 
    UPDATE_SUPPORT_TYPES, 
    UPDATE_TEXTIL_TYPES, 
    UPDATE_COLORS, 
    UPDATE_PRIORITIES } from './DatatypesTypes';

export interface DatatypesState {
    languages: SelectOption[];
    countries: Country[];
    store_types: SelectOption[];
    production_languages: SelectOption[];
    sections: Section[];
    support_types: SelectOption[];
    textile_types: SelectOption[];
    colors: SelectOption[];
    priorities: SelectOption[];
}

const INITIAL_STATE: DatatypesState = {
    languages: [],
    countries: [],
    store_types: [],
    production_languages: [],
    sections: [],
    support_types: [],
    textile_types: [],
    colors: [],
    priorities: []
};

const reducer: Reducer<DatatypesState, DatatypesActions> = (state = INITIAL_STATE, action: DatatypesActions) => {
    switch(action.type) {
        case UPDATE_LANGUAGES:
            return { ...state, languages: action.payload };
        case UPDATE_COUNTRIES:
            return { ...state, countries: action.payload };
        case UPDATE_STORE_TYPES:
            return { ...state, store_types: action.payload };
        case UPDATE_PRODUCTION_LANGUAGES:
            return { ...state, production_languages: action.payload };
        case UPDATE_SECTIONS:
            return { ...state, sections: action.payload };
        case UPDATE_SUPPORT_TYPES:
            return { ...state, support_types: action.payload };
        case UPDATE_TEXTIL_TYPES:
            return { ...state, textile_types: action.payload };
        case UPDATE_COLORS:
            return { ...state, colors: action.payload };
        case UPDATE_PRIORITIES:
            return { ...state, priorities: action.payload };
        default:
            return state;
    }
}

export default reducer;