import {
    UPDATE_TOKEN,
    AuthActions
} from "./AuthTypes";
import { Reducer } from 'redux';

export interface AuthState {
    token?: string|null,
}

const INITIAL_STATE: AuthState = {
    token: null,
};

const reducer: Reducer<AuthState, AuthActions> = (state = INITIAL_STATE, action: AuthActions) => {
    switch(action.type) {
        case UPDATE_TOKEN:
            return { ...state, token: action.payload };
        default:
            return state;
    }
}

export default reducer;