import React from 'react';
import { InputSelect } from '.';
import { FilterOrder, SelectOption } from '../interfaces/interfaces';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

export interface OrderFilterProps {
    filter?: FilterOrder,
    users: SelectOption[],
    statuses: SelectOption[],
    role?: string,
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterOrder) => void
}

interface State {
    status?: string,
    start_date: moment.Moment|null,
    end_date: moment.Moment|null,
    focused: 'startDate'|'endDate'|null,
    user?: string,
}

type OrderFilterPropsSum = OrderFilterProps & WithTranslation;

class OrderFilterComponent extends React.Component<OrderFilterPropsSum, State> {

    constructor(props: OrderFilterPropsSum) {
        super(props);

        this.state = {
            status: props.filter?.status,
            start_date: props.filter && props.filter.start_date ? moment.unix(props.filter.start_date) : null,
            end_date: props.filter && props.filter.end_date ? moment.unix(props.filter.end_date) : null,
            focused: null,
            user: props.filter?.user,

        };
    }

    onSubmit() {
        const { status, start_date, end_date, user } = this.state;
        this.props.onSubmit({
            start_date: start_date !== null ? start_date.unix() : undefined,
            end_date: end_date !== null ? end_date.unix() : undefined,
            status,
            user
        });
    }

    render () {
        const { t } = this.props;
        return (
            <div className="filter-content filter-content--show">

                <div className="filter-container">

                    <div className="filter-header">
                        <h2 className="headline-4--regular">{ t('filter_orders') }</h2>
                        <i className="icon icon-16-x close-filter" onClick={()=> {this.props.onClose()}}></i>
                    </div>

                    <div className="form-row-content">
                        <div className="form-row">
                            <InputSelect id="status" label={ t('status') } value={this.state.status} emptyValue={true} options={this.props.statuses} onChange={(value) => this.setState({status: value || ''})} />
                        </div>
                        <div className="form-row">
                            <DateRangePicker
                                startDate={this.state.start_date} // momentPropTypes.momentObj or null,
                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                endDate={this.state.end_date} // momentPropTypes.momentObj or null,
                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => this.setState({ start_date: startDate, end_date: endDate })} // PropTypes.func.isRequired,
                                focusedInput={this.state.focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => this.setState({ focused: focusedInput })} // PropTypes.func.isRequired,
                                isOutsideRange={() => false}
                            />
                        </div>
                        {!['user'].includes(this.props.role || 'user') &&
                            <div className="form-row">
                                <InputSelect id="user" label={ t('user') } value={this.state.user} emptyValue={true} options={this.props.users} onChange={(value) => this.setState({user: value || ''})} />
                            </div>
                        }
                    </div>

                    <div className="form-row" style={{display: 'flex'}}>
                        <button className="btn--primary btn--l pop-reveal" style={{marginRight: 8}} onClick={() => this.onSubmit()}>{ t('filter') }</button>
                        <button className="btn--secondary btn--l close-pop" onClick={() => this.props.onClear()}>{ t('clear') }</button>
                    </div>

                </div>

            </div>
        );
    };
}

const OrderFilter = withTranslation()(OrderFilterComponent);
export { OrderFilter };
