import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Support } from '../../../interfaces/interfaces';

export const UPDATE_SUPPORT = 'update_support';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SUPPORT_INFO_SHOW = 'update_support_info_show';
export const UPDATE_SUPPORT_ADD_MODAL_SHOW = 'update_support_add_modal_show';

export interface UpdateSupportAction {
    type: typeof UPDATE_SUPPORT,
    payload: Support
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateSupportInfoShowAction {
    type: typeof UPDATE_SUPPORT_INFO_SHOW,
    payload: boolean
}

export interface UpdateSupportAddModalShowAction {
    type: typeof UPDATE_SUPPORT_ADD_MODAL_SHOW,
    payload: boolean
}

export type SupportDetailActions =
UpdateSupportAction |
UpdateLoadingAction |
UpdateSupportInfoShowAction |
UpdateSupportAddModalShowAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
