import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { InputText } from '../../../components';
import { updatePassword, updatePasswordError, updatePasswordRepeat, updatePasswordRepeatError } from './PasswordResetActions';
import { withTranslation, WithTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => {
	return {
		security_password_reset_state: state.securityPasswordReset
	}
}

const connector = connect(mapStateToProps, { updatePassword, updatePasswordError, updatePasswordRepeat, updatePasswordRepeatError });

interface ParamTypes {
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props> {

	save() {
		const { t } = this.props;
		const { password, passwordRepeat } = this.props.security_password_reset_state;
		let hasError = false;
		if (!password) {
			hasError = true;
			this.props.updatePasswordError(t('password_is_required'));
		} else {
			this.props.updatePasswordError('');
		}
		if (!passwordRepeat) {
			hasError = true;
			this.props.updatePasswordRepeatError(t('password_is_required'));
		} else if (password !== passwordRepeat) {
			this.props.updatePasswordRepeatError(t('passwords_must_be_same'));
		} else {
			this.props.updatePasswordRepeatError('');
		}
		if (!hasError) {
			// TODO: send request
		}
	}

	render () {
		const { t } = this.props;
		return (
			<section id="login-box">

				<h1><Link to="/login"><img src="img/main-brand.svg" alt="" /></Link></h1>

				<h2>{ t('password_reset') }</h2>

				<article className="form-row">
					<InputText
						id="email"
						label={ t('email') }
						value=""
						disabled={true}
					/>
				</article>

				<article className="form-row">
					<InputText
						id="password"
						label={ t('password') }
						value={this.props.security_password_reset_state.password}
						onChange={(event) => this.props.updatePassword(event.target.value)}
						helpText={this.props.security_password_reset_state.passwordError}
					/>
				</article>

				<article className="form-row--large">
					<InputText
						id="password-repeat"
						label={ t('repeat_password') }
						value={this.props.security_password_reset_state.passwordRepeat}
						onChange={(event) => this.props.updatePasswordRepeat(event.target.value)}
						helpText={this.props.security_password_reset_state.passwordError}
					/>
				</article>

				<article className="form-row">
					<button className="btn--primary" onClick={() => this.save()}>{ t('save') }</button>
				</article>

			</section>
		);
	}
}

export default withTranslation()(connector(Settings));
