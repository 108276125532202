import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SHOW_FILTER,
    UPDATE_VISUALS_LIST,
    APPEND_VISUALS_LIST,
    UPDATE_VISUAL,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_SAVEING,
    UPDATE_SAVE_OK,
    UPDATE_SAVE_ERROR,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_PREPARED,
    UPDATE_ASSIGNED,
    CampaignMassiveEditionActions
} from "./ActionTypes";
import { Reducer } from 'redux';
import { AssignedVisuals, Campaign, FilterVisual, SelectOption, Visual } from "../../../interfaces/interfaces";

export interface CampaignMassiveEditionState {
    showFilter: boolean,
    loading: boolean,
    campaign: Campaign|null,
    filter: FilterVisual,
    visualsList: { visual: Visual, hasChanges: boolean }[],
    saveing: boolean,
    saveOk: boolean,
    saveError: boolean,
    prepared: SelectOption[],
    assigned: SelectOption[],
    assignationType: string,
    assignedImages: AssignedVisuals
}

const INITIAL_STATE: CampaignMassiveEditionState = {
    showFilter: false,
    loading: false,
    campaign: null,
    filter: new FilterVisual(),
    visualsList: [],
    saveing: false,
    saveOk: false,
    saveError: false,
    prepared: [],
    assigned: [],
    assignationType: '',
    assignedImages: {}
};

const reducer: Reducer<CampaignMassiveEditionState, CampaignMassiveEditionActions> = (state = INITIAL_STATE, action: CampaignMassiveEditionActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SHOW_FILTER:
            return { ...state, showFilter: action.payload };
        case UPDATE_VISUALS_LIST:
            return { ...state, visualsList: action.payload };
        case APPEND_VISUALS_LIST:
            return { ...state, visualsList: [...state.visualsList, ...action.payload ] };
        case UPDATE_VISUAL:
            return { ...state, visualsList: state.visualsList.map(object => {
                if (object.visual.id === action.payload.id) {
                    return { visual: action.payload, hasChanges: true };
                }
                return object;
            }) };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER:
            return { ...state, filter: new FilterVisual() }
        case UPDATE_SAVEING:
            return { ...state, saveing: action.payload }
        case UPDATE_SAVE_OK:
            return { ...state, saveOk: action.payload }
        case UPDATE_SAVE_ERROR:
            return { ...state, SaveError: action.payload }
        case UPDATE_ASSIGNATION_TYPE:
            return { ...state, assignationType: action.payload };
        case UPDATE_ASSIGNED_VISUALS:
            return { ...state, assignedImages: action.payload };
        case UPDATE_PREPARED:
            return { ...state, prepared: action.payload };
        case UPDATE_ASSIGNED:
            return { ...state, assigned: action.payload };
        default:
            return state;
    }
}

export default reducer;