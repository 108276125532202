import React from 'react';
import Dropzone, { IDropzoneProps, IExtra, IFileWithMeta, StatusValue, getFilesFromEvent } from 'react-dropzone-uploader';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface DropzoneProps {
	onUploadCompleted?: () => void
	onUploadStarted?: () => void
	url: string
	token: string
}

type Props = DropzoneProps & WithTranslation;

interface State {
	globalDropzoneProps: IDropzoneProps;
	max_files_disabled: boolean;
}

class DropzoneComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            globalDropzoneProps: {
                ...Dropzone.defaultProps,
				styles: {
					dropzone: { borderWidth: 2, borderStyle: 'dashed', borderColor: '#d5d9e4', overflow: 'hidden' },
					dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
					dropzoneActive: { borderColor: '#00a3da', },
					inputLabel: { color: '#00a3da', fontFamily: 'Inter', fontSize: '1.6rem' },
					inputLabelWithFiles: { display: 'none' }
				},
				accept: "application/pdf",
				multiple: true,
				minSizeBytes: 0,
				maxSizeBytes: 1024 * 1024 * 400,
				maxFiles: 6,
				autoUpload: true,
				inputWithFilesContent: null,
				submitButtonDisabled:true,
				canCancel : false,
				canRemove: false
			},
			max_files_disabled: false
        }
	}

	getUploadParams(file : Blob) { 
		const { url , token } = this.props;

		const body = new FormData()
		body.append('image', file)

		return { 
			url,
			body, 
			headers: {
				Authorization: `Bearer ${token}` 
			}
		} 
	}
	
	handleChangeStatus(file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) {
		if (status === 'uploading') {
			this.props.onUploadStarted?.();
		}
		if (status === 'done') {
			const allFilesUploaded = allFiles.every(file => file.meta.status === 'done');

			if (allFilesUploaded){
				this.props.onUploadCompleted?.();
			}
		}
	}

	inputContent(files: IFileWithMeta[], extra: IExtra) {
		const { t } = this.props;
		const { maxFiles } = this.state.globalDropzoneProps;

		if (extra.dragged.length > maxFiles) {
			return <p style={{color: 'red'}}>{ t('max_files_allowed', { maxFiles }) }</p>;
		}
		if (this.state.max_files_disabled) {
			return <p style={{color: 'red'}}>{ t('max_files_allowed', { maxFiles }) }</p>;
		}
		if (extra.reject) {
			return <p style={{color: 'white'}}>{ t('only_file_type_allowed') }</p>;
		}
		return t('start_dragging_or_attaching_the_images_belong_to_this_campaign');
	}

	getFilesFromEvent(event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): Promise<File[]> {
		const { maxFiles } = this.state.globalDropzoneProps;

		return new Promise((resolve, reject) => {
			const files = getFilesFromEvent(event);

			if (files.length > maxFiles) {
				this.setState({ max_files_disabled: true });

				resolve([]);

				setTimeout(() => { this.setState({ max_files_disabled: false }) }, 1500);
			} else {
				resolve(files as File[]);
			}
		})
	}

    render () {
		const { globalDropzoneProps } = this.state;

        return (
            <div className="drop-area-content">
				<Dropzone
					{...globalDropzoneProps}
					getUploadParams={({file}) => this.getUploadParams(file)}
					disabled={(files, extra) => this.state.max_files_disabled || extra.dragged.length > globalDropzoneProps.maxFiles}
					onChangeStatus={(file, status, allFiles) => this.handleChangeStatus(file, status, allFiles)}
					inputContent={(files, extra) => this.inputContent(files, extra) }
					getFilesFromEvent={event => this.getFilesFromEvent(event)}
				/>
			</div>
        );
    };
}

const DropzoneWrapper = withTranslation()(DropzoneComponent);
export { DropzoneWrapper };