import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { User } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_INFO_SHOW = 'UPDATE_USER_INFO_SHOW';
export const UPDATE_USER_EDIT_MODAL_SHOW = 'UPDATE_USER_EDIT_MODAL_SHOW';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: User
}

export interface UpdateUserInfoShowAction {
    type: typeof UPDATE_USER_INFO_SHOW,
    payload: boolean
}

export interface UpdateUserEditModalShowAction {
    type: typeof UPDATE_USER_EDIT_MODAL_SHOW,
    payload: boolean
}


export type UserDetailActions =
UpdateLoadingAction |
UpdateUserAction |
UpdateUserInfoShowAction |
UpdateUserEditModalShowAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
