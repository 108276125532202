import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SHOW_FILTER,
    UPDATE_FILTER,
    UPDATE_SELECTED_SUPPORTS,
    APPEND_SELECTED_SUPPORTS,
    REMOVE_SELECTED_SUPPORTS,
    CLEAR_FILTER,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_SHOW_CONFIRM_MODAL,
    UPDATE_SUPPORT_STATES,
    UPDATE_VAT,
    CampaignBudgetActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Store, Campaign, SelectOption, FilterStore, AssignedVisuals } from "../../../interfaces/interfaces";

export interface CampaignAddSupportState {
    stores: Store[],
    campaign: Campaign|null,
    loading: boolean,
    showFilter: boolean,
    filter: FilterStore,
    selectedSupports: string[],
    assignedImages: AssignedVisuals,
    showConfirmModal: boolean,
    supportStates: SelectOption[],
    vat: number,
}

const INITIAL_STATE: CampaignAddSupportState = {
    stores: [],
    campaign: null,
    loading: false,
    showFilter: false,
    filter: new FilterStore(),
    selectedSupports: [],
    assignedImages: {},
    showConfirmModal: false,
    supportStates: [],
    vat: 0,
};

const reducer: Reducer<CampaignAddSupportState, CampaignBudgetActions> = (state = INITIAL_STATE, action: CampaignBudgetActions) => {
    switch(action.type) {
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case APPEND_STORES:
            return { ...state, stores: [ ...state.stores, ...action.payload ] };
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SHOW_FILTER:
            return { ...state, showFilter: action.payload };
        case UPDATE_FILTER:
            return { ...state, filter: action.payload };
        case UPDATE_SELECTED_SUPPORTS:
            return { ...state, selectedSupports: action.payload };
        case APPEND_SELECTED_SUPPORTS:
            return { ...state, selectedSupports: [ ...state.selectedSupports, ...action.payload ] };
        case REMOVE_SELECTED_SUPPORTS:
            return { ...state, selectedSupports: state.selectedSupports.filter(val => !action.payload.includes(val)) }
        case CLEAR_FILTER:
            return { ...state, filter: new FilterStore() }
        case UPDATE_ASSIGNED_VISUALS:
            return { ...state, assignedImages: action.payload };
        case UPDATE_SHOW_CONFIRM_MODAL:
            return { ...state, showConfirmModal: action.payload };
        case UPDATE_SUPPORT_STATES:
            return { ...state, supportStates: action.payload };
        case UPDATE_VAT:
            return { ...state, vat: action.payload };
        default:
            return state;
    }
}

export default reducer;