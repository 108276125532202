import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";
import { RootState } from '../../../reducers';
import { Breadcrumb, Spinner } from '../../../components';
import { getAssignationType, updateShowAddUserModal, getUsers} from './SettingsActions';
import { getSections, getPriorities } from '../../../reducers/datatypes/DatatypesActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { create_profile } from '../../../api';
import { get_role } from '../../../util/jwt';
import { User } from '../../../interfaces/interfaces';
import { ProfileAddModal } from '../../../components/ProfileAddModal';
import cookies from '../../../util/cookies';

const mapStateToProps = (state: RootState) => {
	return {
		settings_settings_state: state.settings,
		datatypes: state.datatypes,
		auth_state: state.auth
	}}


const connector = connect(mapStateToProps, { 
	getSections, 
	getAssignationType,
	updateShowAddUserModal,
	getUsers, 
	create_profile,
	getPriorities
});

interface SettingsState {
    filter_value: string,
}

type Props = RouteComponentProps & ConnectedProps<typeof connector> & WithTranslation;

class Settings extends React.Component<Props, SettingsState> {

	constructor(props: Props) {
        super(props);

		this.state = {
            filter_value: ''
		};
	}
	componentDidMount() {
		this.props.getSections();
		this.props.getPriorities();
		this.props.getAssignationType();
		this.props.getUsers();

		}

	logoutClicked() {
		cookies.remove('b2b-kendu-token');
	}

	renderUserAddModal() {
		const { showAddUserModal } = this.props.settings_settings_state;
		if (showAddUserModal) {
			return (
				<ProfileAddModal
					onClose={() => this.props.updateShowAddUserModal(!showAddUserModal)}
					onSubmit={(user: User) => {
						create_profile(user).then(() => {
							this.props.updateShowAddUserModal(!showAddUserModal);
							this.props.getUsers();
						}).catch(err => console.error);
					}}
				/>
			);
		}
	}
	filterUsers(filter_value: string){
		const { users } = this.props.settings_settings_state;
		const pattern = new RegExp(filter_value, 'i');
		const filteredUsers = users.filter( user => pattern.test(user.name) || pattern.test(user.email) || pattern.test(user.position));
		return filteredUsers;
	}

	render () {
		const { t } = this.props;
		const { loading, showAddUserModal } = this.props.settings_settings_state;

		if (loading) {
			return (
				<div className="base-content">
					<div className="content">
						<Spinner message={ t('loading_settings') } />
					</div>
				</div>
			);
		}
		return (
			<div className="base-content">
				{this.renderUserAddModal()}
				<div className="content">
					<div className="container">

						<Breadcrumb items={[{ text: t('settings') }]}/>

						<div className="section-header">
							<div className="section-header-left">
								<h1 className="headline-1--regular">{ t('settings') }</h1>
							</div>
						</div>


						{['backdoor', 'admin'].includes(get_role(this.props.auth_state.token!)) &&
							<div className="basic-card basic-card--32">
								<div className="card-header">
									<div className="card-header-left">
										<p className="headline-3--semibold">{ t('users') }</p>
									</div>
									<div className="card-header-right">
										<input className="input-search search-user" type="text" placeholder={ t('user') }
											onChange={(e) => this.setState({ filter_value: e.target.value })}  />
										<button className="btn--primary btn--m pop-reveal" onClick={() => this.props.updateShowAddUserModal(!showAddUserModal)}>{ t('add_user') }</button>
									</div>
								</div>
								<table id="user_list">
									<thead>
										<tr>
											<th>{ t('name') }</th>
											<th>{ t('email') }</th>
											<th>{ t('position') }</th>
											<th>{ t('role') }</th>
										</tr>
									</thead>
									<tbody>
										{this.filterUsers(this.state.filter_value).map((user) =>
											<tr key={user.id}>
													<td >
														<div className="worker">
															<Link to={`/users/detail/${user.id}`} className="circle">
																<img src={user.photo + "?v="+ Date.now()} alt={user.name} />
															</Link>
															<div className="name">
																<Link to={`/users/detail/${user.id}`}>
																	{user.name}
																</Link>
															</div>
														</div>
													</td>
													<td>
														<Link to={`/users/detail/${user.id}`}>
															{user.email}
														</Link>
													</td>
													<td>{user.position}</td>
													<td>{user.role === 'admin' &&
														<span>Admin</span>
													}
													</td>
												</tr>
										)}
									</tbody>
								</table>
							</div>
						}


						<div className="basic-card basic-card--32">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-3--semibold">{ t('sections') }</p>
								</div>
							</div>
							<table className="settings_list">
								<tbody>
									{this.props.datatypes.sections.map((section) =>
										<tr key={section.id}>
											<td className="text-2--regular">{section.description}</td>
											<td></td>
										</tr>
									)}
								</tbody>
							</table>
						</div>

						<div className="basic-card basic-card--32">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-3--semibold">{ t('priorities') }</p>
								</div>
							</div>
							<table className="settings_list">
								<tbody>
									{this.props.datatypes.priorities.map((priority) =>
										<tr key={priority.value}>
											<td className="text-2--regular">{priority.name}</td>
											<td></td>
										</tr>
									)}
								</tbody>
							</table>
						</div>

						<div className="basic-card basic-card--32">
							<div className="card-header">
								<div className="card-header-left">
									<p className="headline-3--semibold">{ t('assignation') }</p>
								</div>
							</div>
							<table className="settings_list">
								<tbody>
									{this.props.settings_settings_state.assignationTypes.map((assignation) =>
										<tr key={assignation}>
											<td className="text-2--regular"> { t(assignation) } </td>
											<td></td>
										</tr>
									)}
								</tbody>
							</table>
						</div>

						<div className="basic-card basic-card--32 info-card">

							<div className="card-header">
								<div className="card-header-left">
									<h2 className="headline-3--semibold">{ t('logout') }</h2>
								</div>
							</div>

							<ul id="info-card">
								<li>
									<p className="settings_desc">{ t('logout') }</p>
								</li>
								<li>
									<button className="btn--primary btn--l" onClick={() => this.logoutClicked()}>{ t('logout') }</button>
								</li>
							</ul>

						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(Settings));
