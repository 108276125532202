import { get_with_auth } from "./helper";

export const get_support_types = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/supports`);
export const get_store_types = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/stores`);
export const get_production_languages = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/production-languages`);
export const get_production_languages_trek = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/production-languages-trek`);
export const get_store_groups = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/store-groups`);
export const get_colors = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/colors`);
export const get_textil_types = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/textile_types`);
export const get_sections = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/sections`);
export const get_priorities = get_with_auth(`${process.env.REACT_APP_API_URL}/datatypes/priorities`);
export const get_countries = get_with_auth(`${process.env.REACT_APP_API_URL}/countries`);