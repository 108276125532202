import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { User } from '../../../interfaces/interfaces';

export const UPDATE_LOADING = 'update_loading';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE_INFO_SHOW = 'UPDATE_PROFILE_INFO_SHOW';
export const UPDATE_PROFILE_EDIT_MODAL_SHOW = 'UPDATE_PROFILE_EDIT_MODAL_SHOW';
export const UPDATE_SELECTED_LANGUAGE = 'UPDATE_SELECTED_LANGUAGE';

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateUserAction {
    type: typeof UPDATE_USER,
    payload: User
}

export interface UpdateProfileInfoShowAction {
    type: typeof UPDATE_PROFILE_INFO_SHOW,
    payload: boolean
}

export interface UpdateProfileEditModalShowAction {
    type: typeof UPDATE_PROFILE_EDIT_MODAL_SHOW,
    payload: boolean
}

export interface UpdateSelectedLanguageAction {
    type: typeof UPDATE_SELECTED_LANGUAGE,
    payload: string
}

export type ProfileDetailActions =
UpdateLoadingAction |
UpdateUserAction |
UpdateProfileInfoShowAction |
UpdateSelectedLanguageAction |
UpdateProfileEditModalShowAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
