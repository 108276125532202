import {
    UPDATE_STORES,
    APPEND_STORES,
    UPDATE_LOADING,
    UPDATE_FILTER_VISIBILITY,
    UPDATE_ADD_MODAL_VISIBILITY,
    UPDATE_FILTER,
    CLEAR_FILTER,
    UPDATE_CLIENTS,
    StoreIndexActions,
    AppThunk,
} from './ActionTypes';
import { get_stores_with_supports, get_clients } from '../../../api';
import { FilterStoreRequest } from '../../../api/interfaces';
import { FilterStore } from '../../../interfaces/interfaces';

export const getStores = (lastKey?: string): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: true
        } as StoreIndexActions);

        const stores = await get_stores_with_supports({
            ...getState().storeIndex.filter,
            key: lastKey
        } as FilterStoreRequest);

        dispatch({
            type: lastKey ? APPEND_STORES : UPDATE_STORES,
            payload: stores
        } as StoreIndexActions);

        dispatch({
            type: UPDATE_LOADING,
            payload: false
        } as StoreIndexActions);
    }
}

export const updateFilterVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER_VISIBILITY,
            payload: value
        } as StoreIndexActions);
    }
}

export const updateAddModalVisibility = (value: boolean): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_ADD_MODAL_VISIBILITY,
            payload: value
        } as StoreIndexActions);
    }
}

export const updateFilter = (value: FilterStore): AppThunk => {
    return async dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: value
        } as StoreIndexActions);
    }
}

export const clearFilter = (): AppThunk => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTER,
            payload: null
        } as StoreIndexActions);
    }
}

export const getClients = (): AppThunk => {
    return async dispatch => {

        const clients = await get_clients();

        dispatch({
            type: UPDATE_CLIENTS,
            payload: clients.map(client => ({ value: client.id, name: client.name }))
        } as StoreIndexActions);
    }
}