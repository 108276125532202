import {
    UPDATE_CAMPAIGN,
    UPDATE_LOADING,
    UPDATE_SELECTED_SUPPORTS,
    UPDATE_STORES,
    UPDATE_SHOW_VISUAL_ADD_MODAL,
    CampaignDetailActions,
    UPDATE_ASSIGNED_VISUALS,
    UPDATE_VISUAL_FILTER_VISIBILITY,
    UPDATE_VISUAL_FILTER,
    CLEAR_VISUAL_FILTER,
    UPDATE_VISUALS,
    APPEND_VISUALS,
    UPDATE_LOADING_VISUALS,
    UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY,
    CLEAR_STORE_AND_SUPPORT_FILTER,
    UPDATE_STORE_AND_SUPPORT_FILTER,
    UPDATE_ASSIGNATION_TYPE,
    UPDATE_ASSIGNING_MODAL,
    UPDATE_UNASSIGNING_MODAL,
    UPDATE_ASSIGNATION_OK,
    UPDATE_ASSIGNATION_ERROR,
    UPDATE_PREPARED,
    UPDATE_ASSIGNED,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { AssignedVisuals, Campaign, Store, SelectOption , FilterVisual, Visual, FilterStoreAndSupport } from "../../../interfaces/interfaces";

export interface CampaignDetailState {
    campaign: Campaign|null,
    loading: boolean,
    selectedSupports: string[],
    assignedImages: AssignedVisuals,
    stores: Store[],
    showVisualAddModal: boolean,
    showVisualFilter: boolean,
    showStoreAndSupportFilter: boolean,
    visualFilter: FilterVisual,
    storeAndSupportFilter: FilterStoreAndSupport,
    prepared: SelectOption[],
    assigned: SelectOption[],
    visuals: Visual[],
    loadingVisuals: boolean,
    supportStates: SelectOption[],
    assignationType: string,
    showAssigningModal: boolean,
    showUnAssigningModal: boolean,
    assignationOk: boolean,
    assignationError: boolean,
}

const INITIAL_STATE: CampaignDetailState = {
    campaign: null,
    loading: false,
    selectedSupports: [],
    assignedImages: {},
    stores: [],
    showVisualAddModal: false,
    showVisualFilter: false,
    showStoreAndSupportFilter: false,
    visualFilter: new FilterVisual(),
    storeAndSupportFilter: new FilterStoreAndSupport(),
    prepared: [],
    assigned: [],
    visuals: [],
    loadingVisuals: false,
    supportStates: [],
    assignationType:'',
    showAssigningModal: false,
    showUnAssigningModal: false,
    assignationOk: false,
    assignationError: false,
};


const reducer: Reducer<CampaignDetailState, CampaignDetailActions> = (state = INITIAL_STATE, action: CampaignDetailActions) => {
    switch(action.type) {
        case UPDATE_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_SELECTED_SUPPORTS:
            return { ...state, selectedSupports: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_SHOW_VISUAL_ADD_MODAL:
            return { ...state, showVisualAddModal: action.payload };
        case UPDATE_ASSIGNED_VISUALS:
            return { ...state, assignedImages: action.payload };
        case UPDATE_VISUAL_FILTER_VISIBILITY:
            return { ...state, showVisualFilter: action.payload };
        case UPDATE_VISUAL_FILTER:
            return { ...state, visualFilter: action.payload }
        case CLEAR_VISUAL_FILTER:
            return { ...state, visualFilter: new FilterVisual() }
        case UPDATE_VISUALS:
            return { ...state, visuals: action.payload };
        case APPEND_VISUALS:
            return { ...state, visuals: [...state.visuals,...action.payload] };
        case UPDATE_LOADING_VISUALS:
            return { ...state, loadingVisuals: action.payload };
        case UPDATE_STORE_AND_SUPPORT_FILTER_VISIBILITY:
            return { ...state, showStoreAndSupportFilter: action.payload };
        case CLEAR_STORE_AND_SUPPORT_FILTER:
            return { ...state, storeAndSupportFilter: new FilterVisual()};
        case UPDATE_STORE_AND_SUPPORT_FILTER:
            return { ...state, storeAndSupportFilter: action.payload};
        case UPDATE_ASSIGNATION_TYPE:
            return { ...state, assignationType: action.payload };
        case UPDATE_ASSIGNING_MODAL:
            return { ...state, showAssigningModal: action.payload };
        case UPDATE_UNASSIGNING_MODAL:
            return { ...state, showUnAssigningModal: action.payload };
        case UPDATE_ASSIGNATION_OK:
            return { ...state, assignationOk: action.payload };
        case UPDATE_ASSIGNATION_ERROR:
            return { ...state, assignationError: action.payload };
        case UPDATE_PREPARED:
            return { ...state, prepared: action.payload };
        case UPDATE_ASSIGNED:
            return { ...state, assigned: action.payload };
        default:
            return state;
    }
}

export default reducer;