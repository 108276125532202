import {
    UPDATE_CLIENT,
    UPDATE_LOADING,
    UPDATE_SHOW_DETAIL,
    UPDATE_STORES,
    UPDATE_SUPPORTS,
    UPDATE_SHOW_ADD_STORE_MODAL,
    UPDATE_CAMPAIGNS_COUNT,
    UPDATE_CLIENTS,
    ClientDetailActions,
} from "./ActionTypes";
import { Reducer } from 'redux';
import { Client, Store, Support, SelectOption } from '../../../interfaces/interfaces';

export interface ClientDetailState {
    loading: boolean,
    client: Client|null,
    showDetail: boolean,
    stores: Store[],
    supports: Support[],
    showAddStoreModal: boolean,
    campaignsCount: number,
    clients: SelectOption[],
}

const INITIAL_STATE: ClientDetailState = {
    loading: false,
    client: null,
    showDetail: true,
    stores: [],
    supports: [],
    showAddStoreModal: false,
    campaignsCount: 0,
    clients: [],
};

const reducer: Reducer<ClientDetailState, ClientDetailActions> = (state = INITIAL_STATE, action: ClientDetailActions) => {
    switch(action.type) {
        case UPDATE_LOADING:
            return { ...state, loading: action.payload };
        case UPDATE_CLIENT:
            return { ...state, client: action.payload };
        case UPDATE_SHOW_DETAIL:
            return { ...state, showDetail: action.payload };
        case UPDATE_STORES:
            return { ...state, stores: action.payload };
        case UPDATE_SUPPORTS:
            return { ...state, supports: action.payload };
        case UPDATE_SHOW_ADD_STORE_MODAL:
            return { ...state, showAddStoreModal: action.payload };
        case UPDATE_CAMPAIGNS_COUNT:
            return { ...state, campaignsCount: action.payload };
        case UPDATE_CLIENTS:
            return { ...state, clients: action.payload };
        default:
            return state;
    }
}

export default reducer;