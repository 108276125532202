import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface BackArrowProps {
    onClick?: () => void
}

type BackArrowPropsSum = BackArrowProps & WithTranslation;

class BackArrowComponent extends React.Component<BackArrowPropsSum> {

    render () {
        return (
            <i onClick={this.props.onClick} className="icon icon-24-arrow-left section-header-back"></i>
        );
    };
}

const BackArrow = withTranslation()(BackArrowComponent);
export { BackArrow };
