import { OrderStatus } from "../interfaces/interfaces";
import { TFunction } from "i18next";

export const statusPillClass = (status: OrderStatus|undefined) => {
    switch (status) {
        case OrderStatus.CONFIRMED:
            return 'status-pill-mini--confirmed';
        case OrderStatus.PENDING:
            return 'status-pill-mini--pending';
        case OrderStatus.CANCELLED:
            return 'status-pill-mini--cancelled';
        case OrderStatus.DRAFT:
            return 'status-pill-mini--draft';
        case OrderStatus.LAUNCHED:
            return 'status-pill-mini--pending';
        default:
            return 'status-pill-mini--cancelled';
    }
}

export const statusName = (status: OrderStatus|undefined, t: TFunction) => {
    switch (status) {
        case OrderStatus.CONFIRMED:
            return t('order_status_confirmed');
        case OrderStatus.PENDING:
            return t('order_status_pending');
        case OrderStatus.CANCELLED:
            return t('order_status_cancelled');
        case OrderStatus.DRAFT:
            return t('order_status_draft');
        case OrderStatus.LAUNCHED:
            return t('order_status_pending');
        default:
            return 'NO STATUS';
    }
}