import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducers';
import { Breadcrumb, Spinner, SupportFilterAddSupport, SupportListOrder } from '../../../components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { get_client_name } from '../../../util/cookies';
import { getSections, getPriorities, getProductionLanguages, getStoreTypes, getSupportTypes, getCountries } from '../../../reducers/datatypes/DatatypesActions';
import { RouteComponentProps } from 'react-router-dom';
import {
	getStores,
	updateFilter,
	clearFilter,
	updateAssignedImages,
	getOneOrder,
} from './OrderNewOrderActions';
import { country_map, section_map } from '../../../util/select_maps';
import { filter_style } from '../../../util/filter';
import { calculate_prices, delete_visual_2_support } from '../../../api';

const mapStateToProps = (state: RootState) => {
	return {
		order_new_order_state: state.orderNewOrder,
		datatypes: state.datatypes
	}
}

const connector = connect(mapStateToProps, {
	getSections,
	getProductionLanguages,
	getPriorities,
	getStores,
	updateFilter,
	clearFilter,
	getStoreTypes,
	getSupportTypes,
	updateAssignedImages,
	getCountries,
	getOneOrder,
});

interface ParamTypes {
	id: string
}

type Props = RouteComponentProps<ParamTypes> & ConnectedProps<typeof connector> & WithTranslation;

interface State {
	show_filter: boolean,
}

class NewOrder extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			show_filter: false,
        };
	}

	componentDidMount() {
		this.props.getSections();
		this.props.getProductionLanguages();
		this.props.getPriorities();
		this.props.getStoreTypes();
		this.props.getSupportTypes();
		this.props.getStores();
		this.props.getCountries();
		this.props.getOneOrder(this.props.match.params.id);
	}

	renderStores() {
		const { t } = this.props;
		const { loading, stores, assignedImages } = this.props.order_new_order_state;
		if (loading) {
			return (
				<Spinner message={ t('loading_stores') } />
			);
		}
		let storeList = [ ...stores ].filter(store => (store.supports||[]).length > 0);
		return storeList.map((store) => (
			<div key={store.id} className="basic-card basic-card--32 store-card">
				<div className="store-card--view">
					<SupportListOrder
						id={store.id||''}
						name={store.name}
						collapsed={false}
						datatypes={this.props.datatypes}
						supports={(store.supports || [])}
						assignedImages={assignedImages}
						localOrderId={this.props.match.params.id}
						assignVisual={() => null} // TODO: action
						deleteVisual={(supportId: string) => {
							delete_visual_2_support(this.props.match.params.id, supportId).then(() => {
								this.props.getStores();
								this.props.getOneOrder(this.props.match.params.id);
							}).catch(console.error);
						}}
					/>
				</div>
			</div>
		));
	}

	renderFilter() {
		if (this.state.show_filter) {
			return (
				<SupportFilterAddSupport
					filter={this.props.order_new_order_state.filter}
					storeTypes={this.props.datatypes.store_types}
					supportTypes={this.props.datatypes.support_types}
					countries={this.props.datatypes.countries.map(country_map)}
					supportProductionLanguages={this.props.datatypes.production_languages}
					supportSections={this.props.datatypes.sections.map(section_map)}
					supportPriorities={this.props.datatypes.priorities}
					onClose={() => this.setState({ show_filter: !this.state.show_filter })}
					onClear={() => {
						this.props.clearFilter();
						this.props.getStores();
						this.setState({ show_filter: !this.state.show_filter })
					}}
					onSubmit={(filter) => {
						this.props.updateFilter(filter);
						this.props.getStores();
						this.setState({ show_filter: !this.state.show_filter });
					}}
				/>
			);
		}
	}

	goToBasket() {
		calculate_prices(this.props.match.params.id).then(() => {
			this.props.history.push(`/orders/basket/${this.props.match.params.id}`);
		});
	}

	render () {
		const { t } = this.props;
		return (
			<div className="base-content">
				{this.renderFilter()}
				<div className="content">
					<div className="container">
						<Breadcrumb items={[{ text: get_client_name(), url: '/' }, { text: t('orders'), url: '/orders' }, { text: t('new_order') }]}/>
						<div className="section-header">
							<div className="section-header-left">
								<h1 className="headline-1--regular section-header-title">{ t('new_order') }</h1>
							</div>
							<div className="section-header-right">
								<button className="icon icon-24-filter section-header-icon open-filter" style={filter_style(this.props.order_new_order_state.filter)} onClick={() => this.setState({ show_filter: !this.state.show_filter })}></button>
								<button className="btn--primary btn--l" onClick={() => this.goToBasket()}>{ t('basket') }</button>
							</div>
						</div>
						{this.renderStores()}
					</div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(connector(NewOrder));
