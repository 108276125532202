import React from 'react';
import { Store, StoreStatus } from '../interfaces/interfaces';
import { pluralize } from '../util/text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { InputCheckBox } from '.';
import { statusPillClass, statusName} from '../util/store';


export interface StoreCardWithCheckBoxProps {
    store: Store,
    checked: boolean,
    addStores: (ids: string[]) => void,
    removeStores: (ids: string[]) => void,
}

type StoreCardWithCheckBoxPropsSum = StoreCardWithCheckBoxProps & WithTranslation;

class StoreCardWithCheckBoxComponent extends React.Component<StoreCardWithCheckBoxPropsSum> {

    selectCheckBox(value: boolean) {
        if (value) {
            this.props.addStores([this.props.store.id || '']);
        } else {
            this.props.removeStores([this.props.store.id || '']);
        }
    }

    render () {
        const { customer_shop_no, id, name, status, tipo_tienda, supports, ciudad, pais } = this.props.store;
        const { t } = this.props;

        return (

            <div key={id} className="basic-card basic-card--32 store-card">
                <div className="campaing-card-left--user">
                    <h4 className="headline-3--regular">{ name }<span> #{ customer_shop_no }</span></h4>
                    <p>{ tipo_tienda }<span>{ pluralize(supports?.length || 0, [t('support'), t('supports')]) }</span><span> { ciudad } { pais ? `(${pais})` : ''}</span></p>
                </div>
                <div className="campaing-card-right" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <section className="shop_state" style={{ marginRight: '20px' }}>
                        <p className={'text-3--semibold status-pill ' + statusPillClass(status as StoreStatus)}>{statusName(status as StoreStatus, t)}</p>
                    </section>
                    <InputCheckBox
                        id=""
                        checked={this.props.checked}
                        checkBoxChange={(value: boolean) => this.selectCheckBox(value)}
                    />
                </div>
            </div>
        );
    };
}

const StoreCardWithCheckBox = withTranslation()(StoreCardWithCheckBoxComponent);
export { StoreCardWithCheckBox };
