import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux'
import { RootState } from '../../../reducers';
import { Campaign, Store, FilterStore } from '../../../interfaces/interfaces';

export const UPDATE_STORES = 'update_stores';
export const APPEND_STORES = 'append_stores';
export const UPDATE_CAMPAIGN = 'update_campaign';
export const UPDATE_LOADING = 'update_loading';
export const UPDATE_SHOW_FILTER = 'update_show_filter_add_support';
export const UPDATE_FILTER = 'update_filter_add_support';
export const UPDATE_SELECTED_SUPPORTS = 'update_selected_supports';
export const APPEND_SELECTED_SUPPORTS = 'append_selected_supports';
export const REMOVE_SELECTED_SUPPORTS = 'remove_selected_supports';
export const CLEAR_FILTER = 'clear_filter';

export interface UpdateStoresAction {
    type: typeof UPDATE_STORES,
    payload: Store[]
}

export interface AppendStoresAction {
    type: typeof APPEND_STORES,
    payload: Store[]
}

export interface UpdateCampaignAction {
    type: typeof UPDATE_CAMPAIGN,
    payload: Campaign
}

export interface UpdateLoadingAction {
    type: typeof UPDATE_LOADING,
    payload: boolean
}

export interface UpdateShowFilterAction {
    type: typeof UPDATE_SHOW_FILTER,
    payload: boolean
}

export interface UpdateFilter {
    type: typeof UPDATE_FILTER,
    payload: FilterStore
}

export interface UpdateSelectedSupportsAction {
    type: typeof UPDATE_SELECTED_SUPPORTS,
    payload: string[]
}

export interface AppendSelectedSupportsAction {
    type: typeof APPEND_SELECTED_SUPPORTS,
    payload: string[]
}

export interface RemoveSelectedSupportsAction {
    type: typeof REMOVE_SELECTED_SUPPORTS,
    payload: string[]
}

export interface ClearFilterAction {
    type: typeof CLEAR_FILTER,
    payload: null
}

export type CampaignAddSupportActions =
UpdateStoresAction |
AppendStoresAction |
UpdateCampaignAction |
UpdateLoadingAction |
UpdateShowFilterAction |
UpdateFilter |
UpdateSelectedSupportsAction |
AppendSelectedSupportsAction |
RemoveSelectedSupportsAction |
ClearFilterAction;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
