import {
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_ERROR,
    UPDATE_LOGIN_ERROR,
    SetingsActions
} from "./ActionTypes";
import { Reducer } from 'redux';

export interface ClientDetailState {
    user: string,
    userError: string,
    password: string,
    passwordError: string,
    loginError: string,
}

const INITIAL_STATE: ClientDetailState = {
    user: '',
    userError: '',
    password: '',
    passwordError: '',
    loginError: '',
};

const reducer: Reducer<ClientDetailState, SetingsActions> = (state = INITIAL_STATE, action: SetingsActions) => {
    switch(action.type) {
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_ERROR:
            return { ...state, userError: action.payload };
        case UPDATE_PASSWORD:
            return { ...state, password: action.payload };
        case UPDATE_PASSWORD_ERROR:
            return { ...state, passwordError: action.payload };
        case UPDATE_LOGIN_ERROR:
            return { ...state, loginError: action.payload };
        default:
            return state;
    }
}

export default reducer;