import { Country, Section, SelectOption } from "../../interfaces/interfaces";

export const UPDATE_LANGUAGES = 'update_languages';
export const UPDATE_COUNTRIES = 'update_countries';
export const UPDATE_STORE_TYPES = 'update_store_types';
export const UPDATE_PRODUCTION_LANGUAGES = 'update_production_languages';
export const UPDATE_SECTIONS = 'update_sections';
export const UPDATE_SUPPORT_TYPES = 'update_support_types';
export const UPDATE_TEXTIL_TYPES = 'update_textil_types';
export const UPDATE_COLORS = 'update_colors';
export const UPDATE_PRIORITIES = 'UPDATE_PRIORITIES';

type ActionType = typeof UPDATE_LANGUAGES | typeof UPDATE_STORE_TYPES | typeof UPDATE_SUPPORT_TYPES | typeof UPDATE_TEXTIL_TYPES | typeof UPDATE_COLORS | typeof UPDATE_PRIORITIES | typeof UPDATE_PRODUCTION_LANGUAGES;

export interface UpdateDatatypesAction {
    type: ActionType,
    payload: SelectOption[] 
}

export interface UpdateCountriesAction {
    type: typeof UPDATE_COUNTRIES,
    payload: Country[]
}

export interface UpdateSectionsAction {
    type: typeof UPDATE_SECTIONS,
    payload: Section[]
}

export type DatatypesActions = UpdateDatatypesAction | UpdateCountriesAction | UpdateSectionsAction;