import { Campaign, CampaignStatus } from "../interfaces/interfaces";

export const is_blocked = (campaign: Campaign | null) : boolean => {
    if (campaign?.status !== CampaignStatus.DRAFT) {
        return true;
    }
    return false;
}

export const status_pill_class = (status : string) : string => {
    if (status === CampaignStatus.LIVE) {
        return 'status-pill--live';
    }
    return 'status-pill--over';
}